import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		container: {
			background: skin.grayColor6,
			border: `1px solid ${skin.grayColor5}`
		},
		title: {
			color: skin.secondaryColor,
			textTransform: 'uppercase',
			...myTypography.body1,
			fontWeight: 600,
			fontSize: `17.2px`,
			'@media (min-width: 1440px)' : {
				fontSize: '19.35px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '21.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '25.8px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '34.4px'
			},
		},
		titleLine: {
			background: skin.secondaryColor
		},
		point: {
			...myTypography.body2,
			color: skin.grayColor1,
			fontSize: '12px',
			'::before': {
				content: '\'\'',
				position: 'absolute',
				width: '6px',
				height: '6px',
				background: skin.secondaryColor,
				left: '10px',
				top: '10px',
				'@media (min-width: 1440px)' : {
					width: '6.75px',
					height: '6.75px',
					left: '11.25px',
					top: '11.25px'
				},
				'@media (min-width: 1600px)' : {
					width: '7.5px',
					height: '7.5px',
					left: '12.5px',
					top: '12.5px'
				},
				'@media (min-width: 1920px)' : {
					width: '9px',
					height: '9px',
					left: '15px',
					top: '15px'
				},
				'@media (min-width: 2560px)' : {
					width: '12px',
					height: '12px',
					left: '20px',
					top: '20px'
				},
			},
			'@media (min-width: 1440px)' : {
				fontSize: '13.5px',
			},
			'@media (min-width: 1600px)' : {
				fontSize: '15px',
			},
			'@media (min-width: 1920px)' : {
				fontSize: '18px',
			},
			'@media (min-width: 2560px)' : {
				fontSize: '24px',
			},
		}
	});
}

export default getSkin;