import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		metricPopupContainer: {
			backgroundColor: skin.white
		},
		headerClose: {
			backgroundColor: skin.secondaryColor,
			':hover': {
				backgroundColor: hexToRgbA(skin.secondaryColor, 0.8)
			}
		},
		headerContent: {
			...myTypography.body1,
			fontSize: '20px',
			fontWeight: 600,
			'@media (min-width: 1440px)' : {
				fontSize: '22.5px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '25px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '30px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '40px'
			},
		},
		headerLine: {
			backgroundColor: hexToRgbA(skin.black, 0.1)
		},
		headerText: {
			...myTypography.body1,
			fontSize: '10px',
			textTransform: 'uppercase',
			'@media (min-width: 1440px)' : {
				fontSize: '11.5px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '12.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '15px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '20px'
			},
		}
	});
}

export default getSkin;