import React from 'react';
import styles from './converted.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const Converted = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect opacity="0.8" width="38" height="38" rx="6" fill="#1BE1D4"/>
			<path d="M25.48 13.449C25.3479 13.3516 25.1979 13.2813 25.0386 13.2419C24.8793 13.2025 24.7138 13.1949 24.5516 13.2195C24.3893 13.2441 24.2335 13.3005 24.0931 13.3853C23.9526 13.4702 23.8303 13.5819 23.733 13.714L17.809 21.754L14.042 18.74C13.7823 18.541 13.4549 18.4518 13.1301 18.4913C12.8053 18.5308 12.509 18.696 12.3045 18.9514C12.1001 19.2069 12.0039 19.5323 12.0365 19.8578C12.0691 20.1834 12.2279 20.4832 12.479 20.693L17.262 24.519C17.3942 24.6224 17.5456 24.6984 17.7075 24.7426C17.8694 24.7868 18.0384 24.7983 18.2048 24.7764C18.3712 24.7545 18.5315 24.6997 18.6764 24.6152C18.8214 24.5306 18.948 24.418 19.049 24.284L25.749 15.197C25.8462 15.0646 25.9163 14.9143 25.9554 14.7548C25.9945 14.5953 26.0017 14.4296 25.9768 14.2673C25.9518 14.1049 25.895 13.9491 25.8098 13.8087C25.7246 13.6683 25.6125 13.5461 25.48 13.449Z" fill="white"/>
			<path d="M19 7C16.6266 7 14.3066 7.70379 12.3332 9.02236C10.3598 10.3409 8.8217 12.2151 7.91345 14.4078C7.0052 16.6005 6.76756 19.0133 7.23058 21.3411C7.6936 23.6689 8.83649 25.8071 10.5147 27.4853C12.193 29.1635 14.3312 30.3064 16.6589 30.7694C18.9867 31.2324 21.3995 30.9948 23.5922 30.0866C25.7849 29.1783 27.6591 27.6402 28.9776 25.6668C30.2962 23.6935 31 21.3734 31 19C30.9966 15.8185 29.7312 12.7682 27.4815 10.5185C25.2318 8.26883 22.1815 7.00344 19 7V7ZM19 29C17.0222 29 15.0888 28.4135 13.4443 27.3147C11.7998 26.2159 10.5181 24.6541 9.76121 22.8268C9.00433 20.9996 8.8063 18.9889 9.19215 17.0491C9.578 15.1093 10.5304 13.3275 11.9289 11.9289C13.3275 10.5304 15.1093 9.578 17.0491 9.19215C18.9889 8.8063 20.9996 9.00433 22.8268 9.7612C24.6541 10.5181 26.2159 11.7998 27.3147 13.4443C28.4135 15.0888 29 17.0222 29 19C28.9971 21.6513 27.9426 24.1931 26.0679 26.0679C24.1931 27.9426 21.6513 28.9971 19 29Z" fill="white"/>
			</svg>
		</div>
	);
}

export default applyWrappers(Converted, styles);