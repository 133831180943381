import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		startSimPopupContainer: {
			border: `2px solid ${skin.secondaryColor}`,
			backgroundColor: skin.white
		},
		advisorName: {
			...myTypography.body1,
			color: skin.grayColor2
		},
		advisorDesc: {
			...myTypography.body1,
			color: skin.grayColor1
		},
		bulletContainer: {
			...myTypography.body2,
			color: skin.grayColor1
		},
		startButton: {
			backgroundColor: skin.secondaryColor,
			color: skin.white,
			...myTypography.body1,
			fontWeight: 600,
			':hover': {
				boxShadow: `0px 0px 4px ${hexToRgbA(skin.black, 0.5)}`
			}
		},
		timerText: {
			...myTypography.body2,
			color: skin.grayColor1,
			fontStyle: 'italic'
		}
	});
}

export default getSkin;