import React from 'react';
import WalkthroughContent from 'commonComponents/walkthroughContent';

const gameStartSteps = () => {
	return [
		//Step: -1
		{
			target: '#actor-container-you',
			content: (
				<WalkthroughContent contentKey="GAME_START_YOU" />
			),
			disableBeacon: true,
			disableOverlayClose: true,
			hideFooter: false,
			spotlightClicks: false,
			placement: "right",
			styles: {
				options: {
					zIndex: 10000
				}
			}
		},
		//Step: 0
		{
			target: '#actors-container',
			content: (
				<WalkthroughContent contentKey="GAME_START_ECOSYSTEM" />
			),
			disableBeacon: true,
			disableOverlayClose: true,
			hideFooter: false,
			spotlightClicks: false,
			spotlightPadding: 0,
			placement: "right",
			styles: {
				options: {
					zIndex: 10000
				}
			}
		},
		//Step: 1
		{
			target: '#organisation-container',
			content: (
				<WalkthroughContent contentKey="GAME_START_ORGANISATION" />
			),
			disableBeacon: true,
			disableOverlayClose: true,
			hideFooter: false,
			spotlightClicks: false,
			placement: "right",
			styles: {
				options: {
					zIndex: 10000
				}
			}
		},
		//Step: 2
		{
			target: '#clients-container',
			content: (
				<WalkthroughContent contentKey="GAME_START_CLIENTS" />
			),
			textAlign: "center",
			disableBeacon: true,
			disableOverlayClose: true,
			hideFooter: false,
			spotlightClicks: false,
			placement: "bottom",
			styles: {
				options: {
					zIndex: 10000
				}
			}
		},
		//Step: 3
		{
			target: '#partners-container',
			content: (
				<WalkthroughContent contentKey="GAME_START_PARTNERS" />
			),
			textAlign: "center",
			disableBeacon: true,
			disableOverlayClose: true,
			hideFooter: false,
			spotlightClicks: false,
			placement: "left",
			styles: {
				options: {
					zIndex: 10000
				}
			}
		},
		//Step: 4
		{
			target: '#actions-container',
			content: (
				<WalkthroughContent contentKey="GAME_START_ACTIONS" />
			),
			textAlign: "center",
			disableBeacon: true,
			disableOverlayClose: true,
			hideFooter: false,
			spotlightClicks: false,
			placement: "right",
			styles: {
				options: {
					zIndex: 10000
				}
			}
		},
		// Step: 5
		{
			target: '#initialAction',
			content: (
				<WalkthroughContent contentKey="GAME_INITIAL_ACTION_TAKE" />
			),
			disableBeacon: true,
			disableOverlayClose: true,
			hideFooter: true,
			spotlightClicks: true,
			spotlightPadding: -1,
			placement: "right",
			styles: {
				options: {
					zIndex: 10000
				}
			}
		}
	];
}

export default gameStartSteps;
