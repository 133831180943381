import React, { useState, useEffect } from 'react';
import styles from './actionPopup.module.sass';
import { useSelector, useDispatch } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Day from 'svgComponents/day';
import ActionActor from 'commonComponents/actionActor';
import { addElementToArray, removeElementFromArray, checkInRange, shuffleArray } from 'util/functions';
import ActionOptions from 'commonComponents/actionOptions';
import { takeAction } from 'actions/apiActions/action';
import analyticsUtil from 'util/analyticsUtil';
import {updateReduxBackend} from 'actions/apiActions/updateReduxBackend'

const ActionPopup = (props) => {

	const sccMetrics = ["strategy", "capability", "culture"];

	const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			actions: state.actions,
			user: state.user,
			actors: state.actors,
			userMetrics: state.userMetrics,
			sevenDaysRestrictedActionsList: state.restrictions.sevenDaysRestrictedActionsList,
			tenDaysRestrictedActionsList: state.restrictions.tenDaysRestrictedActionsList,
			permanentDisabledActionList: state.restrictions.permanentDisabledActionList
		};
	});

	const onActionPopupPopupClick = (e) => {
		e.stopPropagation();
	}
	const [actionRestricted7, setActionRestricted7] = useState(false);
	const [actionRestricted10, setActionRestricted10] = useState(false);
	const [actionDisabled, setActionDisabled] = useState(false);
	const [enableAction, setEnableAction] = useState(false);
	const [enableSubmit, setEnableSubmit] = useState(true);
	const [enableOptions, setEnableOptions] = useState(false);
	const [optionId, setOptionId] = useState(null);
	const [poolId, setPoolId] = useState(null);

	const actionDetails = myProps.actions.actionsList.find((eachAction) => {
		return eachAction.id === myProps.uiState.selectedAction;
	});

	const actionOptionDetails = myProps.actions.actionOptionsList.filter((eachOption) => {
		return eachOption.actionId == myProps.uiState.selectedAction && eachOption.isVisible;
	});

	useEffect( () => {
		if (myProps.sevenDaysRestrictedActionsList.includes(myProps.uiState.selectedAction)){
			setActionRestricted7(true);
		}
		if (myProps.tenDaysRestrictedActionsList.includes(myProps.uiState.selectedAction)){
			setActionRestricted10(true);
		}
		if (myProps.permanentDisabledActionList.includes(myProps.uiState.selectedAction)){
			setActionDisabled(true);
		}
	},	[myProps.sevenDaysRestrictedActionsList, myProps.tenDaysRestrictedActionsList]
	);

	useEffect(() => {
		const uiState = myProps.uiState;

		const actorsRequiredInRange = checkInRange(uiState.actionSelectedActors.length, uiState.minActorsRequired, uiState.maxActorsRequired);

		if (uiState.isActorSelectionRequired) {

			if (actorsRequiredInRange && !enableOptions) {
				setEnableOptions(true);
			} else if (!actorsRequiredInRange && enableOptions) {
				setEnableOptions(false);
				setOptionId(null);
			}

			if (optionId !== null && actorsRequiredInRange && !enableAction && (!actionRestricted7 || !actionRestricted10 || !actionDisabled ) ) {
				setEnableAction(true);
			} else if ((!actorsRequiredInRange || optionId === null) && enableAction) {
				setEnableAction(false);
			}
		} else {
			if (!enableOptions) {
				setEnableOptions(true);
			}

			if (optionId !== null && !enableAction && !actionRestricted7 && !actionRestricted10 && !actionDisabled) {
				setEnableAction(true);
			}
		}

		if (!enableOptions && props.popupIndex > 50) {
			props.setPopupIndex(50);
		}

		if (actionOptionDetails.length === 1 && (optionId === null || poolId === null)) {
			setOptionId(actionOptionDetails[0].id);
			setPoolId(actionOptionDetails[0].actionOptionPool[0].poolId);
		}

	}, [myProps.uiState, enableOptions, enableAction, optionId, props.popupIndex]);

	const removeActor = (id) => {
		let actorList = myProps.uiState.actionSelectedActors;
		actorList = removeElementFromArray(actorList, id);

		analyticsUtil.track('ACTOR_DESELECTED', { actionId: myProps.uiState.selectedAction, actorId: id });

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				actionSelectedActors: actorList
			}
		});
	}

	const closePopup = () => {
		analyticsUtil.track('POPUP_CLOSED', { popup: myProps.uiState.overlayComponent, type: myProps.uiState.overlayComponentType });
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: '',
				actionSelectedActors: [],
				selectedAction: null,
				highlightActors: false,
				actorSelectionType: '',
				isActorSelectionRequired: false,
				minActorsRequired: 0,
				maxActorsRequired: 0,
				actionDayCost: 0
			}
		});
	}

	const myStyles = getSkin(props.skinGuide);
	const dispatch = useDispatch();

	const getActorDetails = (id) => {
		const actorDetails = myProps.actors.actorsList.find((eachActor) => {
			return eachActor.id === id;
		});
		return actorDetails;
	}

	const renderActorsContainer = () => {
		if (actionRestricted7 || actionRestricted10 || actionDisabled){
			return(
				<div className={css(myStyles.restrictedActionMessageContainer)} styleName="restricted-action-message-container">
					<div className={css(myStyles.image)} styleName="image">
						<img src={props.getImage('IMG_ACTION_RESTRICTED')} alt={`action-restricted`} width="100%" />
					</div>
					<div className={css(myStyles.description)} styleName="description">
						{
							actionDisabled 
							? props.getLabel('label_action_1_response_rejection_limit_desc')
							: actionRestricted7 ? props.getLabel('label_action_restricted_for_twenty_days')
							: props.getLabel('label_action_restricted_for_thirty_days')
						}
					</div>
				</div>
			)
		}
		let i = 0, j = 0;
		const selectedActors = myProps.uiState.actionSelectedActors;
		let actorDivs = [];
		for (i = 0; i < myProps.uiState.maxActorsRequired; i++) {
			if (j < selectedActors.length) {
				actorDivs.push(
					<ActionActor
						isEmptyActor={false}
						id={selectedActors[j]}
						onDeselect={removeActor}
						actorDetails={getActorDetails(selectedActors[j])}
					/>
				);
				j++;
			} else {
				actorDivs.push(
					<ActionActor
						isEmptyActor={true}
					/>
				)
			}
		}
		return (
			<div className={css(myStyles.actorsContainer)} styleName="actors-container">
				{actorDivs}
			</div>
		);
	}

	const renderOptions = () => {
		if (actionRestricted7 || actionRestricted10 || actionDisabled){
			return(
				<div className={css(myStyles.restrictedActionMessageContainer)} styleName="restricted-action-message-container">
					<div className={css(myStyles.image)} styleName="image">
						<img src={props.getImage('IMG_ACTION_RESTRICTED')} alt={`action-restricted`} width="100%" />
					</div>
					<div className={css(myStyles.description)} styleName="description">
						{actionDisabled? props.getLabel('label_action_1_response_rejection_limit_desc') : actionRestricted7 ? props.getLabel('label_action_restricted_for_twenty_days'):props.getLabel('label_action_restricted_for_thirty_days')}
					</div>
				</div>
			)
		}
		if (actionOptionDetails.length === 1) {
			return null;
		}
		return (
			<div className={css(myStyles.optionContainer)} styleName="option-container">
				<ActionOptions
					setOptionId={setOptionId}
					setPoolId={setPoolId}
					options={actionOptionDetails}
					optionId={optionId}
					poolId={poolId}
					setPopupIndex={props.setPopupIndex}
					uiDisplayType={actionOptionDetails[0].uiDisplayType}
				/>
			</div>
		);
	}
	const userMetrics = myProps.userMetrics;
	const getAverageAdoption = () => {
		let sum = 0;
		let count = 0;
		sccMetrics.map((eachMetric, index) => {
			const currentMetricId = userMetrics.userMetricIds[eachMetric];
			const userMetricDetails = userMetrics.userMetricsObj[currentMetricId];
			if (userMetricDetails.value) {
				sum += userMetricDetails.value;
				count++;
			}
		});
		if (count === 0) {
			return 0;
		}
		return (sum / count).toFixed(1);
    }

	const submitAction = () => {
		if (!enableAction) {
			return;
		}
		if(!enableSubmit){
			return;
		}
		setEnableSubmit(false);
		const optionDetails = myProps.actions.actionOptionsList.find((eachOption) => {
			return optionId == eachOption.id;
		})
		const payload = {
			actionOptionId: optionId,
			poolId: poolId,
			actorIds: myProps.uiState.actionSelectedActors,
			route: optionDetails.route,
			currentDay: myProps.user.currentDay,
			currentAverageAdoption: getAverageAdoption()
		};
		// if([7].includes(myProps.uiState.selectedAction)){
		// 	dispatch({
		// 		type:'UPDATE_DISABLED_ACTORS_NO_CONDITION',
		// 		payload:{
		// 			actorsSelected: myProps.uiState.actionSelectedActors,
		// 			actionId: myProps.uiState.selectedAction
		// 		}
		// 	});
		// 	dispatch(updateReduxBackend("DISABLE_ACTION_ACTOR"));
		// }
		dispatch(takeAction(payload, closePopup));
		//Suffle actionOptions after any action is taken
		setTimeout(() => {
			shuffleArray(myProps.actions.actionOptionsList);
		},1000);
	}

	const renderSurveyQuestions = () => {
		if (actionRestricted7 || actionRestricted10 || actionDisabled){
			return;
		}
		const questionList = actionOptionDetails[0].surveyJson.data.list;
		const questionListDiv = questionList.map((eachQuestion, index) => {
			return (
				<div className={css(myStyles.eachSqQuestion)} styleName="each-sq-question">
					{index+1}. {props.getLabel(eachQuestion)}
				</div>
			);
		});
		if (questionListDiv.length === 0) {
			return null;
		}
		return (
			<div className={css(myStyles.sqContainer)} styleName="sq-container">
				<div className={css(myStyles.sqHeading)} styleName="sq-heading">{props.getLabel('label_survey_questions')}</div>
				<div styleName="sq-questions">
					{questionListDiv}
				</div>
			</div>
		);
	}

	return (
		<div className={css(myStyles.container)} styleName="container" onClick={onActionPopupPopupClick}>
			<div className={css(myStyles.header)} styleName="header">
				<div className={css(myStyles.image)} styleName="image">
					<img src={props.getImage(actionDetails.image)} alt={`action-img-${actionDetails.id}`} width="100%" />
				</div>
				<div className={css(myStyles.text)} styleName="text">
					{props.getLabel(actionDetails.name)}
				</div>
			</div>
			<div className={css(myStyles.description)} styleName="description">
				{props.getLabel(actionDetails.description)}
			</div>
			<div styleName="day-container">
				<div styleName="day-image">
					<Day />
				</div>
				<div className={css(myStyles.dayText)} styleName="day-text">
					{myProps.uiState.actionDayCost} {props.getLabel('label_days')}
				</div>
			</div>
			{
				actionOptionDetails[0] && actionOptionDetails[0].isSurveyAvailable
					? renderSurveyQuestions()
					: null
			}
			{
				myProps.uiState.isActorSelectionRequired
					? renderActorsContainer()
					: null
			}
			{
				enableOptions
					? renderOptions()
					: null
			}
			<div className={css(myStyles.footer)} styleName="footer">
				<div onClick={closePopup} className={css(myStyles.cancel)} styleName="cancel">{props.getLabel('label_cancel')}</div>
				<div className={enableAction ? css(myStyles.proceed) : css(myStyles.proceedDisable)} styleName="proceed" onClick={submitAction}>{props.getLabel('label_proceed')}</div>
			</div>
		</div>
	);
}

export default applyWrappers(ActionPopup, styles);
