import React from 'react';
import styles from './delayIndicator.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const DelayIndicator = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 28 45" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.3226 44.0189L0.729837 40.2048C0.298268 40.0837 0 39.6902 0 39.242V0H28V39.2516C28 39.6953 27.7076 40.0859 27.2819 40.211L14.3226 44.0189Z" fill="#14324B"/>
				<circle cx="14" cy="27.5" r="8.5" fill="#FDAD28"/>
				<path d="M13.6602 24.78V28.18L17.7402 31.58" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
		</div>
	);
}

export default applyWrappers(DelayIndicator, styles);