import React, { useState, useEffect, useRef } from 'react';	
import styles from './metricBar.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import { useSelector } from 'react-redux';
import applyWrappers from 'wrappers/ComponentWrapper';

const MetricBar = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const [animate, setAnimate] = useState(false);
	const changeRef = useRef(null);
	const sccMetrics = ["strategy", "capability", "culture"];
	const myProps = useSelector((state) => {
		return {
			storylineDefaults: state.storyline.storylineDefaults
		};
	});

	useEffect(() => {
		if (props.showAnimation) {
			setAnimate(true);
			setTimeout(() => {
				setAnimate(false);
			}, 3000);
		}
	}, [props.value, props.diff]);

	const progressClass = () => {
		let initiative = props.initiative;
		if(initiative) initiative = initiative.toLowerCase()
		switch (initiative) {
			case 'strategy':
			case 'label_strategy':{
				if (props.value <= 44) {
					return css(myStyles.lowProgress);
				} else if (props.value > 44 && props.value < myProps.storylineDefaults[`strategyIndexTarget`]) {
					return css(myStyles.medProgress);
				} else {
					return css(myStyles.highProgress);
				}
				break;
			}
			case 'capability':
			case 'label_capability':{
				if (props.value <= 34) {
					return css(myStyles.lowProgress);
				} else if (props.value > 34 && props.value < myProps.storylineDefaults[`capabilityIndexTarget`]) {
					return css(myStyles.medProgress);
				} else {
					return css(myStyles.highProgress);
				}
				break;
			}
			case 'culture':
			case 'label_culture':{
				if (props.value <= 29) {
					return css(myStyles.lowProgress);
				} else if (props.value > 29 && props.value < myProps.storylineDefaults[`cultureIndexTarget`]) {
					return css(myStyles.medProgress);
				} else {
					return css(myStyles.highProgress);
				}
				break;
			}
			default:{
				if (props.value <= 33) {
					return css(myStyles.lowProgress);
				} else if (props.value > 33 && props.value <= 66) {
					return css(myStyles.medProgress);
				} else {
					return css(myStyles.highProgress);
				}
				break;
			}
		}
	}

	const renderChange = () => {
		if (!props.showAnimation || !animate) {
			return null;
		}

		if (props.diff > 0) {
			return (
				<div ref={changeRef} className={css(myStyles.changePositive)} styleName="change-animation-positive change">
					{`+${props.diff}`}
				</div>
			);
		} else if (props.diff < 0) {
			return (
				<div ref={changeRef} className={css(myStyles.changeNegative)} styleName="change-animation-negative change">
					{`${props.diff}`}
				</div>
			);
		}
		return null;

	}

	return (
		<div className={css(myStyles.container)} styleName="container">
			<div style={{ height: `${Math.round(props.value)}%` }} className={progressClass()} styleName="progress"></div>
			{props.target &&
				<div style={{position: 'absolute', bottom: `${props.target}%`, width: '100%', height: '2%', backgroundColor: '#14324B' }}></div>
			}
			{props.showTarget && props.target &&
				<div styleName = "target-text" style={
					{position: 'absolute', bottom: `${props.target-7}%`, left: '100%' , width: '100%', color: '#14324B'}
				}>
					{props.target}%
				</div>
			}
			{renderChange()}
		</div>
	);
}

export default applyWrappers(MetricBar, styles);