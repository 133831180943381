import React from 'react';
import styles from './metricDown.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const MetricDown = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 8 8" fill="none">
				<path d="M4 8C3.20888 8 2.43552 7.7654 1.77772 7.32588C1.11992 6.88635 0.607234 6.26164 0.304484 5.53073C0.00173314 4.79983 -0.0774802 3.99556 0.0768607 3.21964C0.231201 2.44372 0.612164 1.73098 1.17157 1.17157C1.73098 0.612164 2.44372 0.231202 3.21964 0.0768609C3.99556 -0.0774794 4.79983 0.0017333 5.53073 0.304484C6.26164 0.607234 6.88635 1.11992 7.32588 1.77772C7.7654 2.43552 8 3.20888 8 4C7.99885 5.06051 7.57706 6.07726 6.82716 6.82716C6.07726 7.57706 5.06051 7.99885 4 8V8ZM6.18767 3.765L6.08767 3.665C6.02381 3.60249 5.93855 3.56663 5.84921 3.56471C5.75987 3.56279 5.67315 3.59495 5.60667 3.65467L4.745 4.44667C4.61167 4.57067 4.49933 4.522 4.49933 4.33867V2.177C4.49933 2.0886 4.46422 2.00381 4.4017 1.9413C4.33919 1.87879 4.25441 1.84367 4.166 1.84367H3.83267C3.74426 1.84367 3.65948 1.87879 3.59697 1.9413C3.53445 2.00381 3.49933 2.0886 3.49933 2.177V4.34367C3.49933 4.527 3.38733 4.577 3.25067 4.455L2.393 3.68833C2.32567 3.62983 2.23891 3.59859 2.14974 3.60075C2.06057 3.6029 1.97542 3.6383 1.911 3.7L1.811 3.8C1.78008 3.83067 1.75565 3.86726 1.73917 3.90758C1.72269 3.9479 1.7145 3.99113 1.71509 4.03468C1.71569 4.07823 1.72504 4.12122 1.74261 4.16108C1.76017 4.20093 1.78559 4.23685 1.81733 4.26667L3.758 6.105C3.82367 6.16575 3.90994 6.19932 3.9994 6.19895C4.08885 6.19858 4.17484 6.16429 4.24 6.103L6.18167 4.233C6.21323 4.20292 6.23846 4.16684 6.25588 4.12687C6.2733 4.0869 6.28256 4.04386 6.28312 4.00026C6.28368 3.95667 6.27552 3.9134 6.25913 3.873C6.24274 3.8326 6.21844 3.79588 6.18767 3.765Z" fill="#FD5382" />
			</svg>
		</div>
	);
}

export default applyWrappers(MetricDown, styles);