export default {
	SET_INIT_DATA: 'SET_INIT_DATA',
	SET_LEADERBOARD_DATA: 'SET_LEADERBOARD_DATA',
	SET_ACTION_DATA: 'SET_ACTION_DATA',
	SET_PENDING_ACTION_DATA: 'SET_PENDING_ACTION_DATA',

	ADD_RECOGNITION_BADGE: 'ADD_RECOGNITION_BADGE',
	ADD_NETWORT_BADGE: 'ADD_NETWORT_BADGE',
	ADD_REVEALED_NETWORK: 'ADD_REVEALED_NETWORK',


	// Tutorial actions
	START_TUTORIAL: 'START_TUTORIAL',
	PAUSE_TUTORIAL: 'PAUSE_TUTORIAL',
	END_TUTORIAL: 'END_TUTORIAL',
	ADD_TUTORIAL_STEPS: 'ADD_TUTORIAL_STEPS',
	UPDATE_TUTORIAL_STEP: 'UPDATE_TUTORIAL_STEP',
}