import actionConsts from 'constants/actions';
import _ from 'lodash';

const initialState = {
	metricsObj: {},
	animationValue: false
};

const formatMetrics = (metrics) => {
	const metricObj = _.groupBy(metrics, 'id');
	Object.keys(metricObj).map((eachMetricId) => {
		metricObj[eachMetricId] = metricObj[eachMetricId][0];
		return 1;
	});
	return metricObj;
}

const metrics = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.SET_INIT_DATA:
			return {
				...state,
				metricsObj: formatMetrics(action.payload.metrics)
			};
		case 'ACTION_RESPONSE_POPUP_CLOSE':
			return {
				...state, 
				animationValue: true
			}
		default:
			return state;
	}
};

export default metrics;