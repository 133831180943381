import actionConstants from "constants/actions";

const initialState = {
    userMetricUpdated: []
};

const removeTopPendingAction = (pendingActionResponse) => {
    pendingActionResponse.shift();
}

const userActiveActionResponse = (state = initialState, action = {}) => {
	switch (action.type) {
        case 'SET_ACTIVE_ACTION_RESPONSE':
            return {
                ...state,
                ...action.payload
            }
        case 'POP_PENDING_ACTIONS_STACK' :
            removeTopPendingAction(state.pendingActionResponse)
            return {
                ...state
            }
        case 'POP_MENTOR_TIP_STACK':
            return {
                ...state,
                mentorTip: {}
            }
        case 'ACTION_RESPONSE_POPUP_CLOSE':
            return {
                ...state,
                userMetricUpdated: action.payload.userMetricsForThisAction
            };
        case 'PENDING_ACTION_POPUP_CLOSE':
        return {
            ...state,
            userMetricUpdated: action.payload.userMetricsForThisAction
        };
        case 'ADD_STATIC_EVENT_METRICS':
        return {
            ...state,
            userMetricUpdated: action.payload.userMetricsForThisAction
        };
		default:
			return state;
	}
};

export default userActiveActionResponse;