const typography = (skin) => {
	return {
		title: {
			fontFamily: skin.fontFamily.titleFamily,
			fontSize: '20px',
			fontWeight: 700,
			fontStyle: 'normal',
			fontStretch: 'normal',
			lineHeight: 1.4,
			letterSpacing: 'normal',
			'@media (min-width: 1440px)' : {
				fontSize: '22.5px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '25px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '30px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '40px'
			},
		},
		subtitle: {
			fontFamily: skin.fontFamily.titleFamily,
			fontSize: '16px',
			fontWeight: 400,
			fontStyle: 'normal',
			fontStretch: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
			'@media (min-width: 1440px)' : {
				fontSize: '18px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '20px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '24px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '32px'
			},
		},
		body1: {
			fontFamily: skin.fontFamily.bodyFamily,
			fontSize: '14px',
			fontWeight: 400,
			fontStyle: 'normal',
			fontStretch: 'normal',
			letterSpacing: 'normal',
			'@media (min-width: 1440px)' : {
				fontSize: '15.75px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '17.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '21px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '28px'
			},
		},
		body2: {
			fontFamily: skin.fontFamily.bodyFamily,
			fontSize: '12px',
			fontWeight: 400,
			fontStyle: 'normal',
			fontStretch: 'normal',
			letterSpacing: 'normal',
			'@media (min-width: 1440px)' : {
				fontSize: '13.5px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '15px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '18px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '24px'
			},
		},
		caption: {
			fontFamily: skin.fontFamily.bodyFamily,
			fontSize: '12px',
			fontWeight: 400,
			fontStyle: 'normal',
			fontStretch: 'normal',
			lineHeight: 1.17,
			letterSpacing: 'normal',
			fontStyle: 'italic',
			'@media (min-width: 1440px)' : {
				fontSize: '13.5px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '15px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '18px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '24px'
			},
		},
		timer: {
			fontFamily: skin.fontFamily.timerFamily,
			fontSize: '28px',
			fontWeight: 400,
			fontStyle: 'normal',
			fontStretch: 'normal',
			letterSpacing: 'normal',
			'@media (min-width: 1440px)' : {
				fontSize: '31.5px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '35px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '42px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '56px'
			},
		},
		number: {
			fontFamily: skin.fontFamily.numberFamily,
			fontSize: '28px',
			fontWeight: 400,
			fontStyle: 'normal',
			fontStretch: 'normal',
			letterSpacing: 'normal',
			'@media (min-width: 1440px)' : {
				fontSize: '31.5px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '35px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '42px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '56px'
			},
		},
		bodyCondensed: {
			fontFamily: skin.fontFamily.bodyCondensedFamily,
			fontSize: '14px',
			fontWeight: 700,
			fontStyle: 'normal',
			fontStretch: 'normal',
			letterSpacing: 'normal',
			'@media (min-width: 1440px)' : {
				fontSize: '15.75px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '17.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '21px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '28px'
			},
		}
	}
};

export default typography;