import React from 'react';
import styles from './rightArrowFlat.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const RightArrowFlat = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M10 1L14 6M14 6L10 11M14 6H0.5" stroke="#C2CAD0" stroke-linecap="round"/>
			</svg>
		</div>
	);
}

export default applyWrappers(RightArrowFlat, styles);