import actionConstants from "constants/actions";

const initialState = {
    name: '',
    isSentryInitialized: false,
    isFreshdeskInitialized: false,
    isKfeedbackInitialized: false,
    isKfeedbackSubmitted: false,
	isFirebaseInitialized: false,
	isAnalyticsInitialized: false,
    userDataFetched: false,
    isFeedbackSubmitInProgress: false,
    runTimer: false,
    isTimerCompleted: false,
    groupDetails: {},
    returnType: 'normal',
    timerValue: null,
    walkthroughCompleted: false,
    isGameEndSoundPlayed: false,
    finalReportSeen: false
};

const user = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConstants.SET_INIT_DATA:
            let finalReportSeen = action.payload.userDetails.isGameCompleted ? true : false;
            return {
                ...state,
                ...action.payload.userDetails,
                groupDetails: action.payload.group,
                userDataFetched: true,
                finalReportSeen
            };
        case 'ACTION_RESPONSE_POPUP_CLOSE':
            return {
                ...state,
                ...action.payload.userState
            };
        case 'UPDATE_USER':
            return {
                ...state,
                ...action.payload
            };
        case 'UPDATE_USER_REPORT_DATA':
            return {
                ...state,
                ...action.payload
            }
        case 'UPDATE_EMAIL_RESPONSE':
            return {
                ...state,
                ...action.payload
            }
        case 'ADD_ACTIVE_EVENT_RESPONSE':
            return {
                ...state, 
                currentDay: action.payload.day || state.currentDay
            }
        case 'SET_FINAL_MONTH_REPORT_SEEN':
            return{
                ...state,
                finalReportSeen: true
            }
        case 'UPDATE_GAME_END_SOUND':
            return {
                ...state, 
                isGameEndSoundPlayed: true
            }
        default:
            return state;
    }
};

export default user;
