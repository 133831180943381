import React, { useState, useEffect } from 'react';
import styles from './buUnlockPopup.module.sass';
import { useSelector , useDispatch } from 'react-redux';
import getSkin from './skin.js';
import _ from 'lodash';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import UnlockIcon from 'svgComponents/unlockIcon'
import Confetti from 'svgComponents/confetti'
import { removeActorPopupPayload } from 'util/functions';

const BUUnlockPopup = (props) => {

	const onBUUnlockPopupClick = (e) => {
		e.stopPropagation();
    }
    
    const dispatch = useDispatch();
    const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			actions: state.actions,
			user: state.user,
			actors: state.actors,
            userActiveActionResponse: state.userActiveActionResponse,
            actorInfo: state.actorInfo,
            userMetrics: state.userMetrics,
			storylineDefaults: state.storyline.storylineDefaults
		};
    });

	const closePopup = () => {
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
        });
        
        if(myProps.userActiveActionResponse.pendingActionResponse.length > 0){
            setTimeout(() => {
                dispatch(removeActorPopupPayload());
                dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                        showOverlay: true,
                        overlayComponentType: 'BOTTOM',
                        highlightDesktopHeader: false,
                        overlayComponent: 'PENDING_ACTION_RESPONSE_POPUP'
                    }
                });
            },2000)
        }
        else if(!_.isEmpty(myProps.userActiveActionResponse.event)){
            dispatch({
                type: 'ADD_DETAILS_FOR_MONTHLY_REPORT',
                payload: {
                    isAnyActionPresent: false
                }
            });
            setTimeout(() => {
                		dispatch({
                			type: 'ADD_ACTIVE_EVENT',
                			payload: myProps.userActiveActionResponse.event
                		});
                		dispatch(removeActorPopupPayload());
                		dispatch({
                			type: 'UPDATE_UI_STATE',
                			payload: {
                				showOverlay: true,
                				overlayComponentType: 'BOTTOM',
                				highlightDesktopHeader: false,
                				overlayComponent: 'EVENT_POPUP'
                			}
                		});
            }, 2000);
        }
        else{
            dispatch({
                type: 'ADD_DETAILS_FOR_MONTHLY_REPORT',
                payload: {
                    isAnyActionPresent: false,
                    isAnyEventPresent: false
                }
            });
        }

	}

	const myStyles = getSkin(props.skinGuide);

	return (
		<div className={css(myStyles.Container)} styleName="container" onClick={onBUUnlockPopupClick}>
                <div styleName="close-container" onClick={closePopup}>
                    <Close strokeWidth={1.6} />
                </div>
                
                <div styleName='unlock-container'>
                    <UnlockIcon />
                </div>

                <div styleName='confetti-container'>
                    <Confetti />
                </div>

                <div styleName='content-container'>
                    <div styleName='title'>
                        {props.getLabel('label_bu_unlocked_title')}
                    </div>

                    <div styleName='description'>
                        {props.getLabel('label_bu_unlocked_desc')}
                    </div>

                    <div styleName='button' onClick={closePopup}>
                        <div styleName='button-text'>
                            {props.getLabel('label_bu_unlocked_cta')}
                        </div>
                    </div>
                </div>
		</div>
	);
}

export default applyWrappers(BUUnlockPopup, styles);