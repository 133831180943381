import kfetch from 'util/kfetch';
import urls from 'constants/urls';
import actionConstans from 'constants/actions';
import { checkIfPresent } from 'util/functions';

export const gameStarted = (payload, callback) => (dispatch) => {

	kfetch(urls.GAME_STARTED_API, payload, 'POST')
		.then((response) => {
			console.log("response", response);
			// dispatch(setActionData(payload, response));
			if(response.success) {
				dispatch({
					type: 'UPDATE_USER',
					payload: {
						isGameStarted: true,
						runTimer: true
					}
				});
			}
			if (checkIfPresent(callback)) {
				callback();
			}
		})
};