import actionConsts from 'constants/actions';
import analyticsUtil from 'util/analyticsUtil';

const startTutorial = () => {
	return {
		type: actionConsts.START_TUTORIAL
	}
};

const pauseTutorial = (payload) => {
	return {
		type: actionConsts.PAUSE_TUTORIAL,
		payload
	}
};

const endTutorial = () => {
	return {
		type: actionConsts.END_TUTORIAL
	}
};

const addStepsToTutorial = (steps, type) => {
	analyticsUtil.track('WALKTHROUGH_STARTED', { type });

	return {
		type: actionConsts.ADD_TUTORIAL_STEPS,
		payload: {
			steps,
			type
		}
	};
}

const updateStepNumber = (id) => {
	return {
		type: actionConsts.UPDATE_TUTORIAL_STEP,
		stepId: id
	}
}

export {
	startTutorial,
	pauseTutorial,
	endTutorial,
	addStepsToTutorial,
	updateStepNumber
};
