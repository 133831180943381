import React from 'react';
import styles from './select.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const Select = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 19 19" fill="none">
				<circle cx="9.5" cy="9.5" r="9" fill="#3ABDFF" stroke="#3ABDFF" />
				<path d="M4 9.78571L6.78571 13.5L15.1429 7" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</div>
	);
}

export default applyWrappers(Select, styles);