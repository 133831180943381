import React, { useState, useEffect } from 'react';
import styles from './pendingActionResponsePopup.module.sass';
import { useSelector, useDispatch } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import _ from 'lodash';
import MetricBar from 'commonComponents/metricBar';
import ImpactActor from 'commonComponents/impactActor';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import { checkIfPresent, removeActorPopupPayload, getActorNames, formActorLabelKeys } from 'util/functions';
import analyticsUtil from 'util/analyticsUtil';
import RightArrowFlat from 'svgComponents/rightArrowFlat';
import DelayIndicator from 'svgComponents/delayIndicator';

const PendingActionResponsePopup = (props) => {
	const dispatch = useDispatch();
    const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			actions: state.actions,
			user: state.user,
			actors: state.actors,
            userActiveActionResponse: state.userActiveActionResponse,
            actorInfo: state.actorInfo,
            userMetrics: state.userMetrics,
			storylineDefaults: state.storyline.storylineDefaults
		};
    });
    const myStyles = getSkin(props.skinGuide);

    const sccMetrics = ["strategy", "capability", "culture"];

    //Below conversion is language independent
    const initiativeLabels = {
        'label_strategy' : 'strategy',
        'label_capability' : 'capability',
        'label_culture' : 'culture'
    }

    const onPendingActionResponsePopupClick = (e) => {
		e.stopPropagation();
    }

    const setPendingActionData = (pendingActionResponse) => {
    
        return ({
            type: 'SET_PENDING_ACTION_DATA',
            payload: {
                ...pendingActionResponse[0],
                userActorMetrics: pendingActionResponse[0].metricsImpacted
            }
        });
    }

    const closePopup = () => {
		analyticsUtil.track('POPUP_CLOSED', { popup: myProps.uiState.overlayComponent, type: myProps.uiState.overlayComponentType });
		dispatch(removeActorPopupPayload());
        dispatch({
            type: 'PENDING_ACTION_POPUP_CLOSE',
            payload: {
                userActorMetrics: myProps.userActiveActionResponse.pendingActionResponse[0].metricsImpacted,
                reports: myProps.userActiveActionResponse.pendingActionResponse[0].reports,
                userMetricsForThisAction: myProps.userActiveActionResponse.pendingActionResponse[0].userMetricsForThisAction,
            }
        });
        dispatch(setPendingActionData(myProps.userActiveActionResponse.pendingActionResponse));
        dispatch({
            type: 'POP_PENDING_ACTIONS_STACK',
			payload: { }
        });

        if(myProps.userActiveActionResponse.pendingActionResponse.length > 0){
            setTimeout(() => {
                dispatch(removeActorPopupPayload());
                dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                        showOverlay: true,
                        overlayComponentType: 'BOTTOM',
                        highlightDesktopHeader: false,
                        overlayComponent: 'PENDING_ACTION_RESPONSE_POPUP'
                    }
                });
            },2000)
        }
        else if(!_.isEmpty(myProps.userActiveActionResponse.event)){
            dispatch({
                type: 'ADD_DETAILS_FOR_MONTHLY_REPORT',
                payload: {
                    isAnyActionPresent: false
                }
            });
            setTimeout(() => {
                		dispatch({
                			type: 'ADD_ACTIVE_EVENT',
                			payload: myProps.userActiveActionResponse.event
                		});
                		dispatch(removeActorPopupPayload());
                		dispatch({
                			type: 'UPDATE_UI_STATE',
                			payload: {
                				showOverlay: true,
                				overlayComponentType: 'BOTTOM',
                				highlightDesktopHeader: false,
                				overlayComponent: 'EVENT_POPUP'
                			}
                		});
            }, 2000);
        }
        else{
            dispatch({
                type: 'ADD_DETAILS_FOR_MONTHLY_REPORT',
                payload: {
                    isAnyActionPresent: false,
                    isAnyEventPresent: false
                }
            });
        }
    }
    
    const { networksList, revealedNetworksList } = myProps.actorInfo;

    const actionOptionDetails = myProps.actions.actionOptionsList.find((eachOption) => {
        return eachOption.id == myProps.userActiveActionResponse.pendingActionResponse[0].actionOptionId;
    });

    const delay = actionOptionDetails.delayedDuration;

    let actorNames = [], actorKeys = {};

    if (myProps.userActiveActionResponse.pendingActionResponse[0].response) {
        let actorIds = myProps.userActiveActionResponse.pendingActionResponse[0].response.actorIds;
        if (myProps.userActiveActionResponse.pendingActionResponse[0].isNetworkRevealed) {
            const revealedNetworkId = revealedNetworksList.find(network => network.day === myProps.userActiveActionResponse.pendingActionResponse[0].day).networkId;
            const revealedNetwork = networksList.find(network => network.id === revealedNetworkId);

            actorIds = revealedNetwork.actors.filter(actor => myProps.userActiveActionResponse.pendingActionResponse[0].response.actorIds.indexOf(actor) === -1);
        }
        actorNames = getActorNames(myProps.actors.actorsList, actorIds, props.getLabel);
        actorKeys = formActorLabelKeys(actorNames);
    }

    const actionDetails = myProps.actions.actionsList.find((eachAction) => {
        return eachAction.id === actionOptionDetails.actionId;
    });

    const initiativeDetails = myProps.actions.initiatives.find((eachInitiative) => {
        return eachInitiative.id === actionDetails.initiativeId;
    });

    let convertedActorsName = [];
    if(myProps.actors.convertedActors && myProps.actors.convertedActors.length > 0){
        convertedActorsName = getActorNames(myProps.actors.actorsList, myProps.actors.convertedActors, props.getLabel);
    }

    //Getting Name of CXO actor, current ID of CEO is 7.
	let CXOactorName = getActorNames(myProps.actors.actorsList, [7] , props.getLabel);

    const userMetrics = myProps.userMetrics;
    const getAverageAdoption = () => {
		let sum = 0;
		let count = 0;
		sccMetrics.map((eachMetric, index) => {
			const currentMetricId = userMetrics.userMetricIds[eachMetric];
			const userMetricDetails = userMetrics.userMetricsObj[currentMetricId];
			if (userMetricDetails.value) {
				sum += userMetricDetails.value;
				count++;
			}
		});
		if (count === 0) {
			return 0;
		}
		return (sum / count);
    }

    const initiativeNameSmallCase = initiativeLabels[initiativeDetails.name];
    const currentMetricId = userMetrics.userMetricIds[initiativeNameSmallCase];
    const userMetricDetails = userMetrics.userMetricsObj[currentMetricId];
    let value = userMetricDetails.value ? userMetricDetails.value : 0;
    let diff = 0;
    if(myProps.userActiveActionResponse.pendingActionResponse[0].isActorMetricsImpacted){
        myProps.userActiveActionResponse.pendingActionResponse[0].metricsImpacted.forEach((eachActor) =>{
            diff += eachActor.diff
        })
        diff /= 13;
    }
    
    let target = 80;
    if(myProps.storylineDefaults[`${initiativeNameSmallCase}IndexTarget`]){
        target = myProps.storylineDefaults[`${initiativeNameSmallCase}IndexTarget`]
    }

    const checkImpactForActor = (actorId) => {
        const impactedActors = myProps.userActiveActionResponse.pendingActionResponse[0].metricsImpacted;
        const impactedActor = impactedActors.filter(eachActor => {return eachActor.actorId === actorId})
        if(impactedActor != null && impactedActor != undefined && impactedActor.length > 0){
            return impactedActor[0].diff
        }else{
            return 0;
        }
    }

    let metricConditionalStyle = {}
    let containerConditionalStyle = {}
    let containerConditionalStyleAlpha={}

    if(diff > 0){
        metricConditionalStyle['border'] = '2px solid #1BE1D4'
        containerConditionalStyle['backgroundColor'] = '#1BE1D4'
        containerConditionalStyleAlpha['backgroundColor'] = '#1BE1D414'
    }
    //neutral color
    else if(diff === 0){
        metricConditionalStyle['border'] = '2px solid #7E8E9C'
        containerConditionalStyle['backgroundColor'] = '#7E8E9C'
        containerConditionalStyleAlpha['backgroundColor'] = '#7E8E9C14'
    }
    else{
        metricConditionalStyle['border'] = '2px solid #FD5382'
        containerConditionalStyle['backgroundColor'] = '#FD5382'
        containerConditionalStyleAlpha['backgroundColor'] = '#FD538214'
    }

    const renderActors = (actorIds) => {
        if(!actorIds){
			return null;
		}
        const actorDivs = actorIds.map((actorId,index) => {
			if (checkIfPresent(actorId)) {
				const actorDetails = myProps.actors.actorsList.find(eachActor => eachActor.id === actorId);
				return (
					<div key={index}  styleName = 'actor-container'>
						<img src={props.getImage(actorDetails.image)} styleName = 'actor-image'/>
						<div styleName = 'actor-name'>{props.getLabel(actorDetails.name)} </div>
					</div>
				);
			}
        });
        return actorDivs;
    }

    return (
        <div styleName="container" style={metricConditionalStyle} onClick={onPendingActionResponsePopupClick}>
            <div styleName="header-container" style={containerConditionalStyleAlpha}>
                <div styleName="header-title" className={css(myStyles.headerTitle)} >
                    { `${initiativeDetails.name == 'label_strategy'? props.getLabel('label_strategy_cap'): props.getLabel(initiativeDetails.name).toUpperCase()} ${props.getLabel('label_action').toUpperCase()}` }
                </div>
                <div styleName="header-indicator">
                    <DelayIndicator />
                </div>
                <div styleName="delay-days-container">
                    <div styleName="delay-days-static" className={css(myStyles.delayDaysStatic)}>
                        {props.getLabel('label_delayed_response')}
                    </div>
                    <div styleName="delay-days-dynamic" className={css(myStyles.delayDaysDynamic)}>
                        {props.getLabel(
                            'label_delay_days_ago', '', 
                            {DELAY: delay}
                        )}
                    </div>
                </div>
                <div styleName="close-container" onClick={closePopup}>
                    <Close strokeWidth={1.6} />
                </div>
            </div>
            <div styleName="action-name-container" className={css(myStyles.actionName)} style={containerConditionalStyleAlpha}>
                <div styleName="action-name">
                    {props.getLabel(
                        actionDetails.name+'_cap', '', 
                        {ACTOR: convertedActorsName[convertedActorsName.length-1], 
                        CXO: CXOactorName[0]}
                    )}
                </div>
                <div styleName="header-days" >
                <div styleName="previous-day">{props.getLabel('label_day')} {myProps.userActiveActionResponse.pendingActionResponse[0].day}</div>
                </div>
            </div>
            <div styleName="body-container" style={containerConditionalStyleAlpha}>
                <div styleName="body-desc">
                    {
                        (checkIfPresent(myProps.userActiveActionResponse.pendingActionResponse[0].response.actorIds)
                        && myProps.userActiveActionResponse.pendingActionResponse[0].response.actorIds.length == 1)
                            ?   <div styleName = 'actors-container'>
                                    {renderActors(myProps.userActiveActionResponse.pendingActionResponse[0].response.actorIds)}
                                </div>
                            : null
                    }
                    {
                        checkIfPresent(myProps.userActiveActionResponse.pendingActionResponse[0].response.description)
                            ? props.getLabel(myProps.userActiveActionResponse.pendingActionResponse[0].response.description, '', {
                                DELAY: delay,
                                ACTOR_CONVERTED: convertedActorsName[convertedActorsName.length-1],
                                ...actorKeys
                            })
                            : "***Response Description"
                    }
                </div>
                <div styleName="body-metrics" >
                    <div styleName = 'metric-bar'>
                       <MetricBar value={value.toFixed(1)} showAnimation={false} diff={0} target={target} initiative={initiativeDetails.name}/>
                    </div>
                    <div styleName='initiative-value-container'>
                        <div>
                            <div className={css(myStyles.initiativeValue)} styleName='initiative-value'>
                                {value.toFixed(1)}%
                                <div styleName='initiative-diff' 
                                    style={containerConditionalStyle}
                                > 
                                    {diff.toFixed(1) > 0 ? '+':''}{diff.toFixed(1)}
                                </div>
                            </div>
                            <div styleName='initiative-name'>{initiativeDetails.name == 'label_strategy'? props.getLabel('label_strategy_cap'): props.getLabel(initiativeDetails.name)} </div>
                        </div>
                    </div>
                    <div styleName='right-arrow-flat'>
                        <RightArrowFlat />
                    </div>
                    <div styleName='initiative-value-container'>
                        <div>
                            <div className={css(myStyles.initiativeValue)} styleName='initiative-value'>
                                {getAverageAdoption().toFixed(1)}%
                                <div styleName='initiative-diff' 
                                    style={containerConditionalStyle}> 

                                    {(diff/3).toFixed(1) >0 ? '+':''}
                                    {(diff/3).toFixed(1)}
                                </div>
                            </div>
                            <div styleName='initiative-name'>{props.getLabel('label_ecosystem_adoption')} </div>
                        </div>
                    </div>
                </div>
            </div>
            <div styleName="footer-container">
                <div styleName="org-chart">
                    <div styleName="organization">
                        <div styleName="org-name">{props.getLabel('label_organization')}</div>
                        <div style={{width: '100%', height: '1px', backgroundColor: '#F1F3F4'}}></div>
                        <div styleName="org-actors">
                            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                <ImpactActor id={7} impact={checkImpactForActor(7)}/>
                                <ImpactActor id={2} impact={checkImpactForActor(2)}/>
                                <ImpactActor id={10} impact={checkImpactForActor(10)}/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                <ImpactActor id={11} impact={checkImpactForActor(11)}/>
                                <ImpactActor id={9} impact={checkImpactForActor(9)}/>
                                <ImpactActor id={4} impact={checkImpactForActor(4)}/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                <ImpactActor id={8} impact={checkImpactForActor(8)}/>
                                <ImpactActor id={12} impact={checkImpactForActor(12)}/>
                                <ImpactActor id={5} impact={checkImpactForActor(5)}/>
                            </div>
                        </div>
                    </div>
                    <div styleName="client-partner">
                        <div styleName="client">
                            <div styleName="org-name">{props.getLabel('label_clients')}</div>
                            <div style={{width: '100%', height: '1px', backgroundColor: '#F1F3F4'}}></div>
                            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                <ImpactActor id={15} impact={checkImpactForActor(15)}/>
                                <ImpactActor id={16} impact={checkImpactForActor(16)}/>
                            </div>
                        </div>
                        <div styleName="partner">
                            <div styleName="org-name">{props.getLabel('label_partners')}</div>
                            <div style={{width: '100%', height: '1px', backgroundColor: '#F1F3F4'}}></div>
                            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                <ImpactActor id={13} impact={checkImpactForActor(13)}/>
                                <ImpactActor id={14} impact={checkImpactForActor(14)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default applyWrappers(PendingActionResponsePopup, styles);