import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		reportPopupContainer: {
			backgroundColor: skin.white
		},
		headerClose: {
			backgroundColor: skin.secondaryColor,
			':hover': {
				backgroundColor: hexToRgbA(skin.secondaryColor, 0.8)
			}
		},
		headerContent: {
			...myTypography.body1,
			fontSize: '16px',
			fontWeight: 600,
			'@media (min-width: 1440px)' : {
				fontSize: '18px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '20px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '24px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '32px'
			},
		},
		headerLine: {
			backgroundColor: hexToRgbA(skin.black, 0.1)
		},
		headerText: {
			...myTypography.body1,
			fontSize: '10px',
			textTransform: 'uppercase',
			'@media (min-width: 1440px)' : {
				fontSize: '11.25px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '12.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '15px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '20px'
			},
		}, 
		headerDescription: {
			fontFamily: `Open Sans`,
			fontStyle: `normal`,
			fontWeight: `normal`,
			fontSize: `14px`,
			color : `#000000`,
			'@media (min-width: 1440px)' : {
				fontSize: '15.75px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '17.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '21px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '28px'
			},
		}
	});
}

export default getSkin;