import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './simLeaderBoard.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import HeaderSeparation from 'components/header/headerSeparation';
import Leaderboard from 'svgComponents/leaderboard';

import analyticsUtil from 'util/analyticsUtil';

const SimLeaderBoard = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const dispatch = useDispatch();
	
	const tooltip = <>
	<div className={css(myStyles.toolTipArrow)} styleName="arrow"></div>
	<div className={css(myStyles.showLeaderboardHover)} styleName = "show-leaderboard-hover">
					{props.getLabel('label_leaderboard')}
				</div>
	</>;
	
	const [showHoverMessage, setShowHoverMessage] = useState(false);

	const openLeaderboard = () => {
		analyticsUtil.track('LEADERBOARD_POPUP_OPENED');

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: true,
				overlayComponentType: 'CENTER',
				highlightDesktopHeader: false,
				overlayComponent: 'LEADERBOARD_POPUP'
			}
		});
	}

	return (
		<div styleName="leaderboard-container">
			<div className={css(myStyles.leaderboardImageContainer)} styleName="leaderboard-image-container"
			onClick={openLeaderboard} 
			onMouseEnter={()=>setShowHoverMessage(true)}
			onMouseLeave={()=> setShowHoverMessage(false)}>
				<div styleName="leaderboard-image">
					<Leaderboard />
				</div>
			</div>
		{ showHoverMessage && tooltip}
		</div>
	);
}

export default applyWrappers(SimLeaderBoard, styles);
