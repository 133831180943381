import kfetch from 'util/kfetch';
import urls from 'constants/urls';
import actionConstans from 'constants/actions';

const setInitialData = (payload) => ({
	type: actionConstans.SET_INIT_DATA,
	payload
})

export const fetchInitialData = () => (dispatch) => kfetch(urls.GAME_API)
	.then((response) => {
		dispatch(setInitialData(response));
	})
	.catch((error) => {
		if(error.error && error.error.status && error.error.data && error.error.data.message){
			let err = error.error;
			if(err.status == 500 && err.data.message == 'Looks like you have not loggedin to this service yet.'){
				window.location.href = urls.LOG_IN;
			}
		}
	})