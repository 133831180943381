import actionConstants from "constants/actions";
import { addElementToArray } from 'util/functions';

const initialState = {
	actionOptionsReports: [],
	reportList: [],
	diff: 0
};

const getReportListFromGame = (payload) => {
	const reports = [];
	payload.userActionOptions.map((eachOption) => {
		if (eachOption.reports.length === 0) {
			return 1;
		}
		eachOption.reports.map((eachReport) => {
			addElementToArray(reports, eachReport);
			return 1;
		});
		return 1;
	});
	return reports;
}

const userReports = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConstants.SET_INIT_DATA:
			return {
				...state,
				reportList: getReportListFromGame(action.payload),
				actionOptionsReports: action.payload.actionOptionsReports,
				diff: 0
			};
		case 'ACTION_RESPONSE_POPUP_CLOSE':
			return {
				...state,
				reportList: [...state.reportList, ...action.payload.reports],
				diff: action.payload.reports.length + state.diff
			};
		case 'PENDING_ACTION_POPUP_CLOSE':
		return {
			...state,
			reportList: [...state.reportList, ...action.payload.reports],
			diff: action.payload.reports.length + state.diff
		};
		case 'RESET_REPORT_DIFF':
			return {
				...state,
				diff: 0
			}
		default:
			return state;
	}
};

export default userReports;