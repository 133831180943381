import React, { useState, useEffect } from 'react';
import styles from './actionOptions.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Select from 'svgComponents/select';
import RightArrow from 'svgComponents/rightArrow';
import { useSelector, useDispatch } from 'react-redux';
import Presentation from 'commonComponents/presentation';
import SpeakerProfile from 'commonComponents/speakerProfile';

const ActionOptions = (props) => {

	const myProps = useSelector((state) => {
		return {
			userActionOptions: state.userActionOptions,
			actions: state.actions
		};
	});

	const myStyles = getSkin(props.skinGuide);
	const [popupOpen, setPopupOpen] = useState(false);

	const selectOption = (optionId, poolId) => {
		props.setPoolId(poolId);
		props.setOptionId(optionId);
		// setPopupOpen(false);
	}

	const getPoolOption = (eachOption) => {
		let optionPoolToBeRendered = null;

		const actionDetails = myProps.actions.actionsList.find((eachAction) => {
			return eachAction.id === eachOption.actionId;
		});

		let optionIds = [];
		myProps.actions.actionOptionsList.map((eachActionOption) => {
			if (eachActionOption.actionId === actionDetails.id) {
				optionIds.push(eachActionOption.id);
			}
		});
		let maxPoolId = -1;

		const userActionOptionsTaken = [];
		myProps.userActionOptions.userActionOptionList.map((eachOptionTaken) => {
			if (optionIds.indexOf(eachOptionTaken.actionOptionId) > -1) {
				if (eachOptionTaken.poolId != null /*&& maxPoolId < eachOptionTaken.poolId*/) {
					maxPoolId = eachOptionTaken.poolId;
				}
			}
		});

		const orderedOptionPool = eachOption.actionOptionPool.sort((a, b) => {
			return a.order - b.order;
		});

		if (maxPoolId === -1) {
			optionPoolToBeRendered = orderedOptionPool[0];
		} else {
			if(orderedOptionPool.length < maxPoolId) {
				optionPoolToBeRendered = orderedOptionPool[0];
			} else {
				optionPoolToBeRendered = orderedOptionPool.find((eachOptionPool) => {
					return eachOptionPool.poolId === maxPoolId + 1;
				});

				if(optionPoolToBeRendered == undefined) {
					optionPoolToBeRendered = orderedOptionPool[0];
				}
			}
		}
		return optionPoolToBeRendered;
	}

	const renderRadioButtonOptions = () => {
		return props.options.map((eachOption) => {
			let optionPoolToBeRendered = null;
			if (eachOption.hasPool) {
				optionPoolToBeRendered = getPoolOption(eachOption);
			} else {
				optionPoolToBeRendered = eachOption.actionOptionPool[0];
			}
			return (
				<div className={css(myStyles.optionContainer)} styleName="option-container" onClick={() => { selectOption(eachOption.id, optionPoolToBeRendered.poolId); }}>
					<div styleName="option-select-box">
						{
							eachOption.id === props.optionId
								? <div className={css(myStyles.filterSelectedCircle)} styleName="filter-circle">
									<div className={css(myStyles.selectedCircle)} styleName="filter-selected"></div>
								</div>
								: <div className={css(myStyles.filterCircle)} styleName="filter-circle"></div>
						}
					</div>
					<div styleName="option-content">
						<div className={css(myStyles.name)}>{props.getLabel(optionPoolToBeRendered.name)}</div>
						{
							props.getLabel(optionPoolToBeRendered.description) !== '-'
								? <div className={css(myStyles.description)}>{props.getLabel(optionPoolToBeRendered.description)}</div>
								: null
						}

					</div>
				</div>
			);
		})
	}

	const renderDropDownOptions = (optionPoolToBeRendered) => {
		const type = optionPoolToBeRendered.displayJson.type;
		if (type.indexOf('PRESENTATION') > -1) {
			return (
				<Presentation displayJson={optionPoolToBeRendered.displayJson} />
			);
		} else if (type.indexOf('SPEAKER') > -1) {
			return (
				<SpeakerProfile displayJson={optionPoolToBeRendered.displayJson} />
			);
		}
	}

	const renderTitle = (selectedOptionPoolDetails) => {
		const type = selectedOptionPoolDetails.displayJson.type;
		if (type == null || type == undefined) {
			return null;
		}
		if (type.indexOf('PRESENTATION') > -1) {
			return props.getLabel(selectedOptionPoolDetails.displayJson.data.title);
		} else if (type.indexOf('SPEAKER') > -1) {
			return props.getLabel(selectedOptionPoolDetails.displayJson.data.content.speakerName);
		}

	}

	const renderDropDownBoxOptions = () => {
		let selectedOptionPoolDetails = null;
		const optionDivs = props.options.map((eachOption) => {
			if (eachOption.id === props.optionId && props.poolId !== null) {
				selectedOptionPoolDetails = eachOption.actionOptionPool.find((eachPoolOption) => {
					return props.poolId === eachPoolOption.poolId;
				});
			}
			let optionPoolToBeRendered = null;
			if (eachOption.hasPool) {
				optionPoolToBeRendered = getPoolOption(eachOption);
			} else {
				optionPoolToBeRendered = eachOption.actionOptionPool[0];
			}
			return (
				<div
					className={eachOption.id === props.optionId ? css(myStyles.ddbOptionContainerSelected) : css(myStyles.ddbOptionContainer)}
					styleName="ddb-option-container"
					onClick={() => { selectOption(eachOption.id, optionPoolToBeRendered.poolId); }}
				>
					{
						eachOption.id === props.optionId
							? <div styleName="ddb-option-select-tick">
								<Select />
							</div>
							: null
					}
					{
						optionPoolToBeRendered.displayType === 'JSON'
							? renderDropDownOptions(optionPoolToBeRendered)
							: <div styleName="ddb-option-content">
								<div className={css(myStyles.name)}>{props.getLabel(optionPoolToBeRendered.name)}</div>
								{
									props.getLabel(optionPoolToBeRendered.description) !== '-'
										? <div className={css(myStyles.description)}>{props.getLabel(optionPoolToBeRendered.description)}</div>
										: null
								}

							</div>
					}
				</div>
			);
		});
		return (
			<div className={css(myStyles.selectionBox)} styleName="ddb-option-selection-box">
				{
					popupOpen
						? <div className={css(myStyles.ddbOptionsContainer)} styleName="ddb-options-container">
							{optionDivs}
						</div>
						: null
				}
				<div styleName="ddb-option-selected"
					onClick={() => {
						props.setPopupIndex(popupOpen ? 50 : 51);
						setPopupOpen(!popupOpen);
					}}
				>
					{
						props.optionId == null
							? <React.Fragment>
								<div className={css(myStyles.ddbOptionBoxText)} styleName="ddb-option-box-text">{props.getLabel('label_select_an_option')}</div>
								<div styleName="ddb-option-box-arrow">
									<RightArrow />
								</div>
							</React.Fragment>
							: <React.Fragment>
								<div className={css(myStyles.ddbOptionBoxText)} styleName="ddb-option-box-text">
									{
										selectedOptionPoolDetails != null
											? renderTitle(selectedOptionPoolDetails)
											: null
									}
								</div>
								<div styleName="ddb-option-box-arrow">
									<RightArrow />
								</div>
							</React.Fragment>
					}
				</div>
			</div>
		);
	}

	const renderOptionsBasedOnType = () => {
		switch (props.uiDisplayType) {
			case 'RADIO_BUTTON':
				return renderRadioButtonOptions();
			case 'DROP_DOWN_BOX':
				return renderDropDownBoxOptions();
			default:
				console.error("No OPTION TYPE has been recieved to render options");
				return null;
		}
	}

	const getClassName = () => {
		switch (props.uiDisplayType) {
			case 'RADIO_BUTTON':
				return "container";
			case 'DROP_DOWN_BOX':
				return "ddb-container";
			default:
				console.error("No OPTION TYPE has been recieved to render options");
				return null;
		}
	}

	return (
		<div styleName={getClassName()}>
			{renderOptionsBasedOnType()}
		</div>
	);
}

export default applyWrappers(ActionOptions, styles);