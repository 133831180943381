const advisorTalk = {
	designation: 'label_designation_exeAdv',
	chatList: [
		{
			type: 'ADVISOR',
			labelKey: 'label_intro_advisor_chat_1'
		},
		{
			type: 'ADVISOR',
			labelKey: 'label_intro_advisor_chat_2'
		},
		{
			type: 'ADVISOR',
			labelKey: 'label_intro_advisor_chat_3'
		},
		{
			type: 'YOU',
			labelKey: 'label_intro_you_chat_1'
		},
		// {
		// 	type: 'ADVISOR',
		// 	labelKey: 'label_intro_advisor_chat_4'
		// },
		// {
		// 	type: 'ADVISOR',
		// 	labelKey: 'label_intro_advisor_chat_5'
		// },
		// {
		// 	type: 'ADVISOR',
		// 	labelKey: 'label_intro_advisor_chat_6'
		// },
		// {
		// 	type: 'YOU',
		// 	labelKey: 'label_intro_you_chat_2'
		// }
	]
}

export default advisorTalk;