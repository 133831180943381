import { StyleSheet } from 'aphrodite';
// import typography from 'defaults/typography';

const getSkin = (theme) => {
	const skin = theme.globalProfiles.palette;
	// const myTypography = typography(theme);

	return StyleSheet.create({
		headerContainer: {
			// background:  `linear-gradient(to right, ${skin.primaryColor}, ${skin.secondaryColor})`,
		},	
	
	middleHeaderSelectActorsInstruction: {
		backgroundColor: `#FFFFFF15`,
		color: `#FFFFFF`,
		fontFamily: `Open Sans`,
		fontWeight: `400`,
		fontStyle: `italic`,
		boxshadow: `0px 0px 14px rgba(0, 0, 0, 0.06)`,
	},
});
}

export default getSkin;