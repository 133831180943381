import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		headerContainer: {
			backgroundColor: skin.secondaryColor
		},
		headerText: {
			...myTypography.body1,
			color: skin.white,
			fontWeight: 600
		},
		actionsContainer: {
			boxShadow: `1px -4px 10px ${hexToRgbA(skin.black, 0.2)}`
		},
		actionHeaderText: {
			...myTypography.body2,
			textTransform: 'uppercase',
			fontWeight: 600,
			color: skin.grayColor3,
		},
		actionText: {
			...myTypography.body1,
		},
		eachAction: {
			border: `1px solid #E3E6E9`,
			color: skin.grayColor1,
			':hover': {
				backgroundColor: skin.grayColor6
			},
			backgroundColor : '#F8F9F9'
		},
		leftArrow: {
			borderRight: `10px solid ${skin.grayColor5}`,
			borderBottom: `10px solid transparent`,
			'@media (min-width: 1440px)' : {
				borderRight: `11.25px solid ${skin.grayColor5}`,
				borderBottom: `11.25px solid transparent`,
			},
			'@media (min-width: 1600px)' : {
				borderRight: `12.5px solid ${skin.grayColor5}`,
				borderBottom: `12.5px solid transparent`,
			},
			'@media (min-width: 1920px)' : {
				borderRight: `15px solid ${skin.grayColor5}`,
				borderBottom: `15px solid transparent`,
			},
			'@media (min-width: 2560px)' : {
				borderRight: `20px solid ${skin.grayColor5}`,
				borderBottom: `20px solid transparent`,
			},
		},
		leftMessage: {
			...myTypography.body2,
			color: skin.grayColor1,
			backgroundColor: skin.white,
			boxShadow: `0px 0px 5px ${hexToRgbA(skin.black, 0.3)}`
		},

		leftStaticMessage: {
			...myTypography.body2,
			color: `#14324B`,
		},

		rightArrow: {
			borderLeft: `10px solid ${skin.grayColor5}`,
			borderBottom: `10px solid transparent`,
			'@media (min-width: 1440px)' : {
				borderLeft: `11.25px solid ${skin.grayColor5}`,
				borderBottom: `11.25px solid transparent`,
			},
			'@media (min-width: 1600px)' : {
				borderLeft: `12.5px solid ${skin.grayColor5}`,
				borderBottom: `12.5px solid transparent`,
			},
			'@media (min-width: 1920px)' : {
				borderLeft: `15px solid ${skin.grayColor5}`,
				borderBottom: `15px solid transparent`,
			},
			'@media (min-width: 2560px)' : {
				borderLeft: `20px solid ${skin.grayColor5}`,
				borderBottom: `20px solid transparent`,
			},
		},
		rightMessage: {
			...myTypography.body2,
			color: skin.grayColor1,
			backgroundColor: skin.grayColor5,
			// boxShadow: `0px 0px 5px ${hexToRgbA(skin.black, 0.4)}`
		},
		initiativeValue: {
			...myTypography.number,
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '14px',
			lineHeight: '14px',
			'@media (min-width: 1440px)' : {
				fontSize: '15.75px',
				lineHeight: '15.75px',
			},
			'@media (min-width: 1600px)' : {
				fontSize: '17.5px',
				lineHeight: '17.5px',
			},
			'@media (min-width: 1920px)' : {
				fontSize: '21px',
				lineHeight: '21px',
			},
			'@media (min-width: 2560px)' : {
				fontSize: '28px',
				lineHeight: '28px',
			},
		}
	});
}

export default getSkin;