import React from 'react';
import styles from './eventImage.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const EventImage = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none">
				<rect width="26" height="26" rx="13" fill="#3ABDFF" />
				<g clip-path="url(#clip0)">
					<path d="M17.2206 18.6626C17.2206 18.3039 17.2206 6.70557 17.2206 6.41659C17.2206 6.1739 17.0238 5.97714 16.7811 5.97714H6.43945C6.19676 5.97714 6 6.1739 6 6.41659V17.7837C6 18.9952 6.98568 19.9809 8.19724 19.9809H17.6612C17.3847 19.6134 17.2206 19.1568 17.2206 18.6626ZM8.34373 14.4146H9.74996C9.99266 14.4146 10.1894 14.6113 10.1894 14.854C10.1894 15.0967 9.99266 15.2935 9.74996 15.2935H8.34373C8.10103 15.2935 7.90428 15.0967 7.90428 14.854C7.90428 14.6113 8.10103 14.4146 8.34373 14.4146ZM7.90428 12.979C7.90428 12.7363 8.10103 12.5396 8.34373 12.5396H9.74996C9.99266 12.5396 10.1894 12.7363 10.1894 12.979C10.1894 13.2217 9.99266 13.4185 9.74996 13.4185H8.34373C8.10103 13.4185 7.90428 13.2217 7.90428 12.979ZM14.9062 17.8716H8.34373C8.10103 17.8716 7.90428 17.6748 7.90428 17.4321C7.90428 17.1894 8.10103 16.9927 8.34373 16.9927H14.9062C15.1489 16.9927 15.3456 17.1894 15.3456 17.4321C15.3456 17.6748 15.1489 17.8716 14.9062 17.8716ZM15.3456 15.5571C15.3456 15.7998 15.1489 15.9966 14.9062 15.9966H11.6249C11.3823 15.9966 11.1855 15.7998 11.1855 15.5571V12.2759C11.1855 12.0332 11.3823 11.8365 11.6249 11.8365H14.9062C15.1489 11.8365 15.3456 12.0332 15.3456 12.2759V15.5571ZM14.9062 10.8404H8.34373C8.10103 10.8404 7.90428 10.6436 7.90428 10.4009C7.90428 10.1582 8.10103 9.96148 8.34373 9.96148H14.9062C15.1489 9.96148 15.3456 10.1582 15.3456 10.4009C15.3456 10.6436 15.1489 10.8404 14.9062 10.8404ZM14.9062 8.96539H8.34373C8.10103 8.96539 7.90428 8.76864 7.90428 8.52595C7.90428 8.28325 8.10103 8.0865 8.34373 8.0865H14.9062C15.1489 8.0865 15.3456 8.28325 15.3456 8.52595C15.3456 8.76864 15.1489 8.96539 14.9062 8.96539Z" fill="white" />
					<path d="M12.0645 12.7154H14.4668V15.1177H12.0645V12.7154Z" fill="white" />
					<path d="M20.5608 9.02399H18.0999C18.0999 18.9817 18.0984 18.6629 18.1025 18.6629C18.1404 19.2764 18.5597 19.7863 19.1261 19.9591C20.0321 20.2356 20.9611 19.5802 20.9988 18.6305C21.001 18.6305 21.0002 18.85 21.0002 9.46344C21.0002 9.22074 20.8035 9.02399 20.5608 9.02399Z" fill="white" />
				</g>
				<defs>
					<clipPath id="clip0">
						<rect width="15" height="15" fill="white" transform="translate(6 5.5)" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
}

export default applyWrappers(EventImage, styles);