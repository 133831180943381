import React from 'react';
import styles from './reportCard.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const ReportCard = (props) => {

	const myStyles = getSkin(props.skinGuide);

	const reportData = {
		"type": "SURVEY_QUESTIONS",
		"data": {
			"title": 'label_title',
			"list": [
				"label_action_19_surveyQuestion_1",
				"label_action_19_surveyQuestion_2"
			]
		}
	};
	const data = props.reportDetails.displayJson.data;

	const renderPoints = () => {
		return data.list.map((eachPoint) => {
			return (
				<div className={css(myStyles.point)} styleName="point">
					{props.getLabel(eachPoint)}
				</div>
			);
		});
	}

	return (
		<div className={css(myStyles.container)} styleName="report-card-container">
			<div className={css(myStyles.title)} styleName="title">
				{props.getLabel(data.title)}
			</div>
			<div className={css(myStyles.titleLine)} styleName="title-line"></div>
			
			<div styleName="content">
				{renderPoints()}
			</div>
		</div>
	);
}

export default applyWrappers(ReportCard, styles);