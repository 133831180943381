import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		headerContainer: {
			backgroundColor: skin.secondaryColor
		},
		metricsContainer: {
			backgroundColor: skin.white,
			boxShadow: `0px 0px 6px ${hexToRgbA(skin.black, 0.3)}`
		},
		detailsContainer: {
			backgroundColor: skin.white
		},
		timelineContainer: {
			backgroundColor: skin.grayColor7
		},
		name: {
			...myTypography.body1,
			color: skin.white,
			fontWeight: 600
		},
		designation: {
			...myTypography.body2,
			color: skin.white
		},
		buMemberDetails: {
			...myTypography.body2,
			color: skin.white,
			fontSize: '10px'
		},
		lastJoined: {
			...myTypography.caption,
			color: skin.white,
			borderTop: `1px solid ${skin.grayColor4}`
		},
		timelineHeading: {
			...myTypography.body2,
			fontWeight: 600,
			textTransform: 'uppercase',
			color: skin.grayColor2,
			borderBottom: `1px solid ${skin.grayColor3}`
		},
		emptyText: {
			...myTypography.body2,
			color: skin.grayColor3
		},
		historyItemContent: {
			borderLeft: `1px solid ${skin.grayColor3}`
		},
		historyNumber: {
			borderTop: `1px solid ${skin.grayColor3}`,
			borderBottom: `1px solid ${skin.grayColor3}`,
			...myTypography.body2,
			color: skin.grayColor3,
			backgroundColor: skin.grayColor7
		},
		historyChangePositive: {
			backgroundColor: skin.green,
			color: skin.white,
			...myTypography.body2,
			fontWeight: 600
		},
		historyChangeNegative: {
			backgroundColor: skin.red,
			color: skin.white,
			...myTypography.body2,
			fontWeight: 600
		},
		historyAction: {
			...myTypography.body2,
			color: skin.grayColor2,
			textTransform: 'uppercase'
		},
		historyMessage: {
			...myTypography.body1,
			color: skin.grayColor1
		},
		detailHeadingText: {
			...myTypography.body2,
			fontWeight: 600,
			color: skin.grayColor3,
			textTransform: 'uppercase'
		},
		detailValue: {
			...myTypography.body2,
			color: skin.grayColor1
		}
	});
}

export default getSkin;
