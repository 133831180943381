import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		eachActorContainer: {
			backgroundColor: skin.grayColor5
		},
		eachActorContainerSelected: {
			backgroundColor: skin.grayColor5,
			cursor: 'pointer',
			':hover': {
				border: `1px solid ${skin.red}`
			}
		}
	});
}

export default getSkin;