import actionConsts from 'constants/actions';
import { getMonthIntervals } from 'util/functions';

const initialState = {
	dayBeforeAction: 0,
	dayAfterAction: 0,
	isAnyEventPresent: false,
	isAnyActionPresent: false,
	reportSeenData: {},
	monthIntervals: []
};

const getReportSeenData = (currentDay, monthIntervals) => {
	let obj = {};
	monthIntervals.map((eachInterval) => {
		obj[eachInterval] = eachInterval <= currentDay ? true : false
	});
	return obj;
}

const monthlyReports = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.SET_INIT_DATA:
			const { group, userDetails } = action.payload;
			const monthIntervals = getMonthIntervals(group.monthDays, group.days);
			return {
				...state,
				reportSeenData: getReportSeenData(userDetails.currentDay, monthIntervals),
				monthIntervals
			};
		case 'PUSH_ACTIVE_EVENT_TO_LIST':
			return {
				...state,
				isAnyEventPresent: false
			}
		case 'ADD_DETAILS_FOR_MONTHLY_REPORT':
			return {
				...state,
				...action.payload
			}
		case 'SET_MONTH_REPORT_SEEN':
			return {
				...state,
				reportSeenData: {
					...state.reportSeenData,
					...action.payload
				}
			}
		default:
			return state;
	}
};

export default monthlyReports;