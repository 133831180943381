import React from 'react';
import styles from './speakerProfile.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const SpeakerProfile = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const type = props.displayJson.type;
	const data = props.displayJson.data.content;

	return (
		<div className={css(myStyles.container)} styleName="speaker-card-container">
			<div className={css(myStyles.titleContainer)} styleName="title-container">
				<div styleName="image">
					<img width="100%" height="100%" src={props.getImage(data.speakerImage)} alt="speaker" />
				</div>
				<div styleName="title-content">
					<div className={css(myStyles.title)} styleName="title">{props.getLabel(data.speakerName)}</div>
					<div className={css(myStyles.designation)} styleName="designation">{props.getLabel(data.speakerDesignation)}</div>
				</div>
			</div>
			{/* <div className={css(myStyles.titleLine)} styleName="title-line"></div> */}
			<div styleName="content">
				<div className={css(myStyles.point)} styleName="point">
					<b>{props.getLabel(data.speakerText1)}</b>
					<br />
					{props.getLabel(data.speakerText2)}
				</div>
			</div>
		</div>
	);
}

export default applyWrappers(SpeakerProfile, styles);