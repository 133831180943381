const objectivesData = {
	designation: 'label_designation_exeAdv',
	headingKey: 'label_intro_objectives_heading',
	heading2Key: 'label_intro_objectives_heading2',
	list: [
		{
			labelKey: 'label_intro_objective_1'
		},
		{
			labelKey: 'label_intro_objective_2'
		},
		// {
		// 	labelKey: 'label_intro_objective_3'
		// }
	]
}

export default objectivesData;