import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		container: {
			backgroundColor: skin.white
		},
		header: {
			borderBottom: `1px solid ${skin.grayColor6}`
		},
		image: {
			backgroundColor: skin.grayColor6
		},
		text: {
			...myTypography.body1,
			color: skin.grayColor1,
			fontWeight: 600
		},
		description: {
			...myTypography.body2,
			color: skin.grayColor1
		},
		dayText: {
			...myTypography.body1,
			color: skin.grayColor2,
			fontWeight: 600
		},
		footer: {
			borderTop: `1px solid ${skin.grayColor6}`
		},
		cancel: {
			...myTypography.body1,
			color: skin.primaryColor,
			textTransform: 'uppercase',
			cursor: 'pointer',
			':hover': {
				textDecoration: 'underline'
			}
		},
		proceed: {
			backgroundColor: skin.primaryColor,
			color: skin.white,
			textTransform: 'uppercase',
			...myTypography.body1,
			cursor: 'pointer'
		},
		proceedDisable: {
			backgroundColor: skin.primaryColor,
			color: skin.white,
			opacity: 0.6,
			cursor: 'not-allowed',
			textTransform: 'uppercase',
			...myTypography.body1
		},
		actorsContainer: {
			backgroundColor: skin.grayColor7
		},
		optionContainer: {
			backgroundColor: skin.grayColor7
		},
		sqContainer: {
			background: skin.grayColor7
		},
		sqHeading: {
			...myTypography.body1,
			color: skin.grayColor3,
			fontWeight: 600,
			textTransform: 'uppercase'
		},
		eachSqQuestion: {
			...myTypography.body1,
			color: skin.grayColor1
		},
		restrictedActionMessageContainer: {
			// borderBottom: `1px solid ${skin.grayColor6}`,
			backgroundColor: skin.grayColor7
		}
	});
}

export default getSkin;