import React from 'react';
import styles from './dislike.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const Dislike = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 11 11" fill="none">
				<path d="M10.7774 5.11061C10.8346 5.29806 10.847 5.49629 10.8137 5.68941C10.7804 5.88253 10.7023 6.06516 10.5857 6.22264C10.4691 6.38012 10.3171 6.50807 10.1421 6.59622C9.96708 6.68437 9.77383 6.73026 9.57786 6.73022H7.03282C6.93655 6.7302 6.84184 6.75458 6.75754 6.80108C6.67325 6.84758 6.60211 6.91468 6.55077 6.99613C6.49944 7.07757 6.46958 7.1707 6.46399 7.26681C6.4584 7.36292 6.47726 7.45888 6.5188 7.54573L7.17011 8.91083C7.22984 9.08817 7.24359 9.27777 7.21009 9.46187C7.17659 9.64598 7.09692 9.81858 6.97855 9.96351C6.86916 10.0983 6.73005 10.2058 6.57212 10.2778C6.41419 10.3498 6.24173 10.3842 6.06827 10.3784C5.8948 10.3726 5.72505 10.3266 5.57231 10.2442C5.41957 10.1618 5.288 10.0451 5.1879 9.9033L2.95758 6.74345C2.90486 6.66898 2.83507 6.60821 2.75405 6.56624C2.67304 6.52426 2.58315 6.50229 2.4919 6.50217C2.43578 6.50217 2.38195 6.47988 2.34226 6.44019C2.30257 6.4005 2.28027 6.34667 2.28027 6.29054V1.13069C2.28027 1.08786 2.29233 1.04588 2.31508 1.00959C2.33783 0.973286 2.37034 0.944127 2.40889 0.925449C4.09646 0.10812 4.65701 2.47955e-05 6.03123 2.47955e-05C6.1895 2.47955e-05 7.08938 0.00914764 7.41823 0.00914764C8.64057 0.00914764 9.4538 0.718381 9.90671 2.18884L10.7733 5.09374L10.7774 5.11061Z" fill="#A6B1BB" />
				<path d="M1.59635 6.50217H1.58449C1.55761 6.6612 1.47541 6.80562 1.3524 6.90994C1.22939 7.01425 1.07348 7.07176 0.912198 7.0723H0.22805C0.167567 7.0723 0.109562 7.04827 0.0667941 7.0055C0.0240266 6.96273 0 6.90473 0 6.84425V0.458861C0 0.398378 0.0240266 0.340373 0.0667941 0.297606C0.109562 0.254838 0.167567 0.230812 0.22805 0.230812H0.912198C1.09365 0.230812 1.26766 0.302891 1.39596 0.431193C1.52427 0.559496 1.59635 0.733512 1.59635 0.91496V6.50217Z" fill="#A6B1BB" />
			</svg>
		</div>
	);
}

export default applyWrappers(Dislike, styles);