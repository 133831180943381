import actionConstants from "constants/actions";
import _ from 'lodash';

const initialState = {
	userActorMetricsObj: {},
	actorMetricIds: {}
};

const actorMetricConfig =  ["strategy", "capability", "culture"];

const formatActorMetrics = (userActorMetrics) => {
	const metricObj = _.groupBy(userActorMetrics, 'actorId');
	Object.keys(metricObj).map((eachActorId) => {
		const actorMetricObj = _.groupBy(metricObj[eachActorId], 'metricId');
		Object.keys(actorMetricObj).map((eachMetricId) => {
			actorMetricObj[eachMetricId] = actorMetricObj[eachMetricId][0];
			return 1;
		})
		metricObj[eachActorId] = actorMetricObj;
		return 1;
	});
	return metricObj;
}

const formatActorMetricsObject = (metrics) => {
	const actorMetricIds = {};
	actorMetricConfig.map((eachActorMetric) => {
		metrics.map((eachMetric) => {
			if(eachMetric.key === eachActorMetric) {
				actorMetricIds[eachActorMetric] = eachMetric.id;
			}
			return 1;
		});
		return 1;
	});
	return actorMetricIds;
}

const updateActorMetrics = (userActorMetrics, updatedUserActorMetrics, is_action_data = true) => {
	updatedUserActorMetrics.map((eachUpdatedMetric) => {
		if(!(eachUpdatedMetric.isStaticEvent && is_action_data)){
			userActorMetrics[eachUpdatedMetric.actorId][eachUpdatedMetric.metricId] = eachUpdatedMetric;
		}
	});

	return userActorMetrics;

}

const userActorMetrics = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConstants.SET_INIT_DATA:
			return {
				userActorMetricsObj: formatActorMetrics(action.payload.userActorMetrics),
				actorMetricIds: formatActorMetricsObject(action.payload.metrics),
				actorMetricKeys: actorMetricConfig
			};
		case 'ACTION_RESPONSE_POPUP_CLOSE':
			return {
				...state,
				userActorMetricsObj: updateActorMetrics(state.userActorMetricsObj, action.payload.userActorMetrics)
			};
		case 'PENDING_ACTION_POPUP_CLOSE':
			return {
				...state,
				userActorMetricsObj: updateActorMetrics(state.userActorMetricsObj, action.payload.userActorMetrics)
			};
		case "ADD_STATIC_EVENT_METRICS":
			return {
				...state, 
				userActorMetricsObj: updateActorMetrics(state.userActorMetricsObj, action.payload.userActorMetrics, false)
			}
		default:
			return state;
	}
};

export default userActorMetrics;