import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './overlay.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Leaderboard from 'components/leaderboard';
import Objectives from 'components/objectives';
import ActionPopup from 'components/actionPopup';
import ActorPopup from 'components/actorPopup';
import MonthlyReportPopup from 'components/monthlyReportPopup';
import MetricPopup from 'components/metricPopup';
import ReportPopup from 'components/reportPopup';
import EventPopup from 'components/eventPopup';
import AdvisorPopup from 'components/advisorPopup';
import StartSimPopup from 'components/startSimPopup';
import TimerCompletedPopup from 'components/timerCompletedPopup';
import MentorTipsPopup from 'components/mentorTipsPopup';
import EventActionPopup from 'components/eventActionPopup';
import LogoutPopupComponent from 'components/logoutPopupComponent';
import analyticsUtil from 'util/analyticsUtil';
import ActionResponsePopup from '../../components/actionResponsePopup/ActionResponsePopup';
import PendingActionResponsePopup from '../../components/pendingActionResponsePopup/PendingActionResponsePopup';
import BUUnlockPopup from 'components/buUnlockPopup';
import ActorConvertedPopup from '../../components/actorConvertedPopup';
import TimerReminderPopup from 'components/timerReminderPopup';

const Overlay = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const dispatch = useDispatch();

	const [popupIndex, setPopupIndex] = useState(50);

	const closePopup = (e) => {
		e.stopPropagation();
		analyticsUtil.track('POPUP_CLOSED', { popup: props.uiState.overlayComponent, type: props.uiState.overlayComponentType });
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: '',
				selectedActor: null,
				selectedAction: null,
				highlightActors: false,
				actorSelectionType: '',
				isActorSelectionRequired: false
			}
		});
	}

	const renderOverlayComponent = () => {
		switch (props.uiState.overlayComponent) {

			case 'LEADERBOARD_POPUP':
				return <Leaderboard />

			case 'OBJECTIVES_POPUP':
				return <Objectives />

			case 'ACTION_POPUP':
				return <ActionPopup setPopupIndex={setPopupIndex} popupIndex={popupIndex} />

			case 'ACTOR_POPUP':
				return <ActorPopup />

			case 'EVENT_POPUP':
				return <EventPopup />

			case 'MENTOR_TIPS_POPUP':
				return <MentorTipsPopup />

			case 'EVENT_ACTION_POPUP':
				return <EventActionPopup />

			case 'ADVISOR_POPUP':
				return <AdvisorPopup />

			case 'MONTHLY_REPORT_POPUP':
				return <MonthlyReportPopup />

			case 'BU_UNLOCK_POPUP':
				return <BUUnlockPopup />

			case 'ACTOR_CONVERTED_POPUP':
				return <ActorConvertedPopup />
				
			case 'METRIC_POPUP':
				return <MetricPopup />

			case 'REPORT_POPUP':
				return <ReportPopup />

			case 'START_SIM_POPUP':
				return <StartSimPopup />

			case 'TIME_COMPLETED_POPUP':
				return <TimerCompletedPopup />

			case 'TIME_REMINDER_POPUP': 
				return <TimerReminderPopup/>

			case 'LOGOUT_POPUP':
				return <LogoutPopupComponent />

			case 'ACTION_RESPONSE_POPUP':
				return <ActionResponsePopup />

			case 'PENDING_ACTION_RESPONSE_POPUP':
				return <PendingActionResponsePopup />

			default:
				return (
					<div>Render your component here</div>
				);
		}
	}

	const renderComponent = () => {
		switch (props.uiState.overlayComponentType) {
			case 'BOTTOM':
				return (
					<div
						style={{ zIndex: popupIndex }}
						styleName="complete-container bottom-container"
						className={css(myStyles.completeContainer)}
					// onClick={(e) => { closePopup(e); }}
					>
						<div styleName="bottom-container-component" className={css(myStyles.bottomContainerComponent)}>
							{renderOverlayComponent()}
						</div>
					</div>
				);
			case 'RIGHT':
				return (
					<div
						style={{ zIndex: popupIndex }}
						styleName="complete-container right-container"
						className={css(myStyles.completeContainer)}
						// onClick={(e) => { closePopup(e); }}
					>
						<div styleName="right-container-component" className={css(myStyles.rightContainerComponent)}>
							{renderOverlayComponent()}
						</div>
					</div>
				);
			case 'LEFT':
				return (
					<div
						style={{ zIndex: popupIndex }}
						styleName="complete-container left-container"
						className={css(myStyles.completeContainer)}
					// onClick={(e) => { closePopup(e); }}
					>
						<div styleName="left-container-component" className={css(myStyles.leftContainerComponent)}>
							{renderOverlayComponent()}
						</div>
					</div>
				);
			case 'CENTER':
				return (
					<div
						style={{ zIndex: popupIndex }}
						styleName="complete-container center-container"
						className={css(myStyles.completeContainer)}
					// onClick={(e) => { closePopup(e); }}
					>
						{renderOverlayComponent()}
					</div>
				);
			default:
				return null;
		}
	}

	return renderComponent();
}

export default applyWrappers(Overlay, styles);
