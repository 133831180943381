import kfetch from 'util/kfetch';
import urls from 'constants/urls';
import actionConstans from 'constants/actions';
import { checkIfPresent } from 'util/functions';

export const updateTimer = (payload, callback) => (dispatch) => {

	kfetch(urls.GAME_UPDATE_TIMER, payload, 'POST')
		.then((response) => {
			if(response.success) {
				console.log("Timer updated in DB");
			}
			if (checkIfPresent(callback)) {
				callback();
			}
		})
		.catch((error) => {
			if(error.error && error.error.status && error.error.data && error.error.data.message){
				let err = error.error;
				if(err.status == 500 && err.data.message == 'Looks like you have not loggedin to this service yet.'){
					window.location.href = urls.LOG_IN;
				}
			}
		})
};