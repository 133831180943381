import React, { useState, useEffect } from 'react';
import styles from './reportPopup.module.sass';
import { useSelector, useDispatch } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import ReportCard from 'commonComponents/reportCard';
import Bulb from 'svgComponents/bulb'; 

import analyticsUtil from 'util/analyticsUtil';

const ReportPopup = (props) => {

	const myProps = useSelector(state => {
		return {
			uiState: state.uiState,
			userReports: state.userReports
		}
	});

	const onReportPopupPopupClick = (e) => {
		e.stopPropagation();
	}

	const closePopup = () => {
		analyticsUtil.track('IN_GAME_REPORT_POPUP_CLOSED', {});

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
		});
	}

	const myStyles = getSkin(props.skinGuide);
	const dispatch = useDispatch();

	const renderReports = () => {
		return myProps.userReports.reportList.map((eachReport) => {
			const reportDetails = myProps.userReports.actionOptionsReports.find((eachActionReport) => {
				return eachActionReport.id === eachReport;
			});
			return (
				<ReportCard reportDetails={reportDetails} />
			);
		});
	}

	return (
		<div className={css(myStyles.reportPopupContainer)} styleName="reportPopup-container" onClick={onReportPopupPopupClick}>
			<div styleName="header-container">
				<div className={css(myStyles.headerContent)} styleName="header-content">{props.getLabel('label_in_game_reports_pop_up_title')}</div>
				<div className={css(myStyles.headerClose)} styleName="header-close" onClick={closePopup}>
					<div styleName="close-image">
						<Close />
					</div>
				</div>
			</div>
			<div className={css(myStyles.headerLine)} styleName="header-line"></div>
			<div styleName="reports-container">
				<div styleName="header-description-container">
					<Bulb/>
					<div className = {css(myStyles.headerDescription)} styleName="header-description">
						{props.getLabel('label_in_game_reports_pop_up_desc')}
					</div>
				</div>
				<div styleName="content-container">
				{renderReports()}
				</div>
			</div>
		</div>
	);
}

export default applyWrappers(ReportPopup, styles);
