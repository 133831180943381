import kfetch from 'util/kfetch';
import urls from 'constants/urls';
import actionConsts from 'constants/actions';
import { checkIfPresent, removeActorPopupPayload } from 'util/functions';

const formatActionBody = (payload, userActionOptions) => {
	
	const list = userActionOptions.userActionOptionList;
	const selectedActors = userActionOptions.selectedActors;
	selectedActors[list.length] = payload.actorIds; 
	return {
		actionOptionId: payload.actionOptionId,
		poolId: payload.poolId,
		actorIds: payload.actorIds,
		selectedActors
	};
}

export const takeAction = (payload, callback) => (dispatch, getState) => {

	const url = urls.ACTION_API.replace(
		'{ACTION_URL}',
		payload.route
	);
	
	// add selectedActors
	const {userActionOptions} = getState();
	const body = formatActionBody(payload, userActionOptions);

	kfetch(url, body, 'POST')
		.then((response) => {
			if (checkIfPresent(callback)) {
				callback();
			}

			//New Actor converted, Dispatch for actor converted popup (after action response popup is closed)
			const NewActorConverted = 
				response.userActorMetrics
				.filter(actor => actor.metricId == 3)
				.map(eachactor => eachactor.actorId);

			if(NewActorConverted.length > 0){
				dispatch({
					type: "NEW_ACTOR_CONVERTED",
					payload: {
						NewActorConverted: NewActorConverted
					}
				});
			}

			if (response.actionResponse != undefined) {
				if (payload.isEvent) {
					dispatch({
						type: 'ADD_ACTIVE_EVENT_RESPONSE',
						payload: {
							...response.actionResponse,
							day: response.userState.currentDay,
							userMetrics: response.userMetrics,
							userActorMetrics: response.actionResponse.metricsImpacted
						}
					});
				}
				dispatch(
					{
						type: 'SET_ACTIVE_ACTION_RESPONSE',
						payload: {
							...response,
							userState: response.userState,
							previousDay: payload.currentDay,
							previousAverageAdoption: payload.currentAverageAdoption,
							actorIds: payload.actorIds,
							userMetrics: response.userMetrics,
							userActorMetrics: response.actionResponse.isActorMetricsImpacted? response.actionResponse.metricsImpacted: [],
						}
					}
				);
			}
			if (response.pendingActionResponse != undefined && response.pendingActionResponse.length > 0) {

				// Network gets revelaed in pending action response
				response.pendingActionResponse.map((eachPendingResponse) => {
					if (eachPendingResponse.isNetworkRevealed) {
						dispatch({
							type: actionConsts.ADD_REVEALED_NETWORK,
							payload: {
								day: eachPendingResponse.day,
								networkId: eachPendingResponse.revealedNetworkId[0]
							}
						});
					}
				});

			}
			if (response.actionResponse.isRecognitionBadgeGiven) {
				dispatch({
					type: actionConsts.ADD_RECOGNITION_BADGE,
					payload: {
						actorIds: payload.actorIds
					}
				});
			}
			if (response.actionResponse.isNetworkCreated) {
				dispatch({
					type: actionConsts.ADD_NETWORT_BADGE,
					payload: {
						actorIds: payload.actorIds
					}
				});
			}

			// Network gets revelaed in action response
			if (response.actionResponse.isNetworkRevealed) {
				dispatch({
					type: actionConsts.ADD_REVEALED_NETWORK,
					payload: {
						networkId: response.actionResponse.revealedNetworkId[0]
					}
				});
			}

			//Action Response Popup
			if (response.actionResponse != null && response.actionResponse != undefined && !payload.isEvent) {
				dispatch(removeActorPopupPayload());
				dispatch({
					type: 'UPDATE_UI_STATE',
					payload: {
						showOverlay: true,
						overlayComponentType: 'BOTTOM',
						highlightDesktopHeader: false,
						overlayComponent: 'ACTION_RESPONSE_POPUP'
					}
				});
			}


			if(!payload.isEvent) {

				// setTimeout(() => {
					dispatch({
						type: 'ADD_DETAILS_FOR_MONTHLY_REPORT',
						payload: {
							dayBeforeAction: payload.currentDay,
							dayAfterAction: response.userState.currentDay,
							isAnyEventPresent: response.event.type != null || response.event.type != undefined,
							isAnyActionPresent: true
						}
					});
				// }, 2000);
			}

		})
		.catch((error) => {
			if (checkIfPresent(callback)) {
				callback();
			}
			if(error.error && error.error.status && error.error.data && error.error.data.message){
				let err = error.error;
				if(err.status == 500 && err.data.message == 'Looks like you have not loggedin to this service yet.'){
					window.location.href = urls.LOG_IN;
				}
			}
		})
};
