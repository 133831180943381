import React, { useState, useEffect } from 'react';
import styles from './eventActionPopup.module.sass';
import { useSelector, useDispatch } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Day from 'svgComponents/day';
import ActionActor from 'commonComponents/actionActor';
import { addElementToArray, removeElementFromArray, checkInRange } from 'util/functions';
import ActionOptions from 'commonComponents/actionOptions';
import { takeAction } from 'actions/apiActions/action';

import analyticsUtil from 'util/analyticsUtil';

const ActionPopup = (props) => {

	const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			actions: state.actions,
			user: state.user,
			actors: state.actors
		};
	});

	const onActionPopupPopupClick = (e) => {
		e.stopPropagation();
	}

	const [enableAction, setEnableAction] = useState(false);
	// const [enableOptions, setEnableOptions] = useState(false);
	const [optionId, setOptionId] = useState(myProps.uiState.selectedEventOption);
	const [poolId, setPoolId] = useState(null);

	// const actionDetails = myProps.actions.actionsList.find((eachAction) => {
	// 	return eachAction.id === myProps.uiState.selectedAction;
	// });

	const actionOptionDetails = myProps.actions.actionOptionsList.find((eachOption) => {
		return eachOption.id == myProps.uiState.selectedEventOption;
	});

	useEffect(() => {
		const uiState = myProps.uiState;

		const actorsRequiredInRange = checkInRange(uiState.actionSelectedActors.length, uiState.minActorsRequired, uiState.maxActorsRequired);

		if(uiState.isActorSelectionRequired) {

			// if (actorsRequiredInRange && !enableOptions) {
			// 	setEnableOptions(true);
			// } else if (!actorsRequiredInRange && enableOptions) {
			// 	setEnableOptions(false);
			// }

			if (optionId !== null && actorsRequiredInRange && !enableAction) {
				setEnableAction(true);
			} else if ((!actorsRequiredInRange || optionId === null) && enableAction ) {
				setEnableAction(false);
			}
		} else {
			// if(!enableOptions) {
			// 	setEnableOptions(true);
			// }

			if(optionId !== null && !enableAction) {
				setEnableAction(true);
			}
		}

		// if (!enableOptions && props.popupIndex > 50) {
		// 	props.setPopupIndex(50);
		// }

		if (optionId === null || poolId === null) {
			setOptionId(actionOptionDetails.id);
			setPoolId(actionOptionDetails.actionOptionPool[0].poolId);
		}

	}, [myProps.uiState, enableAction, optionId]);

	const removeActor = (id) => {
		let actorList = myProps.uiState.actionSelectedActors;
		actorList = removeElementFromArray(actorList, id);

		analyticsUtil.track('ACTOR_DESELECTED', {
			actionId: myProps.uiState.selectedAction,
			eventOption: myProps.uiState.selectedEventOption,
			actorId: id
		});

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				actionSelectedActors: actorList
			}
		});
	}

	const closePopup = () => {
		analyticsUtil.track('POPUP_CLOSED', { popup: myProps.uiState.overlayComponent, type: myProps.uiState.overlayComponentType });
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: '',
				actionSelectedActors: [],
				selectedAction: null,
				highlightActors: false,
				actorSelectionType: '',
				isActorSelectionRequired: false,
				minActorsRequired: 0,
				maxActorsRequired: 0,
				actionDayCost: 0
			}
		});
	}

	const myStyles = getSkin(props.skinGuide);
	const dispatch = useDispatch();

	const getActorDetails = (id) => {
		const actorDetails = myProps.actors.actorsList.find((eachActor) => {
			return eachActor.id === id;
		});
		return actorDetails;
	}

	const renderActorsContainer = () => {
		let i = 0, j = 0;
		const selectedActors = myProps.uiState.actionSelectedActors;
		let actorDivs = [];
		for (i = 0; i < myProps.uiState.maxActorsRequired; i++) {
			if (j < selectedActors.length) {
				actorDivs.push(
					<ActionActor
						isEmptyActor={false}
						id={selectedActors[j]}
						onDeselect={removeActor}
						actorDetails={getActorDetails(selectedActors[j])}
					/>
				);
				j++;
			} else {
				actorDivs.push(
					<ActionActor
						isEmptyActor={true}
					/>
				)
			}
		}
		return (
			<div className={css(myStyles.actorsContainer)} styleName="actors-container">
				{actorDivs}
			</div>
		);
	}

	// const renderOptions = () => {
	// 	if (actionOptionDetails.length === 1) {
	// 		return null;
	// 	}
	// 	return (
	// 		<div className={css(myStyles.optionContainer)} styleName="option-container">
	// 			<ActionOptions
	// 				setOptionId={setOptionId}
	// 				setPoolId={setPoolId}
	// 				options={actionOptionDetails}
	// 				optionId={optionId}
	// 				poolId={poolId}
	// 				setPopupIndex={props.setPopupIndex}
	// 				uiDisplayType={actionOptionDetails[0].uiDisplayType}
	// 			/>
	// 		</div>
	// 	);
	// }

	const openEventPopup = () => {
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: true,
				overlayComponentType: 'BOTTOM',
				highlightDesktopHeader: false,
				overlayComponent: 'EVENT_POPUP'
			}
		});
	}

	const submitAction = () => {
		if (!enableAction) {
			return;
		}
		const optionDetails = myProps.actions.actionOptionsList.find((eachOption) => {
			return optionId == eachOption.id;
		})
		const payload = {
			actionOptionId: optionId,
			poolId: poolId,
			actorIds: myProps.uiState.actionSelectedActors,
			route: optionDetails.route,
			currentDay: myProps.user.currentDay,
			isEvent: true
		};
		dispatch(takeAction(payload, openEventPopup));
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				isActorSelectionRequired: false,
				highlightActors: false
			}
		});
	}

	return (
		<div className={css(myStyles.container)} styleName="container" onClick={onActionPopupPopupClick}>
			<div className={css(myStyles.header)} styleName="header">
				<div className={css(myStyles.image)} styleName="image">
					<img src={props.getImage(actionOptionDetails.image)} alt={`action-img-${actionOptionDetails.id}`} width="100%" />
				</div>
				<div className={css(myStyles.text)} styleName="text">
					{props.getLabel(actionOptionDetails.actionOptionPool[0].name)}
				</div>
			</div>
			<div className={css(myStyles.description)} styleName="description">
				{props.getLabel(actionOptionDetails.actionOptionPool[0].description)}
			</div>
			<div styleName="day-container">
				<div styleName="day-image">
					<Day />
				</div>
				<div className={css(myStyles.dayText)} styleName="day-text">
					{myProps.uiState.actionDayCost} {props.getLabel('label_days')}
				</div>
			</div>
			{
				myProps.uiState.isActorSelectionRequired
					? renderActorsContainer()
					: null
			}
			{/* {
				enableOptions
					? renderOptions()
					: null
			} */}
			<div className={css(myStyles.footer)} styleName="footer">
				<div onClick={closePopup} className={css(myStyles.cancel)} styleName="cancel">{props.getLabel('label_cancel')}</div>
				<div className={enableAction ? css(myStyles.proceed) : css(myStyles.proceedDisable)} styleName="proceed" onClick={submitAction}>{props.getLabel('label_proceed')}</div>
			</div>
		</div>
	);
}

export default applyWrappers(ActionPopup, styles);
