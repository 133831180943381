import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from './walkthroughContent.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';

import applyWrappers from 'wrappers/ComponentWrapper';
import {
	startTutorial,
	pauseTutorial,
	endTutorial,
	addStepsToTutorial,
	updateStepNumber
} from 'actions/tutorial';

import { walkthroughHighlightObject } from 'util/functions';

const WalkthroughContent = (props) => {

	const myProps = useSelector((state) => {
		return {
			user: state.user
		};
	});

	const myStyles = getSkin(props.skinGuide);

	const dispatch = useDispatch();

	const getComponentHighlightObject = (impactType, label) => {
		const payload = {
			actor: {
				strategy: false,
				capability: false,
				culture: false
			},
			action: {
				strategy: false,
				capability: false,
				culture: false
			}
		};
		switch (impactType) {
			case 'ACTOR':
				return {
					onEnterPayload: {
						...payload,
						actor: walkthroughHighlightObject(label)
					},
					onLeavePayload: payload
				}
			case 'ACTION':
				return {
					onEnterPayload: {
						...payload,
						action: walkthroughHighlightObject(label)
					},
					onLeavePayload: payload
				}

			default:
				break;
		}
	}

	const getLabelWithComponents = (label, impactType) => {
		const labelArray = label.split('**');
		const labelDivs = labelArray.map((eachLabel) => {
			if (eachLabel[0] === '{' && eachLabel[eachLabel.length - 1] === '}') {
				return getHoverText(eachLabel.slice(1, -1), impactType);
			}
			return eachLabel;
		});
		return labelDivs;
	}

	const getHoverText = (text, impactType) => {
		const payload = getComponentHighlightObject(impactType, text);
		return (
			<span
				className={css(myStyles.highlightText)}
				onMouseEnter={() => { dispatch({ type: 'UPDATE_WALKTHROUGH_UI_STATE', payload: payload.onEnterPayload }) }}
				onMouseLeave={() => { dispatch({ type: 'UPDATE_WALKTHROUGH_UI_STATE', payload: payload.onLeavePayload }) }}
			>
				{text}
			</span>
		);
	}

	const getContentForWalkthrough = () => {
		switch (props.contentKey) {
			case 'GAME_START_ECOSYSTEM':
				return props.getLabel('label_wt_game_start_ecosystem');
			case 'GAME_START_YOU':
				return props.getLabel('label_wt_game_start_you');
			case 'GAME_START_ORGANISATION':
				return getLabelWithComponents(props.getLabel('label_wt_game_start_org'), 'ACTOR');
			case 'GAME_START_CLIENTS':
				return props.getLabel('label_wt_game_start_clients');
			case 'GAME_START_PARTNERS':
				return props.getLabel('label_wt_game_start_partners');
			case 'GAME_START_ACTIONS':
				return getLabelWithComponents(props.getLabel('label_wt_game_start_actions'), 'ACTION');
			case 'GAME_INITIAL_ACTION_TAKE':
				return props.getLabel('label_wt_initial_action_start', '', {MAX_DAYS : myProps.user.groupDetails.days});
			case 'GAME_AFTER_ACTION_HISTORY':
				return props.getLabel('label_wt_history_after_action');
			case 'GAME_AFTER_ACTION_METRICS':
				return props.getLabel('label_wt_metrics_after_action');
			default:
				break;
		}
	}

	return (
		<div styleName="complete-container">
			<div styleName="header-container">
				<div styleName="image-container">
					<img src={props.getImage('IMAGE_CDO')} alt="advisor" width="100%" height="100%" />
				</div>
				<div className={css(myStyles.headingText)} styleName="heading-text-container">{props.getLabel('label_name_exeAdv')}</div>
			</div>
			<div className={css(myStyles.contentText)} styleName="content-container">
				{getContentForWalkthrough()}
			</div>
		</div>
	);
}

export default applyWrappers(WalkthroughContent, styles);
