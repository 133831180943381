import React , {useState} from 'react';
import { useDispatch } from 'react-redux';
import styles from './simObjectives.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import HeaderSeparation from 'components/header/headerSeparation';
import Objectives from 'svgComponents/objectives';

import analyticsUtil from 'util/analyticsUtil';

const SimObjectives = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const dispatch = useDispatch();
	const [showHoverMessage, setShowHoverMessage] = useState(false);

	const tooltip = <>
		<div className={css(myStyles.toolTipArrow)} styleName="arrow"></div>
			<div className={css(myStyles.showObjectivesHover)} styleName = "show-objectives-hover">
					{props.getLabel('label_objectives')}
			</div>
		</>;

	const openObjectives = () => {
		analyticsUtil.track('OBJECTIVES_CLICKED');

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: true,
				overlayComponentType: 'CENTER',
				highlightDesktopHeader: false,
				overlayComponent: 'OBJECTIVES_POPUP'
			}
		});
	}

	return (
		<div styleName="objectives-container">
			<div className={css(myStyles.objectiveImageContainer)} styleName="objectives-image-container" 
				 onClick={openObjectives}
				onMouseEnter = {()=> setShowHoverMessage(true)}
				onMouseLeave = {()=> setShowHoverMessage(false)}>

				<div styleName="objectives-image">
					<Objectives />
				</div>
			{showHoverMessage && tooltip}
			</div>
		</div>
	);
}

export default applyWrappers(SimObjectives, styles);
