import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		startSimPopupContainer: {
			border: `2px solid ${skin.secondaryColor}`,
			backgroundColor: skin.white
		},
		advisorHeading: {
			...myTypography.body1,
			fontSize: '20px',
			color: skin.grayColor1,
			fontWeight: 600,
			'@media (min-width: 1440px)' : {
				fontSize: '22.5px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '25px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '30px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '40px'
			},
		},
		advisorDesc: {
			...myTypography.body1,
			color: skin.grayColor2
		},
		startButton: {
			backgroundColor: skin.secondaryColor,
			color: skin.white,
			...myTypography.body1,
			fontWeight: 600,
			':hover': {
				boxShadow: `0px 0px 4px ${hexToRgbA(skin.black, 0.5)}`
			}
		},
		normalButton: {
			// backgroundColor: skin.secondaryColor,
			color: skin.grayColor2,
			...myTypography.body1,
			fontWeight: 600,
			':hover': {
				// boxShadow: `0px 0px 4px ${hexToRgbA(skin.black, 0.5)}`,
				textDecoration: 'underline'
			}
		},
		timerText: {
			...myTypography.body2,
			color: skin.grayColor1,
			fontStyle: 'italic'
		}
	});
}

export default getSkin;