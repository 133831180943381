import React from 'react';
import styles from './rightArrow.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const RightArrow = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 66 66" fill="none">
				<circle cx="34" cy="34" r="32" fill="#835AC5" />
				<circle cx="32" cy="32" r="31.5" stroke="#FFFFFF" />
				<path d="M28 21L39 32.5L28 44" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</div>
	);
}

export default applyWrappers(RightArrow, styles);