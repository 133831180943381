import kfetch from 'util/kfetch';
import urls from 'constants/urls';
import actionConstans from 'constants/actions';
import { checkIfPresent } from 'util/functions';

const setLeaderboardData = (payload) => ({
	type: actionConstans.SET_LEADERBOARD_DATA,
	payload
})

export const fetchLeaderboardData = (callback) => (dispatch) => kfetch(urls.LEADERBOARD_API)
	.then((response) => {
		dispatch(setLeaderboardData(response.data));
		if (checkIfPresent(callback)) {
			callback();
		}
	})
	.catch((error) => {
		if(error.error && error.error.status && error.error.data && error.error.data.message){
			let err = error.error;
			if(err.status == 500 && err.data.message == 'Looks like you have not loggedin to this service yet.'){
				window.location.href = urls.LOG_IN;
			}
		}
	})