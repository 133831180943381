import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		metricName: {
			...myTypography.bodyCondensed,
			fontSize: '8px',
			textTransform: 'uppercase',
			color: skin.grayColor2,
			'@media (min-width: 1440px)' : {
				fontSize: '9px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '10px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '12px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '16px'
			},
		},
		metricNamePopup: {
			...myTypography.bodyCondensed,
			fontSize: '11px',
			textTransform: 'uppercase',
			color: skin.grayColor2,
			'@media (min-width: 1440px)' : {
				fontSize: '12.375px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '13.75px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '16.5px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '22px'
			},
		},
		metricNumber: {
			...myTypography.number,
			fontWeight: 600,
			fontSize: '10px',
			color: skin.grayColor2,
			'@media (min-width: 1440px)' : {
				fontSize: '11.25px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '12.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '15px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '20px'
			},
		},
		metricNumberPopup: {
			...myTypography.number,
			fontWeight: 600,
			color: skin.grayColor2,
			fontSize: '14px',
			'@media (min-width: 1440px)' : {
				fontSize: '15.75px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '17.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '21px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '28px'
			},
		},
		metricContainer: {
			borderRight: `1px solid ${skin.grayColor5}`
		},
		metricBox: {
			boxShadow: `0px 0px 8px ${skin.blue}`
		},
		metricChangeNegative: {
			background: `linear-gradient(360deg, #FD5382 0%, rgba(253, 83, 130, 0) 121.43%)`,
			color: skin.white,
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '18px',
			'@media (min-width: 1440px)' : {
				fontSize: '20.25px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '22.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '27px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '36px'
			},
		},
		metricChangePostive: {
			background: `linear-gradient(359.16deg, #1BE1D4 9.12%, rgba(27, 225, 212, 0) 126.92%)`,
			color: skin.white,
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '18px',
			'@media (min-width: 1440px)' : {
				fontSize: '20.25px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '22.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '27px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '36px'
			},
		}
	});
}

export default getSkin;