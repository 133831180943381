import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		container: {
			background: skin.grayColor6,
			border: `1px solid ${skin.grayColor5}`
		},
		titleContainer: {
			background: skin.grayColor7,
		},
		title: {
			color: skin.secondaryColor,
			...myTypography.body1,
			fontWeight: 600,
		},
		designation: {
			color: skin.grayColor3,
			...myTypography.body1,
			fontWeight: 600,
		},
		titleLine: {
			background: skin.secondaryColor
		},
		point: {
			...myTypography.body1,
			color: skin.grayColor1,
			// '::before': {
			// 	content: '\'\'',
			// 	position: 'absolute',
			// 	width: '6px',
			// 	height: '6px',
			// 	background: skin.secondaryColor,
			// 	left: '10px',
			// 	top: '10px'
			// }
		}
	});
}

export default getSkin;