import React, { useState, useEffect, useRef } from 'react';
import styles from './actorMetric.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import MetricBar from 'commonComponents/metricBar';
import { TweenMax } from 'gsap';
import { useSelector, useDispatch } from 'react-redux';
import MetricAdoption from 'svgComponents/metricAdoption/MetricAdoption.jsx';

const ActorMetric = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const changeRef = useRef(null);
	const [animate, setAnimate] = useState(false);

	const popup = props.popup ? props.popup : false;

	const [enableAnimation, setEnableAnimation] = useState(false);

	const myProps = useSelector(state => {
		return {
			storylineDefaults: state.storyline.storylineDefaults
		}
	});

	useEffect(() => {
		if (!enableAnimation) {
			setEnableAnimation(true);
			return;
		}
		setAnimate(true);
		setTimeout(() => {
			setAnimate(false);
		}, 3000);
	}, [props.day, props.value]);

	const renderChange = () => {
		// Positive response (Blue Animation)
		if (props.diff > 0) {
			return (
				<div ref={changeRef} className={css(myStyles.metricChangePostive)} styleName="metric-change-animation metric-change">
					{`+${props.diff}`}
				</div>
			);
		} 
		// Negative response (Red Animation)
		else {
			return (
				<div ref={changeRef} className={css(myStyles.metricChangeNegative)} styleName="metric-change-animation metric-change">
					{`${props.diff}`}
				</div>
			);
		}
	}

	const getClassNames = () => {
		if(props.borderValue && props.highlight) {
			return css(myStyles.metricContainer, myStyles.metricBox)
		} else if (props.borderValue && !props.highlight) {
			return css(myStyles.metricContainer);
		} else if (!props.borderValue && props.highlight) {
			return css(myStyles.metricBox);
		}
		return null;
	}

	const metricDisplay = () => {
		if(props.name === 'label_strategy' && props.value >= myProps.storylineDefaults[`strategyIndexTarget`] 
			|| props.name === 'label_capability' && props.value >= myProps.storylineDefaults[`capabilityIndexTarget`]
			|| props.name === 'label_culture' && props.value >= myProps.storylineDefaults[`cultureIndexTarget`]
		) 
		{return <MetricAdoption />}
	}

	return (
		<div
			key={`actor-${props.actorId}-eachMetric-${props.metricId}`}
			className={getClassNames()}
			styleName="metric-container">
			<div styleName={popup ? "metric-bar-popup" : "metric-bar"} >
				<MetricBar value={props.value} showAnimation={false} initiative={props.name}/>
			</div>
			<div styleName="metric-details">
				<div styleName = 'metricAdoption'>
					<div className={popup ? css(myStyles.metricNumberPopup) : css(myStyles.metricNumber)} styleName="metric-number">{props.value}%</div>
					<div styleName = 'metric-tick' >
						{metricDisplay()}
				    </div>
				</div>
				<div className={popup ? css(myStyles.metricNamePopup) :css(myStyles.metricName)} styleName="metric-name">
					{props.name == 'label_strategy'? props.getLabel('label_strategy_cap'): props.getLabel(props.name)}
				</div>
			</div>
			{
				animate
					? renderChange()
					: null
			}
		</div>
	);
}

export default applyWrappers(ActorMetric, styles);