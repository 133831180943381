import actionConstants from "constants/actions";

const initialState = {
	userEventsList: [],
	activeEvent: {}
};

const userEvents = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConstants.SET_INIT_DATA:
			return {
				...state,
				userEventsList: action.payload.userEvents
			};
		case 'ADD_ACTIVE_EVENT':
			return {
				...state,
				activeEvent: {
					...action.payload,
					eventId: action.payload.id,
					actionOptionId: action.payload.response ? action.payload.response.aOId : null,
					responseAdded: false,
					userActorMetrics: action.payload.metricsImpacted
				}
			}
		case 'ADD_ACTIVE_EVENT_RESPONSE':
			return {
				...state,
				activeEvent: {
					...state.activeEvent,
					...action.payload,
					responseAdded: true
				}
			}
		case 'PUSH_ACTIVE_EVENT_TO_LIST':
			const updatedList = state.userEventsList;
			updatedList.push(state.activeEvent)
			return {
				userEventsList: updatedList,
				activeEvent: {}
			}
		default:
			return state;
	}
};

export default userEvents;