import actionConstants from "constants/actions";

const initialState = {
	userMetrics: [],
	userActorMetrics: []
};

const updateUserMetrics = (oldUserMetrics, newUserMetrics) => {
	newUserMetrics.map((eachNewUserMetric) => {
		let foundMetric = oldUserMetrics.find((eachOldUserMetric) => {
			return eachOldUserMetric.id === eachNewUserMetric.id;
		});
		if(foundMetric == undefined ) {
			oldUserMetrics.push(eachNewUserMetric);
		}
	});
	return oldUserMetrics;
}

const allMetrics = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConstants.SET_INIT_DATA:
			return {
				...state,
				userMetrics: action.payload.allUserMetrics,
				userActorMetrics: action.payload.allUserActorMetrics,
			};
		case 'ACTION_RESPONSE_POPUP_CLOSE':
			let oldUserMetrics = state.userMetrics;
			let oldUserActionMetrics = state.userActorMetrics;

			return {
				...state,
				userMetrics: updateUserMetrics(oldUserMetrics, action.payload.userMetrics),
				userActorMetrics: [...oldUserActionMetrics, ...action.payload.userActorMetrics],
			};
		case 'ADD_DYNAMIC_EVENT_METRICS':
			let oldUserMetricsEvents = state.userMetrics;

			return {
				...state,
				userMetrics: updateUserMetrics(oldUserMetricsEvents, action.payload.userMetrics),
			};
		case "UPDATE_METRICS":
			return {
				...state,
				userMetrics: action.payload.allUserMetrics,
				userActorMetrics: action.payload.allUserActorMetrics
			}
		default:
			return state;
	}
};

export default allMetrics;