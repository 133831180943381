import React from 'react';
import styles from './verticalBar.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { ResponsiveBar } from '@nivo/bar'

const VerticalBar = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<ResponsiveBar
				data={props.data}
				keys={[props.filterState]}
				indexBy="actor"
				minValue={0}
				maxValue={100}
				padding={0.65}
				// innerPadding={0.5}
				margin={{ top: 10, right: 10, bottom: 80, left: 60 }}
				// padding={0.3}
				colors={[props.graphColor]}
				borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: -45,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: props.legend && props.legend.yLabel ? props.legend.yLabel : '(%)',
					legendPosition: 'middle',
					legendOffset: -40
				}}
				enableLabel={false}
				label={d => `${d.actor}: ${d.metricValue}`}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor="#FDAD28"
				enableGridX={false}
				enableGridY={true}
				gridYValues={[0, 20, 40, 60, 80, 100]}
				animate={true}
				motionStiffness={90}
				motionDamping={15}
			/>
		</div>
	);
}

export default applyWrappers(VerticalBar, styles);
