import React from 'react';
import styles from './logout.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const Logout = (props) => {

	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 14 13" fill="none">
				<circle cx="8.28375" cy="7.29" r="5.69" fill="#FDAD28" />
				<path d="M1 6.50219H9.53125" stroke="#14324B" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M7.5 8.53344L9.53125 6.50219L7.5 4.47094" stroke="#14324B" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M2.38452 8.99171C2.94355 10.1401 3.87286 11.067 5.02279 11.6229C6.17271 12.1789 7.4763 12.3316 8.72357 12.0564C9.97084 11.7813 11.0892 11.0943 11.8985 10.1061C12.7078 9.11796 13.1609 7.88616 13.1848 6.60912C13.2088 5.33208 12.8022 4.08415 12.0305 3.06634C11.2589 2.04853 10.1671 1.32008 8.93101 0.998344C7.69494 0.676605 6.38654 0.780299 5.21657 1.29272C4.04661 1.80515 3.08318 2.69648 2.48148 3.82313" stroke="#14324B" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</div>
	);
}

export default applyWrappers(Logout, styles);