import React from 'react';
import styles from './confetti.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const Confetti = (props) => {
	let fillColor = '#000000'
	if(props.shade && props.shade == 'light'){
		fillColor = '#FFFFFF'
	}else{
		fillColor = '#7E8E9C'
	}
	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="90%" height="90%" viewBox="0 0 536 116" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d)">
                <path d="M177.425 31.0242C174.193 31.2349 172.431 32.4463 171.396 33.3921C171.154 33.6129 171.1 33.9701 171.255 34.2584C173.7 38.7918 176.891 41.1059 178.68 41.9853C178.875 42.081 179.077 41.8908 179.011 41.6839C177.99 38.4612 178.042 35.0363 177.945 31.5033C177.937 31.2253 177.703 31.0061 177.425 31.0242Z" fill="#FFC140"/>
                </g>
                <path d="M334.879 93.4718C333.864 94.1597 333 95.0642 332.346 96.1212C332.199 96.3589 331.858 96.3594 331.71 96.1221C331.053 95.0671 330.186 94.165 329.17 93.4801C328.944 93.3281 328.943 92.9806 329.169 92.8279C330.183 92.14 331.048 91.2355 331.702 90.1785C331.849 89.9409 332.19 89.9404 332.338 90.1777C332.994 91.2327 333.862 92.1348 334.878 92.8197C335.104 92.9718 335.104 93.3192 334.879 93.4718Z" fill="url(#paint0_linear)"/>
                <path d="M534.879 63.4718C533.864 64.1597 533 65.0642 532.346 66.1212C532.199 66.3589 531.858 66.3594 531.71 66.1221C531.053 65.0671 530.186 64.165 529.17 63.4801C528.944 63.3281 528.943 62.9806 529.169 62.8279C530.183 62.14 531.048 61.2355 531.702 60.1785C531.849 59.9409 532.19 59.9404 532.338 60.1777C532.994 61.2327 533.862 62.1348 534.878 62.8197C535.104 62.9718 535.104 63.3192 534.879 63.4718Z" fill="url(#paint1_linear)"/>
                <path d="M310.693 26.0622C308.848 27.2633 307.275 28.8426 306.085 30.6882C305.818 31.1032 305.198 31.1041 304.929 30.6898C303.735 28.8476 302.157 27.2725 300.308 26.0765C299.898 25.8112 299.897 25.2044 300.307 24.9378C302.152 23.7367 303.725 22.1574 304.914 20.3118C305.182 19.8968 305.802 19.8959 306.071 20.3102C307.265 22.1524 308.843 23.7275 310.692 24.9235C311.102 25.189 311.103 25.7956 310.693 26.0622Z" fill="url(#paint2_linear)"/>
                <path d="M226.429 85.9442C225.838 86.3294 225.333 86.836 224.952 87.4279C224.866 87.561 224.667 87.5612 224.581 87.4284C224.198 86.8376 223.692 86.3324 223.099 85.9488C222.967 85.8637 222.967 85.6691 223.098 85.5836C223.69 85.1984 224.195 84.6919 224.576 84.1C224.662 83.9669 224.861 83.9666 224.947 84.0995C225.33 84.6903 225.836 85.1955 226.429 85.579C226.561 85.6642 226.561 85.8587 226.429 85.9442Z" fill="#3ABDFF"/>
                <path d="M402.429 93.9442C401.838 94.3294 401.333 94.836 400.952 95.4279C400.866 95.561 400.667 95.5612 400.581 95.4284C400.198 94.8376 399.692 94.3324 399.099 93.9488C398.967 93.8637 398.967 93.6691 399.098 93.5836C399.69 93.1984 400.195 92.6919 400.576 92.1C400.662 91.9669 400.861 91.9666 400.947 92.0995C401.33 92.6903 401.836 93.1955 402.429 93.579C402.561 93.6642 402.561 93.8587 402.429 93.9442Z" fill="#FD5382"/>
                <path d="M46.4295 45.9442C45.8375 46.3294 45.3331 46.836 44.9517 47.4279C44.8659 47.561 44.667 47.5612 44.5808 47.4284C44.1978 46.8376 43.6919 46.3324 43.0989 45.9488C42.9673 45.8637 42.967 45.6691 43.0984 45.5836C43.6903 45.1984 44.1948 44.6919 44.5761 44.1C44.6619 43.9669 44.8608 43.9666 44.947 44.0995C45.33 44.6903 45.8359 45.1955 46.4289 45.579C46.5606 45.6642 46.5609 45.8587 46.4295 45.9442Z" fill="#3ABDFF"/>
                <path d="M7.42948 85.9442C6.83755 86.3294 6.33305 86.836 5.95167 87.4279C5.86589 87.561 5.667 87.5612 5.58084 87.4284C5.19779 86.8376 4.69192 86.3324 4.09888 85.9488C3.96726 85.8637 3.96698 85.6691 4.09838 85.5836C4.69032 85.1984 5.19481 84.6919 5.57614 84.1C5.66192 83.9669 5.86081 83.9666 5.94697 84.0995C6.33001 84.6903 6.83595 85.1955 7.42893 85.579C7.5606 85.6642 7.56088 85.8587 7.42948 85.9442Z" fill="#FDAD28"/>
                <path d="M98.4295 105.944C97.8375 106.329 97.3331 106.836 96.9517 107.428C96.8659 107.561 96.667 107.561 96.5808 107.428C96.1978 106.838 95.6919 106.332 95.0989 105.949C94.9673 105.864 94.967 105.669 95.0984 105.584C95.6903 105.198 96.1948 104.692 96.5761 104.1C96.6619 103.967 96.8608 103.967 96.947 104.099C97.33 104.69 97.8359 105.195 98.4289 105.579C98.5606 105.664 98.5609 105.859 98.4295 105.944Z" fill="#1BE1D4"/>
                <path d="M169.429 15.9442C168.838 16.3294 168.333 16.836 167.952 17.4279C167.866 17.561 167.667 17.5612 167.581 17.4284C167.198 16.8376 166.692 16.3324 166.099 15.9488C165.967 15.8637 165.967 15.6691 166.098 15.5836C166.69 15.1984 167.195 14.6919 167.576 14.1C167.662 13.9669 167.861 13.9666 167.947 14.0995C168.33 14.6903 168.836 15.1955 169.429 15.579C169.561 15.6642 169.561 15.8587 169.429 15.9442Z" fill="#FD5382"/>
                <path d="M400.429 5.94424C399.838 6.32944 399.333 6.83596 398.952 7.42787C398.866 7.56097 398.667 7.56124 398.581 7.42837C398.198 6.83755 397.692 6.3324 397.099 5.94884C396.967 5.86372 396.967 5.66912 397.098 5.58362C397.69 5.19842 398.195 4.6919 398.576 4.09998C398.662 3.96689 398.861 3.96662 398.947 4.09949C399.33 4.69031 399.836 5.19546 400.429 5.57902C400.561 5.66419 400.561 5.85874 400.429 5.94424Z" fill="#FDAD28"/>
                <g filter="url(#filter1_d)">
                <path d="M349.567 10.9985C347.911 8.98877 348.952 4.50302 351.521 4.07276C352.29 3.94388 353.086 4.15606 353.647 4.69795C360.459 11.2759 360.635 20.7839 361.97 26.3793C361.99 26.4608 361.999 26.5424 361.999 26.6262C361.999 27.604 360.821 28.0178 360.257 27.2187C356.886 22.4401 353.559 15.8419 349.567 10.9985Z" fill="url(#paint3_linear)"/>
                </g>
                <g filter="url(#filter2_d)">
                <path d="M495.977 107.072L496.481 104.804L497.363 104.048L502.403 105.182L504.041 108.332C504.429 108.987 504.368 110.736 504.041 112.363C503.722 113.944 502.865 113.707 502.277 113.371C500.639 112.195 497.187 109.718 496.481 109.214C495.775 108.71 495.851 107.576 495.977 107.072Z" fill="url(#paint4_linear)"/>
                <path d="M492.635 98.5976C486.788 101.521 483.815 105.695 483.059 107.417C482.757 108.324 483.689 108.215 484.193 108.047C484.445 107.837 486.562 107.014 493.013 105.401C499.464 103.789 503.176 106.997 504.226 108.803C502.798 104.183 498.481 95.6745 492.635 98.5976Z" fill="#FFD945"/>
                </g>
                <path d="M13.3699 45.6698C8.63247 45.2666 4.34024 41.7219 2.7863 40C1.19036 40.84 -1.37153 43.2759 0.896375 50.8356C3.16428 58.3953 10.346 55.4974 14.3778 54.1114C18.4097 52.7255 23.7015 55.1194 24.3314 55.4974C24.533 53.7838 23.1555 51.4235 22.4415 50.4576C21.3916 49.0296 18.1073 46.073 13.3699 45.6698Z" fill="url(#paint5_linear)"/>
                <g filter="url(#filter3_d)">
                <path d="M39.4512 6.2528C43.066 6.57324 42.0551 12.7176 42.0551 12.7176C42.0551 12.7176 39.7968 10.6346 37.7411 10.1695C34.6195 9.46316 29.235 11.8156 29.235 11.8156C29.235 11.8156 34.3906 5.80419 39.4512 6.2528Z" fill="#FFC140"/>
                </g>
                <path d="M370.5 65.0102C372.312 66.8259 372.846 69.781 372.887 71.0317C373.837 71.2412 375.741 71.1295 377.439 67.2248C379.136 63.32 375.168 62.0135 373.024 61.1807C370.88 60.348 369.533 57.5189 369.405 57.1438C368.725 57.7797 368.471 59.232 368.429 59.8786C368.365 60.8326 368.688 63.1945 370.5 65.0102Z" fill="url(#paint6_linear)"/>
                <g filter="url(#filter4_d)">
                <path d="M137.943 88.9347C139.947 80.6187 144.008 73.0206 156.294 77.1069C157.724 77.5823 158.556 79.0386 158.375 80.5342C158.096 82.8415 155.616 84.1546 153.411 83.419C143.546 80.1273 140.292 85.5036 137.867 89.5602L137.943 88.9347Z" fill="url(#paint7_linear)"/>
                <g filter="url(#filter5_d)">
                <path d="M138.509 87.8619C137.362 92.8851 135.59 97.8936 129.134 95.7481C128.178 95.4302 127.586 94.4972 127.569 93.4894C127.535 91.4937 129.669 90.2353 131.594 90.7651C135.732 91.9043 137.383 89.9749 138.59 87.764L138.509 87.8619Z" fill="url(#paint8_linear)"/>
                </g>
                </g>
                <g filter="url(#filter6_d)">
                <path d="M99.5408 31.1953C91.0145 31.8831 82.5292 30.3925 82.5827 17.4446C82.589 15.9381 83.7134 14.6936 85.1909 14.3993C87.4701 13.9453 89.4909 15.8928 89.479 18.2168C89.4259 28.6166 95.5485 30.0327 100.159 31.0722L99.5408 31.1953Z" fill="url(#paint9_linear)"/>
                <g filter="url(#filter7_d)">
                <path d="M98.3453 30.9919C103.476 30.5166 108.787 30.6393 108.761 37.4422C108.757 38.4501 108.055 39.3035 107.102 39.6337C105.216 40.2877 103.356 38.6518 103.259 36.6581C103.052 32.3714 100.704 31.4034 98.227 30.9453L98.3453 30.9919Z" fill="url(#paint10_linear)"/>
                </g>
                </g>
                <g filter="url(#filter8_d)">
                <path d="M484.282 36.2156C483.191 32.3624 483.104 28.3299 489.055 27.1772C489.747 27.043 490.421 27.4463 490.691 28.0981C491.106 29.1035 490.396 30.2087 489.327 30.4145C484.546 31.3358 484.453 34.2764 484.395 36.4881L484.282 36.2156Z" fill="url(#paint11_linear)"/>
                <g filter="url(#filter9_d)">
                <path d="M484.267 35.6479C484.952 37.9609 485.378 40.4113 482.251 41.0177C481.788 41.1075 481.332 40.8627 481.094 40.4554C480.622 39.6488 481.204 38.6454 482.111 38.4199C484.061 37.9349 484.292 36.7686 484.277 35.5893L484.267 35.6479Z" fill="url(#paint12_linear)"/>
                </g>
                </g>
                <g filter="url(#filter10_d)">
                <path d="M225.282 15.2156C224.191 11.3624 224.104 7.32993 230.055 6.17717C230.747 6.04305 231.421 6.44629 231.691 7.09806C232.106 8.10353 231.396 9.20866 230.327 9.41454C225.546 10.3358 225.453 13.2764 225.395 15.4881L225.282 15.2156Z" fill="url(#paint13_linear)"/>
                <g filter="url(#filter11_d)">
                <path d="M225.267 14.6479C225.952 16.9609 226.378 19.4113 223.251 20.0177C222.788 20.1075 222.332 19.8627 222.094 19.4554C221.622 18.6488 222.204 17.6454 223.111 17.4199C225.061 16.9349 225.292 15.7686 225.277 14.5893L225.267 14.6479Z" fill="url(#paint14_linear)"/>
                </g>
                </g>
                <g filter="url(#filter12_d)">
                <path d="M422.539 62.6039C430.838 63.997 438.574 67.4584 435.411 79.7967C435.043 81.2323 433.671 82.1492 432.191 82.0748C429.908 81.9601 428.448 79.6167 429.018 77.4026C431.568 67.4948 426.068 64.6724 421.92 62.5728L422.539 62.6039Z" fill="url(#paint15_linear)"/>
                <path d="M423.633 63.0839C418.625 62.3042 413.588 60.9105 415.248 54.4275C415.494 53.467 416.369 52.8216 417.357 52.7355C419.313 52.565 420.695 54.5727 420.307 56.4977C419.475 60.6367 421.482 62.1245 423.735 63.1568L423.633 63.0839Z" fill="url(#paint16_linear)"/>
                </g>
                <g filter="url(#filter13_d)">
                <path d="M40.1025 97.1226C46.2775 98.1592 52.0338 100.735 49.6802 109.916C49.4063 110.984 48.3856 111.666 47.2843 111.611C45.5852 111.525 44.4992 109.782 44.9232 108.134C46.8209 100.762 42.7283 98.6618 39.6419 97.0994L40.1025 97.1226Z" fill="url(#paint17_linear)"/>
                <path d="M40.9117 97.4797C37.185 96.8995 33.437 95.8625 34.6725 91.0386C34.8556 90.3239 35.5065 89.8437 36.2415 89.7796C37.697 89.6527 38.7253 91.1466 38.4371 92.579C37.8176 95.6587 39.3111 96.7658 40.9873 97.5339L40.9117 97.4797Z" fill="url(#paint18_linear)"/>
                </g>
                <defs>
                <filter id="filter0_d" x="170.16" y="31.0231" width="9.87231" height="13.0035" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="1.00796"/>
                <feGaussianBlur stdDeviation="0.50398"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter1_d" x="345.844" y="2.84167" width="22.0478" height="27.2101" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="1.00796"/>
                <feGaussianBlur stdDeviation="0.50398"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter2_d" x="481.992" y="98" width="23.3255" height="17.6333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="1.00796"/>
                <feGaussianBlur stdDeviation="0.50398"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter3_d" x="27.992" y="4" width="17.2229" height="16.5911" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="1.00796"/>
                <feGaussianBlur stdDeviation="0.50398"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter4_d" x="125.55" y="75.9678" width="34.8645" height="24.826" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="2.5199"/>
                <feGaussianBlur stdDeviation="1.00796"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter5_d" x="122.275" y="87.764" width="21.6058" height="19.5815" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="5.79577"/>
                <feGaussianBlur stdDeviation="2.64589"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter6_d" x="80.5661" y="14.3331" width="30.21" height="29.9827" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="2.5199"/>
                <feGaussianBlur stdDeviation="1.00796"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter7_d" x="92.9348" y="30.8271" width="21.1172" height="20.0405" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="5.79577"/>
                <feGaussianBlur stdDeviation="2.64589"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter8_d" x="477.812" y="27.1515" width="15.0153" height="18.8277" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="2.5199"/>
                <feGaussianBlur stdDeviation="1.00796"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter9_d" x="474.536" y="35.5894" width="16.6914" height="16.9415" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="5.79577"/>
                <feGaussianBlur stdDeviation="2.64589"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter10_d" x="218.812" y="6.15149" width="15.0153" height="18.8277" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="2.5199"/>
                <feGaussianBlur stdDeviation="1.00796"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter11_d" x="215.536" y="14.5894" width="16.6914" height="16.9415" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="5.79577"/>
                <feGaussianBlur stdDeviation="2.64589"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter12_d" x="412.097" y="51.0864" width="27.1452" height="34.6961" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="1.00796"/>
                <feGaussianBlur stdDeviation="1.25995"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <filter id="filter13_d" x="31.9137" y="88.2601" width="20.8237" height="26.8816" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="1.00796"/>
                <feGaussianBlur stdDeviation="1.25995"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear" x1="328.999" y1="93.1609" x2="335.046" y2="93.1433" gradientUnits="userSpaceOnUse">
                <stop stop-color="#9B62CD"/>
                <stop offset="0.3003" stop-color="#985DCB"/>
                <stop offset="0.6716" stop-color="#8F4EC7"/>
                <stop offset="1" stop-color="#833AC1"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="528.999" y1="63.1609" x2="535.046" y2="63.1433" gradientUnits="userSpaceOnUse">
                <stop stop-color="#9B62CD"/>
                <stop offset="0.3003" stop-color="#985DCB"/>
                <stop offset="0.6716" stop-color="#8F4EC7"/>
                <stop offset="1" stop-color="#833AC1"/>
                </linearGradient>
                <linearGradient id="paint2_linear" x1="299.998" y1="25.5192" x2="310.998" y2="25.4859" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFC56E"/>
                <stop offset="0.3003" stop-color="#FFB443"/>
                <stop offset="0.6716" stop-color="#FFB443"/>
                <stop offset="1" stop-color="#F3A100"/>
                </linearGradient>
                <linearGradient id="paint3_linear" x1="355.763" y1="13.6872" x2="351.193" y2="16.9836" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FD5382"/>
                <stop offset="1" stop-color="#FF749A"/>
                </linearGradient>
                <linearGradient id="paint4_linear" x1="500.102" y1="104.048" x2="500.891" y2="113.623" gradientUnits="userSpaceOnUse">
                <stop offset="0.166984" stop-color="#F09A0C"/>
                <stop offset="1" stop-color="#FDAD28"/>
                </linearGradient>
                <linearGradient id="paint5_linear" x1="0" y1="40" x2="27.3409" y2="53.3555" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1BE1D4"/>
                <stop offset="0.338542" stop-color="#1FDACE"/>
                <stop offset="0.625" stop-color="#67ECE3"/>
                <stop offset="1" stop-color="#1BE1D4"/>
                </linearGradient>
                <linearGradient id="paint6_linear" x1="374.035" y1="72.0018" x2="367.419" y2="56.9786" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1BE1D4"/>
                <stop offset="0.338542" stop-color="#1FDACE"/>
                <stop offset="0.625" stop-color="#67ECE3"/>
                <stop offset="1" stop-color="#1BE1D4"/>
                </linearGradient>
                <linearGradient id="paint7_linear" x1="147.182" y1="76.492" x2="148.816" y2="88.2392" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FD5382"/>
                <stop offset="1" stop-color="#FF94B1"/>
                </linearGradient>
                <linearGradient id="paint8_linear" x1="133.252" y1="95.8615" x2="132.088" y2="88.6959" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FD5382"/>
                <stop offset="1" stop-color="#FF94B1"/>
                </linearGradient>
                <linearGradient id="paint9_linear" x1="84.8383" y1="26.2941" x2="95.4912" y2="21.0806" gradientUnits="userSpaceOnUse">
                <stop stop-color="#D153FD"/>
                <stop offset="1" stop-color="#B094FF"/>
                </linearGradient>
                <linearGradient id="paint10_linear" x1="107.585" y1="33.4946" x2="101.139" y2="36.8333" gradientUnits="userSpaceOnUse">
                <stop stop-color="#9E53FD"/>
                <stop offset="1" stop-color="#D094FF"/>
                </linearGradient>
                <linearGradient id="paint11_linear" x1="485.196" y1="29.0177" x2="488.559" y2="33.436" gradientUnits="userSpaceOnUse">
                <stop stop-color="#D153FD"/>
                <stop offset="1" stop-color="#B094FF"/>
                </linearGradient>
                <linearGradient id="paint12_linear" x1="483.958" y1="40.1189" x2="481.838" y2="37.4621" gradientUnits="userSpaceOnUse">
                <stop stop-color="#9E53FD"/>
                <stop offset="1" stop-color="#D094FF"/>
                </linearGradient>
                <linearGradient id="paint13_linear" x1="226.196" y1="8.01773" x2="229.559" y2="12.436" gradientUnits="userSpaceOnUse">
                <stop stop-color="#D153FD"/>
                <stop offset="1" stop-color="#B094FF"/>
                </linearGradient>
                <linearGradient id="paint14_linear" x1="224.958" y1="19.1189" x2="222.838" y2="16.4621" gradientUnits="userSpaceOnUse">
                <stop stop-color="#9E53FD"/>
                <stop offset="1" stop-color="#D094FF"/>
                </linearGradient>
                <linearGradient id="paint15_linear" x1="436.247" y1="72.7855" x2="421.445" y2="72.0419" gradientUnits="userSpaceOnUse">
                <stop stop-color="#3ABDFF"/>
                <stop offset="1" stop-color="#A5E0FE"/>
                </linearGradient>
                <linearGradient id="paint16_linear" x1="414.885" y1="57.3613" x2="424.003" y2="57.8194" gradientUnits="userSpaceOnUse">
                <stop stop-color="#3ABDFF"/>
                <stop offset="1" stop-color="#A5E0FE"/>
                </linearGradient>
                <linearGradient id="paint17_linear" x1="50.3023" y1="104.699" x2="39.2879" y2="104.145" gradientUnits="userSpaceOnUse">
                <stop stop-color="#3ABDFF"/>
                <stop offset="1" stop-color="#A5E0FE"/>
                </linearGradient>
                <linearGradient id="paint18_linear" x1="34.4026" y1="93.2216" x2="41.1869" y2="93.5624" gradientUnits="userSpaceOnUse">
                <stop stop-color="#3ABDFF"/>
                <stop offset="1" stop-color="#A5E0FE"/>
                </linearGradient>
                </defs>
            </svg>
		</div>
	);
}

export default applyWrappers(Confetti, styles);