import actionConstants from "constants/actions";
import _ from 'lodash';

const initialState = {
	userMetricsObj: {},
	userMetricIds: {}
};

const userMetricsConfig = ["adoption", "digitalAwareness", "adopters", "strategy", "capability", "culture"];

const formatUserMetrics = (userMetrics) => {
	const metricObj = _.groupBy(userMetrics, 'metricId');
	Object.keys(metricObj).map((eachMetricId) => {
		metricObj[eachMetricId] = metricObj[eachMetricId][0];
		return 1;
	});
	return metricObj;
}

const formatUserMetricsObject = (metrics) => {
	const userMetricIds = {};
	userMetricsConfig.map((eachUserMetric) => {
		metrics.map((eachMetric) => {
			if(eachMetric.key === eachUserMetric) {
				userMetricIds[eachUserMetric] = eachMetric.id;
			}
		});
	});
	return userMetricIds;
}

const userMetrics = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConstants.SET_INIT_DATA:

			return {
				userMetricsObj: formatUserMetrics(action.payload.userMetrics),
				userMetricIds: formatUserMetricsObject(action.payload.metrics)
			};
		case 'ACTION_RESPONSE_POPUP_CLOSE':
			return {
				...state,
				userMetricsObj: {
					...state.userMetricsObj,
					...formatUserMetrics(action.payload.userMetrics)
				},
			};
		case 'ADD_DYNAMIC_EVENT_METRICS':
			return {
				...state,
				userMetricsObj: {
					...state.userMetricsObj,
					...formatUserMetrics(action.payload.userMetrics)
				},
			};
		default:
			return state;
	}
};

export default userMetrics;