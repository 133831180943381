import React from 'react';
import styles from './like.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const Like = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 11 11" fill="none">
				<path d="M10.7774 5.26841C10.8346 5.08097 10.847 4.88273 10.8137 4.68961C10.7804 4.49649 10.7023 4.31387 10.5857 4.15638C10.4691 3.9989 10.3171 3.87096 10.1421 3.78281C9.96708 3.69466 9.77383 3.64876 9.57786 3.64881H7.03282C6.93655 3.64883 6.84184 3.62445 6.75754 3.57795C6.67325 3.53145 6.60211 3.46435 6.55077 3.3829C6.49944 3.30146 6.46958 3.20833 6.46399 3.11222C6.4584 3.01611 6.47726 2.92015 6.5188 2.8333L7.17011 1.4682C7.22984 1.29086 7.24359 1.10126 7.21009 0.917154C7.17659 0.733048 7.09692 0.560453 6.97855 0.415521C6.86916 0.280771 6.73005 0.173189 6.57212 0.101208C6.41419 0.0292264 6.24173 -0.00519744 6.06827 0.000634742C5.8948 0.00646692 5.72505 0.0523966 5.57231 0.134823C5.41957 0.21725 5.288 0.333933 5.1879 0.475726L2.95758 3.63558C2.90486 3.71005 2.83507 3.77082 2.75405 3.81279C2.67304 3.85477 2.58315 3.87674 2.4919 3.87686C2.43578 3.87686 2.38195 3.89915 2.34226 3.93884C2.30257 3.97853 2.28027 4.03236 2.28027 4.08849V9.24833C2.28027 9.29117 2.29233 9.33314 2.31508 9.36944C2.33783 9.40574 2.37034 9.4349 2.40889 9.45358C4.09646 10.2709 4.65701 10.379 6.03123 10.379C6.1895 10.379 7.08938 10.3699 7.41823 10.3699C8.64057 10.3699 9.4538 9.66065 9.90671 8.19018L10.7733 5.28529L10.7774 5.26841Z" fill="#A6B1BB" />
				<path d="M1.59635 3.87685H1.58449C1.55761 3.71783 1.47541 3.57341 1.3524 3.46909C1.22939 3.36477 1.07348 3.30727 0.912198 3.30673H0.22805C0.167567 3.30673 0.109562 3.33076 0.0667941 3.37353C0.0240266 3.41629 0 3.4743 0 3.53478V9.92017C0 9.98065 0.0240266 10.0387 0.0667941 10.0814C0.109562 10.1242 0.167567 10.1482 0.22805 10.1482H0.912198C1.09365 10.1482 1.26766 10.0761 1.39596 9.94783C1.52427 9.81953 1.59635 9.64551 1.59635 9.46407V3.87685Z" fill="#A6B1BB" />
			</svg>
		</div>
	);
}

export default applyWrappers(Like, styles);