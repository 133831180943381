import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
	const skin = theme.globalProfiles.palette;
	const myTypography = typography(theme);
	const fontSkin = theme.fontFamily;

	return StyleSheet.create({
		leaderboardText: {
			color: skin.white,
			textTransform: 'uppercase',
			...myTypography.body1,
			fontWeight: 600,
		},
		leaderboardImageContainer: {
			backgroundColor: hexToRgbA(skin.white, 0.1),
			':hover': {
				backgroundColor: hexToRgbA(skin.white, 0.3),
			},
		},
		showLeaderboardHover:{
			fontFamily: `Open Sans`,
			fontStyle: `normal`,
			fontWeight: `600`,
			fontSize: `12px`,	
			lineHeight: `16px`,
			backgroundColor: `#7E8E9C`, 
			color: '#FFFFFF',
			'@media (min-width: 1440px)' : {
				fontSize: `13.5px`,	
				lineHeight: `18px`,
			},
			'@media (min-width: 1600px)' : {
				fontSize: `15px`,	
				lineHeight: `20px`,
			},
			'@media (min-width: 1920px)' : {
				fontSize: `18px`,	
				lineHeight: `24px`,
			},
			'@media (min-width: 2560px)' : {
				fontSize: `24px`,	
				lineHeight: `32px`,
			},
		}, 
		toolTipArrow:{
			borderLeft: `5px solid transparent`,
			borderRight: `5px solid transparent`,
			borderBottom: `5px solid #7E8E9C`,
			'@media (min-width: 1440px)' : {
				borderLeft: `5.625px solid transparent`,
				borderRight: `5.625px solid transparent`,
				borderBottom: `5.625px solid #7E8E9C`,
			},
			'@media (min-width: 1600px)' : {
				borderLeft: `6.25px solid transparent`,
				borderRight: `6.25px solid transparent`,
				borderBottom: `6.25px solid #7E8E9C`,
			},
			'@media (min-width: 1920px)' : {
				borderLeft: `7.5px solid transparent`,
				borderRight: `7.5px solid transparent`,
				borderBottom: `7.5px solid #7E8E9C`,
			},
			'@media (min-width: 2560px)' : {
				borderLeft: `10px solid transparent`,
				borderRight: `10px solid transparent`,
				borderBottom: `10px solid #7E8E9C`,
			},
		}
	});
}

export default getSkin;