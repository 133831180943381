import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		container: {
			background: skin.grayColor6,
			border: `1px solid ${skin.grayColor5}`
		},
		title: {
			color: skin.secondaryColor,
			// textTransform: 'uppercase',
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '13px',
			'@media (min-width: 1440px)' : {
				fontSize: '14.625px',
			},
			background: skin.grayColor7,
			'@media (min-width: 1600px)' : {
				fontSize: '16.25px',
			},
			'@media (min-width: 1920px)' : {
				fontSize: '19.5px',
			},
			'@media (min-width: 2560px)' : {
				fontSize: '26px',
			},
		},
		footer: {
			...myTypography.body2,
			color: skin.grayColor1,
			background: skin.grayColor7,
		},
		titleLine: {
			background: skin.secondaryColor,
		},
		operation: {
			...myTypography.body1,
			fontSize: '30px',
			color: skin.grayColor3,
			'@media (min-width: 1440px)' : {
				fontSize: '33.75px',
			},
			'@media (min-width: 1600px)' : {
				fontSize: '37.5px',
			},
			'@media (min-width: 1920px)' : {
				fontSize: '45px',
			},
			'@media (min-width: 2560px)' : {
				fontSize: '60px',
			},
		},
		graphBar: {
			background: skin.secondaryColor
		},
		graphHeading: {
			...myTypography.body2,
			color: skin.grayColor1,
			fontSize: '11px',
			'@media (min-width: 1440px)' : {
				fontSize: '12.375px',
			},
			'@media (min-width: 1600px)' : {
				fontSize: '13.75px',
			},
			'@media (min-width: 1920px)' : {
				fontSize: '16.5px',
			},
			'@media (min-width: 2560px)' : {
				fontSize: '22px',
			},
		},
		graphText: {
			...myTypography.body2,
			color: skin.grayColor1,
			fontSize: '10px',
			'@media (min-width: 1440px)' : {
				fontSize: '11.25px',
			},
			'@media (min-width: 1600px)' : {
				fontSize: '12.5px',
			},
			'@media (min-width: 1920px)' : {
				fontSize: '15px',
			},
			'@media (min-width: 2560px)' : {
				fontSize: '20px',
			},
		},
		graphValue: {
			...myTypography.body2,
			color: skin.grayColor1,
			fontWeight: 600
		},
		point: {
			...myTypography.body2,
			color: skin.grayColor1,
			'::before': {
				content: '\'\'',
				position: 'absolute',
				width: '6px',
				height: '6px',
				background: skin.secondaryColor,
				left: '10px',
				top: '10px',
				'@media (min-width: 1440px)' : {
					content: '\'\'',
					position: 'absolute',
					width: '6.75px',
					height: '6.75px',
					background: skin.secondaryColor,
					left: '11.25px',
					top: '11.25px',
				},
				'@media (min-width: 1600px)' : {
					content: '\'\'',
					position: 'absolute',
					width: '7.5px',
					height: '7.5px',
					background: skin.secondaryColor,
					left: '12.5px',
					top: '12.5px',
				},
				'@media (min-width: 1920px)' : {
					content: '\'\'',
					position: 'absolute',
					width: '9px',
					height: '9px',
					background: skin.secondaryColor,
					left: '15px',
					top: '15px',
				},
				'@media (min-width: 2560px)' : {
					content: '\'\'',
					position: 'absolute',
					width: '12px',
					height: '12px',
					background: skin.secondaryColor,
					left: '20px',
					top: '20px',
				},
			}
		}
	});
}

export default getSkin;
