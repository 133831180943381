import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loader from 'svgComponents/loader';

import BasicValidationRoute from './BasicValidationRoute';

const NotFound = () => <div>Not Found</div>

const AsyncIntroContainer = Loadable({
	loader: () => import('containers/IntroContainer'),
	loading: Loader
});

const AsyncGameContainer = Loadable({
	loader: () => import('containers/GameContainer'),
	loading: Loader
});

const AsyncCompletedContainer = Loadable({
	loader: () => import('containers/CompletedContainer'),
	loading: Loader
});

const DesktopRouter = () => (
	<Switch>
		<BasicValidationRoute exact path="/introduction" component={AsyncIntroContainer} />
		<BasicValidationRoute exact path="/game" component={AsyncGameContainer} />
		<BasicValidationRoute path="/completed" component={AsyncCompletedContainer} />
		<Route render={() => <NotFound />} />
	</Switch>
)

export default DesktopRouter;