import actionConsts from 'constants/actions';

const initalState = {
	isRunning: false,
	stepIndex: 0,
	steps: [],
	type: ''
};

const tutorial = (state = initalState, action = {}) => {
	switch (action.type) {
		case actionConsts.START_TUTORIAL:
			return {
				...state,
				isRunning: true,
				stepIndex: 0
			};
		case actionConsts.ADD_TUTORIAL_STEPS:
			return {
				...state,
				steps: action.payload.steps,
				type: action.payload.type
			};
		case actionConsts.UPDATE_TUTORIAL_STEP:
			return {
				...state,
				stepIndex: action.stepId
			}
		case actionConsts.END_TUTORIAL:
			return {
				...initalState
			};
		case actionConsts.PAUSE_TUTORIAL:
			return {
				...state,
				isRunning: false
			};
		default:
			return { ...state };
	}
};

export default tutorial;