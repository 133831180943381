const timelineData = {
	list: [
		{
			titleKey: 'label_intro_timeline_title_1',
			descKey: 'label_intro_timeline_desc_1',
			imageKey: 'IMG_INTRO_TL_ADVISOR_JOINS'
		},
		{
			titleKey: 'label_intro_timeline_title_2',
			descKey: 'label_intro_timeline_desc_2',
			imageKey: 'IMG_INTRO_TL_ROLLED_OUT'
		},
		{
			titleKey: 'label_intro_timeline_title_3',
			descKey: 'label_intro_timeline_desc_3',
			imageKey: 'IMG_INTRO_TL_MISS_TARGET'
		},
		{
			titleKey: 'label_intro_timeline_title_4',
			descKey: 'label_intro_timeline_desc_4',
			imageKey: 'IMG_INTRO_TL_TEAM_EXPAND'
		},
		{
			titleKey: 'label_intro_timeline_title_5',
			descKey: 'label_intro_timeline_desc_5',
			imageKey: 'IMG_INTRO_TL_INITIATE_CHANGE'
		}
	]
}

export default timelineData;