import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import applyWrappers from 'wrappers/ComponentWrapper';

import styles from './advisorPopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';

import AdvisorTalk from 'config/game/advisorTalk';

import Close from 'svgComponents/close';
import Mentor from 'svgComponents/mentor';
import TypingText from 'commonComponents/typingText';
import {
	startTutorial,
	pauseTutorial,
	endTutorial,
	addStepsToTutorial,
	updateStepNumber
} from 'actions/tutorial';
import gameStartSteps from 'config/game/walkthrough/gameStartSteps';

const AdvisorPopup = (props) => {

	const dispatch = useDispatch();
	const eachMessageTime = 3000; //in milli seconds

	const [chatList, setChatList] = useState([]);
	const [count, setCount] = useState(0);
	const [proceed, setProceed] = useState(true);

	const [youMessage, setYouMessage] = useState('');

	const [showAnimation, setShowAnimation] = useState(false);

	const advisorChatList = AdvisorTalk.chatList;

	const closePopup = () => {
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
		});
	}

	useEffect(() => {
		if (count < advisorChatList.length) {
			if (advisorChatList[count].type === 'ADVISOR' && proceed) {
				setProceed(false);
				const newChatList = chatList;
				newChatList.push(advisorChatList[count]);
				setChatList(newChatList);
				setCount(count + 1);
				setTimeout(() => {
					setProceed(true);
				}, eachMessageTime);
			} else if (advisorChatList[count].type === 'YOU' && proceed) {
				setProceed(false);
				setYouMessage(advisorChatList[count].labelKey);
			}
		} else {
			setYouMessage('');
			setShowAnimation(true);
			setTimeout(() => {
				closePopup();
				dispatch(addStepsToTutorial(gameStartSteps(), 'GAME_STARTED_WALKTHROUGH'));
				dispatch(startTutorial());
			}, 2000);
		}
	}, [count, chatList, proceed]);

	const onAdvisorPopupPopupClick = (e) => {
		e.stopPropagation();
	}

	const renderLeftTalkBubble = (message) => {
		return (
			<div styleName="message-container-left">
				<div className={css(myStyles.leftArrow)} styleName="left-arrow"></div>
				<div className={css(myStyles.leftMessage)} styleName="left-message">
					<TypingText message={message} maxTime={eachMessageTime} />
				</div>
			</div>
		);
	}

	const renderRightTalkBubble = (message) => {
		return (
			<div styleName="message-container-right">
				<div className={css(myStyles.rightMessage)} styleName="right-message">
					{message}
				</div>
				<div className={css(myStyles.rightArrow)} styleName="right-arrow"></div>
			</div>
		);
	}

	const renderAdvisorTalk = () => {
		return chatList.map((eachTalk) => {
			if (eachTalk.type === 'ADVISOR') {
				return renderLeftTalkBubble(props.getLabel(eachTalk.labelKey));
			} else if (eachTalk.type === 'YOU') {
				return renderRightTalkBubble(props.getLabel(eachTalk.labelKey));
			}
			return null;
		});
	}

	const sendYouMessage = () => {
		const newChatList = chatList;
		newChatList.push(advisorChatList[count]);
		setChatList(newChatList);
		setYouMessage('');
		setCount(count + 1);
		setProceed(true);
	}

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="container" onClick={onAdvisorPopupPopupClick}>
			<div className={css(myStyles.headerContainer)} styleName="header-container">
				<div styleName="close-container" onClick={closePopup}>
					<Close strokeWidth={3} />
				</div>
				<div styleName="header-image">
					<Mentor />
				</div>
				<div className={css(myStyles.headerText)} styleName="header-text">Niko Ichihara</div>
			</div>
			<div styleName="events-container">
				{renderAdvisorTalk()}
			</div>
			{
				showAnimation
					? <div className={css(myStyles.sendingContainer)} styleName="sending-container">
						{props.getLabel('label_please_wait')}
					</div>
					: <div className={css(myStyles.sendingContainer)} styleName="sending-container">
						<div className={css(myStyles.sendingTextBox)} styleName="sending-text-box">
							{
								youMessage !== ''
									? <TypingText message={props.getLabel(youMessage)} maxTime={1000} />
									: null
							}
						</div>
						<div styleName="send-button-container">
							<div className={youMessage !== '' ? css(myStyles.sendText) : css(myStyles.sendDisabled)} styleName="send-button" onClick={sendYouMessage}>
								{props.getLabel('label_send')}
							</div>
						</div>
					</div>
			}

		</div>
	);
}

export default applyWrappers(AdvisorPopup, styles);