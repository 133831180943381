import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		container: {
			backgroundColor: skin.grayColor6
		},
		lowProgress: {
			backgroundColor: skin.red
		},
		medProgress: {
			backgroundColor: skin.yellow
		},
		highProgress: {
			backgroundColor: skin.green
		},
		changePositive: {
			backgroundColor: skin.green,
			...myTypography.body2,
			fontWeight: 600,
			color: skin.white,
		},
		changeNegative: {
			backgroundColor: skin.red,
			...myTypography.body2,
			fontWeight: 600,
			color: skin.white,
		}
	});
}

export default getSkin;