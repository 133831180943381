import kfetch from 'util/kfetch';
import urls from 'constants/urls';
import actionConstans from 'constants/actions';
import { checkIfPresent } from 'util/functions';

export const gameCompleted = (payload, callback) => (dispatch) => {

	kfetch(urls.GAME_COMPLETED_API, payload, 'POST')
		.then((response) => {
			if (response.success) {
				dispatch({
					type: 'UPDATE_USER',
					payload: {
						isGameCompleted: true
					}
				});
			}
			if (checkIfPresent(callback)) {
				callback();
			}
		})
};