import actionConsts from 'constants/actions';
import { getUniqueItemsOnly } from 'util/functions.js'

const initialState = {
	sevenDaysRestrictedActionsList: [],
	tenDaysRestrictedActionsList: [],
	permanentDisabledActionList: [],
	disabledActionActorObj: {},
};

const findActionIdOfOption = (actionOptionId, isDelayedAction, actionOptionsList) => {
	for(let i = 0 ; i < actionOptionsList.length; i++){
		if(actionOptionsList[i].id == actionOptionId){
			if([2,8,9,10,16].includes(actionOptionsList[i].actionId) && !isDelayedAction){
				//Case where this is response of a delayed action, hence avoiding repetition of 
				return -1;
			}else{
				return actionOptionsList[i].actionId;
			}
		}
	}
}

const calculateActionFrequency = (payload) => {
	let actionOptionsTakenList = payload.actionOptionsTakenList;
	actionOptionsTakenList.sort((a, b) => {
		return a.day - b.day;
	});
	let actionOptionsList = payload.actionOptionsList;
	let actionFrequency = {};
	let actionLastTakenDay = {};
	let noOfSuccessAction2 = 0;
	let isActionOption8 = false;
	for (let eachActionOptionTaken = 0; eachActionOptionTaken < actionOptionsTakenList.length; eachActionOptionTaken++) {
		let actionID = findActionIdOfOption(
			actionOptionsTakenList[eachActionOptionTaken].actionOptionId, 
			actionOptionsTakenList[eachActionOptionTaken].isDelayedAction, 
			actionOptionsList
		);
		//Creating and populating actionFrequency
		if(actionFrequency[actionID]){
			actionFrequency[actionID] = actionFrequency[actionID] + 1;
		}else{
			actionFrequency[actionID] = 1;
		}

		//Creating and populating actionLastTakenDay
		if(!actionLastTakenDay[actionID]){
			actionLastTakenDay[actionID] = actionOptionsTakenList[eachActionOptionTaken].day;
		}else if(actionLastTakenDay[actionID] < actionOptionsTakenList[eachActionOptionTaken].day){
			actionLastTakenDay[actionID] = actionOptionsTakenList[eachActionOptionTaken].day;
		}

		//Allowing Change Policy 3 times successfull
		if(actionOptionsTakenList[eachActionOptionTaken].actionOptionId == 8 &&
			actionOptionsTakenList[eachActionOptionTaken].isDelayedAction)
		{
			noOfSuccessAction2++;
		}
		if(noOfSuccessAction2 >= 3){
			isActionOption8 = true;
		}
	}
	return { actionFrequency, actionLastTakenDay, isActionOption8 }
}

const calculateIsSuccessDisabling = (payload) => {
	let actionOptionsTakenList = payload.actionOptionsTakenList;
	actionOptionsTakenList.sort((a, b) => {
		return a.day - b.day;
	});
	let actionOptionsList = payload.actionOptionsList;
	let actionActorToDisable = {};
	let actorsOfaction14 = {};
	let actorsOfaction10 = {};
	for (let eachActionOptionTaken = 0; eachActionOptionTaken < actionOptionsTakenList.length; eachActionOptionTaken++) {
		let actionID = findActionIdOfOption(
			actionOptionsTakenList[eachActionOptionTaken].actionOptionId, 
			actionOptionsTakenList[eachActionOptionTaken].isDelayedAction, 
			actionOptionsList
		);
		if ([6, 7, 21].includes(actionID) && actionOptionsTakenList[eachActionOptionTaken].response.responseType == 'SUCCESS'){
			if(actionActorToDisable[actionID]){
				if(! actionActorToDisable[actionID].includes(actionOptionsTakenList[eachActionOptionTaken].response.actorIds[0]) ){
					actionActorToDisable[actionID].push(actionOptionsTakenList[eachActionOptionTaken].response.actorIds[0]);
				}
			}else{
				actionActorToDisable[actionID] = actionOptionsTakenList[eachActionOptionTaken].response.actorIds;
			}
		}
		if ([19].includes(actionID) && actionOptionsTakenList[eachActionOptionTaken].response.responseType == 'PRELAG'){
			if(actionActorToDisable[actionID]){
				if(! actionActorToDisable[actionID].includes(actionOptionsTakenList[eachActionOptionTaken].response.actorIds[0]) ){
					actionActorToDisable[actionID].push(actionOptionsTakenList[eachActionOptionTaken].response.actorIds[0]);
				}
			}else{
				actionActorToDisable[actionID] = actionOptionsTakenList[eachActionOptionTaken].response.actorIds;
			}
		}
		if ([14].includes(actionID)){
			let currentActor = actionOptionsTakenList[eachActionOptionTaken].response.actorIds[0];
			if(actorsOfaction14[currentActor]){
				actorsOfaction14[currentActor] = actorsOfaction14[currentActor] + 1;
			}else{
				actorsOfaction14[currentActor] = 1;
			}
		}
		if ([10].includes(actionID) && actionOptionsTakenList[eachActionOptionTaken].isDelayedAction){
			let currentActorsFor10 = actionOptionsTakenList[eachActionOptionTaken].response.actorIds;
			currentActorsFor10.map((eachActor)=>{
				if(actorsOfaction10[eachActor]){
					actorsOfaction10[eachActor] = actorsOfaction10[eachActor] + 1;
				}else{
					actorsOfaction10[eachActor] = 1;
				}
			})
		}
	}

	//Disabling actors for Kickstart Innovation after action on them twice
	for(let actors in actorsOfaction14){
		if(actorsOfaction14[actors] >= 2){
			if(actionActorToDisable[14]){
				if(! actionActorToDisable[14].includes(actors) ){
					actionActorToDisable[14].push(+actors);
				}
			}else{
				actionActorToDisable[14] = [+actors];
			}
		}
	}

	//Disabling actors for Share Webinars after action on them thrice
	for(let actors in actorsOfaction10){
		if(actorsOfaction10[actors] >= 3){
			if(actionActorToDisable[10]){
				if(! actionActorToDisable[10].includes(actors) ){
					actionActorToDisable[10].push(+actors);
				}
			}else{
				actionActorToDisable[10] = [+actors];
			}
		}
	}
	return actionActorToDisable;
}

const updateDisabledActors = (disabledActionActorObj, actionId, actorsSelected) => {
	if(disabledActionActorObj[actionId]){
		disabledActionActorObj[actionId].push(actorsSelected[0]);
	}else{
		disabledActionActorObj[actionId] = actorsSelected;
	}

	return disabledActionActorObj;
}

const updateDisabledActorsWithBadge = (disabledActionActorObj, actionId, actorsSelected) => {
	disabledActionActorObj[actionId] = actorsSelected;
	return disabledActionActorObj;
}

const updateDisabledSuccessfulCondition = (disabledActionActorObj, actionActorToDisable) => {
	for (let keys in actionActorToDisable) {
		disabledActionActorObj[keys] = actionActorToDisable[keys];
	}
	return disabledActionActorObj;
}

const restrictions = (state = initialState, action = {}) => {
	switch (action.type) {
		case 'SET_INIT_DATA':{
			return {
				...state,
				disabledActionActorObj: action.payload.reduxData["DISABLE_ACTION_ACTOR"] || state.disabledActionActorObj
			};
		}
		case 'UPDATE_RESTRICTIONS':{
			let currentDay = action.payload.currentDay;
			let { actionFrequency, actionLastTakenDay, isActionOption8} = calculateActionFrequency(action.payload);
			let actionMaxedOut = [];
			let actionTemp7Restricted = [];
			let actionTemp10Restricted = [];

			//Handing special case of Action 2 ActionOption 8
			if(isActionOption8){
				actionMaxedOut.push(2);
			}

			//<Action ids> here in Disabling logic is hardcoded
			//TODO: Recieve Disabling logic from backend and store in redux
			for(const actions in actionFrequency){
				// if([1,5,9,12].includes(parseInt(actions,10)) && actionFrequency[actions] >= 5){
				// 	actionMaxedOut.push(parseInt(actions,10));
				// }
				if([8].includes(parseInt(actions,10)) && actionFrequency[actions] >= 7){
					actionMaxedOut.push(parseInt(actions,10));
				}
				if([3].includes(parseInt(actions,10)) && actionFrequency[actions] >= 1){
					actionMaxedOut.push(parseInt(actions,10));
				}
				// if([4].includes(parseInt(actions,10)) && actionFrequency[actions] >= 3){
				// 	actionMaxedOut.push(parseInt(actions,10));
				// }
				if([15].includes(parseInt(actions,10)) && actionFrequency[actions] >= 15){
					actionMaxedOut.push(parseInt(actions,10));
				}
			}


			for(const actions in actionLastTakenDay){
				if([1,5,9,12,20].includes(parseInt(actions,10)) && currentDay - actionLastTakenDay[actions] < 7){
					actionTemp7Restricted.push(parseInt(actions,10));
				}
				if([2,4].includes(parseInt(actions,10)) && currentDay - actionLastTakenDay[actions] < 10){
					actionTemp10Restricted.push(parseInt(actions,10));
				}
			}

			let newMaxedOut = getUniqueItemsOnly(actionMaxedOut, state.permanentDisabledActionList)

			return{
				...state,
				permanentDisabledActionList: [...state.permanentDisabledActionList, ...newMaxedOut],
				sevenDaysRestrictedActionsList: [...actionTemp7Restricted],
				tenDaysRestrictedActionsList: [...actionTemp10Restricted]
			};
		}

		case 'UPDATE_ACTIONS_MAXEDOUT':{
			let disabledActors = action.payload.disabledActors;
			let actionMaxedOut = [];

			for(const actions in disabledActors){
				if([6, 7, 14, 17, 19, 21].includes(parseInt(actions,10)) && disabledActors[actions].length >= 3){
					actionMaxedOut.push(parseInt(actions,10));
				}
				if([18].includes(parseInt(actions,10)) && disabledActors[actions].length >= 9){
					actionMaxedOut.push(parseInt(actions,10));
				}
				if([10].includes(parseInt(actions,10)) && disabledActors[actions].length >= 4){
					actionMaxedOut.push(parseInt(actions,10));
				}
			}

			let newMaxedOut = getUniqueItemsOnly(actionMaxedOut, state.permanentDisabledActionList)

			return{
				...state,
				permanentDisabledActionList: [...state.permanentDisabledActionList,...newMaxedOut]
			}
		}

		case 'UPDATE_DISABLED_ACTORS_NO_CONDITION':{
			let actorsSelected = action.payload.actorsSelected;
			let actionId = action.payload.actionId;
			return{
				...state,
				disabledActionActorObj: updateDisabledActors(state.disabledActionActorObj, actionId, actorsSelected)
			};
		}

		case 'UPDATE_DISABLED_ACTORS_BUILD_NETWORK':{
			let actorsSelected = action.payload.actorsSelected;
			let actionId = action.payload.actionId;
			return{
				...state,
				disabledActionActorObj: updateDisabledActorsWithBadge(state.disabledActionActorObj, actionId, actorsSelected)
			};
		} 

		case 'UPDATE_DISABLED_ACTORS_RECOGNITION':{
			let actorsSelected = action.payload.actorsSelected;
			let actionId = action.payload.actionId;
			return{
				...state,
				disabledActionActorObj: updateDisabledActorsWithBadge(state.disabledActionActorObj, actionId, actorsSelected)
			};
		}

		case 'UPDATE_DISABLED_ACTORS_SUCCESSFUL_CONDITION':{
			let actionActorToDisable = calculateIsSuccessDisabling(action.payload);
			return{
				...state,
				disabledActionActorObj: updateDisabledSuccessfulCondition(state.disabledActionActorObj, actionActorToDisable)
			}
		}

		default:
			return state;
	}
};

export default restrictions;