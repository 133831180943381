import React from 'react';
import styles from './metricAdoption.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const MetricAdoption = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="4" cy="4" r="4" fill="#1BE1D4"/>
			<path d="M1.41177 4.13446L2.7227 5.88236L6.65547 2.82353" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>

		</div>
	);
}

export default applyWrappers(MetricAdoption, styles);