const initialState = {
    scrollHistoryToTop: false,
    showHeader: true,
    showOverlay: false,
    overlayComponentType: '',
    highlightDesktopHeader: false,
    overlayComponent: '',
    selectedActor: null,
    selectedAction: null,
    selectedEventOption: null,
    highlightActors: false,
    actorSelectionType: '',
    isActorSelectionRequired: false,
    actionSelectedActors: [],
    minActorsRequired: 0,
    maxActorsRequired: 0,
    actionDayCost: 0,
    downloadingReport: false,
    walkthrough: {
        actor: {
            strategy: false,
            capability: false,
            culture: false
        },
        action: {
            strategy: false,
            capability: false,
            culture: false
        }
    }
};


const uiState = (state = initialState, action = {}) => {
    switch (action.type) {
        case 'INIT_GAME_DATA':
            return {
                ...state
            };

        case 'SET_ACTION_DATA':
            return {
                ...state,
                ...action.payload.uiState
            };

        case 'UPDATE_UI_STATE':
            return {
                ...state,
                ...action.payload
            }
        case 'UPDATE_WALKTHROUGH_UI_STATE':
            return {
                ...state,
                walkthrough: {
                    ...state.walkthrough,
                    ...action.payload
                }
            }
        case 'UPDATE_REPORT_DOWNLOADING_UISTATE' :
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
};

export default uiState;