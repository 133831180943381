import actionConsts from 'constants/actions';

const initialState = {
	actorsList: [],
	personasList: [],
	alreadySelectedActors: [],
	convertedActors: [],
	NewActorConverted: []
};

const actors = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.SET_INIT_DATA:
			return {
				...state,
				actorsList: action.payload.actors,
				networksList: action.payload.networks,
				personasList: action.payload.personas,
				alreadySelectedActors: action.payload.reduxData["ALREADY_SELECTED_ACTORS"] || state.alreadySelectedActors,
				convertedActors: action.payload.reduxData["CONVERTED_ACTORS"] || state.convertedActors
			};
		case "UPDATE_UI_STATE":
			if(action.payload.selectedActor && !state.alreadySelectedActors.find(id => id === action.payload.selectedActor)){
				return {
					...state,
					alreadySelectedActors: [...state.alreadySelectedActors, action.payload.selectedActor],
				};
			}
			else{
				return state;
			}
		case "ACTOR_CONVERTED":
			return {
				...state,
				convertedActors: [...state.convertedActors, action.payload.actorId]
			}
		case "NEW_ACTOR_CONVERTED":
			return {
				...state,
				NewActorConverted: [...action.payload.NewActorConverted],
			}	
		case "RESET_NEW_ACTOR_CONVERTED":
			return {
				...state,
				NewActorConverted: [],
			}
		default:
			return state;
	}
};

export default actors;