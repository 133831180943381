import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		headerContainer: {
			backgroundColor: skin.grayColor8
		},
		mainHeading: {
			...myTypography.body1,
			fontSize: '16px',
			fontWeight: 600,
			color: skin.grayColor1,
			'@media (min-width: 1440px)' : {
				fontSize: '18px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '20px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '24px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '32px'
			},
		},
		subHeading: {
			...myTypography.body2,
			fontWeight: 600,
			color: skin.grayColor1,
			textTransform: 'uppercase',
		},
		metricHeading: {
			...myTypography.body2,
			color: skin.grayColor1,
			fontSize: '9px',
			textTransform: 'uppercase',
			'@media (min-width: 1440px)' : {
				fontSize: '10.125px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '11.25px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '13.5px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '18px'
			},
		},
		metricValue: {
			...myTypography.body2,
			fontWeight: 600,
			color: skin.grayColor1,
			fontSize: '10px',
			textTransform: 'uppercase',
			'@media (min-width: 1440px)' : {
				fontSize: '11.25px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '12.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '15px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '20px'
			},
		},
		axisLabel: {
			...myTypography.body2,
			color: skin.grayColor2,
			textTransform: 'uppercase',
		},
		legendLabel: {
			...myTypography.body2,
			color: skin.grayColor2,
		},
		graphBoxText: {
			...myTypography.body1,
			color: skin.grayColor1,
			backgroundColor: skin.grayColor8,
		},
		filtersContainer: {
			backgroundColor: skin.grayColor8
		},
		filtersAAContainer:{
			backgroundColor: skin.grayColor8,
			border: `1px solid ${skin.grayColor6}`
		},
		filterCircle: {
			border: `1px solid #7E8E9C`,
			':hover': {
				border: `1px solid #3ABDFF`
			}
		},
		filterSelectedCircle: {
			border: `4px solid #3ABDFF`,
			'@media (min-width: 1440px)' : {
				border: `4.5px solid #3ABDFF`
			},
			'@media (min-width: 1600px)' : {
				border: `5px solid #3ABDFF`
			},
			'@media (min-width: 1920px)' : {
				border: `6px solid #3ABDFF`
			},
			'@media (min-width: 2560px)' : {
				border: `8px solid #3ABDFF`
			},
		},
		selectedCircle: {
			backgroundColor: skin.white
		},
		filterText: {
			...myTypography.body1,
			fontSize: '12px',
			fontWeight: 600,
			color: '#7E8E9C',
			'@media (min-width: 1440px)' : {
				fontSize: '13.5px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '15px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '18px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '24px'
			},
		},
	});
}

export default getSkin;