import React, { Component } from 'react';
import styles from './leaderboardSvg.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/functions';

const Leaderboard = (props) => {

    const skin = props.skinGuide.globalProfiles.palette
    let color = skin.white;
    if (checkIfPresent(props.svgColor)) {
        color = props.svgColor;
    }
    return (
        <div styleName="main-component">
            <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.351562 0.866089H1.89148C1.88589 0.695526 1.88278 0.523956 1.88278 0.351563C1.88278 0.157379 2.04025 0 2.23434 0H9.76556C9.95975 0 10.1171 0.157379 10.1171 0.351563C10.1171 0.523956 10.1141 0.695526 10.1085 0.866089H11.6484C11.8426 0.866089 12 1.02347 12 1.21765C12 2.79291 11.5883 4.27908 10.8408 5.40253C10.1018 6.51315 9.11755 7.15018 8.05435 7.21216C7.81329 7.47446 7.55841 7.68833 7.293 7.85147V9.414H7.88278C8.59579 9.414 9.17578 9.99408 9.17578 10.707V11.2968H9.20078C9.39496 11.2968 9.55234 11.4543 9.55234 11.6483C9.55234 11.8425 9.39496 11.9999 9.20078 11.9999H2.79922C2.60504 11.9999 2.44766 11.8425 2.44766 11.6483C2.44766 11.4543 2.60504 11.2968 2.79922 11.2968H2.82422V10.707C2.82422 9.99408 3.4043 9.414 4.11722 9.414H4.707V7.85147C4.44159 7.68833 4.1868 7.47446 3.94574 7.21216C2.88245 7.15018 1.89825 6.51315 1.15924 5.40253C0.411713 4.27908 0 2.79291 0 1.21765C0 1.02347 0.157471 0.866089 0.351562 0.866089ZM10.2554 5.01306C10.872 4.08637 11.2343 2.87448 11.2895 1.56921H10.0692C9.94244 3.17377 9.5658 4.65683 8.97711 5.8342C8.88336 6.0217 8.78531 6.19876 8.68359 6.36566C9.26834 6.14484 9.80951 5.68323 10.2554 5.01306ZM1.74463 5.01306C2.19049 5.68323 2.73166 6.14484 3.31641 6.36566C3.2146 6.19876 3.11664 6.0217 3.02289 5.8342C2.4342 4.65683 2.05765 3.17377 1.93076 1.56921H0.710541C0.765747 2.87448 1.12802 4.08637 1.74463 5.01306Z" fill="#14324B"/>
</svg>
        </div>
    );
}

export default applyWrappers(Leaderboard, styles);
