import React from 'react';
import styles from './close.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const Close = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const strokeWidth = props.strokeWidth ? props.strokeWidth : "2";
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 17 18" fill="none">
				<path d="M15.5 1L8.25 8.67647M1 16.3529L8.25 8.67647M8.25 8.67647L1 1L15.5 16.3529" stroke="white" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</div>
	);
}

export default applyWrappers(Close, styles);