import actionConstants from "constants/actions";

const initialState = {
	eventsList: []
};

const events = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConstants.SET_INIT_DATA:
			return {
				eventsList: action.payload.events
			};
		default:
			return state;
	}
};

export default events;