import React, { useState } from 'react';
import styles from './graphBox.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { useSelector, useDispatch } from 'react-redux';
import VerticalBar from 'commonComponents/graphs/verticalBar';
import LineChart from 'commonComponents/graphs/lineChart';
import LineChartWithDot from 'commonComponents/graphs/lineChartWithDot';

const GraphBox = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const sccMetrics = ["strategy", "capability", "culture"];
	const sccColors = ['#835AC5', '#3ABDFF', '#FF75D8'];

	const dispatch = useDispatch();
	const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			userActorMetrics: state.userActorMetrics,
			metrics: state.metrics,
			actors: state.actors,
			actorInfo: state.actorInfo,
			tutorial: state.tutorial
		};
	});

	const [filterState, setFilterState] = useState(0);
  const userActorMetricsForLabels = myProps.userActorMetrics;
	const metrics = myProps.metrics;
  const currentMetricId = userActorMetricsForLabels.actorMetricIds[sccMetrics[filterState]];
  const metricDetails = metrics.metricsObj[currentMetricId];
  const filterStateKey = props.getLabel(metricDetails.name);

	const renderGraph = () => {
		switch (props.graphType) {
			case 'VERTICAL_BAR':
				return <VerticalBar data={props.graphData} {...props.graphData} graphColor={sccColors[filterState]} filterState={filterStateKey} legend={props.legend} />;

			case 'LINE_CHART':
				return <LineChart data={props.graphData} colors={props.colors} legend={props.legend} />;

			case 'LINE_CHART_WITH_DOT':
				return <LineChartWithDot data1={props.graphData1} data2={props.graphData2} legend={props.legend} />;

			default:
				break;
		}
	}


	const renderHeaderMetrics = () => {
		return props.headerMetrics.map((eachMetric) => {
			return (
				<div styleName="header-metric">
					<span className={css(myStyles.metricHeading)} styleName="header-metric-title">{eachMetric.title}:</span>
					{
						props.headerMetricsColorCoded
							? <span className={css(myStyles.metricValue)} style={{ color: eachMetric.color }} styleName="header-metric-value">{eachMetric.value}{eachMetric.postFix}</span>
							: <span className={css(myStyles.metricValue)} styleName="header-metric-value">{eachMetric.value}{eachMetric.postFix}</span>
					}

				</div>
			);
		});
	}

	const renderSccFilters = () => {


		const userActorMetrics = myProps.userActorMetrics;
		const metrics = myProps.metrics;

		const curr_filter_style = (props.graphName === "ACTOR_ADOPTION")?"filters-container-actor-adoption":"filters-container";
		const curr_filter_class = (props.graphName === "ACTOR_ADOPTION")? myStyles.filtersAAContainer: myStyles.filtersContainer;
		const metricFilters = sccMetrics.map((eachMetric, index) => {
			const currentMetricId = userActorMetrics.actorMetricIds[eachMetric];
			const metricDetails = metrics.metricsObj[currentMetricId];

			return (
				<div key={`scc-filter-${index}`} styleName="filter-container" onClick={() => { setFilterState(index) }}>
					{
						filterState === index
							? <div style={{ borderColor: sccColors[index] }} className={css(myStyles.filterSelectedCircle)} styleName="filter-circle">
								<div className={css(myStyles.selectedCircle)} styleName="filter-selected"></div>
							</div>
							: <div style={{ borderColor: sccColors[index] }} className={css(myStyles.filterCircle)} styleName="filter-circle"></div>
					}
					<div style={{ color: sccColors[index] }} className={css(myStyles.filterText)} styleName="filter-text">{props.getLabel(metricDetails.name)}</div>
				</div>
			);
		});
		return (
			<div className={css(curr_filter_class)} styleName={curr_filter_style}>
				{metricFilters}
			</div>
		);
	}

	const renderLegends = () => {
		return props.headerMetrics.map((eachMetric) => {
			return (
				<div styleName="each-legend">
					{
						eachMetric.legendType === 'point'
						? <div styleName="legend-color-point" style={{ borderColor: eachMetric.color }}></div>
						: <div styleName="legend-color" style={{ background: eachMetric.color }}></div>
					}
					<div className={css(myStyles.metricHeading)} styleName="legend-text">{eachMetric.title}</div>
				</div>
			);
		});
	}

	const renderText = () => {
		const returnTextArray = [];
		if(props.textArray == undefined) {
			return returnTextArray;
		}
		props.textArray.map((eachText, index) => {
			returnTextArray.push(<div>{props.getLabel(eachText)}</div>);
			if (index !== (props.textArray.length - 1)) {
				returnTextArray.push(
					<br />
				);
			}
		});

		return returnTextArray;
	}


	const curr_graph_style = (props.graphName === "ACTOR_ADOPTION")?"graph-container-actor-adoption":"graph-container";
	const curr_heading_style = (props.graphName === "ACTOR_ADOPTION")?"header-container-actor-adoption":"header-container";
	return (
		<div styleName="graph-box-container">
			<div className={css(myStyles.headerContainer)} styleName={curr_heading_style}>
				<div className={css(myStyles.mainHeading)} styleName="main-heading-container">
					{props.graphTitle}
				</div>
				<div styleName="sub-heading-container">
					{
						props.subHeadingAvailable
							? <div className={css(myStyles.subHeading)} styleName="sub-heading">{props.subHeading}:</div>
							: null
					}
					{
						props.headerMetricsAvailable
							? renderHeaderMetrics()
							: null
					}
				</div>
			</div>
			{
				props.showSccFilters
					? renderSccFilters()
					: null
			}
			<div styleName={curr_graph_style}>
				{renderGraph()}
			</div>
			{
				props.showLegend
					? <div styleName="legends-container">
						{renderLegends()}
					</div>
					: null
			}
			<div className={css(myStyles.graphBoxText)} styleName="text-container">
				{renderText()}
			</div>
		</div>
	);
}

export default applyWrappers(GraphBox, styles);
