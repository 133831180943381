import React, { useState, useEffect, useRef } from 'react';
import styles from './eventPopup.module.sass';
import { useSelector, useDispatch } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import Mentor from 'svgComponents/mentor';
import MetricBar from 'commonComponents/metricBar';
import EventImage from 'svgComponents/eventImage';
import DownArrow from 'svgComponents/downArrow';
import UpArrow from 'svgComponents/upArrow';
import RightArrowFlat from 'svgComponents/rightArrowFlat';
import { takeAction } from 'actions/apiActions/action';
import { getActorNames, formActorLabelKeys, removeActorPopupPayload } from 'util/functions';
import { TweenMax } from 'gsap';
import EcosystemSvg from 'svgComponents/ecosystemSvg';


import analyticsUtil from 'util/analyticsUtil';

const EventPopup = (props) => {

	const scrollableContainerRef = useRef(null);
	const dispatch = useDispatch();

	const myProps = useSelector((state) => {
		return {
			events: state.events,
			userEvents: state.userEvents,
			uiState: state.uiState,
			actions: state.actions,
			user: state.user,
			actors: state.actors,
			storylineDefaults: state.storyline.storylineDefaults,
			userMetrics: state.userMetrics,
			userActiveActionResponse: state.userActiveActionResponse,
		}
	});

	useEffect(() => {
		scrollContainer();
	},
	[myProps.userEvents.activeEvent.responseAdded]);

	const scrollContainer = () => {
		TweenMax.to(
			scrollableContainerRef.current,
			2,
			{
				scrollTop: 200
			}
		);
	}

	const sccMetrics = ["strategy", "capability", "culture"];
	//Below conversion is language independent
	const initiativeLabels = {
		'label_strategy' : 'strategy',
		'label_capability' : 'capability',
		'label_culture' : 'culture'
	}
				
	const myStyles = getSkin(props.skinGuide);

	const activeEvent = myProps.userEvents.activeEvent;

	const eventDetails = myProps.events.eventsList.find((eachEvent) => {
		return eachEvent.id === activeEvent.id;
	});

	useEffect(() => {
		analyticsUtil.track('EVENT_POPUP', { eventId: activeEvent.id, eventType: activeEvent.type });
	}, []);

	const actionDetails = myProps.actions.actionsList.find((eachAction) => {
		return eachAction.id === activeEvent.actionId;
	});

	const actionOptionDetails = myProps.actions.actionOptionsList.filter((eachOption) => {
		return eachOption.actionId == activeEvent.actionId;
	});

	const onEventPopupClick = (e) => {
		e.stopPropagation();
	}

	const [openAction, setOpenActions] = useState(true);

	const [showImpact, setshowImpact] = useState(false);

	const onClickEventAction = (optionDetails) => {
		if (optionDetails.isActorRequired) {

			dispatch({
				type: 'UPDATE_UI_STATE',
				payload: {
					showOverlay: true,
					overlayComponentType: 'LEFT',
					highlightDesktopHeader: false,
					overlayComponent: 'EVENT_ACTION_POPUP',
					selectedEventOption: optionDetails.id,
					highlightActors: true,
					actorSelectionType: optionDetails.actorSelectionType,
					isActorSelectionRequired: optionDetails.isActorRequired,
					actionSelectedActors: [],
					minActorsRequired: optionDetails.minSelection,
					maxActorsRequired: optionDetails.maxSelection,
					actionDayCost: optionDetails.daysCost
				}
			});
		} else {

			const payload = {
				actionOptionId: optionDetails.id,
				poolId: optionDetails.actionOptionPool[0].poolId,
				actorIds: activeEvent.actorIds,
				route: optionDetails.route,
				currentDay: myProps.user.currentDay,
				isEvent: true
			};

			analyticsUtil.track('EVENT_ACTION_CLICKED', payload);

			dispatch(takeAction(payload));
		}
	}

	const showImpactsClicked = () => {
		setshowImpact(true);
	}

	const renderLeftTalkBubble = (details, isResponse=false) => {
		const response = details.response;
		let actorKeys = {};
		if (activeEvent.actorIds) {
			const actorId  =  activeEvent.actorIds[0]
			actorKeys = {'ACTOR': props.getLabel(`label_actor_${actorId}_name`)};
		}
		let actorKeysForResponse = {};
		if(isResponse){
			if (activeEvent.responseAdded) {
				const actorId  =  response.actorIds[0]
				actorKeysForResponse = {'ACTOR': props.getLabel(`label_actor_${actorId}_name`)};
			}
		}
		let messageConditionalStyle = {}
		let arrowConditionalStyle = {}
		if(isResponse && details.responseAdded && details.isActorMetricsImpacted){
			let diffSum = 0;
			details.userActorMetrics.forEach((eachActor) =>{
				diffSum += eachActor.diff
			})
			if(diffSum > 0){
				messageConditionalStyle['backgroundColor'] = '#1BE1D4'
				arrowConditionalStyle['borderRight'] =  `10px solid #1BE1D4`
			}
			//neutral color
			else if(diffSum === 0){
				messageConditionalStyle['backgroundColor'] = '#7E8E9C'
				arrowConditionalStyle['borderRight'] =  `10px solid #7E8E9C`
			}
			else{
				messageConditionalStyle['backgroundColor'] = '#FD5382'
				arrowConditionalStyle['borderRight'] =  `10px solid #FD5382`
			}
		}

		if(isResponse && details.type == 'STATIC'){
			let diffSum = 0;
			details.userActorMetrics.forEach((eachActor) =>{
				diffSum += eachActor.diff
			})
			if(diffSum > 0){
				messageConditionalStyle['backgroundColor'] = '#1BE1D4'
				arrowConditionalStyle['borderRight'] =  `10px solid #1BE1D4`
			}

			//neutral color
			else if(diffSum === 0){
				messageConditionalStyle['backgroundColor'] = '#7E8E9C'
				arrowConditionalStyle['borderRight'] =  `10px solid #7E8E9C`
			}
			
			else{
				messageConditionalStyle['backgroundColor'] = '#FD5382'
				arrowConditionalStyle['borderRight'] =  `10px solid #FD5382`
			}
		}

		//Getting Name of CXO actor, current ID of CEO is 7.
		let CXOactorName = getActorNames(myProps.actors.actorsList, [7] , props.getLabel);

		return (
			<div styleName="message-container-left">
				<div className={css(myStyles.leftArrow)} styleName="left-arrow" style={arrowConditionalStyle}></div>
				<div className={css(myStyles.leftMessage)} styleName="left-message" style={messageConditionalStyle}>
					{/* <b>{props.getLabel(details.title)}</b>
					<br /> */}
					{isResponse && props.getLabel(response.title) !== 'NULL' ?
							<React.Fragment>
								<b>{props.getLabel(response.title)}</b>
								<br />
								{props.getLabel(response.description, '', 
									{
										...actorKeysForResponse,
										CLIENT:actorKeys['ACTOR'],
										CXO: CXOactorName[0],
										ACTOR_CONVERTED: convertedActorsName[convertedActorsName.length-1]
									})
								}
							</React.Fragment>
						: null
					}

					{isResponse && details.type == 'STATIC'?
							<React.Fragment>
								<div className={css(myStyles.leftStaticMessage)}styleName="left-message" style={messageConditionalStyle}>
								{props.getLabel('label_impact_ecosystem')} </div>
							</React.Fragment>
						: null
					} 

					{!isResponse &&
						props.getLabel(details.description, '', 
						{
							...actorKeys,
							CXO: CXOactorName[0],
							CLIENT:actorKeys['ACTOR']
						})
					}
				</div>
			</div>
		);
	}

	const renderRightTalkBubble = (activeEvent) => {
		const response = activeEvent.response
		let actorNames = [], actorKeys = {};
		if (activeEvent.response) {
			actorNames = getActorNames(myProps.actors.actorsList, activeEvent.response.actorIds, props.getLabel);
			actorKeys = formActorLabelKeys(actorNames);
		}
		let convertedActorsName = [];
		if(myProps.actors.convertedActors && myProps.actors.convertedActors.length > 0){
			convertedActorsName = getActorNames(myProps.actors.actorsList, myProps.actors.convertedActors, props.getLabel);
		}
		const actionOptionSelected = myProps.actions.actionOptionsList.filter((eachOption) => {
			return eachOption.id == response.aOId
		});
		const actionOptionPool = actionOptionSelected[0].actionOptionPool[0] ? actionOptionSelected[0].actionOptionPool[0] : null;

		if(activeEvent.type == 'STATIC'){
			return (
				<div styleName="message-container-right">
					<div className={css(myStyles.rightMessage)} styleName="right-message">
						{props.getLabel('label_static_events_cta')}
						<br />
						<div className={css(myStyles.eachAction)} styleName="each-action"style={{pointerEvents:'none'}}>
							<div styleName="ecosystem-svg">
								<EcosystemSvg/>
							</div>
							<div className={css(myStyles.actionText)} styleName="action-text">
								{props.getLabel('label_see_impact_ecosystem')}
							</div>
						</div>
					</div>
					<div className={css(myStyles.rightArrow)} styleName="right-arrow"></div>
				</div>
			);
		}

		return (
			<div styleName="message-container-right">
				<div className={css(myStyles.rightMessage)} styleName="right-message">
					{props.getLabel('label_dynamic_events_cta')}
					<br />
					<div className={css(myStyles.eachAction)} styleName="each-action" style={{pointerEvents:'none'}}>
						<div styleName="action-image">
							<img height="100%" src={props.getImage(actionOptionSelected[0].image)} alt={`action-img`} />
						</div>
						{actionOptionPool &&
							<div className={css(myStyles.actionText)} styleName="action-text">
								{props.getLabel(actionOptionPool.name, '', actorKeys)}
							</div>
						}
					</div>
				</div>
				<div className={css(myStyles.rightArrow)} styleName="right-arrow"></div>
			</div>
		);
	}

	const getAverageAdoption = (diff) => {
		let sum = 0;
		let count = 0;
		sccMetrics.map((eachMetric, index) => {
			const currentMetricId = myProps.userMetrics.userMetricIds[eachMetric];
			const userMetricDetails = myProps.userMetrics.userMetricsObj[currentMetricId];
			if (userMetricDetails.value) {
				sum += userMetricDetails.value;
				count++;
			}
		});
		if (count === 0) {
			return 0;
		}
		return ((sum / count) + diff).toFixed(1);
    }

	const renderResponse = (activeEvent) => {
	
		const initiativeDetails = myProps.actions.initiatives.find((eachInitiative) => {
			return eachInitiative.id === actionDetails.initiativeId;
		});
		const initiativeNameSmallCase = initiativeLabels[initiativeDetails.name];
		const currentMetricId = myProps.userMetrics.userMetricIds[initiativeNameSmallCase];
		const userMetricDetails = myProps.userMetrics.userMetricsObj[currentMetricId];
		let value = userMetricDetails.value ? userMetricDetails.value : 0;
		let diff = 0;
		if(myProps.userEvents.activeEvent.isActorMetricsImpacted){
			myProps.userEvents.activeEvent.metricsImpacted.forEach((eachActor) =>{
				diff += eachActor.diff
			})
			diff /= 13;
		}
		value = value+diff;
		let target = 80;
		if(myProps.storylineDefaults[`${initiativeNameSmallCase}IndexTarget`]){
			target = myProps.storylineDefaults[`${initiativeNameSmallCase}IndexTarget`]
		}
		const rightBubble = renderRightTalkBubble(activeEvent)
		const leftBubble = renderLeftTalkBubble(activeEvent, true)

		let metricConditionalStyle = {}
		let containerConditionalStyle = {}
		let averageConditionalStyle = {}

		//These style components below are for the action taken, where the containerConditonal
	    // is for the border and metric is the change in strategy/capability...etc. 

		if(diff.toFixed(1) > 0){
				containerConditionalStyle['border'] = '1px solid #1BE1D4'
				metricConditionalStyle['backgroundColor'] = '#1BE1D4'
			}
			else if(diff.toFixed(1) < 0){
				containerConditionalStyle['border'] = '1px solid #FD5382'
				metricConditionalStyle['backgroundColor'] = '#FD5382'
			}
			else{
				containerConditionalStyle['border'] = '1px solid #7E8E9C'
				metricConditionalStyle['backgroundColor'] = '#7E8E9C'
			}

		// This below style component is for the average ecosystem adoption 

		if((diff/3).toFixed(1) > 0){
				averageConditionalStyle['backgroundColor'] = '#1BE1D4'
			}
			else if((diff/3).toFixed(1) < 0){
				averageConditionalStyle['backgroundColor'] = '#FD5382'
			}
			else{
				averageConditionalStyle['backgroundColor'] = '#7E8E9C'
			}

		return(
			<div>
				{rightBubble}
				{leftBubble}
				<div styleName="body-metrics" style={containerConditionalStyle}>
                    <div styleName = 'metric-bar'>
                       <MetricBar value={(value).toFixed(1)} showAnimation={false} diff={0} target={target} initiative={initiativeDetails.name}/>
                    </div>
                    <div styleName='initiative-value-container'>
                        <div styleName='initiative-diff' 
                            style={metricConditionalStyle}
                        > 
                            {diff.toFixed(1)>0 ? '+':''}{(diff).toFixed(1)}
                        </div>
                        <div>
                            <div className={css(myStyles.initiativeValue)} styleName='initiative-value'>{(value).toFixed(1)}%</div>
                            <div styleName='initiative-name'>{props.getLabel(initiativeDetails.name)} </div>
                        </div>
                    </div>
                    <div styleName = 'right-arrow-flat'>
							<RightArrowFlat />
                    </div> 
                    <div styleName='initiative-value-container'>
                        <div styleName='initiative-diff' 
                            style={averageConditionalStyle}> 
                            {(diff/3).toFixed(1) > 0 ? '+': ''}
                            {(diff/3).toFixed(1)}
                        </div>
                        <div>
                            <div className={css(myStyles.initiativeValue)} styleName='initiative-value'>{getAverageAdoption(diff/3)}%</div>
                            <div styleName='initiative-name'>{props.getLabel('label_ecosystem_adoption')}</div>
                        </div>
                    </div>
                </div>
			</div>
		)
	}

	const renderStaticResponse = (activeEvent) => {
	
		const initiativeDetails = myProps.actions.initiatives.find((eachInitiative) => {
			return eachInitiative.id === actionDetails.initiativeId;
		});
		const initiativeNameSmallCase = initiativeLabels[initiativeDetails.name];
		const currentMetricId = myProps.userMetrics.userMetricIds[initiativeNameSmallCase];
		const userMetricDetails = myProps.userMetrics.userMetricsObj[currentMetricId];
		let value = userMetricDetails.value ? userMetricDetails.value : 0;
		let diff = 0;	
			myProps.userEvents.activeEvent.metricsImpacted.forEach((eachActor) =>{
				diff += eachActor.diff
			})
			diff /= 13;
		value = value+diff;
		let target = 80;
		if(myProps.storylineDefaults[`${initiativeNameSmallCase}IndexTarget`]){
			target = myProps.storylineDefaults[`${initiativeNameSmallCase}IndexTarget`]
		}
		const rightBubble = renderRightTalkBubble(activeEvent)
		const leftBubble = renderLeftTalkBubble(activeEvent, true)

		let metricConditionalStyle = {}
		let containerConditionalStyle = {}
		let averageConditionalStyle = {}

		//The style components below is for the action taken, where the containerConditonal
	    // is for the border and metric is the change. 

		if(diff.toFixed(1) > 0){
			containerConditionalStyle['border'] = '1px solid #1BE1D4'
			metricConditionalStyle['backgroundColor'] = '#1BE1D4'
			}
			else if(diff.toFixed(1) < 0){
				containerConditionalStyle['border'] = '1px solid #FD5382'
				metricConditionalStyle['backgroundColor'] = '#FD5382'
			}
			else{
				containerConditionalStyle['border'] = '1px solid #7E8E9C'
				metricConditionalStyle['backgroundColor'] = '#7E8E9C'
			}

		// The style component below is for the average ecosystem adoption 

		if((diff/3).toFixed(1) > 0){
				averageConditionalStyle['backgroundColor'] = '#1BE1D4'
			}
			else if((diff/3).toFixed(1) < 0){
				averageConditionalStyle['backgroundColor'] = '#FD5382'
			}
			else{
				averageConditionalStyle['backgroundColor'] = '#7E8E9C'
			}


		return(
			<div>
				{rightBubble}
				{leftBubble}
				<div styleName="body-metrics" style={containerConditionalStyle}>
                    <div styleName = 'metric-bar'>
                       <MetricBar value={(value).toFixed(1)} showAnimation={false} diff={0} target={target} initiative={initiativeDetails.name}/>
                    </div>
                    <div styleName='initiative-value-container'>
                        <div styleName='initiative-diff' 
                            style={metricConditionalStyle}>
                            {diff.toFixed(1) > 0 ? '+': ''}{(diff).toFixed(1)}
                        </div>
                        <div>
                            <div className={css(myStyles.initiativeValue)} styleName='initiative-value'>{(value).toFixed(1)}%</div>
                            <div styleName='initiative-name'>{props.getLabel(initiativeDetails.name)} </div>
                        </div>
                    </div>
                     <div styleName = 'right-arrow-flat'>
							<RightArrowFlat />
                    </div> 
					
                    <div styleName='initiative-value-container'>
                        <div styleName='initiative-diff' 
                            style={averageConditionalStyle}>
                            {(diff/3).toFixed(1) >0 ? '+': ''}
                            {(diff/3).toFixed(1)}
                        </div>
                        <div>
                            <div className={css(myStyles.initiativeValue)} styleName='initiative-value'>{getAverageAdoption(diff/3)}%</div>
                            <div styleName='initiative-name'>{props.getLabel('label_ecosystem_adoption')}</div>
                        </div>
                    </div>
                </div>
			</div>
		)
	}

	const renderAction = (eachActionOption) => {

		const actionOptionPool = eachActionOption.actionOptionPool[0] ? eachActionOption.actionOptionPool[0] : null;
		if (actionOptionPool == null) {
			return null;
		}

		let actorNames = [], actorKeys = {};
		if (activeEvent.response) {
			actorNames = getActorNames(myProps.actors.actorsList, activeEvent.response.actorIds, props.getLabel);
			actorKeys = formActorLabelKeys(actorNames);
		}

		return (
			<div className={css(myStyles.eachAction)} styleName="each-action" onClick={() => { onClickEventAction(eachActionOption) }}>
				<div styleName="action-image">
					<img width="100%" height="100%" src={props.getImage(eachActionOption.image)} alt={`action-img`} />
				</div>
				<div className={css(myStyles.actionText)} styleName="action-text">
					{props.getLabel(actionOptionPool.name, '', actorKeys)}
				</div>
			</div>
		);
	}

	const closePopup = () => {
		analyticsUtil.track('EVENT_POPUP_CLOSED', { eventId: activeEvent.id, eventType: activeEvent.type });
		dispatch({
			type: 'ADD_STATIC_EVENT_METRICS',
			payload: {
				userActorMetrics: activeEvent.userActorMetrics,
				userMetricsForThisAction: activeEvent.userMetricsForThisAction
			}
		});
		if(activeEvent.type != 'STATIC'){
			dispatch({
				type: 'ADD_DYNAMIC_EVENT_METRICS',
				payload: {
					userMetrics: activeEvent.userMetrics,
				}
			});
		}
		dispatch({
			type: 'PUSH_ACTIVE_EVENT_TO_LIST',
			payload: {}
		});
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: '',
				actionSelectedActors: [],
				selectedAction: null,
				highlightActors: false,
				actorSelectionType: '',
				isActorSelectionRequired: false,
				minActorsRequired: 0,
				maxActorsRequired: 0,
				actionDayCost: 0
			}
		});

		if(myProps.userActiveActionResponse.pendingActionResponse.length > 0){
			dispatch({
                type: 'ADD_DETAILS_FOR_MONTHLY_REPORT',
                payload: {
                    isAnyActionPresent: true
                }
            });
			setTimeout(() => {
				dispatch(removeActorPopupPayload());
				dispatch({
					type: 'UPDATE_UI_STATE',
					payload: {
						showOverlay: true,
						overlayComponentType: 'BOTTOM',
						highlightDesktopHeader: false,
						overlayComponent: 'PENDING_ACTION_RESPONSE_POPUP'
					}
				});
			},2000)
		}
	}

	const renderActionList = () => {
		return actionOptionDetails.map((eachActionOption) => {
			return renderAction(eachActionOption);
		});
	}

	if (myProps.userEvents.activeEvent.type == undefined || myProps.userEvents.activeEvent.type == null) {
		return (
			<div styleName="container" onClick={onEventPopupClick}>
				{props.getLabel('label_loading')}
			</div>
		);
	}
	let convertedActorsName = [];
	if(myProps.actors.convertedActors && myProps.actors.convertedActors.length > 0){
		convertedActorsName = getActorNames(myProps.actors.actorsList, myProps.actors.convertedActors, props.getLabel);
	}

	    //Getting Name of CXO actor, current ID of CEO is 7.
		let CXOactorName = getActorNames(myProps.actors.actorsList, [7] , props.getLabel);

	return (
		<div styleName="container" onClick={onEventPopupClick}>
			<div className={css(myStyles.headerContainer)} styleName="header-container">
				{
					activeEvent.type == 'STATIC' || activeEvent.responseAdded
						? <div styleName="close-container" onClick={closePopup}>
							<Close strokeWidth={3} />
						</div>
						: null
				}
				<div styleName="header-image">
					<EventImage />
				</div>
				<div className={css(myStyles.headerText)} styleName="header-text">{props.getLabel(eventDetails.title,'',{ACTOR: convertedActorsName[convertedActorsName.length-1], CXO: CXOactorName[0]})}</div>
			</div>
			<div ref={scrollableContainerRef} styleName="events-container">
				{renderLeftTalkBubble(eventDetails)}
				{
					activeEvent.type !== 'STATIC' && activeEvent.responseAdded
						? renderResponse(activeEvent) 
						: null
				}

				{	 
					activeEvent.type == 'STATIC' && showImpact 
					? renderStaticResponse(activeEvent)
					: null
				}
			</div>
			{
				activeEvent.type !== 'STATIC' && !activeEvent.responseAdded
					? <div className={css(myStyles.actionsContainer)} styleName="actions-container">
						<div styleName="actions-header-container" onClick={() => { setOpenActions(!openAction); }}>
							<div className={css(myStyles.actionHeaderText)} styleName="actions-header-text">{props.getLabel('label_actions')}</div>
							<div styleName="actions-header-arrow">
								{
									openAction
										? <UpArrow svgColor="#A6B1BB" />
										: <DownArrow svgColor="#A6B1BB" />
								}
							</div>
						</div>
						{
							openAction && actionOptionDetails.length > 0
								? <div styleName="actions-list">
									{renderActionList()}
								</div>
								: null
						}

					</div>
					: null
			}

			{
				activeEvent.type == 'STATIC' && !showImpact
					? <div className={css(myStyles.actionsContainer)} styleName="actions-container">
						<div styleName="actions-header-container" onClick={() => { setOpenActions(!openAction); }}>
							<div className={css(myStyles.actionHeaderText)} styleName="actions-header-text">{props.getLabel('label_actions')}</div>
							<div styleName="actions-header-arrow">
								{
									openAction
										? <UpArrow svgColor="#A6B1BB" />
										: <DownArrow svgColor="#A6B1BB" />
								}
							</div>
						</div>
						{
							openAction && actionOptionDetails.length > 0
								? <div className={css(myStyles.eachAction)} styleName="static-action">
									<div styleName="ecosystem-svg">
										<EcosystemSvg/>
									</div>
									<div className={css(myStyles.actionText)} styleName="action-text" style={{cursor:'pointer'}} onClick={() => { showImpactsClicked()}}>
										{props.getLabel('label_see_impact_ecosystem')}
									</div>
								</div>
								: null
						}

					</div>
					: null
			}
		</div>
	);
}

export default applyWrappers(EventPopup, styles);
