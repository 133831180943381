import actionConstants from "constants/actions";

const initialState = {
    labelsList: {},
    isLabelsFetched: false
};

const labels = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConstants.SET_INIT_DATA:
            return {
                ...state,
                labelsList: {
                    ...state.labelsList,
                    ...action.payload.labels
                },
                isLabelsFetched: true
            }
        default:
            return state;
    }
};

export default labels;