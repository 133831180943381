import actionConsts from 'constants/actions';
import {
	addElementToArray,
	addElementsFromArray,
	removeElementFromArray
} from 'util/functions';

const initialState = {
	networksList: [],
	revealedNetworksList: [],
	recognitionBadgeActors: [],
	buildNetworkActors: []
};

const getBadgeActors = (userActionOptions) => {
	const details = {
		badgeActorList: [],
		buildNetworkActorList: []
	}
	userActionOptions.map((eachOption) => {
		if (eachOption.isActionSuccessful) {
			eachOption.userActorMetrics.map((eachMetric) => {
				if (eachMetric.isRecognitionBadgeGiven) {
					details.badgeActorList = addElementToArray(details.badgeActorList, eachMetric.actorId);
				}
				if (eachMetric.isNetworkCreated) {
					details.buildNetworkActorList = addElementToArray(details.buildNetworkActorList, eachMetric.actorId);
				}
			});
		}
	});

	return details;
}

const actorInfo = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.SET_INIT_DATA:
			const actorDetails = getBadgeActors(action.payload.userActionOptions);
			return {
				...state,
				networksList: action.payload.networks,
				revealedNetworksList: action.payload.userActorNetworks,
				recognitionBadgeActors: actorDetails.badgeActorList,
				buildNetworkActors: actorDetails.buildNetworkActorList
			};
		case actionConsts.ADD_RECOGNITION_BADGE:
			return {
				...state,
				recognitionBadgeActors: addElementsFromArray(state.recognitionBadgeActors, action.payload.actorIds)
			}
		case actionConsts.ADD_NETWORT_BADGE:
			return {
				...state,
				buildNetworkActors: addElementsFromArray(state.buildNetworkActors, action.payload.actorIds)
			}
		case actionConsts.ADD_REVEALED_NETWORK:
			const updatedRevealedNetworkList = state.revealedNetworksList;
			updatedRevealedNetworkList.push(action.payload);
			return {
				...state,
				revealedNetworksList: updatedRevealedNetworkList
			}
		default:
			return state;
	}
};

export default actorInfo;