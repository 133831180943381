import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		leaderboardContainer: {
			backgroundColor: skin.white
		},
		headerClose: {
			backgroundColor: skin.secondaryColor,
			':hover': {
				backgroundColor: hexToRgbA(skin.secondaryColor, 0.8)
			}
		},
		headerContent: {
			...myTypography.body1,
			fontSize: '16px',
			textTransform: 'uppercase',
			fontWeight: 600,
			'@media (min-width: 1440px)' : {
				fontSize: '18px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '20px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '24px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '32px'
			},
		},
		headerLine: {
			backgroundColor: hexToRgbA(skin.black, 0.1)
		},
		headerText: {
			...myTypography.body1,
			fontSize: '10px',
			textTransform: 'uppercase',
			'@media (min-width: 1440px)' : {
				fontSize: '11.25px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '12.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '15px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '20px'
			},
		},
		userRank: {
			...myTypography.body1,
			fontSize: '16px',
			fontWeight: 600,
			'@media (min-width: 1440px)' : {
				fontSize: '18px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '20px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '24px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '32px'
			},
		},
		userName: {
			...myTypography.body1,
			fontSize: '16px',
			fontWeight: 600,
			'@media (min-width: 1440px)' : {
				fontSize: '18px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '20px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '24px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '32px'
			},
		},
		userText: {
			...myTypography.body1,
			fontSize: '14px',
			textTransform: 'uppercase',
			fontWeight: 600,
			'@media (min-width: 1440px)' : {
				fontSize: '15.75px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '17.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '21px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '28px'
			},
		},
		userStatus: {
			...myTypography.body2,
			fontWeight: 'bold',
		},
		userStatusRed: {
			background: hexToRgbA(skin.red, 0.2),
			color: skin.red
		},
		userStatusYellow: {
			background: hexToRgbA(skin.yellow, 0.1),
			color: skin.yellow
		},
		userStatusGreen: {
			background: hexToRgbA(skin.green, 0.1),
			color: skin.green
		},
		highlight: {
			background: skin.grayColor7
		},
		headerContainer: {
			borderBottom: `1px solid ${hexToRgbA(skin.black, 0.2)}`
		},
		userContainer: {
			borderBottom: `1px solid ${hexToRgbA(skin.black, 0.2)}`
		},
		userContainerSelf: {
			borderBottom: `1px solid ${hexToRgbA(skin.black, 0.2)}`,
		},
		youArrow: {
			borderBottom: `30px solid transparent`,
			borderTop: `30px solid transparent`,
			borderLeft: `30px solid ${skin.grayColor7}`,
			'@media (min-width: 1440px)' : {
				borderBottom: `33.75px solid transparent`,
				borderTop: `33.75px solid transparent`,
				borderLeft: `33.75px solid ${skin.grayColor7}`
			},
			'@media (min-width: 1600px)' : {
				borderBottom: `37.5px solid transparent`,
				borderTop: `37.5px solid transparent`,
				borderLeft: `37.5px solid ${skin.grayColor7}`
			},
			'@media (min-width: 1920px)' : {
				borderBottom: `45px solid transparent`,
				borderTop: `45px solid transparent`,
				borderLeft: `45px solid ${skin.grayColor7}`
			},
			'@media (min-width: 2560px)' : {
				borderBottom: `60px solid transparent`,
				borderTop: `60px solid transparent`,
				borderLeft: `60px solid ${skin.grayColor7}`
			},
		},
		refreshText: {
			...myTypography.body1,
			color: skin.secondaryColor,
			fontWeight: 600,
			textTransform: 'uppercase',
			':hover': {
				textDecoration: 'underline'
			},
		}
	});
}

export default getSkin;
