import React from 'react';
import styles from './startSim.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const StartSim = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg xmlns="http://www.w3.org/2000/svg" width="212" height="220" viewBox="0 0 212 220" fill="none">
				<circle cx="98.7248" cy="71.6897" r="71.4052" fill="#F1F3F4" stroke="#E3E6E9" strokeWidth="0.568965" />
				<circle cx="38.4131" cy="69.4138" r="2.84483" stroke="#7E8E9C" strokeWidth="3.41379" />
				<circle cx="161.31" cy="22.7585" r="2.84483" stroke="#7E8E9C" strokeWidth="3.41379" />
				<circle cx="136.275" cy="119.483" r="1.7069" stroke="#7E8E9C" strokeWidth="3.41379" />
				<circle cx="164.725" cy="73.9656" r="1.13793" stroke="#7E8E9C" strokeWidth="2.27586" />
				<circle cx="29.311" cy="102.414" r="1.13793" stroke="#7E8E9C" strokeWidth="2.27586" />
				<circle cx="118.069" cy="9.1035" r="1.13793" stroke="#7E8E9C" strokeWidth="2.27586" />
				<circle cx="71.9835" cy="22.1896" r="1.7069" stroke="#7E8E9C" strokeWidth="2.27586" />
				<path d="M140.828 52.9138V59.7414" stroke="#7E8E9C" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M38.4141 25.0344V31.862" stroke="#7E8E9C" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M137.414 56.3276L144.242 56.3276" stroke="#7E8E9C" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M35 28.4482L41.8276 28.4482" stroke="#7E8E9C" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M56.0527 124.034V129.724" stroke="#7E8E9C" strokeWidth="2.27586" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M53.207 126.879L58.8967 126.879" stroke="#7E8E9C" strokeWidth="2.27586" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M94.7128 97.4279V112.47C94.7128 113.356 94.3606 114.207 93.7338 114.834C93.1069 115.46 92.2567 115.813 91.3701 115.813H79.6708C78.7843 115.813 77.934 115.46 77.3072 114.834C76.6803 114.207 76.3281 113.356 76.3281 112.47V105.785C76.3281 92.2802 94.7128 88.1353 94.7128 97.4279Z" fill="#FFE5C0" />
				<path d="M123.125 104.113V110.799C123.125 114.309 121.621 115.813 119.783 115.813H108.083C107.197 115.813 106.346 115.461 105.72 114.834C105.093 114.207 104.741 113.357 104.741 112.47C104.741 96.7095 104.239 95.8571 106.211 93.8849C110.624 89.4726 123.125 95.4392 123.125 104.113Z" fill="#FFE5C0" />
				<path d="M123.127 109.127H104.742V115.813H123.127V109.127Z" fill="#68539B" />
				<path d="M94.7128 109.127H76.3281V115.813H94.7128V109.127Z" fill="#68539B" />
				<path d="M97.2207 30.4577C101.472 32.4865 105.063 35.6775 107.577 39.6614C110.091 43.6454 111.426 48.2597 111.427 52.9706C111.427 59.5481 112.895 66.0428 115.722 71.9815C118.55 77.9202 122.667 83.1534 127.773 87.2998C138.837 96.3417 138.168 96.6091 138.168 109.127H146.525C146.525 102.442 147.327 96.3752 141.227 91.4614C134.826 86.2636 128.826 81.9515 124.079 71.9904C121.254 66.0475 119.787 59.5506 119.784 52.9706C119.763 47.503 117.955 42.192 114.636 37.8471C111.317 33.5021 106.669 30.3613 101.399 28.9033C99.9627 29.2957 98.5643 29.8159 97.2207 30.4577Z" fill="#FDAD28" />
				<path d="M56.2734 109.127H78.0008V104.113C78.0008 93.3162 96.3855 87.6838 96.3855 97.4277C96.3565 92.0147 98.0803 86.7377 101.299 82.3857C104.604 86.7051 106.4 91.9892 106.413 97.4277C106.413 88.0849 124.798 92.3134 124.798 105.784V109.127H138.169C138.169 102.442 138.971 96.3748 132.871 91.4611C126.47 86.2632 120.469 81.9511 115.723 71.99C112.898 66.0471 111.431 59.5502 111.427 52.9702C111.426 48.2593 110.091 43.645 107.577 39.6611C105.063 35.6771 101.473 32.4861 97.2211 30.4573C92.9782 32.4973 89.3954 35.6909 86.883 39.6724C84.3706 43.6538 83.03 48.2623 83.0148 52.9702C83.017 59.5481 81.5508 66.0436 78.7229 71.9827C75.895 77.9218 71.7769 83.1546 66.6691 87.2994C55.6049 96.3413 56.2734 96.6088 56.2734 109.127Z" fill="#FDAD28" />
				<path d="M76.3289 102.442H54.6016V109.127H76.3289V102.442Z" fill="#68539B" />
				<path d="M144.854 102.442H123.127V109.127H144.854V102.442Z" fill="#68539B" />
				<path d="M104.742 97.2107V114.141C104.742 115.471 104.214 116.747 103.274 117.687C102.333 118.627 101.058 119.155 99.7283 119.155C98.3985 119.155 97.1231 118.627 96.1828 117.687C95.2425 116.747 94.7143 115.471 94.7143 114.141C94.7143 98.0798 93.4106 90.7427 99.8286 82.386C103.023 86.6678 104.747 91.8684 104.742 97.2107Z" fill="#835AC5" />
				<path d="M107.985 32.9142C107.063 34.2518 105.83 35.3453 104.392 36.1007C102.954 36.8561 101.354 37.2508 99.7291 37.2508C98.1045 37.2508 96.5043 36.8561 95.0661 36.1007C93.6278 35.3453 92.3947 34.2518 91.4727 32.9142C93.9473 31.0686 96.7487 29.3946 99.7291 28.5899C102.711 29.3911 105.513 31.0655 107.985 32.9142Z" fill="#68539B" />
				<path d="M110.424 53.3048C110.424 53.472 111.059 53.3048 87.0254 53.3048C87.0256 50.8005 87.8806 48.3713 89.4488 46.4189C91.8723 43.2768 94.981 42.6751 98.7247 41.6055C103.588 42.9927 102.535 42.7587 102.703 42.7587C104.944 43.4664 106.902 44.8707 108.29 46.7673C109.679 48.664 110.426 50.9541 110.424 53.3048Z" fill="#1BE1D4" />
				<path d="M97.2878 53.3048H93.427L89.4492 46.4189C90.1708 45.4994 91.0475 44.7132 92.0398 44.0957L97.2878 53.3048Z" fill="#93E6D9" />
				<path d="M108.853 53.3048H101.132L94.998 42.6751C95.1652 42.6751 97.0371 42.1069 98.792 41.6055C102.419 42.6417 102.586 42.6751 102.753 42.7587C102.92 42.8423 102.703 42.625 108.853 53.3048Z" fill="#93E6D9" />
				<path d="M68.273 78.4751C74.9751 70.7464 78.666 60.8605 78.6687 50.6306C78.6818 46.3394 79.7273 42.1144 81.717 38.3124C83.7068 34.5103 86.5823 31.243 90.1007 28.7863C90.469 28.6001 90.8931 28.5567 91.2915 28.6645C91.6898 28.7723 92.0342 29.0237 92.2583 29.3702C92.4824 29.7167 92.5704 30.1339 92.5053 30.5414C92.4402 30.9489 92.2266 31.3179 91.9057 31.5774C88.8555 33.733 86.3657 36.5875 84.6444 39.9023C82.923 43.217 82.0202 46.8956 82.0114 50.6306C82.0261 61.6864 78.0363 72.3734 70.78 80.7147C70.4831 81.0471 70.0662 81.248 69.6211 81.2731C69.176 81.2981 68.7392 81.1454 68.4067 80.8484C68.0743 80.5514 67.8734 80.1345 67.8484 79.6894C67.8233 79.2444 67.9761 78.8076 68.273 78.4751Z" fill="#14324B" />
				<path d="M75.327 108.459H53.5996C53.1563 108.459 52.7312 108.283 52.4178 107.969C52.1043 107.656 51.9282 107.231 51.9282 106.788C51.9282 99.6343 51.126 93.2498 57.8448 87.8179C63.6276 83.1716 63.2933 83.3889 63.6276 83.0379C63.9565 82.7712 64.3744 82.6393 64.7968 82.6688C65.2192 82.6983 65.6146 82.8871 65.9032 83.1969C66.1918 83.5067 66.3521 83.9146 66.3516 84.338C66.3511 84.7614 66.1899 85.1689 65.9006 85.4781C65.4661 85.8792 66.0009 85.4781 59.9005 90.4921C54.5355 94.7874 55.2709 100.336 55.2709 105.116H73.6556V103.445C73.6556 91.4782 87.0263 85.5616 92.6253 89.6731C93.4454 85.8073 95.1121 82.1713 97.5056 79.0267C97.6705 78.807 97.887 78.6312 98.1359 78.5151C98.3849 78.399 98.6586 78.346 98.933 78.3608C99.2073 78.3757 99.4737 78.458 99.7087 78.6004C99.9436 78.7427 100.14 78.9408 100.28 79.1771C102.555 82.289 104.123 85.8595 104.876 89.6397C106.053 88.9595 107.364 88.5443 108.718 88.4231C110.072 88.3019 111.436 88.4775 112.715 88.9377C113.158 88.9887 113.563 89.2137 113.84 89.5632C114.118 89.9127 114.245 90.358 114.194 90.8013C114.143 91.2446 113.918 91.6494 113.568 91.9268C113.219 92.2042 112.774 92.3314 112.33 92.2804C111.662 92.2804 108.286 90.8765 106.397 92.7818C105.781 93.3935 105.428 94.2206 105.411 95.0883C105.411 95.5315 105.235 95.9566 104.921 96.2701C104.608 96.5835 104.183 96.7596 103.74 96.7596C100.397 96.7596 103.907 91.6286 98.7257 83.0212C96.5483 86.6705 95.3936 90.8388 95.383 95.0883C95.383 95.5315 95.2069 95.9566 94.8935 96.2701C94.58 96.5835 94.1549 96.7596 93.7117 96.7596C93.2684 96.7596 92.8433 96.5835 92.5298 96.2701C92.2164 95.9566 92.0403 95.5315 92.0403 95.0883C92.0403 88.2358 76.9983 92.2136 76.9983 103.445V106.788C76.9983 107.231 76.8222 107.656 76.5088 107.969C76.1953 108.283 75.7702 108.459 75.327 108.459Z" fill="#14324B" />
				<path d="M143.852 108.459H122.125C118.013 108.459 123.629 100.904 117.011 94.9545C116.712 94.6503 116.541 94.2435 116.533 93.8175C116.524 93.3914 116.679 92.9782 116.965 92.6623C117.251 92.3464 117.647 92.1517 118.071 92.1179C118.496 92.0842 118.918 92.2141 119.25 92.4809C124.231 96.9601 123.796 101.205 123.796 105.116H142.181C142.181 94.7372 142.649 94.6202 132.404 86.2468C127.09 81.9585 122.808 76.532 119.872 70.3676C116.936 64.2033 115.421 57.4585 115.44 50.6306C115.434 45.5964 113.804 40.6983 110.793 36.6637C107.782 32.629 103.551 29.6731 98.7262 28.2347C98.2917 28.3517 97.8571 28.5021 97.4393 28.6526C97.0261 28.7889 96.5761 28.7588 96.1848 28.5686C95.7935 28.3783 95.4918 28.043 95.3438 27.6339C95.1958 27.2248 95.2132 26.774 95.3923 26.3775C95.5713 25.981 95.898 25.6699 96.3028 25.5104C97.1884 25.0678 98.1713 24.8551 99.1608 24.8921C104.795 26.4483 109.764 29.8076 113.307 34.4559C116.851 39.1043 118.774 44.7856 118.782 50.6306C118.768 56.9611 120.173 63.2142 122.895 68.9297C125.617 74.6452 129.586 79.6775 134.51 83.6562L139.607 87.8346C146.493 93.4168 145.524 100.47 145.524 106.788C145.524 107.231 145.348 107.656 145.034 107.969C144.721 108.283 144.296 108.459 143.852 108.459Z" fill="#14324B" />
				<path d="M78.6699 83.3888V49.9621C78.6699 49.5188 78.846 49.0937 79.1594 48.7803C79.4729 48.4669 79.898 48.2908 80.3413 48.2908C80.7845 48.2908 81.2096 48.4669 81.5231 48.7803C81.8365 49.0937 82.0126 49.5188 82.0126 49.9621V83.3888C82.0126 83.8321 81.8365 84.2572 81.5231 84.5706C81.2096 84.8841 80.7845 85.0602 80.3413 85.0602C79.898 85.0602 79.4729 84.8841 79.1594 84.5706C78.846 84.2572 78.6699 83.8321 78.6699 83.3888Z" fill="#14324B" />
				<path d="M115.439 83.3888V49.9621C115.439 49.5188 115.616 49.0937 115.929 48.7803C116.242 48.4669 116.668 48.2908 117.111 48.2908C117.554 48.2908 117.979 48.4669 118.293 48.7803C118.606 49.0937 118.782 49.5188 118.782 49.9621V83.3888C118.782 83.8321 118.606 84.2572 118.293 84.5706C117.979 84.8841 117.554 85.0602 117.111 85.0602C116.668 85.0602 116.242 84.8841 115.929 84.5706C115.616 84.2572 115.439 83.8321 115.439 83.3888Z" fill="#14324B" />
				<path d="M90.3696 115.144H85.3556C84.9123 115.144 84.4872 114.968 84.1738 114.655C83.8604 114.341 83.6843 113.916 83.6843 113.473C83.6843 113.03 83.8604 112.604 84.1738 112.291C84.4872 111.978 84.9123 111.801 85.3556 111.801H90.3696C90.8129 111.801 91.238 111.625 91.5514 111.312C91.8649 110.999 92.0409 110.573 92.0409 110.13V95.0881C92.0409 88.5365 76.9989 91.9794 76.9989 103.445V110.13C76.9989 110.573 77.175 110.999 77.4884 111.312C77.8019 111.625 78.227 111.801 78.6703 111.801C79.1135 111.801 79.5386 111.978 79.8521 112.291C80.1655 112.604 80.3416 113.03 80.3416 113.473C80.3416 116.665 73.6562 115.144 73.6562 110.13V103.445C73.6562 87.8679 95.3836 83.2718 95.3836 95.0881V110.13C95.3836 111.46 94.8554 112.735 93.9151 113.676C92.9747 114.616 91.6994 115.144 90.3696 115.144Z" fill="#14324B" />
				<path d="M118.782 115.144H107.083C105.753 115.144 104.477 114.616 103.537 113.676C102.597 112.735 102.069 111.46 102.069 110.13C102.069 94.7541 101.417 92.9824 104.024 90.3584C106.33 88.052 109.506 88.052 112.715 88.9378C112.939 88.9825 113.151 89.0725 113.338 89.2022C113.526 89.332 113.685 89.4987 113.806 89.6923C113.927 89.8858 114.006 90.102 114.04 90.3276C114.074 90.5531 114.062 90.7833 114.004 91.0038C113.945 91.2243 113.842 91.4306 113.701 91.6099C113.56 91.7892 113.384 91.9378 113.184 92.0464C112.983 92.155 112.762 92.2215 112.535 92.2416C112.308 92.2618 112.079 92.2352 111.863 92.1635C109.991 91.6454 107.768 91.3613 106.397 92.7317C105.027 94.1022 105.411 93.8014 105.411 110.13C105.411 110.574 105.587 110.999 105.901 111.312C106.214 111.626 106.639 111.802 107.083 111.802H118.782C121.055 111.802 120.453 106.788 120.453 101.774C120.375 100.462 120.029 99.1808 119.437 98.008C118.845 96.8353 118.019 95.7962 117.01 94.9546C116.712 94.6504 116.541 94.2437 116.532 93.8176C116.524 93.3916 116.678 92.9783 116.964 92.6624C117.25 92.3465 117.646 92.1518 118.071 92.1181C118.496 92.0844 118.918 92.2142 119.25 92.481C124.698 97.3781 123.796 101.941 123.796 108.459C123.796 113.473 121.205 115.144 118.782 115.144Z" fill="#14324B" />
				<path d="M98.7253 118.487C96.9523 118.487 95.2518 117.783 93.9981 116.529C92.7444 115.275 92.04 113.575 92.04 111.802C92.04 96.1412 90.6695 87.9684 97.5053 79.0267C97.6701 78.807 97.8866 78.6312 98.1356 78.5151C98.3846 78.399 98.6583 78.346 98.9327 78.3608C99.207 78.3757 99.4734 78.458 99.7084 78.6004C99.9433 78.7427 100.14 78.9408 100.28 79.1771C103.603 83.7362 105.399 89.2294 105.411 94.871V111.802C105.411 113.575 104.706 115.275 103.453 116.529C102.199 117.783 100.498 118.487 98.7253 118.487ZM98.7253 83.0212C94.2796 90.3082 95.3827 95.7401 95.3827 111.802C95.3827 112.688 95.7348 113.538 96.3617 114.165C96.9886 114.792 97.8388 115.144 98.7253 115.144C99.6119 115.144 100.462 114.792 101.089 114.165C101.716 113.538 102.068 112.688 102.068 111.802C102.068 94.3362 102.937 90.0742 98.8089 83.0212H98.7253Z" fill="#14324B" />
				<path d="M110.426 54.9762H97.0548C96.6116 54.9762 96.1864 54.8001 95.873 54.4866C95.5596 54.1732 95.3835 53.7481 95.3835 53.3048C95.3835 52.8616 95.5596 52.4364 95.873 52.123C96.1864 51.8096 96.6116 51.6335 97.0548 51.6335H108.604C108.285 49.9028 107.491 48.2951 106.31 46.9903C105.129 45.6856 103.609 44.7354 101.918 44.2462L98.7262 43.2768C95.2999 44.2462 92.7595 44.6139 90.5366 47.5721C89.6547 48.77 89.0756 50.1634 88.8486 51.6335H90.3695C90.8127 51.6335 91.2379 51.8096 91.5513 52.123C91.8647 52.4364 92.0408 52.8616 92.0408 53.3048C92.0408 53.7481 91.8647 54.1732 91.5513 54.4866C91.2379 54.8001 90.8127 54.9762 90.3695 54.9762H87.0268C86.5835 54.9762 86.1584 54.8001 85.845 54.4866C85.5316 54.1732 85.3555 53.7481 85.3555 53.3048C85.3547 50.5356 86.2565 47.8416 87.9243 45.6309C89.5921 43.4203 91.9349 41.8135 94.598 41.0539C99.7457 39.5999 97.8571 39.5999 102.871 41.0539C105.524 41.8286 107.855 43.4399 109.518 45.6476C111.181 47.8553 112.085 50.5412 112.097 53.3048C112.097 53.7481 111.921 54.1732 111.607 54.4866C111.294 54.8001 110.869 54.9762 110.426 54.9762Z" fill="#14324B" />
				<path d="M89.0999 31.5774C88.8496 31.219 88.7497 30.7769 88.8216 30.3458C88.8934 29.9146 89.1313 29.5288 89.4843 29.271L90.1027 28.8364C90.3839 28.6566 90.7126 28.5651 91.0464 28.5738C91.3801 28.5825 91.7036 28.691 91.9751 28.8852C92.2467 29.0794 92.4538 29.3505 92.5699 29.6635C92.686 29.9765 92.7056 30.3171 92.6264 30.6414C93.3826 31.4509 94.2921 32.1019 95.3022 32.5566C96.3122 33.0113 97.4026 33.2607 98.5099 33.2903C99.6172 33.3198 100.719 33.1289 101.752 32.7287C102.785 32.3285 103.728 31.7269 104.526 30.959C102.73 29.784 100.778 28.8669 98.7268 28.2347C98.2922 28.3517 97.8577 28.5021 97.4398 28.6526C97.0267 28.7889 96.5766 28.7588 96.1853 28.5686C95.794 28.3783 95.4923 28.043 95.3443 27.6339C95.1964 27.2248 95.2137 26.774 95.3928 26.3775C95.5719 25.981 95.8985 25.6699 96.3033 25.5104C97.189 25.0678 98.1718 24.8551 99.1613 24.8921C102.352 25.7683 105.346 27.2429 107.986 29.2375C108.164 29.3667 108.315 29.5298 108.43 29.7175C108.545 29.9052 108.622 30.1137 108.656 30.3311C108.69 30.5485 108.681 30.7705 108.629 30.9844C108.577 31.1983 108.484 31.3998 108.354 31.5774C107.278 33.1356 105.839 34.4093 104.163 35.2891C102.486 36.169 100.62 36.6286 98.7268 36.6286C96.8331 36.6286 94.9678 36.169 93.2909 35.2891C91.6141 34.4093 90.1759 33.1356 89.0999 31.5774Z" fill="#14324B" />
				<path d="M137.166 101.774H122.124C121.681 101.774 121.256 101.597 120.943 101.284C120.629 100.971 120.453 100.546 120.453 100.102C120.453 99.659 120.629 99.2339 120.943 98.9204C121.256 98.607 121.681 98.4309 122.124 98.4309H137.166C137.61 98.4309 138.035 98.607 138.348 98.9204C138.662 99.2339 138.838 99.659 138.838 100.102C138.838 100.546 138.662 100.971 138.348 101.284C138.035 101.597 137.61 101.774 137.166 101.774Z" fill="#14324B" />
				<path d="M75.3266 101.774H60.2846C59.8414 101.774 59.4162 101.597 59.1028 101.284C58.7894 100.971 58.6133 100.546 58.6133 100.102C58.6133 99.659 58.7894 99.2339 59.1028 98.9204C59.4162 98.607 59.8414 98.4309 60.2846 98.4309H75.3266C75.7699 98.4309 76.195 98.607 76.5085 98.9204C76.8219 99.2339 76.998 99.659 76.998 100.102C76.998 100.546 76.8219 100.971 76.5085 101.284C76.195 101.597 75.7699 101.774 75.3266 101.774Z" fill="#14324B" />
				<path d="M93.7125 108.459H82.0131C81.5699 108.459 81.1448 108.283 80.8313 107.969C80.5179 107.656 80.3418 107.231 80.3418 106.788C80.3418 106.344 80.5179 105.919 80.8313 105.606C81.1448 105.292 81.5699 105.116 82.0131 105.116H93.7125C94.1558 105.116 94.5809 105.292 94.8943 105.606C95.2077 105.919 95.3838 106.344 95.3838 106.788C95.3838 107.231 95.2077 107.656 94.8943 107.969C94.5809 108.283 94.1558 108.459 93.7125 108.459Z" fill="#14324B" />
				<path d="M117.111 108.459H105.412C104.968 108.459 104.543 108.283 104.23 107.969C103.916 107.656 103.74 107.231 103.74 106.788C103.74 106.344 103.916 105.919 104.23 105.606C104.543 105.292 104.968 105.116 105.412 105.116H117.111C117.554 105.116 117.979 105.292 118.293 105.606C118.606 105.919 118.782 106.344 118.782 106.788C118.782 107.231 118.606 107.656 118.293 107.969C117.979 108.283 117.554 108.459 117.111 108.459Z" fill="#14324B" />
				<path d="M83.9318 153.621C98.3932 146.38 105.577 147.863 116.932 159.311C116.932 159.311 123.759 157.393 128.88 159.88C134.001 162.367 136.846 168.983 136.846 168.983C136.846 168.983 143.673 162.156 151.639 167.276C159.604 172.397 156.19 183.776 156.19 183.776C156.19 183.776 164.725 174.673 174.397 176.949C184.07 179.225 181.794 190.604 181.794 190.604C181.794 190.604 190.897 186.937 197.156 188.328C203.415 189.719 207.397 196.293 207.397 196.293C207.397 196.293 141.915 219.052 97.5869 219.052C53.2591 219.052 3.13867 206.535 3.13867 206.535V201.414C3.13867 201.414 5.7176 194.297 8.82833 191.741C11.9391 189.186 19.0697 188.328 19.0697 188.328C19.0697 188.328 16.2249 178.655 24.1904 173.535C32.1559 168.414 43.5352 174.673 43.5352 174.673C43.5352 174.673 43.5352 168.983 48.0869 167.276C54.5241 164.862 61.7421 170.69 61.7421 170.69C61.7421 170.69 58.8973 161.586 66.2938 155.897C73.1214 150.645 83.9318 153.621 83.9318 153.621Z" fill="#F1F3F4" />
				<path d="M184.068 186.542C184.068 186.542 191.88 182.756 199.578 185.123C207.276 187.489 210.241 194.586 210.241 194.586" stroke="#14324B" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M120.346 155.897C120.346 155.897 126.035 153.834 131.725 157.035C137.415 160.235 138.553 166.138 138.553 166.138" stroke="#14324B" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M137.414 162.724C137.414 162.724 145.38 158.715 152.776 162.155C160.173 165.595 161.88 174.104 161.88 174.104" stroke="#14324B" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M156.758 180.123C156.758 180.123 164.154 169.882 175.534 172.158C186.913 174.433 190.327 184.106 190.327 184.106" stroke="#14324B" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M57.7578 165C57.7578 165 57.7578 155.898 65.7233 151.345C73.6888 146.793 81.6544 151.345 81.6544 151.345" stroke="#14324B" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M2 198C2 198 2.49138 189.604 10.3534 187.164C18.2155 184.724 23.6207 191.292 23.6207 191.292" stroke="#14324B" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M16.3004 186.408C16.3004 186.408 12.2667 173.863 24.3577 169.338C36.4487 164.814 41.9942 178.365 41.9942 178.365" stroke="#14324B" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M39.5547 174.103C39.5547 174.103 39.1269 165.877 47.2343 163.279C55.3416 160.682 60.0335 168.042 60.0335 168.042" stroke="#14324B" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M90.7578 126.879V147.362M90.7578 158.172V186.052" stroke="#14324B" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M106.689 129.724V155.897M106.689 163.293V174.672" stroke="#14324B" strokeWidth="3.41379" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</div>
	);
}

export default applyWrappers(StartSim, styles);