import React from 'react';
import styles from './userProfile.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/functions';

const UserProfile = (props) => {
    const skin = props.skinGuide.globalProfiles.palette
    let color = skin.white;
    if (checkIfPresent(props.svgColor)) {
        color = props.svgColor;
    }
    return (
        <div styleName="main-component">
            <svg width="100%" height="100%" viewBox="0 0 10 11" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.95268 6.90289C5.95738 6.98093 6.00906 7.04827 6.08323 7.07299C7.43161 7.5155 8.50594 8.54662 9.0034 9.87571C9.07026 10.0515 9.04657 10.2489 8.94001 10.4039C8.83345 10.5588 8.65761 10.6516 8.46954 10.6521H0.843103C0.654897 10.652 0.478796 10.5593 0.37216 10.4042C0.265523 10.2491 0.242 10.0515 0.309252 9.87571C0.811157 8.52838 1.90583 7.48744 3.2767 7.05393C3.3515 7.03063 3.40443 6.96395 3.41016 6.88582C3.41588 6.80769 3.37323 6.73401 3.30263 6.70006C1.9322 6.04483 1.20007 4.52628 1.54091 3.046C1.88175 1.56573 3.20426 0.52027 4.72324 0.530346C6.24222 0.540422 7.55075 1.60333 7.87192 3.088C8.19309 4.57267 7.44088 6.08137 6.06188 6.71836C5.99121 6.7518 5.94798 6.82486 5.95268 6.90289ZM3.07997 3.23994C3.01682 3.41615 2.98432 3.60187 2.98388 3.78905C2.98461 4.7042 3.70398 5.45743 4.61813 5.50023C5.53228 5.54302 6.31888 4.86028 6.4051 3.9492C6.40943 3.89141 6.38725 3.83479 6.34482 3.79532C6.30238 3.75586 6.2443 3.73783 6.18698 3.74634C5.99407 3.77481 5.79932 3.78909 5.60432 3.78905C4.81017 3.78374 4.03291 3.55915 3.35833 3.14004C3.30917 3.11069 3.24944 3.10489 3.19555 3.12423C3.14166 3.14357 3.09925 3.18603 3.07997 3.23994Z" fill="white" />
            </svg>
        </div>
    );
}



export default applyWrappers(UserProfile, styles);
