import React from 'react';
import styles from './mentor.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const Mentor = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 17 24" fill="none">
				<path d="M13.375 11.3125C13.375 12.5889 12.868 13.8129 11.9655 14.7155C11.0629 15.618 9.83885 16.125 8.5625 16.125C7.28615 16.125 6.06207 15.618 5.15955 14.7155C4.25703 13.8129 3.75 12.5889 3.75 11.3125H13.375Z" stroke="#14324B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M3.0625 3.0625L3.75 11.3125H13.3768L14.0643 3.0625C14.0643 2.69783 13.9195 2.34809 13.6616 2.09023C13.4037 1.83237 13.054 1.6875 12.6893 1.6875H4.43933C4.25861 1.68726 4.07962 1.72265 3.91258 1.79164C3.74555 1.86063 3.59375 1.96188 3.46588 2.08958C3.338 2.21729 3.23656 2.36895 3.16734 2.53589C3.09813 2.70283 3.0625 2.88178 3.0625 3.0625V3.0625Z" fill="#FDAD28" stroke="#14324B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M3.0625 3.0625L3.75 11.3125H13.3768L14.0643 3.0625C14.0643 2.69783 13.9195 2.34809 13.6616 2.09023C13.4037 1.83237 13.054 1.6875 12.6893 1.6875H4.43933C4.25861 1.68726 4.07962 1.72265 3.91258 1.79164C3.74555 1.86063 3.59375 1.96188 3.46588 2.08958C3.338 2.21729 3.23656 2.36895 3.16734 2.53589C3.09813 2.70283 3.0625 2.88178 3.0625 3.0625V3.0625Z" stroke="#14324B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M1 11.3125H16.125" stroke="#14324B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M3.75 8.5625H13.375" stroke="#14324B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M5.25449 18.2636C5.17111 18.2145 5.07625 18.1882 4.97949 18.1875C4.83678 18.1875 4.69992 18.2442 4.59901 18.3451C4.4981 18.446 4.44141 18.5829 4.44141 18.7256V21.7735C4.44185 21.8686 4.46749 21.9619 4.51571 22.0438C4.56394 22.1258 4.63302 22.1935 4.71593 22.2401C4.79884 22.2867 4.89262 22.3105 4.9877 22.309C5.08279 22.3076 5.17579 22.2809 5.25724 22.2318L11.8765 18.2599C11.958 18.2114 12.0508 18.1852 12.1457 18.184C12.2405 18.1828 12.334 18.2066 12.4167 18.2531C12.4993 18.2996 12.5683 18.3671 12.6165 18.4488C12.6647 18.5305 12.6906 18.6234 12.6914 18.7183V21.7662C12.6909 21.8611 12.6652 21.9543 12.617 22.0361C12.5688 22.1179 12.4999 22.1855 12.4171 22.2321C12.3343 22.2786 12.2407 22.3025 12.1458 22.3011C12.0508 22.2998 11.9579 22.2734 11.8765 22.2245L5.25449 18.2636Z" stroke="#14324B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</div>
	);
}

export default applyWrappers(Mentor, styles);