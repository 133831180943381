import React from 'react';
import styles from './timerCompletedPopup.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import TimeUp from 'svgComponents/timeUp';

import { gameStarted } from 'actions/apiActions/gameStarted';

import analyticsUtil from 'util/analyticsUtil';

const TimerCompleted = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const dispatch = useDispatch();
	const myProps = useSelector((state) => {
		return {
			uiState: state.uiState
		};
	});

	const onTimerCompletedPopupClick = (e) => {
		e.stopPropagation();
	}

	const closePopup = () => {
		analyticsUtil.track('POPUP_CLOSED', { popup: myProps.uiState.overlayComponent, type: myProps.uiState.overlayComponentType });

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
		});
	}


	return (
		<div className={css(myStyles.startSimPopupContainer)} styleName="startSimPopup-container" onClick={onTimerCompletedPopupClick}>
			<div styleName="content-container">
				<div styleName="content-details">
					<div styleName="advisor-details">
						<div styleName="advisor-image">
							<TimeUp />
						</div>
					</div>
					<div className={css(myStyles.advisorHeading)} styleName="advisor-desc">
						{ props.getLabel("label_timer_ran_out_head") }
					</div>
					<div className={css(myStyles.advisorDesc)} styleName="advisor-desc">
						{ props.getLabel("label_timer_ran_out_desc") }
					</div>
					<div styleName="button-container">
						<div className={css(myStyles.startButton)} styleName="button" onClick={closePopup}>
							{ props.getLabel("label_timer_ran_out_continue_button") }
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default applyWrappers(TimerCompleted, styles);
