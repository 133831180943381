import React from 'react';
import styles from './waitingClock.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const WaitingClock = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 36 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.8364" cy="12.5" r="12.5" fill="#FDAD28"/>
                <path d="M17.3364 8.5V13.5L23.3364 18.5" stroke="white" stroke-width="2" stroke-linecap="round"/>
                <path d="M33.6724 41.3363H20.6896M15.0387 41.3363H1.99995" stroke="#FDAD28" stroke-width="3.41379" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M27.6724 49.3363H14.6647M9.1034 49.3363H7.72408" stroke="#FDAD28" stroke-width="3.41379" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
		</div>
	);
}

export default applyWrappers(WaitingClock, styles);