import React from 'react';
import styles from './deselect.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const Deselect = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 19 19" fill="none">
				<circle cx="9.5" cy="9.5" r="9" fill="#FD5382" stroke="#FD5382" />
				<path d="M13.2756 5.5L9.49778 9.5M5.72 13.5L9.49778 9.5M9.49778 9.5L5.72 5.5L13.2756 13.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</div>
	);
}

export default applyWrappers(Deselect, styles);