import React, { useState, useEffect } from 'react';
import styles from './actorPopup.module.sass';
import { useSelector, useDispatch } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import UserProfile from 'svgComponents/userProfile';
import ActorMetric from 'commonComponents/actorMetric';
import EmptyHistory from 'svgComponents/emptyHistory';
import { getActorNames } from 'util/functions';
import Like from 'svgComponents/like';
import Dislike from 'svgComponents/dislike';
import Background from 'svgComponents/background';
import KnownFor from 'svgComponents/knownFor';
import Drive from 'svgComponents/drive';
import Team from 'svgComponents/team';

import analyticsUtil from 'util/analyticsUtil';

const ActorPopup = (props) => {

	const onActorPopupPopupClick = (e) => {
		e.stopPropagation();
	}

	const closePopup = () => {
		analyticsUtil.track('POPUP_CLOSED', { popup: myProps.uiState.overlayComponent, type: myProps.uiState.overlayComponentType });
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
		});
	}

	const actorDetailsObject = [
		{
			imageComponent: <Like />,
			key: 'likes',
			text: props.getLabel('label_actor_desc_likes'),
			styles: {
				width: '50%'
			}
		},
		{
			imageComponent: <Dislike />,
			key: 'dislikes',
			text: props.getLabel('label_actor_desc_dislikes'),
			styles: {
				width: '50%'
			}
		},
		{
			imageComponent: <Background />,
			key: 'description',
			text: props.getLabel('label_actor_desc_reputation'),
			styles: {
				width: '100%'
			}
		},
		{
			imageComponent: <KnownFor />,
			key: 'knownFor',
			text: props.getLabel('label_actor_desc_believes'),
			styles: {
				width: '100%'
			}
		},
		{
			imageComponent: <Drive />,
			key: 'drives',
			text: props.getLabel('label_actor_desc_goal'),
			styles: {
				width: '100%'
			}
		}
	];

	const buDetailsObject = [
		{
			imageComponent: <Like />,
			key: 'likes',
			text: props.getLabel('label_actor_desc_likes'),
			styles: {
				width: '50%'
			}
		},
		{
			imageComponent: <Dislike />,
			key: 'dislikes',
			text: props.getLabel('label_actor_desc_dislikes'),
			styles: {
				width: '50%'
			}
		},
		{
			imageComponent: <Background />,
			key: 'description',
			text: props.getLabel('label_bu_desc_turnover'),
			styles: {
				width: '100%'
			}
		},
		{
			imageComponent: <KnownFor />,
			key: 'knownFor',
			text: props.getLabel('label_bu_desc_reputation'),
			styles: {
				width: '100%'
			}
		},
		{
			imageComponent: <Drive />,
			key: 'drives',
			text: props.getLabel('label_actor_desc_goal'),
			styles: {
				width: '100%'
			}
		}
	];


	const myStyles = getSkin(props.skinGuide);
	const dispatch = useDispatch();
	const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			actors: state.actors,
			actions: state.actions,
			userEvents: state.userEvents,
			userActorMetrics: state.userActorMetrics,
			metrics: state.metrics,
			userActionOptions: state.userActionOptions,
		};
	});

	const actorDetails = myProps.actors.actorsList.find((eachActor) => {
		return eachActor.id === myProps.uiState.selectedActor;
	});

	const historyList = myProps.userActionOptions.userActionOptionList;
	const eventList = myProps.userEvents.userEventsList;

	const list = [...historyList, ...eventList];

	list.sort((a, b) => {
		return a.day - b.day;
	});

	const actorHistoryDetails = list.map((eachItem) => {

		// if (eachItem.actionOptionId == null) {
		// 	return null;
		// }
		const actionOptionDetails = myProps.actions.actionOptionsList.find((eachOption) => {
			return eachOption.id === eachItem.actionOptionId;
		});

		const actionDetails = myProps.actions.actionsList.find((eachAction) => {
			return eachAction.id === actionOptionDetails.actionId;
		});

		const initiativeDetails = myProps.actions.initiatives.find((eachInitiative) => {
			return eachInitiative.id === actionDetails.initiativeId;
		});

		const actionActorMetrics = eachItem.userActorMetrics.filter((eachActorMetric) => {
			return eachActorMetric.actorId === myProps.uiState.selectedActor;
		});

		if (actionActorMetrics.length > 0) {
			return {
				actionDetails,
				actionOptionDetails,
				initiativeDetails,
				actorMetrics: actionActorMetrics,
				message: eachItem.message,
				day: eachItem.day
			};
		};
	});

	const getHistoryItemChange = (change) => {
		if (change < 0) {
			return (
				<div
					className={css(myStyles.historyChangeNegative)}
					styleName="each-history-item-change"
				>
					{`${change}`}
				</div>
			);
		} else if (change > 0) {
			return (
				<div
					className={css(myStyles.historyChangePositive)}
					styleName="each-history-item-change"
				>
					{`+${change}`}
				</div>
			);
		}
		return (
			<div
				className={css(myStyles.historyChangePositive)}
				styleName="each-history-item-change"
			>
				{`${change}`}
			</div>
		);
	}

	const renderHistoryItems = () => {
		let historyDivs = [];
		//Getting Name of CXO actor, current ID of CEO is 7.
		let CXOactorName = getActorNames(myProps.actors.actorsList, [7] , props.getLabel);
		let convertedActorsName = [];
		if(myProps.actors.convertedActors && myProps.actors.convertedActors.length > 0){
			convertedActorsName = getActorNames(myProps.actors.actorsList, myProps.actors.convertedActors, props.getLabel);
		}
		actorHistoryDetails.forEach((eachItem) => {
			if (!eachItem) {
				return;
			}
			eachItem.actorMetrics.map((eachActorMetric) => {
				historyDivs.push(
					<div styleName="each-history-item">
						<div className={css(myStyles.historyItemContent)} styleName="each-history-item-content">
							<div className={css(myStyles.historyNumber)} styleName="each-history-item-number">{`${props.getLabel('label_day')} ${eachItem.day}`}</div>
							{getHistoryItemChange(eachActorMetric.diff)}
							<div styleName="each-history-item-details">
								<div className={css(myStyles.historyAction)} styleName="each-history-item-action">{props.getLabel(eachItem.actionDetails.name, '', {ACTOR: convertedActorsName[convertedActorsName.length-1] ,CXO: CXOactorName[0]})}</div>
								<div className={css(myStyles.historyMessage)} styleName="each-history-item-message">{eachItem.message}</div>
							</div>
						</div>
					</div>
				);
			});
		});
		if (historyDivs.length === 0) {
			return (
				<div styleName="timeline-content">
					<div styleName="empty-container">
						<div styleName="empty-image">
							<EmptyHistory />
						</div>
						<div className={css(myStyles.emptyText)} styleName="empty-text">
							{props.getLabel('label_actor_pop_up_no_timeline')}
						</div>
					</div>
				</div>
			);
		}
		return (
			<div styleName="timeline-content">
				{historyDivs}
			</div>
		);
	}

	const renderMetrics = () => {
		const sccMetrics = ["strategy", "capability", "culture"];

		const userActorMetrics = myProps.userActorMetrics;
		const metrics = myProps.metrics;
		const userActorMetricDetails = userActorMetrics.userActorMetricsObj[myProps.uiState.selectedActor];
		const metricDivs = sccMetrics.map((eachMetric, index) => {

			const currentMetricId = userActorMetrics.actorMetricIds[eachMetric];
			const metricDetails = metrics.metricsObj[currentMetricId];
			const currentActorMetricDetails = userActorMetricDetails[currentMetricId];

			const value = Math.round(currentActorMetricDetails.value);

			const borderValue = (sccMetrics.length > 1) && (index !== sccMetrics.length - 1) ? true : false
			return (
				<ActorMetric
					actorId={myProps.uiState.selectedActor}
					metricId={currentMetricId}
					borderValue={borderValue}
					value={value}
					diff={currentActorMetricDetails.diff}
					name={metricDetails.name}
					popup={true}
				/>
			);
		});
		return metricDivs;
	}

	const renderActorDetails = () => {
		const detailsDiv = [];
		const detailsObject = actorDetails.stakeholderType === 'BU' ? buDetailsObject : actorDetailsObject;

		detailsObject.map((eachDetail) => {
			if (actorDetails[eachDetail.key] !== undefined) {
				detailsDiv.push(
					<div style={eachDetail.styles} styleName="each-detail-container">
						<div styleName="each-detail-heading-content">
							<div styleName="each-detail-heading-image">
								{eachDetail.imageComponent}
							</div>
							<div className={css(myStyles.detailHeadingText)} styleName="each-detail-heading-text">
								{eachDetail.text}
							</div>
						</div>
						<div className={css(myStyles.detailValue)} styleName="each-detail-value">
							{props.getLabel(actorDetails[eachDetail.key])}
						</div>
					</div>
				);
			}
		});

		return detailsDiv;
	}

	const renderLastJoinedOrDetails = () => {
		if (actorDetails.stakeholderType === 'BU') {
			return (
				<div className={css(myStyles.lastJoined)} styleName="last-joined">
					<div styleName="bu-member-container">
						<div styleName="bu-member-image">
							<Team />
						</div>
						<div styleName="bu-member-text" className={css(myStyles.buMemberDetails)}>{props.getLabel(actorDetails.joinedPeriod)}</div>
					</div>
				</div>
			);
		} else if (actorDetails.stakeholderType === 'CLIENT' || actorDetails.stakeholderType === 'PARTNER') {
			return (
				<div className={css(myStyles.lastJoined)} styleName="last-joined">
					{props.getLabel(actorDetails.joinedPeriod)}
				</div>
			);
		}
		return (
			<div className={css(myStyles.lastJoined)} styleName="last-joined">
				{props.getLabel('label_joined_company', '', {
					ORG_NAME: props.getLabel('label_sim_org_name'),
					TIME: props.getLabel(actorDetails.joinedPeriod)
				})}
			</div>
		);
	}

	return (
		<div styleName="container" onClick={onActorPopupPopupClick}>
			<div className={css(myStyles.headerContainer)} styleName="header-container">
				<div styleName="header-content">
					<div styleName="image-container">
						<img src={props.getImage(actorDetails.image)} alt={`actorPopup-${actorDetails.id}`} width="100%" height="100%" />
					</div>
					<div styleName="actor-details-container">
						<div className={css(myStyles.name)} styleName="name">{props.getLabel(actorDetails.name)}</div>
						<div className={css(myStyles.designation)} styleName="designation">{props.getLabel(actorDetails.designation)}</div>
						{renderLastJoinedOrDetails()}
					</div>
				</div>
				<div className={css(myStyles.metricsContainer)} styleName="metrics-container">
					{renderMetrics()}
				</div>
				<div styleName="close-container" onClick={closePopup}>
					<Close strokeWidth={3} />
				</div>
			</div>
			<div className={css(myStyles.detailsContainer)} styleName="details-container">
				{renderActorDetails()}
			</div>
			<div className={css(myStyles.timelineContainer)} styleName="timeline-container">
				<div className={css(myStyles.timelineHeading)} styleName="timeline-header">
					{props.getLabel('label_timeline')}
				</div>
				{renderHistoryItems()}
			</div>
		</div>
	);
}

export default applyWrappers(ActorPopup, styles);
