import React from 'react';
import styles from './timeUp.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const TimeUp = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 64 64" fill="none">
				<g clip-path="url(#clip0)">
					<path d="M33.1172 65C43.0583 65 51.1172 56.9411 51.1172 47C51.1172 37.0589 43.0583 29 33.1172 29C23.1761 29 15.1172 37.0589 15.1172 47C15.1172 56.9411 23.1761 65 33.1172 65Z" fill="#FDAD28" />
					<path d="M31.1172 62C41.0583 62 49.1172 53.9411 49.1172 44C49.1172 34.0589 41.0583 26 31.1172 26C21.1761 26 13.1172 34.0589 13.1172 44C13.1172 53.9411 21.1761 62 31.1172 62Z" stroke="#14324B" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M28.668 34V44L36.668 50" stroke="#14324B" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M51.8757 33.8424C53.2778 31.8879 54.0219 29.5354 53.9995 27.1272C53.9287 23.0461 52.2673 19.1554 49.3715 16.2895C49.284 17.0071 49.0529 17.6995 48.6921 18.3253C48.3313 18.9511 47.8483 19.4972 47.272 19.931C46.6957 20.3648 46.038 20.6772 45.3384 20.8496C44.6389 21.022 43.9118 21.0508 43.2009 20.9342C41.2332 19.274 39.8649 17.0099 39.3073 14.4914C38.7497 11.973 39.0337 9.3403 40.1155 7C30.4728 7 24.3022 12.7099 23.1462 20.9342C21.7237 21.0833 20.2975 20.6876 19.1533 19.8263C18.0091 18.965 17.2313 17.7018 16.9756 16.2895C12.1484 20.4081 11.0594 26.6478 12.7393 31.5" stroke="#14324B" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
				</g>
				<defs>
					<clipPath id="clip0">
						<rect width="64" height="64" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
}

export default applyWrappers(TimeUp, styles);