import React from 'react';
import ReactJoyride, { ACTIONS, EVENTS } from 'react-joyride';
import { useSelector, useDispatch } from 'react-redux';

import styles from './tutorial.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';

import applyWrappers from 'wrappers/ComponentWrapper';
import {
	startTutorial,
	pauseTutorial,
	endTutorial,
	addStepsToTutorial,
	updateStepNumber
} from 'actions/tutorial';
import { removeActorPopupPayload } from 'util/functions';
import analyticsUtil from 'util/analyticsUtil';

const Tutorial = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const globalProfiles = props.skinGuide.globalProfiles;
	const skin = globalProfiles.palette;

	const dispatch = useDispatch();
	const myProps = useSelector(state => {
		return {
			tutorial: state.tutorial
		}
	});

	const getNextStepNumber = (data) => {
		if (data.action === ACTIONS.PREV) {
			return myProps.tutorial.stepIndex - 1
		} else if (data.action === ACTIONS.NEXT) {
			return myProps.tutorial.stepIndex + 1
		}
		return;
	}

	const openSimPopup = () => {
		dispatch(removeActorPopupPayload());
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: true,
				overlayComponentType: 'CENTER',
				highlightDesktopHeader: false,
				overlayComponent: 'START_SIM_POPUP'
			}
		});
	}

	const callback = (data) => {
		switch (data.type) {
			case EVENTS.STEP_AFTER:
				const nextStepId = getNextStepNumber(data);
				analyticsUtil.track('WALKTHROUGH_STEP', { stepId: nextStepId, type: myProps.tutorial.type });
				dispatch(updateStepNumber(nextStepId));
				break;
			case EVENTS.TOUR_END:
				analyticsUtil.track('WALKTHROUGH_ENDED', { type: myProps.tutorial.type });
				if (myProps.tutorial.type === 'GAME_AFTER_ACTION_WALKTHROUGH') {
					dispatch(endTutorial());
					setTimeout(() => {
						openSimPopup();
					}, 100);
				} else {
					dispatch(endTutorial());
				}
				break;
			default:
				break;
		}
	}


	const locale = {
		next: props.getLabel('label_walkthrough_next'),
		back: props.getLabel('label_walkthrough_previous'),
		last: props.getLabel('label_walkthrough_finish')
	}

	const buttonBox = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontFamily: 'Open Sans',
		fontSize: '1vw',
		fontWeight: 600,
		borderRadius: 4,
		padding: '4px',
	}

	return (
		<ReactJoyride
			continuous
			run={myProps.tutorial.isRunning}
			steps={myProps.tutorial.steps}
			stepIndex={myProps.tutorial.stepIndex}
			callback={callback}
			styles={
				{
					options: {
						backgroundColor: 'white',
						overlayColor: 'rgba(0, 0, 0, 0.8)',
						primaryColor: skin.primaryColor,
						textColor: skin.grayColor1,
						arrowColor: 'white'
					},
					buttonClose: {
						display: 'none',
					},
					tooltipFooter: {
						alignItems: 'center',
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: 0,
					},
					buttonNext: {
						// backgroundColor: skin.primaryColor,
						// color: skin.white,
						// width: '40%',
						// height: '24px',
						marginRight: '5%',
						...buttonBox
					},
					buttonBack: {
						backgroundColor: skin.white,
						color: skin.primaryColor,
						marginLeft: '0',
						marginRight: '10%',
						...buttonBox,
						':hover': {
							textDecoration: 'underline'
						}
					},
					tooltip: {
						padding: '5px 0'
					},
					tooltipContent: {
						padding: '15px',
					},
				}
			}
			// disableOverlay={false}
			locale={locale}
			disableBeacon
			disableCloseOnEsc
			disableOverlayClose
			scrollToFirstStep
			showBackButton
			disableOverlayClicks
			scrollToSteps={false}
		/>
	);
}

export default applyWrappers(Tutorial, styles);
