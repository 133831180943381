import React from 'react';
import styles from './presentation.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const Presentation = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const type = props.displayJson.type;
	const data = props.displayJson.data;

	const renderContent = () => {
		switch (type) {
			case 'PRESENTATION_POINTS':
				const points = data.content.list.map((eachPoint) => {
					return (
						<div className={css(myStyles.point)} styleName="point">
							{props.getLabel(eachPoint)}
						</div>
					);
				});
				return (
					<div styleName="content">
						{points}
					</div>
				);
			case 'PRESENTATION_IMAGES':
				const leftImages = [];
				data.content.imageLeftList.map((eachImage, index) => {
					leftImages.push(
						<div styleName="option-image">
							<img width="100%" height="100%" src={props.getImage(eachImage)} alt="" />
						</div>
					);
					if (index !== data.content.imageLeftList.length - 1) {
						leftImages.push(<div styleName="option-operation" className={css(myStyles.operation)}>+</div>);
					}
				});
				const rightImages = [];
				data.content.imageRightList.map((eachImage, index) => {
					rightImages.push(
						<div styleName="option-image">
							<img width="100%" height="100%" src={props.getImage(eachImage)} alt="" />
						</div>
					);
					if (index !== data.content.imageRightList.length - 1) {
						rightImages.push(<div styleName="option-operation" className={css(myStyles.operation)}>+</div>);
					}
				});
				return (
					<div styleName="content image-type">
						{leftImages}
						<div styleName="option-operation" className={css(myStyles.operation)}>=</div>
						{rightImages}
					</div>
				);
			case 'PRESENTATION_GRAPH':
				// console.log(data);
				const graphs = [];
				const numGraphs = data.content.graphList.length
				data.content.graphList.map((eachGraph) => {
					graphs.push(
						<div style={{ width: `${Math.floor(100 / numGraphs)}%` }} styleName="each-graph-container">
							<div styleName="graph-bar-container">
								<div style={{ height: `${eachGraph.value}%` }} className={css(myStyles.graphBar)} styleName="graph-bar">
									<div className={css(myStyles.graphValue)} styleName="graph-bar-value">{eachGraph.valueLabel}</div>
								</div>
							</div>
							<div className={css(myStyles.graphText)} styleName="graph-bar-text">{props.getLabel(eachGraph.graphText)}</div>
						</div>
					);
				});
				return (
					<div styleName="content">
						<div className={css(myStyles.graphHeading)} styleName="graph-heading">
							{props.getLabel(data.content.heading)}
						</div>
						<div styleName="graphs-container">
							{graphs}
						</div>
					</div>
				);
			default:
				break;
		}
	}

	return (
		<div className={css(myStyles.container)} styleName="presentation-card-container">
			<div className={css(myStyles.title)} styleName="title">{props.getLabel(data.title)}</div>
			<div className={css(myStyles.titleLine)} styleName="title-line"></div>
			{renderContent()}
			<div className={css(myStyles.footer)} styleName="footer">{props.getLabel(data.footer)}</div>
		</div>
	);
}

export default applyWrappers(Presentation, styles);