import React from 'react';
import styles from './lineChart.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { ResponsiveLine } from '@nivo/line';

const LineChart = (props) => {

	const data = props.data ? props.data : [
		{
			"id": "Strategy",
			"data": [
				{
					"x": 0,
					"y": 15
				},
				{
					"x": 10,
					"y": 27
				},
				{
					"x": 20,
					"y": 33
				},
				{
					"x": 30,
					"y": 27
				},
				{
					"x": 40,
					"y": 65
				}
			]
		},
		{
			"id": "Capability",
			"data": [
				{
					"x": 0,
					"y": 41
				},
				{
					"x": 10,
					"y": 23
				},
				{
					"x": 22,
					"y": 62
				},
				{
					"x": 30,
					"y": 11
				},
				{
					"x": 40,
					"y": 55
				}
			]
		},
		{
			"id": "Culture",
			"data": [
				{
					"x": 0,
					"y": 14
				},
				{
					"x": 12,
					"y": 36
				},
				{
					"x": 20,
					"y": 83
				},
				{
					"x": 30,
					"y": 64
				},
				{
					"x": 40,
					"y": 22
				}
			]
		}
	]
    
	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<ResponsiveLine
				tooltip={({ point }) => {
						return(
							<div
								style={{
									background: 'white',
									padding: '5px 5px',
									marginRight: '20px',
									fontSize: '10px',
									textTransform: 'capitalize',
								}}
							>
								<div>
									<div style={{width: '8px' , height: '8px', marginRight: '4px' , padding: '0px 0px 5px 5px', background: point.serieColor, float: 'left'}}></div>
									{props.getLabel('label_day')}: <strong style={{ marginRight: '4px'}}>{point.data.x},</strong>
									(%): <strong >{point.data.y}</strong> 
								</div>
							</div>
						)
				}}
				data={data}
				margin={{ top: 50, right: 10, bottom: 60, left: 60 }}
				xScale={{ type: 'point' }}
				yScale={{ type: 'linear', min: 0, max: 100, stacked: false, reverse: false }}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					format: e => Math.floor(e) === e && e,
					orient: 'bottom',
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: props.legend && props.legend.xLabel ? props.legend.xLabel : 'Days',
					legendOffset: 36,
					legendPosition: 'middle'
				}}
				axisLeft={{
					orient: 'left',
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: props.legend && props.legend.yLabel ? props.legend.yLabel : '(%)',
					legendOffset: -40,
					legendPosition: 'middle'
				}}
				colors={props.colors ? props.colors : ['#FDAD28', '#FD5382', '#1BE1D4']}
				pointSize={1}
				pointColor={{ theme: 'background' }}
				pointBorderWidth={1}
				pointBorderColor={{ from: 'serieColor' }}
				pointLabel="y"
				pointLabelYOffset={-12}
				useMesh={true}
				enableGridX={false}
				enableGridY={true}
				gridYValues={[0, 20, 40, 60, 80, 100]}
				curve="monotoneX"
				xScale={{
					type: 'linear'
				}}
			/>
		</div>
	);
}

export default applyWrappers(LineChart, styles);
