import React from 'react';
import styles from './day.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const Day = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 13 14" fill="none">
				<path d="M13.0003 6.4166C13.0013 5.61856 12.8259 4.83016 12.4868 4.10779C12.1476 3.38541 11.653 2.74691 11.0383 2.23794C10.4236 1.72896 9.7041 1.36209 8.93115 1.16357C8.1582 0.965042 7.35093 0.939763 6.56707 1.08954C5.78321 1.23931 5.04213 1.56044 4.39682 2.02996C3.75152 2.49948 3.21793 3.10578 2.83422 3.80552C2.4505 4.50526 2.22615 5.28114 2.17719 6.07767C2.12824 6.87421 2.25589 7.67172 2.55103 8.41318C2.57119 8.46347 2.60596 8.50656 2.65086 8.53688C2.69576 8.5672 2.74873 8.58336 2.80291 8.58326H4.20582C4.60226 8.58296 4.99005 8.69919 5.32096 8.91752C5.65187 9.13585 5.9113 9.44663 6.06699 9.81122C6.15456 10.0145 6.19065 10.2363 6.17207 10.4569C6.16892 10.4943 6.17358 10.532 6.18577 10.5675C6.19796 10.6031 6.21741 10.6357 6.24288 10.6633C6.26835 10.6909 6.29929 10.7129 6.33372 10.7279C6.36816 10.7429 6.40534 10.7506 6.4429 10.7505H8.39615C8.60231 10.7507 8.80563 10.7985 8.99032 10.8901C9.17501 10.9817 9.3361 11.1146 9.46107 11.2786C9.4982 11.3267 9.55045 11.361 9.6094 11.3758C9.66835 11.3906 9.73058 11.3852 9.78607 11.3604C10.7419 10.9346 11.5541 10.2412 12.1245 9.36393C12.6948 8.48665 12.999 7.463 13.0003 6.4166ZM7.04199 6.68743V3.43743C7.04199 3.29377 7.09906 3.156 7.20064 3.05441C7.30222 2.95283 7.44 2.89576 7.58365 2.89576C7.72731 2.89576 7.86509 2.95283 7.96667 3.05441C8.06825 3.156 8.12532 3.29377 8.12532 3.43743V5.87493C8.12532 5.94676 8.15386 6.01565 8.20465 6.06644C8.25544 6.11723 8.32433 6.14576 8.39615 6.14576H9.75032C9.89398 6.14576 10.0318 6.20283 10.1333 6.30441C10.2349 6.406 10.292 6.54377 10.292 6.68743C10.292 6.83109 10.2349 6.96886 10.1333 7.07045C10.0318 7.17203 9.89398 7.2291 9.75032 7.2291H7.58365C7.44 7.2291 7.30222 7.17203 7.20064 7.07045C7.09906 6.96886 7.04199 6.83109 7.04199 6.68743Z" fill="#C2CAD0" />
				<path d="M3.45313 11.5624C3.36334 11.5624 3.27723 11.5268 3.21374 11.4633C3.15025 11.3998 3.11458 11.3137 3.11458 11.2239C3.11458 11.1341 3.15025 11.048 3.21374 10.9845C3.27723 10.921 3.36334 10.8853 3.45313 10.8853H4.82083C4.91047 10.8853 4.9987 10.8631 5.07762 10.8206C5.15654 10.7781 5.22367 10.7166 5.27301 10.6418C5.32234 10.567 5.35234 10.4811 5.3603 10.3918C5.36826 10.3025 5.35394 10.2126 5.31863 10.1303C5.22544 9.91224 5.07027 9.7264 4.87238 9.59582C4.67449 9.46524 4.44259 9.39567 4.2055 9.39575H1.89583C1.824 9.39575 1.75512 9.42429 1.70433 9.47508C1.65353 9.52587 1.625 9.59476 1.625 9.66659V13.1874C1.625 13.2592 1.65353 13.3281 1.70433 13.3789C1.75512 13.4297 1.824 13.4583 1.89583 13.4583H7.72633C8.04733 13.4578 8.35506 13.3302 8.58209 13.1032C8.80912 12.8763 8.93693 12.5686 8.9375 12.2476V12.1041C8.9375 11.9604 8.88043 11.8227 8.77885 11.7211C8.67727 11.6195 8.53949 11.5624 8.39583 11.5624H3.45313Z" fill="#C2CAD0" />
				<path d="M1.08333 9.53172C1.08223 9.39707 1.03062 9.26775 0.938708 9.16934C0.888757 9.11334 0.827608 9.06845 0.759215 9.03756C0.690822 9.00668 0.616708 8.99049 0.541667 8.99005C0.398008 8.99005 0.260233 9.04712 0.158651 9.1487C0.0570683 9.25028 0 9.38806 0 9.53172L0 13.4583C0 13.6019 0.0570683 13.7397 0.158651 13.8413C0.260233 13.9429 0.398008 13.9999 0.541667 13.9999C0.62875 13.9988 0.714222 13.9763 0.790548 13.9344C0.866873 13.8924 0.931708 13.8323 0.979333 13.7594C1.04443 13.6723 1.08078 13.567 1.08333 13.4583V9.53172Z" fill="#C2CAD0" />
			</svg>
		</div>
	);
}

export default applyWrappers(Day, styles);