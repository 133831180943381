import React, { useState, useEffect } from 'react';
import styles from './actionResponsePopup.module.sass';
import { useSelector, useDispatch } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import _ from 'lodash';
import MetricBar from 'commonComponents/metricBar';
import ImpactActor from 'commonComponents/impactActor';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import { checkIfPresent, removeElementFromArray, getActorNames, formActorLabelKeys, removeActorPopupPayload } from 'util/functions';
import analyticsUtil from 'util/analyticsUtil';
import WaitingClock from 'svgComponents/waitingClock';
import RightArrowFlat from 'svgComponents/rightArrowFlat';

const ActionResponsePopup = (props) => {
	const dispatch = useDispatch();
    const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			actions: state.actions,
			user: state.user,
			actors: state.actors,
            userActiveActionResponse: state.userActiveActionResponse,
            actorInfo: state.actorInfo,
            userMetrics: state.userMetrics,
			storylineDefaults: state.storyline.storylineDefaults
		};
    });
    const myStyles = getSkin(props.skinGuide);

    const sccMetrics = ["strategy", "capability", "culture"];

    //Below conversion is language independent
    const initiativeLabels = {
        'label_strategy' : 'strategy',
        'label_capability' : 'capability',
        'label_culture' : 'culture'
    }

    const onActionResponsePopupClick = (e) => {
		e.stopPropagation();
    }

    const closePopup = () => {
		analyticsUtil.track('POPUP_CLOSED', { popup: myProps.uiState.overlayComponent, type: myProps.uiState.overlayComponentType });
		dispatch(removeActorPopupPayload());
        dispatch({
            type: 'ACTION_RESPONSE_POPUP_CLOSE',
            payload: {
                userActionOption: {
                    ...myProps.userActiveActionResponse.actionResponse,
                    reports: myProps.userActiveActionResponse.reports,
                    day: myProps.userActiveActionResponse.previousDay,
                    userActorMetrics: myProps.userActiveActionResponse.actionResponse.metricsImpacted,
				    actorIds: myProps.userActiveActionResponse.actorIds
                },
                userMetrics: myProps.userActiveActionResponse.userMetrics,
                userActorMetrics: myProps.userActiveActionResponse.userActorMetrics,
                reports: myProps.userActiveActionResponse.reports,
                userState: myProps.userActiveActionResponse.userState,
                actorIds: myProps.userActiveActionResponse.actorIds,
                userMetricsForThisAction: myProps.userActiveActionResponse.actionResponse.userMetricsForThisAction,
            }
        })
        if(!_.isEmpty(myProps.userActiveActionResponse.mentorTip)){
            dispatch({
                type: 'ADD_NEW_MENTOR_TIP',
                payload: myProps.userActiveActionResponse.mentorTip
            });
        }

        //Sequence Logic of what happens once Action Popup is closed
        if(myProps.actors.NewActorConverted && myProps.actors.NewActorConverted.length > 0){
            setTimeout(() => {
                dispatch(removeActorPopupPayload());
                dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                        showOverlay: true,
                        overlayComponentType: 'CENTER',
                        highlightDesktopHeader: false,
                        overlayComponent: 'ACTOR_CONVERTED_POPUP'
                    }
                });
            },2000)
        }
        else if(myProps.userActiveActionResponse.isBUUnlockedInThisAction && myProps.user.isGameStarted){
            setTimeout(() => {
                dispatch(removeActorPopupPayload());
                dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                        showOverlay: true,
                        overlayComponentType: 'CENTER',
                        highlightDesktopHeader: false,
                        overlayComponent: 'BU_UNLOCK_POPUP'
                    }
                });
            },2000)
        }
        else if(myProps.userActiveActionResponse.pendingActionResponse.length > 0){
            setTimeout(() => {
                dispatch(removeActorPopupPayload());
                dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                        showOverlay: true,
                        overlayComponentType: 'BOTTOM',
                        highlightDesktopHeader: false,
                        overlayComponent: 'PENDING_ACTION_RESPONSE_POPUP'
                    }
                });
            },2000)
        }
        else if(!_.isEmpty(myProps.userActiveActionResponse.event)){
            dispatch({
                type: 'ADD_DETAILS_FOR_MONTHLY_REPORT',
                payload: {
                    isAnyActionPresent: false
                }
            });
            setTimeout(() => {
                		dispatch({
                			type: 'ADD_ACTIVE_EVENT',
                			payload: myProps.userActiveActionResponse.event
                		});
                		dispatch(removeActorPopupPayload());
                		dispatch({
                			type: 'UPDATE_UI_STATE',
                			payload: {
                				showOverlay: true,
                				overlayComponentType: 'BOTTOM',
                				highlightDesktopHeader: false,
                				overlayComponent: 'EVENT_POPUP'
                			}
                		});
            }, 2000);
        }
        else{
            dispatch({
                type: 'ADD_DETAILS_FOR_MONTHLY_REPORT',
                payload: {
                    isAnyActionPresent: false,
                    isAnyEventPresent: false
                }
            });
        }
    }

    const { networksList, revealedNetworksList } = myProps.actorInfo;

    const actionOptionDetails = myProps.actions.actionOptionsList.find((eachOption) => {
        return eachOption.id == myProps.userActiveActionResponse.actionResponse.actionOptionId;
    });

    const delay = actionOptionDetails.delayedDuration;

    let actorNames = [], actorKeys = {};

    if (myProps.userActiveActionResponse.actionResponse.response) {
        let actorIds = myProps.userActiveActionResponse.actionResponse.response.actorIds;
        if (myProps.userActiveActionResponse.actionResponse.isNetworkRevealed) {
            const revealedNetworkId = revealedNetworksList.find(network => network.day === myProps.userActiveActionResponse.previousDay).networkId;
            const revealedNetwork = networksList.find(network => network.id === revealedNetworkId);

            actorIds = revealedNetwork.actors.filter(actor => myProps.userActiveActionResponse.actionResponse.response.actorIds.indexOf(actor) === -1);
        }
        actorNames = getActorNames(myProps.actors.actorsList, actorIds, props.getLabel);
        actorKeys = formActorLabelKeys(actorNames);
    }

    const actionDetails = myProps.actions.actionsList.find((eachAction) => {
        return eachAction.id === actionOptionDetails.actionId;
    });

    const initiativeDetails = myProps.actions.initiatives.find((eachInitiative) => {
        return eachInitiative.id === actionDetails.initiativeId;
    });

    let convertedActorsName = [];
    if(myProps.actors.convertedActors && myProps.actors.convertedActors.length > 0){
        convertedActorsName = getActorNames(myProps.actors.actorsList, myProps.actors.convertedActors, props.getLabel);
    }

    //Getting Name of CXO actor, current ID of CEO is 7.
	let CXOactorName = getActorNames(myProps.actors.actorsList, [7] , props.getLabel);

    const userMetrics = myProps.userMetrics;
    const getAverageAdoption = () => {
		let sum = 0;
		let count = 0;
		sccMetrics.map((eachMetric, index) => {
			const currentMetricId = userMetrics.userMetricIds[eachMetric];
			const userMetricDetails = userMetrics.userMetricsObj[currentMetricId];
			if (userMetricDetails.value) {
				sum += userMetricDetails.value;
				count++;
			}
		});
		if (count === 0) {
			return 0;
		}
		return (sum / count);
    }

    const initiativeNameSmallCase = initiativeLabels[initiativeDetails.name];
    const currentMetricId = userMetrics.userMetricIds[initiativeNameSmallCase];
    const userMetricDetails = userMetrics.userMetricsObj[currentMetricId];
    let value = userMetricDetails.value ? userMetricDetails.value : 0;
    let diff = 0;
    if(myProps.userActiveActionResponse.actionResponse.isActorMetricsImpacted){
        myProps.userActiveActionResponse.actionResponse.metricsImpacted.forEach((eachActor) =>{
            diff += eachActor.diff
        })
        diff /= 13;
    }
    value = value + diff;

    let target = 80;
    if(myProps.storylineDefaults[`${initiativeNameSmallCase}IndexTarget`]){
        target = myProps.storylineDefaults[`${initiativeNameSmallCase}IndexTarget`]
    }

    const checkImpactForActor = (actorId) => {
        const impactedActors = myProps.userActiveActionResponse.actionResponse.metricsImpacted;
        const impactedActor = impactedActors.filter(eachActor => {return eachActor.actorId === actorId})
        if(impactedActor != null && impactedActor != undefined && impactedActor.length > 0){
            return impactedActor[0].diff
        }else{
            return 0;
        }
    }

    const renderPendingActors = (actorIds) => {
        if(!actorIds){
			return null;
		}
        const actorDivs = actorIds.map((actorId,index) => {
			if (checkIfPresent(actorId)) {
				const actorDetails = myProps.actors.actorsList.find(eachActor => eachActor.id === actorId);
				return (
					<div key={index}  styleName = 'pending-actor-container'>
						<img src={props.getImage(actorDetails.image)} styleName = 'pending-actor-image'/>
						<div styleName = 'pending-actor-name'>{props.getLabel(actorDetails.name)} </div>
					</div>
				);
			}
        });
        return actorDivs;
    }

    	let metricConditionalStyle = {}
		let containerConditionalStyle = {}
        let containerConditionalStyleAlpha={}

    		if(diff > 0){
				metricConditionalStyle['border'] = '2px solid #1BE1D4'
				containerConditionalStyle['backgroundColor'] = '#1BE1D4'
                containerConditionalStyleAlpha['backgroundColor'] = '#1BE1D414'
			}
			//neutral color
			else if(diff === 0){
				metricConditionalStyle['border'] = '2px solid #7E8E9C'
				containerConditionalStyle['backgroundColor'] = '#7E8E9C'
                containerConditionalStyleAlpha['backgroundColor'] = '#7E8E9C14'
			}
			else{
				metricConditionalStyle['border'] = '2px solid #FD5382'
				containerConditionalStyle['backgroundColor'] = '#FD5382'
                containerConditionalStyleAlpha['backgroundColor'] = '#FD538214'
			}


    if(myProps.userActiveActionResponse.actionResponse.isDelayedAction){
        return (
            <div styleName="container" style={{border: '2px solid #FDAD28'}} onClick={onActionResponsePopupClick}>
                <div styleName="header-container" style={{backgroundColor: '#FDAD2814'}}>
                    <div styleName="header-title" className={css(myStyles.headerTitle)} >
                        { `${initiativeDetails.name == 'label_strategy'? props.getLabel('label_strategy_cap'): props.getLabel(initiativeDetails.name).toUpperCase()} ${props.getLabel('label_action').toUpperCase()}` }
                    </div>
                    <div styleName="close-container" onClick={closePopup}>
                        <Close strokeWidth={1.6} />
                    </div>
                </div>
                <div styleName="action-name-container" className={css(myStyles.actionName)} style={{backgroundColor: '#FDAD2814'}}>
                    <div styleName="action-name">
                        {props.getLabel(
                            actionDetails.name+'_cap', '', 
                            {ACTOR: convertedActorsName[convertedActorsName.length-1], 
                            CXO: CXOactorName[0]}
                        )}
                    </div>
                    <div styleName="header-days" style={{justifyContent: 'center'}}>
                        <div styleName="previous-day">{`${props.getLabel('label_day')} ${myProps.userActiveActionResponse.previousDay} >>`}</div>
                        <div styleName="current-day">{`> ${props.getLabel('label_day')} ${myProps.userActiveActionResponse.userState.currentDay}`}</div>
                    </div>
                </div>
                <div styleName="body-container" style={{backgroundColor: '#FDAD2814'}}>
                    <div styleName="body-desc">
                        {
                            checkIfPresent(myProps.userActiveActionResponse.actionResponse.response.description)
                                ? props.getLabel(myProps.userActiveActionResponse.actionResponse.response.description, '', {
                                    DELAY: delay,
                                    ACTOR_CONVERTED: convertedActorsName[convertedActorsName.length-1],
                                    ...actorKeys
                                })
                                : "***Response Description"
                        }<br/>
                        {props.getLabel('label_action_was_taken_on')}<p/>
                        <div styleName = 'pending-actors-container'>
                            {renderPendingActors(myProps.userActiveActionResponse.actionResponse.response.actorIds)}
                        </div>
                    </div>
                    <div styleName = 'waiting-clock-container'>
                        <div styleName = 'waiting-clock'>
                            <WaitingClock /><br/>
                        </div>
                        <div className={css(myStyles.expectedIn)}>
                            {props.getLabel('label_expected_in')}
                        </div>
                        <div className={css(myStyles.days)}>
                            { `${delay} ${props.getLabel('label_days')}`}
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{
        return (
            <div styleName="container" style={metricConditionalStyle} onClick={onActionResponsePopupClick}>
                <div styleName="header-container" style={containerConditionalStyleAlpha}>
                    <div styleName="header-title" className={css(myStyles.headerTitle)} >
                        { `${initiativeDetails.name == 'label_strategy'? props.getLabel('label_strategy_cap'): props.getLabel(initiativeDetails.name).toUpperCase()} ${props.getLabel('label_action').toUpperCase()}` }
                    </div>
                    <div styleName="close-container" onClick={closePopup}>
                        <Close strokeWidth={1.6} />
                    </div>
                </div>
                <div styleName="action-name-container" className={css(myStyles.actionName)} style={containerConditionalStyleAlpha}>
                    <div styleName="action-name">
                        {props.getLabel(
                            actionDetails.name+'_cap', '', 
                            {ACTOR: convertedActorsName[convertedActorsName.length-1], 
                            CXO: CXOactorName[0]}
                        )}
                    </div>
                    <div styleName="header-days" >
                        <div styleName="previous-day">{`${props.getLabel('label_day')} ${myProps.userActiveActionResponse.previousDay} >>`}</div>
                        <div styleName="current-day">{`> ${props.getLabel('label_day')} ${myProps.userActiveActionResponse.userState.currentDay}`}</div>
                    </div>
                </div>
                <div styleName="body-container" style={containerConditionalStyleAlpha}>
                    <div styleName="body-desc">
                        {
                            (checkIfPresent(myProps.userActiveActionResponse.actionResponse.response.actorIds)
                            && myProps.userActiveActionResponse.actionResponse.response.actorIds.length == 1)
                                ?   <div styleName = 'pending-actors-container'>
                                        {renderPendingActors(myProps.userActiveActionResponse.actionResponse.response.actorIds)}
                                    </div>
                                : null
                        }
                        {
                            checkIfPresent(myProps.userActiveActionResponse.actionResponse.response.description)
                                ? props.getLabel(myProps.userActiveActionResponse.actionResponse.response.description, '', {
                                    DELAY: delay,
                                    ACTOR_CONVERTED: convertedActorsName[convertedActorsName.length-1],
                                    ...actorKeys
                                })
                                : "***Response Description"
                        }
                    </div>
                    <div styleName="body-metrics" >
                        <div styleName = 'metric-bar'>
                        <MetricBar value={value.toFixed(1)} showAnimation={false} diff={0} target={target} initiative={initiativeDetails.name}/>
                        </div>
                        <div styleName='initiative-value-container'>
                            <div>
                                <div className={css(myStyles.initiativeValue)} styleName='initiative-value'>
                                    {value.toFixed(1)}%
                                    <div styleName='initiative-diff' 
                                        style={containerConditionalStyle}
                                    > 
                                        {diff.toFixed(1) > 0 ? '+':''}{diff.toFixed(1)}
                                    </div>
                                </div>
                                <div styleName='initiative-name'>{initiativeDetails.name == 'label_strategy'? props.getLabel('label_strategy_cap'): props.getLabel(initiativeDetails.name)} </div>
                            </div>
                        </div>
                        <div styleName='right-arrow-flat'>
                            <RightArrowFlat />
                        </div>
                        <div styleName='initiative-value-container'>
                            <div>
                                <div className={css(myStyles.initiativeValue)} styleName='initiative-value'>
                                    {(getAverageAdoption()+(diff/3)).toFixed(1)}%
                                    <div styleName='initiative-diff' 
                                        style={containerConditionalStyle}>
                                        {(diff/3).toFixed(1) > 0 ? '+':''}
                                        {(diff/3).toFixed(1)}
                                    </div>
                                </div>
                                <div styleName='initiative-name'>{props.getLabel('label_ecosystem_adoption')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div styleName="footer-container">
                    <div styleName="org-chart">
                        <div styleName="organization">
                            <div styleName="org-name">{props.getLabel('label_organization')}</div>
                            <div style={{width: '100%', height: '1px', backgroundColor: '#F1F3F4'}}></div>
                            <div styleName="org-actors">
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <ImpactActor id={7} impact={checkImpactForActor(7)}/>
                                    <ImpactActor id={2} impact={checkImpactForActor(2)}/>
                                    <ImpactActor id={10} impact={checkImpactForActor(10)}/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <ImpactActor id={11} impact={checkImpactForActor(11)}/>
                                    <ImpactActor id={9} impact={checkImpactForActor(9)}/>
                                    <ImpactActor id={4} impact={checkImpactForActor(4)}/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <ImpactActor id={8} impact={checkImpactForActor(8)}/>
                                    <ImpactActor id={12} impact={checkImpactForActor(12)}/>
                                    <ImpactActor id={5} impact={checkImpactForActor(5)}/>
                                </div>
                            </div>
                        </div>
                        <div styleName="client-partner">
                            <div styleName="client">
                                <div styleName="org-name">{props.getLabel('label_clients')}</div>
                                <div style={{width: '100%', height: '1px', backgroundColor: '#F1F3F4'}}></div>
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <ImpactActor id={15} impact={checkImpactForActor(15)}/>
                                    <ImpactActor id={16} impact={checkImpactForActor(16)}/>
                                </div>
                            </div>
                            <div styleName="partner">
                                <div styleName="org-name">{props.getLabel('label_partners')}</div>
                                <div style={{width: '100%', height: '1px', backgroundColor: '#F1F3F4'}}></div>
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <ImpactActor id={13} impact={checkImpactForActor(13)}/>
                                    <ImpactActor id={14} impact={checkImpactForActor(14)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default applyWrappers(ActionResponsePopup, styles);