import React, { useState, useEffect, useRef } from 'react';
import styles from './mentorTipsPopup.module.sass';
import { useSelector, useDispatch } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import Mentor from 'svgComponents/mentor';
import { TweenMax } from 'gsap';
import analyticsUtil from 'util/analyticsUtil';

const MentorTipsPopup = (props) => {

	//const scrollableContainerRef = useRef(null);
	const dispatch = useDispatch();

	const [scrollToBottom, setScrollToBottom] = useState(true);

	// useEffect(() => {
	// 	if(scrollToBottom) {
	// 		scrollContainer();
	// 		setScrollToBottom(false);
	// 	}
	// }, [scrollToBottom]);

	const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			user: state.user,
			userMentorTips: state.userMentorTips
		}
	});

	const onEventPopupClick = (e) => {
		e.stopPropagation();
	}

	// const scrollContainer = () => {
    //     TweenMax.to(scrollableContainerRef.current, 2, { scrollTop: scrollableContainerRef.current.scrollHeight });
    // }

	const renderLeftTalkBubble = (eachTip, isNewTip, index) => {
		return (
			<div styleName="message-container-left">
				{/* <div className={css(myStyles.leftArrow)} styleName="left-arrow"></div> */}
				<div className={css(myStyles.leftMessage)} styleName="left-message">
					<div styleName="left-message-header">
						<div styleName="tip-label"><b>{props.getLabel(eachTip.title)}</b></div>
						{isNewTip && <div className={css(myStyles.newTip)} styleName="new-tip">{props.getLabel('label_new_tip')}</div> }
						<div styleName="tip-count">{props.getLabel('label_tip_num','',{NUM: index+1})}</div>
					</div>
					{props.getLabel(eachTip.description)}
				</div>
			</div>
		);
	}

	const closePopup = () => {
		analyticsUtil.track('POPUP_CLOSED', { popup: myProps.uiState.overlayComponent, type: myProps.uiState.overlayComponentType });

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
		});
		dispatch({
			type: 'CLEAR_TIPS_COUNT_POPUP',
			payload: {}
		});
	}

	const renderProgress = () => {
		let progressDivs = []
		let currStyleName = "tips-available"
		for(let progressDivsCount = 0; progressDivsCount < 12; progressDivsCount++){
			if(progressDivsCount >= myProps.userMentorTips.tipsList.length){
				currStyleName = "tips-unavailable"
			}
			progressDivs.push(<div styleName={currStyleName}></div>)
		}
		return progressDivs
	}

	const renderTips = () => {
		const tipsList = myProps.userMentorTips.tipsList;
		if (tipsList.length === 0) {
			return (
				<div className={css(myStyles.emptyText)} styleName="empty-container">
					{props.getLabel('label_no_tips_yet')}
				</div>
			);
		}
		return tipsList.map((eachTip, index) => {
			let isNewTip = false;
			if(tipsList.length - (index + 1) < myProps.userMentorTips.newTipsForPopup){
				isNewTip = true;
			}
			return renderLeftTalkBubble(eachTip, isNewTip, index);
		}).reverse();
	}

	const myStyles = getSkin(props.skinGuide);

	return (
		<div styleName="container" onClick={onEventPopupClick}>
			<div className={css(myStyles.headerContainer)} styleName="header-container">
				<div styleName="header-image-text">
					<img styleName = "mentor-image" src={props.getImage('IMAGE_CDO')}/>
					{/* <div styleName="header-image">
						<Mentor />
					</div> */}
					<div className={css(myStyles.headerText)} styleName="header-text">{props.getLabel('label_mentor_tip')}</div>
				</div>
				<div styleName="close-container" onClick={closePopup}>
					<Close strokeWidth={2} />
				</div>
			</div>
			<div className={css(myStyles.headerBorderLine)} styleName="header-border-line"></div>
			{/* <div styleName="tips-progress-container">
				{ renderProgress() }
			</div> */}
			<div  styleName="events-container">
				{renderTips()}
			</div>
		</div>
	);
}

export default applyWrappers(MentorTipsPopup, styles);
