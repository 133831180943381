import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		headerContainer: {
			backgroundColor: skin.white,
			borderBottomWidth: `80px`,
			'@media (min-width: 1440px)' : {
				borderBottomWidth: `90px`
			},
			'@media (min-width: 1600px)' : {
				borderBottomWidth: `100px`
			},
			'@media (min-width: 1920px)' : {
				borderBottomWidth: `120px`
			},
			'@media (min-width: 2560px)' : {
				borderBottomWidth: `160px`
			},
		},
		headerText: {
			...myTypography.body1,
			color: skin.secondaryColor,
			fontWeight: 600,
			'@media (min-width: 1440px)' : {
				fontSize: '15.75px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '17.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '21px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '28px'
			},
		},
		headerBorderLine: {
			backgroundColor: `${skin.grayColor2}`
		},
		newTip: {
			backgroundColor: `${skin.red}`,
			color: `${skin.white}`
		},
		actionsContainer: {
			boxShadow: `1px -4px 10px ${hexToRgbA(skin.black, 0.2)}`,
			'@media (min-width: 1440px)' : {
				boxShadow: `1.125px -4.5px 11.25px ${hexToRgbA(skin.black, 0.2)}`,
			},
			'@media (min-width: 1600px)' : {
				boxShadow: `1.25px -5px 12.5px ${hexToRgbA(skin.black, 0.2)}`,
			},
			'@media (min-width: 1920px)' : {
				boxShadow: `1.5px -6px 15px ${hexToRgbA(skin.black, 0.2)}`,
			},
			'@media (min-width: 2560px)' : {
				boxShadow: `2px -8px 20px ${hexToRgbA(skin.black, 0.2)}`,
			},
		},
		actionHeaderText: {
			...myTypography.body2,
			textTransform: 'uppercase',
			fontWeight: 600,
			color: skin.grayColor3
		},
		actionText: {
			...myTypography.body1
		},
		eachAction: {
			border: `1px solid ${skin.grayColor2}`,
			color: skin.grayColor1,
			':hover': {
				backgroundColor: skin.grayColor6
			}
		},
		leftArrow: {
			borderRight: `10px solid ${skin.grayColor5}`,
			borderBottom: `10px solid transparent`,
			'@media (min-width: 1440px)' : {
				borderRight: `11.25px solid ${skin.grayColor5}`,
				borderBottom: `11.25px solid transparent`,
			},
			'@media (min-width: 1600px)' : {
				borderRight: `12.5px solid ${skin.grayColor5}`,
				borderBottom: `12.5px solid transparent`,
			},
			'@media (min-width: 1920px)' : {
				borderRight: `15px solid ${skin.grayColor5}`,
				borderBottom: `15px solid transparent`,
			},
			'@media (min-width: 2560px)' : {
				borderRight: `20px solid ${skin.grayColor5}`,
				borderBottom: `20px solid transparent`,
			},
		},
		leftMessage: {
			...myTypography.body2,
			color: skin.grayColor1,
			backgroundColor: `#835AC514`
		},
		rightArrow: {
			borderLeft: `10px solid ${skin.yellow}`,
			borderBottom: `10px solid transparent`,
			'@media (min-width: 1440px)' : {
				borderRight: `11.25px solid ${skin.yellow}`,
				borderBottom: `11.25px solid transparent`,
			},
			'@media (min-width: 1600px)' : {
				borderleft: `12.5px solid ${skin.yellow}`,
				borderBottom: `12.5px solid transparent`,
			},
			'@media (min-width: 1920px)' : {
				borderRight: `15px solid ${skin.yellow}`,
				borderBottom: `15px solid transparent`,
			},
			'@media (min-width: 2560px)' : {
				borderleft: `20px solid ${skin.yellow}`,
				borderBottom: `20px solid transparent`,
			},
		},
		rightMessage: {
			...myTypography.body2,
			color: skin.grayColor1,
			backgroundColor: hexToRgbA(skin.yellow, 0.7)
		},
		emptyText: {
			...myTypography.body1,
			color: skin.grayColor3
		}
	});
}

export default getSkin;
