import React, { useState, useEffect } from 'react';
import styles from './objectives.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import ObjectivesConfig from 'config/intro/objectives';
import TimelineConfig from 'config/intro/orgTimeline';
import BulletPoint from 'svgComponents/bulletPoint';
import { checkIfPresent } from 'util/functions';

import analyticsUtil from 'util/analyticsUtil';

const Objectives = (props) => {

	const onObjectivesPopupClick = (e) => {
		e.stopPropagation();
	}

	const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			storylineDefaults: state.storyline.storylineDefaults,
			groupDetails: state.user.groupDetails
		};
	});

	const closePopup = () => {
		analyticsUtil.track('POPUP_CLOSED', { popup: myProps.uiState.overlayComponent, type: myProps.uiState.overlayComponentType });

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
		});
	}

	const myStyles = getSkin(props.skinGuide);
	const dispatch = useDispatch();

	const renderObjectivesList = () => {
		const { storylineDefaults } = myProps;
		const objectives = [
			<div key={`objective-1`} styleName="objective-container">
				<div styleName="objective-point">
					<BulletPoint />
				</div>
				<div className={css(myStyles.objectiveText)} styleName="objective-text">
					<b>{props.getLabel(
						'label_intro_objective_1',
						'',
						{'ECOSYSTEM_ADOPTION_TARGET': storylineDefaults.ecosystemAdoptionTarget}
					)}</b> {props.getLabel('label_intro_objective_1b')}
				</div>
			</div>
			,
			<div key={`objective-2`} styleName="objective-container">
				<div styleName="objective-point">
					<BulletPoint />
				</div>
				<div className={css(myStyles.objectiveText)} styleName="objective-text">
					<b>
						{props.getLabel(
							'label_intro_objective_2',
							'',
							{'S_TARGET': storylineDefaults.strategyIndexTarget}
						)}
					</b> {props.getLabel('label_intro_objective_2Alignment')}
					<br/>
					<b>
						{props.getLabel(
							'label_intro_objective_2b',
							'',
							{'CAP_TARGET': storylineDefaults.capabilityIndexTarget}
						)}
					</b> {props.getLabel('label_intro_objective_2readiness')}
					<br/>
					<b>
						{props.getLabel(
							'label_intro_objective_2c',
							'',
							{'CUL_TARGET': storylineDefaults.cultureIndexTarget}
						)}
					</b> {props.getLabel('label_intro_objective_2fit')}
				</div>
			</div>
		];
		return (
			<div styleName="cdo-intro-content-list">
				<div className={css(myStyles.objectiveHeading)} styleName="objective-heading">
					{props.getLabel(ObjectivesConfig.headingKey)}
				</div>
				<div className={css(myStyles.objectiveHeading)} styleName="objective-heading">
					{ props.getLabel(ObjectivesConfig.heading2Key,
						'', 
						{MAX_DAYS : myProps.groupDetails.days})
					}
				</div>
				<div styleName="objectives-list">
					{objectives}
				</div>
			</div>

		);
	}

	const renderIntroEvents = () => {
		const fullTime = 1;
		const eachTime = (fullTime / TimelineConfig.list.length).toFixed(1);
		const eachAnimTime = (eachTime / 3).toFixed(1);
		const introEventDivs = TimelineConfig.list.map((eachEvent, i) => {

			return (
				<div key={`timeline-${i}`} styleName="intro-event-container">
					<div styleName="intro-event-image">
						<img src={props.getImage(eachEvent.imageKey)} width="100%" height="100%" />
					</div>
					<div styleName="intro-event-content">
						<div className={css(myStyles.introEventHeading)} styleName="intro-event-heading">{props.getLabel(eachEvent.titleKey)}</div>
						<div className={css(myStyles.introEventDesc)} styleName="intro-event-desc">{props.getLabel(eachEvent.descKey)}</div>
					</div>
					{
						i !== 0
							? <div className={css(myStyles.introEventLine)} styleName="intro-event-line"></div>
							: null
					}

					<div className={css(myStyles.introEventDot)} styleName="intro-event-dot"></div>
				</div>
			);
		});

		return introEventDivs;
	}

	return (
		<div className={css(myStyles.objectivesContainer)} styleName="objectives-container" onClick={onObjectivesPopupClick}>
			<div styleName="header-container">
				<div className={css(myStyles.headerContent)} styleName="header-content">{props.getLabel('label_objectives')}</div>
				<div className={css(myStyles.headerClose)} styleName="header-close" onClick={closePopup}>
					<div styleName="close-image">
						<Close />
					</div>
				</div>
			</div>
			<div className={css(myStyles.headerLine)} styleName="header-line"></div>
			<div styleName="content-container">
				<div styleName="left-container">
					<div styleName="events-container">
						{renderIntroEvents()}
					</div>
				</div>
				<div styleName="right-container">
					<div styleName="intro-image-container">
						<div styleName="intro-image">
							<img src={props.getImage('IMG_INTRO_ROLE')} alt="Intro Role" width="100%" height="100%" />
						</div>
					</div>
					<div styleName="cdo-intro-content-div">
						<div className={css(myStyles.cdoContentBox)} styleName="cdo-intro-content">
							{renderObjectivesList()}
							<div styleName="cdo-intro-content-border"></div>
						</div>
					</div>
				</div>

			</div>
		</div>
	);
}

export default applyWrappers(Objectives, styles);
