import React from 'react';
import styles from './startSimPopup.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import StartSim from 'svgComponents/startSim';
import BulletPoint from 'svgComponents/bulletPoint';

import { gameStarted } from 'actions/apiActions/gameStarted';
import {
	startTutorial,
	pauseTutorial,
	endTutorial,
	addStepsToTutorial,
	updateStepNumber
} from 'actions/tutorial';

import initialActionSteps from 'config/game/walkthrough/initialActionSteps';

const StartSimPopup = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const dispatch = useDispatch();

	const myProps = useSelector(state => {
		return {
			groupDetails: state.user.groupDetails
		};
	});

	const isTimerEnabled = () => myProps.groupDetails && myProps.groupDetails.isTimerEnabled;

	const onStartSimPopupPopupClick = (e) => {
		e.stopPropagation();
	}

	const closePopupAndOpenMentorTips = () => {
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
		});
		setTimeout(() => {
			dispatch({
				type: 'UPDATE_UI_STATE',
				payload: {
					showOverlay: true,
					overlayComponentType: 'RIGHT',
					highlightDesktopHeader: false,
					overlayComponent: 'MENTOR_TIPS_POPUP'
				}
			});
			dispatch({
				type: 'CLEAR_TIPS_COUNT',
				payload: {}
			});
		}, 750
		)
		// setTimeout(() => {
		// 	dispatch(addStepsToTutorial(initialActionSteps(), 'INITIAL_ACTION_WALKTHROUGH'));
		// 	dispatch(startTutorial());
		// }, 1000);
	}

	const startSimulationandTimer = () => {
		dispatch(gameStarted({}, closePopupAndOpenMentorTips));
	}

	const renderGameDuration = () => {
		return(
			<div>
				{myProps.groupDetails.isTimerEnabled &&
					<div className={css(myStyles.bulletContainer)} styleName="bullet-container">
						<div styleName="bullet-point"><BulletPoint /> </div> 
						<div styleName="bullet-desc">
							{props.getLabel('label_start_sim_timer_duration',
								'',
								{MAX_TIME : (myProps.groupDetails.timer/60)})
							}
						</div>
					</div>
				}
				<div className={css(myStyles.bulletContainer)} styleName="bullet-container">
					<div styleName="bullet-point"><BulletPoint /> </div> 
					<div styleName="bullet-desc">
						{ props.getLabel('label_start_sim_days_duration',
							'', 
							{MAX_DAYS : myProps.groupDetails.days})
						}
					</div>
				</div>
			</div>
		);
	}


	return (
		<div className={css(myStyles.startSimPopupContainer)} styleName="startSimPopup-container" onClick={onStartSimPopupPopupClick}>
			<div styleName="content-container">
				<div styleName="image-container">
					<StartSim />
				</div>
				<div styleName="content-details">
					<div styleName="advisor-details">
						<div styleName="advisor-image">
							<img src={props.getImage('IMAGE_CDO')} alt="advisor" width="100%" height="100%" />
						</div>
						<div className={css(myStyles.advisorName)} styleName="advisor-name">
							{props.getLabel('label_name_exeAdv')}
						</div>
					</div>
					<div className={css(myStyles.advisorDesc)} styleName="advisor-desc">
						{props.getLabel('label_start_sim_desc')}
					</div>
					<div styleName="duration-list">
						{renderGameDuration()}
					</div>
					<div styleName="button-container">
						<div className={css(myStyles.startButton)} styleName="button" onClick={startSimulationandTimer}>
							{props.getLabel('label_start_sim_button_text')}
						</div>
					</div>
					{ isTimerEnabled() &&
						<div className={css(myStyles.timerText)} styleName="timer-text">
							{props.getLabel('label_start_sim_timer_text')}
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default applyWrappers(StartSimPopup, styles);
