import React from 'react';
import styles from './emptyActor.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const EmptyActor = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 41 40" fill="none">
				<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="40">
					<circle cx="20.502" cy="20" r="20" fill="#D2D8DD" />
				</mask>
				<g mask="url(#mask0)">
					<path d="M20.0021 25.6042C21.4685 25.6044 22.9021 25.1699 24.1216 24.3553C25.341 23.5408 26.2915 22.383 26.8529 21.0283C27.4143 19.6735 27.5613 18.1828 27.2754 16.7444C26.9895 15.3061 26.2835 13.9849 25.2466 12.9479C24.2098 11.9109 22.8887 11.2046 21.4505 10.9184C20.0122 10.6322 18.5214 10.7789 17.1666 11.3401C15.8117 11.9012 14.6537 12.8515 13.839 14.0707C13.0242 15.29 12.5894 16.7235 12.5894 18.19C12.5913 20.1555 13.3728 22.04 14.7625 23.4299C16.1522 24.8199 18.0365 25.6018 20.0021 25.6042ZM20.0021 22.6875C19.3266 22.6871 18.6599 22.5345 18.0515 22.2412C17.443 21.948 16.9084 21.5214 16.4873 20.9933C16.0662 20.4652 15.7695 19.849 15.619 19.1905C15.4686 18.5321 15.4683 17.8482 15.6183 17.1896C15.6317 17.1328 15.6583 17.0801 15.6962 17.0358C15.734 16.9915 15.7818 16.9568 15.8358 16.9347C15.8897 16.9126 15.9481 16.9037 16.0062 16.9087C16.0642 16.9137 16.1202 16.9325 16.1696 16.9635C18.5034 18.4394 21.3232 18.9405 24.0227 18.3592C24.0797 18.347 24.1389 18.3487 24.1952 18.3641C24.2514 18.3796 24.3032 18.4083 24.346 18.4479C24.3889 18.4874 24.4216 18.5367 24.4415 18.5916C24.4614 18.6464 24.4678 18.7053 24.4602 18.7631C24.32 19.8469 23.7904 20.8427 22.9701 21.5648C22.1498 22.2868 21.0949 22.6859 20.0021 22.6875Z" fill="#A6B1BB" />
					<path d="M20.002 26.3333C17.1021 26.3364 14.3219 27.4898 12.2714 29.5403C10.2209 31.5908 9.06754 34.371 9.06445 37.2708C9.06445 37.4642 9.14128 37.6497 9.27802 37.7864C9.41477 37.9232 9.60023 38 9.79362 38H30.2103C30.4037 38 30.5891 37.9232 30.7259 37.7864C30.8626 37.6497 30.9395 37.4642 30.9395 37.2708C30.9364 34.371 29.783 31.5908 27.7325 29.5403C25.682 27.4898 22.9018 26.3364 20.002 26.3333ZM18.9082 29.25C18.9082 28.9599 19.0234 28.6817 19.2286 28.4766C19.4337 28.2715 19.7119 28.1563 20.002 28.1563C20.292 28.1563 20.5702 28.2715 20.7754 28.4766C20.9805 28.6817 21.0957 28.9599 21.0957 29.25V33.625C21.0957 33.9151 20.9805 34.1933 20.7754 34.3984C20.5702 34.6035 20.292 34.7188 20.002 34.7188C19.7119 34.7188 19.4337 34.6035 19.2286 34.3984C19.0234 34.1933 18.9082 33.9151 18.9082 33.625V29.25Z" fill="#A6B1BB" />
					<path d="M20.002 49C17.1021 48.9969 14.3219 47.8436 12.2714 45.7931C10.2209 43.7426 9.06754 40.9624 9.06445 38.0625C9.06445 37.8691 9.14128 37.6836 9.27802 37.5469C9.41477 37.4102 9.60023 37.3333 9.79362 37.3333H30.2103C30.4037 37.3333 30.5891 37.4102 30.7259 37.5469C30.8626 37.6836 30.9395 37.8691 30.9395 38.0625C30.9364 40.9624 29.783 43.7426 27.7325 45.7931C25.682 47.8436 22.9018 48.9969 20.002 49ZM18.9082 46.0833C18.9082 46.3734 19.0234 46.6516 19.2286 46.8567C19.4337 47.0619 19.7119 47.1771 20.002 47.1771C20.292 47.1771 20.5702 47.0619 20.7754 46.8567C20.9805 46.6516 21.0957 46.3734 21.0957 46.0833V41.7083C21.0957 41.4183 20.9805 41.1401 20.7754 40.9349C20.5702 40.7298 20.292 40.6146 20.002 40.6146C19.7119 40.6146 19.4337 40.7298 19.2286 40.9349C19.0234 41.1401 18.9082 41.4183 18.9082 41.7083V46.0833Z" fill="#A6B1BB" />
				</g>
			</svg>
		</div>
	);
}

export default applyWrappers(EmptyActor, styles);