import environment from 'util/environment';
import {
    getAppEnv
} from 'util/appVariables';

const initializeSentry = (sentryScopeObj) => {
	window.Sentry.init({
		environment: getAppEnv(),
		dsn: environment.REACT_APP_SENTRY_DSN
	});
	window.Sentry.configureScope((scope) => {
		scope.setUser(sentryScopeObj);
	});
}

export default initializeSentry;