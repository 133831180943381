import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
	const skin = theme.globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		customOption: {
			...myTypography.body1,
			color: skin.black,
			cursor: 'pointer',
			':hover': {
				color: skin.black,
				fontWeight: 600,
				backgroundColor: skin.grayColor6
			},
			'@media (min-width: 1440px)' : {
				fontSize: '15.75px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '17.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '21px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '28px'
			},
		},
		userProfileOptionContainer: {
			backgroundColor: skin.white,
			borderRadius: '15px',
			boxShadow: `0px 0px 25px ${hexToRgbA(skin.black, 0.6)}`,
			'@media (min-width: 1440px)' : {
				fontSize: '16.875px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '18.75px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '22.5px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '30px'
			},
		},
		userName: {
			borderBottom: `1px solid ${hexToRgbA(skin.black, 0.1)}`,
			...myTypography.body2,
			color: skin.black,
		},
		userprofileImageContainer: {
			backgroundColor: hexToRgbA(skin.black, 0.1),
			':hover': {
				backgroundColor: hexToRgbA(skin.black, 0.3),
			}
		}
	});
}

export default getSkin;