import React, { useState, useEffect } from 'react';
import styles from './metricPopup.module.sass';
import { useSelector, useDispatch } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import GraphBox from 'components/graphBox';
import _ from 'lodash';
import { checkInRange, checkInternalType } from 'util/functions';
import {getUpdatedUserMetrics} from 'actions/apiActions/updateReduxBackend'

import analyticsUtil from 'util/analyticsUtil';

import store from 'store/store.js';

const MetricPopup = (props) => {

	const state = store.getState();
	const isGameAlreadyStarted = state.user.isGameStarted

	const dispatch = useDispatch();

	const [updateMetrics, setUpdateMetrics] = useState(false);

	if(!updateMetrics){
		dispatch(getUpdatedUserMetrics());
		setUpdateMetrics(true);
	}
	
	const myProps = useSelector((state) => {
		return {
			user: state.user,
			actors: state.actors,
			uiState: state.uiState,
			userActorMetrics: state.userActorMetrics,
			userMetrics: state.userMetrics,
			allMetrics: state.allMetrics,
      metrics: state.metrics,
		};
	});
	const sccMetrics = ["strategy", "capability", "culture"];

	const getVerticalGraphData = () => {
		const graphData = [];

		myProps.actors.actorsList.map((eachActor) => {

			if (eachActor.isYou || eachActor.stakeholderType == 'NA') {
				return 1;
			}

			const actorMetricDetails = myProps.userActorMetrics.userActorMetricsObj[eachActor.id];
      const userActorMetricsForLabels = myProps.userActorMetrics;
		  const metrics = myProps.metrics;
      
      
			let eachActorGraphData = {
				actorId: eachActor.id,
				actor: props.getLabel(eachActor.name),
			};

			sccMetrics.map((eachMetric) => {
        const currentMetricId = userActorMetricsForLabels.actorMetricIds[eachMetric];
        const metricDetails = metrics.metricsObj[currentMetricId];
        
        eachActorGraphData[props.getLabel(metricDetails.name)] = Math.round(actorMetricDetails[myProps.userActorMetrics.actorMetricIds[eachMetric]].value);
			});
			graphData.push(eachActorGraphData);
		});
		return graphData;
	}

	const getAverageAdoption = () => {
		let sum = 0;
		let count = 0;
		const userMetrics = myProps.userMetrics;
		sccMetrics.map((eachMetric, index) => {
			const currentMetricId = userMetrics.userMetricIds[eachMetric];
			const userMetricDetails = userMetrics.userMetricsObj[currentMetricId];
			if (userMetricDetails.value) {
				sum += userMetricDetails.value;
				count++;
			}
		});
		if (count === 0) {
			return 0;
		}
		return (sum / count).toFixed(1);
	}

	const getActors = (type) => {
		// const actors = [];
		// myProps.actors.actorsList.map((eachActor) => {
		// 	if (eachActor.stakeholderType != 'null') {
		// 		actors.push(eachActor.id);
		// 		return 1;
		// 	}
		// });
		// return actors;

		if (type === null) {
			return [];
		}
		if (type === 'INTERNAL') {
			const actors = [];
			myProps.actors.actorsList.map((eachActor) => {
				if (checkInternalType(eachActor.stakeholderType) && !eachActor.isYou) {
					actors.push(eachActor.id);
					return 1;
				}
			});
			return actors;
		} else if (type === 'CLIENT' || type === 'PARTNER') {

			const actors = [];
			myProps.actors.actorsList.map((eachActor) => {
				if (eachActor.stakeholderType === type && !eachActor.isYou) {
					actors.push(eachActor.id);
					return 1;
				}
			});
			return actors;
		}
		return [];

	}

	const getWeightsObject = () => {
		let obj = {};
		myProps.actors.actorsList.map((eachActor) => {
			obj[eachActor.id] = eachActor.weight;
		});
		return obj;
	}

	const getActorsWeight = (type) => {

		if (type === null) {
			return 1;
		}
		if (type === 'INTERNAL') {
			let sum = 0;
			myProps.actors.actorsList.map((eachActor) => {
				if (checkInternalType(eachActor.stakeholderType) && !eachActor.isYou) {
					sum += eachActor.weight;
					return 1;
				}
			});
			return sum;
		} else if (type === 'CLIENT' || type === 'PARTNER') {

			let sum = 0;
			myProps.actors.actorsList.map((eachActor) => {
				if (eachActor.stakeholderType === type && !eachActor.isYou) {
					sum += eachActor.weight;
					return 1;
				}
			});
			return sum;
		}
		return 1;

	}

	const getDayWiseSccMetrics = () => {
		const sortedMetrics = myProps.allMetrics.userMetrics.sort((a,b) => {
			return a.day < b.day;
		});

		const graphData = sccMetrics.map((eachScc) => {
			let metricObj = {};
			metricObj['id'] = eachScc;
			metricObj['data'] = [];
			// metricObj.data.push({
			// 	x: 0,
			// 	y: 0
			// });

			const step = 1;
			for (let i = 0; i <= myProps.user.currentDay; i += step) {

				const metricData = sortedMetrics.filter((eachUserMetric) => {
					return (eachUserMetric.metricId === myProps.userMetrics.userMetricIds[eachScc] && eachUserMetric.day <= i)
				});

				if (metricData.length !== 0) {
					metricObj.data.push({
						x: i,
						y: (metricData[0].value).toFixed(1)
					});
				}
			}

			// myProps.allMetrics.userMetrics.map((eachUserMetric) => {
			// 	if (eachUserMetric.metricId === myProps.userMetrics.userMetricIds[eachScc]) {
			// 		metricObj.data.push({
			// 			x: eachUserMetric.day,
			// 			y: eachUserMetric.value
			// 		});
			// 	}
			// });

			return metricObj;
		});

		return graphData;
	}

	const getDayWiseEcosystemAdoptionMetrics = () => {
		const graphData = [
			{
				id: 'Organisation',
				data: []
			},
			{
				id: 'Client',
				data: []
			},
			{
				id: 'Partner',
				data: []
			}
		];

		//Initial Values
		// graphData[0].data.push({ x: 0, y: 0 });
		// graphData[1].data.push({ x: 0, y: 0 });
		// graphData[2].data.push({ x: 0, y: 0 });

		const actorWiseData = _.groupBy(myProps.allMetrics.userActorMetrics, 'actorId');

		Object.keys(actorWiseData).map((eachKey) => {
			actorWiseData[eachKey] = _.groupBy(actorWiseData[eachKey], 'metricId');
		});

		const orgActors = getActors('INTERNAL');
		const clientActors = getActors('CLIENT');
		const partnerActors = getActors('PARTNER');

		const weightObj = getWeightsObject();

		const step = 1;
		for (let i = 0; i <= myProps.user.currentDay; i += step) {

			let orgActorMetrics = {};
			let clientActorMetrics = {};
			let partnerActorMetrics = {};

			let averageOrgAdoption = 0;
			let averageClientAdoption = 0;
			let averagePartnerAdoption = 0;

			sccMetrics.forEach((eachScc) => {

				const metricId = myProps.userMetrics.userMetricIds[eachScc];

				orgActorMetrics[eachScc] = 0;
				orgActors.forEach((eachActorId) => {
					const myActorData = actorWiseData[eachActorId][metricId].filter((eachActorData) => {
						return eachActorData.day <= i;
					});
					if (myActorData.length !== 0) {
						orgActorMetrics[eachScc] += (myActorData[0].value) * (weightObj[eachActorId]);
					}
				});
				orgActorMetrics[eachScc] = orgActorMetrics[eachScc] / getActorsWeight('INTERNAL');
				averageOrgAdoption += orgActorMetrics[eachScc];


				clientActorMetrics[eachScc] = 0;
				clientActors.forEach((eachActorId) => {
					const myActorData = actorWiseData[eachActorId][metricId].filter((eachActorData) => {
						return eachActorData.day <= i;
					});
					if (myActorData.length !== 0) {
						clientActorMetrics[eachScc] += (myActorData[0].value) * (weightObj[eachActorId]);
					}
				});
				clientActorMetrics[eachScc] = clientActorMetrics[eachScc] / getActorsWeight('CLIENT');
				averageClientAdoption += clientActorMetrics[eachScc];

				partnerActorMetrics[eachScc] = 0;
				partnerActors.forEach((eachActorId) => {
					const myActorData = actorWiseData[eachActorId][metricId].filter((eachActorData) => {
						return eachActorData.day <= i;
					});
					if (myActorData.length !== 0) {
						partnerActorMetrics[eachScc] += (myActorData[0].value) * (weightObj[eachActorId]);
					}
				});
				partnerActorMetrics[eachScc] = partnerActorMetrics[eachScc] / getActorsWeight('PARTNER');
				averagePartnerAdoption += partnerActorMetrics[eachScc];

			});

			orgActorMetrics['averageAdoption'] = (averageOrgAdoption / sccMetrics.length).toFixed(1);
			clientActorMetrics['averageAdoption'] = (averageClientAdoption / sccMetrics.length).toFixed(1);
			partnerActorMetrics['averageAdoption'] = (averagePartnerAdoption / sccMetrics.length).toFixed(1);

			graphData[0].data.push({ x: i, y: orgActorMetrics.averageAdoption });
			graphData[1].data.push({ x: i, y: clientActorMetrics.averageAdoption });
			graphData[2].data.push({ x: i, y: partnerActorMetrics.averageAdoption });
		}
		return graphData;
	}

	const onMetricPopupPopupClick = (e) => {
		e.stopPropagation();
	}

	const closePopup = () => {
		analyticsUtil.track('METRIC_POPUP_CLOSED', {});

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
		});
	}

	const myStyles = getSkin(props.skinGuide);

	const graph3Data = getDayWiseEcosystemAdoptionMetrics();

	const graph1Data = getDayWiseSccMetrics();

	// console.log(isGameAlreadyStarted)

	if (isGameAlreadyStarted){
		return (
			<div className={css(myStyles.metricPopupContainer)} styleName="metricPopup-container" onClick={onMetricPopupPopupClick}>
				<div styleName="header-container">
					<div className={css(myStyles.headerContent)} styleName="header-content">{props.getLabel('label_metrics_pop_up_title')}</div>
					<div className={css(myStyles.headerClose)} styleName="header-close" onClick={closePopup}>
						<div styleName="close-image">
							<Close />
						</div>
					</div>
				</div>
				{/* <div className={css(myStyles.headerLine)} styleName="header-line"></div> */}
				<div styleName="content-container">
					<div styleName="graphs-box">
						<div styleName="each-graph-box" style={{ width: '30%' }}>
							<GraphBox
								graphTitle={`${props.getLabel('label_scc_trends')}`}
								graphType='LINE_CHART'
								graphData={getDayWiseSccMetrics()}
								subHeadingAvailable={false}
								headerMetricsAvailable={true}
								headerMetricsColorCoded={true}
								showLegend={true}
								colors={['#835AC5', '#3ABDFF', '#FF75D8']}
								headerMetrics={[
									{
										title: props.getLabel('label_strategy_cap'),
										value: (graph1Data[0].data[graph1Data[0].data.length - 1].y),
										postFix: '%',
										color: '#835AC5'
									},
									{
										title: props.getLabel('label_capability'),
										value: (graph1Data[1].data[graph1Data[1].data.length - 1].y),
										postFix: '%',
										color: '#3ABDFF'
									},
									{
										title: props.getLabel('label_culture'),
										value: (graph1Data[2].data[graph1Data[2].data.length - 1].y),
										postFix: '%',
										color: '#FF75D8'
									}
								]}
								textArray={['label_metric_popup_scc_para_1', 'label_metric_popup_scc_para_2']}
								legend={{
									xLabel: props.getLabel('label_metric_popup_days'),
									yLabel: props.getLabel('label_metric_popup_adoption')
								}}
							/>
						</div>
						<div styleName="each-graph-box" style={{ width: '37%' }}>
							<GraphBox
								graphTitle={`${props.getLabel('label_actor_vs_adoption')}`}
								graphType='VERTICAL_BAR'
								graphName='ACTOR_ADOPTION'
								graphData={getVerticalGraphData()}
								subHeadingAvailable={false}
								headerMetricsAvailable={false}
								headerMetricsColorCoded={true}
								showSccFilters={true}
								textArray={['label_metric_popup_actorAdoption_para_1', 'label_metric_popup_actorAdoption_para_2']}
								legend={{
									yLabel: props.getLabel('label_metric_popup_adoption')
								}}
							/>
						</div>
						<div styleName="each-graph-box" style={{ width: '30%' }}>
							<GraphBox
								graphTitle={`${props.getLabel('label_ecosystem_adoption_trend')}`}
								graphType='LINE_CHART'
								graphData={graph3Data}
								subHeadingAvailable={false}
								headerMetricsAvailable={true}
								headerMetricsColorCoded={true}
								colors={['#835AC5', '#3ABDFF', '#FF75D8']}
								showLegend={true}
								headerMetrics={[
									{
										title: props.getLabel('label_org'),
										value: (graph3Data[0].data[graph3Data[0].data.length - 1].y),
										postFix: '%',
										color: '#835AC5'
									},
									{
										title: props.getLabel('label_clients'),
										value: (graph3Data[1].data[graph3Data[1].data.length - 1].y),
										postFix: '%',
										color: '#3ABDFF'
									},
									{
										title: props.getLabel('label_partners'),
										value: (graph3Data[2].data[graph3Data[2].data.length - 1].y),
										postFix: '%',
										color: '#FF75D8'
									}
								]}
								textArray={['label_metric_popup_ecoAdoption_para_1', 'label_metric_popup_ecoAdoption_para_2']}
								legend={{
									xLabel: props.getLabel('label_metric_popup_days'),
									yLabel: props.getLabel('label_metric_popup_adoption')
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	else {
		return (
			<div className={css(myStyles.metricPopupContainer)} styleName="metricPopup-container" onClick={onMetricPopupPopupClick}>
				<div styleName="header-container">
					<div className={css(myStyles.headerContent)} styleName="header-content">{props.getLabel('label_metrics_pop_up_title')}</div>
					<div className={css(myStyles.headerClose)} styleName="header-close" onClick={closePopup}>
						<div styleName="close-image">
							<Close />
						</div>
					</div>
				</div>
				<div styleName="empty-container">
					{props.getLabel('label_not_available')}
				</div>
			</div>
		);
	}
}
	
export default applyWrappers(MetricPopup, styles);
