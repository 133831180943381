const checkIfPresent = (value) => {
	if (value === undefined || value === null || value === "") {
		return false;
	}
	return true;
}


const calculateAverageScore = (list, id) => {
	let sum = 0;
	if (list === null) {
		return 0;
	}
	list.map((eachItem) => {
		if (checkIfPresent(eachItem[id])) {
			sum += eachItem[id];
		}
		return 1;
	});
	if (list.length === 0) {
		return 0;
	}
	return Math.round((sum / list.length) * 10) / 10;
}

const validateEmail = (mail) => {
	if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,})+$/.test(mail)) {
		return true;
	}
	return false;
}

const removeElementFromArray = (arr, ele) => {
	return arr.filter((eachEle) => {
		return eachEle != ele;
	})
}

const addElementsFromArray = (arr1, arr2) => {
	arr2.map((ele) => {
		if (arr1.indexOf(ele) === -1) {
			arr1.push(ele);
		}
	});
	return arr1;
}

const addElementToArray = (arr, ele) => {
	if (arr.indexOf(ele) >= 0) {
		return arr;
	}
	arr.push(ele);
	return arr;
}

const checkInRange = (count, min, max) => {
	if (min === max) {
		return count === min;
	}
	if (min <= count && count <= max) {
		return true;
	}
	return false;
}

const checkInternalType = (type) => {
	switch (type) {
		case 'SLT':
			return true;
		case 'SLT_BUHEAD':
			return true;
		case 'BU':
			return true;
		default:
			return false;
	}
}

const checkStakeholderSelectionType = (selectiontype, actorType, isBUUnlocked = true) => {
	let arr = [];
	switch (selectiontype) {
		case 'SLT':
			arr = ['SLT', 'SLT_BUHEAD', 'BUHEAD'];
			if (arr.indexOf(actorType) > -1) {
				return true;
			}
			return false;
		case 'BUHEAD':
			arr = ['SLT_BUHEAD', 'BUHEAD'];
			if (arr.indexOf(actorType) > -1) {
				return true;
			}
			return false;
		case 'CLIENT_PARTNER':
			arr = ['CLIENT', 'PARTNER'];
			if (arr.indexOf(actorType) > -1) {
				return true;
			}
			return false;
		case 'SLT_CLIENT_PARTNER':
			arr = ['SLT', 'SLT_BUHEAD', 'BUHEAD', 'CLIENT', 'PARTNER'];
			if (arr.indexOf(actorType) > -1) {
				return true;
			}
			return false;
		case 'SLT_BU_CLIENT_PARTNER':
			isBUUnlocked
				? arr = ['SLT', 'SLT_BUHEAD', 'BUHEAD', 'BU', 'CLIENT', 'PARTNER']
				: arr = ['SLT', 'SLT_BUHEAD', 'BUHEAD', 'CLIENT', 'PARTNER'];
			if (arr.indexOf(actorType) > -1) {
				return true;
			}
			return false;
		case 'SLT_BU':
			isBUUnlocked
				? arr = ['SLT', 'SLT_BUHEAD', 'BU']
				: arr = ['SLT', 'SLT_BUHEAD']
			if (arr.indexOf(actorType) > -1) {
				return true;
			}
			return false;
		case 'BU':
			isBUUnlocked
				? arr = ['BU']
				: arr = []
			if (arr.indexOf(actorType) > -1) {
				return true;
			}
			return false;
		default:
			return false;
	}
}

const strategyArray = ['strategy', 'strategic', 'stratejik', 'strateji'];
const capabilityArray = ['capability', 'yetenek'];
const cultureArray = ['culture', 'cultural', 'kültürel', 'kültür'];

const walkthroughHighlightObject = (label) => {


	label = label.toLowerCase();

	if (strategyArray.indexOf(label) > -1) {
		return {
			strategy: true,
			capability: false,
			culture: false
		};
	} else if (capabilityArray.indexOf(label) > -1) {
		return {
			strategy: false,
			capability: true,
			culture: false
		};
	} else if (cultureArray.indexOf(label) > -1) {
		return {
			strategy: false,
			capability: false,
			culture: true
		};
	}
	return {
		strategy: false,
		capability: false,
		culture: false
	};
}

const checkToHighlight = (uiStateObj, metricName) => {
	metricName = metricName.toLowerCase();

	if (uiStateObj.strategy) {
		if (strategyArray.indexOf(metricName) > -1) {
			return true;
		}
	} else if (uiStateObj.capability) {
		if (capabilityArray.indexOf(metricName) > -1) {
			return true;
		}
	} else if (uiStateObj.culture) {
		if (cultureArray.indexOf(metricName) > -1) {
			return true;
		}
	}
	return false;
}

const getMonthIntervals = (monthDays, totalDays) => {
	const monthIntervals = [];
	for (let index = monthDays; index < totalDays; index += monthDays) {
		monthIntervals.push(index);
	}
	monthIntervals.push(totalDays);
	return monthIntervals;
}

const checkToShowMonthlyReport = (monthlyReportState) => {
	const {
		dayBeforeAction,
		dayAfterAction,
		isAnyEventPresent,
		isAnyActionPresent,
		reportSeenData,
		monthIntervals,
	} = monthlyReportState;

	let showMonthlyReport = false;
	let intervalDay = 0;
	if (isAnyEventPresent || isAnyActionPresent) {
		return {
			showMonthlyReport,
			intervalDay
		}
	}
	monthIntervals.map((eachInterval) => {
		if (dayBeforeAction < eachInterval && dayAfterAction >= eachInterval) {
			showMonthlyReport = true;
			intervalDay = eachInterval;
		}
	});
	return {
		showMonthlyReport,
		intervalDay
	}
}

const getActorNames = (actorsList, actorIds, getLabel) => {
	const actorsLength = actorIds.length;
	let actorNames = [];
	if(checkIfPresent(actorIds) && actorsLength > 0) {
		actorNames = actorIds.map((eachActorId, index) => {
			const actorDetails = actorsList.find((eachActorDetails) => {
				return eachActorDetails.id === eachActorId;
			});
			return actorDetails ? getLabel(actorDetails.name) : 'ACTOR_NAME';
		});
	}
	return actorNames;
}

const formActorLabelKeys = (actorNames) => {
	const actorKeys = actorNames.reduce((prev, name, idx) => {
		const key = idx !== 0 ? `ACTOR_${idx}` : 'ACTOR';
		return {
			...prev,
			[key]: name
		}
	}, {});
	return actorKeys;
}

const removeActorPopupPayload = () => {
	const redux_dispatch = {
		type: 'UPDATE_UI_STATE',
		payload: {
			showOverlay: false,
			overlayComponentType: '',
			highlightDesktopHeader: false,
			overlayComponent: '',
			actionSelectedActors: [],
			selectedActor: null,
			selectedAction: null,
			highlightActors: false,
			actorSelectionType: '',
			isActorSelectionRequired: false,
			minActorsRequired: 0,
			maxActorsRequired: 0,
			actionDayCost: 0
		}
	};
	return redux_dispatch;
}

const getUniqueItemsOnly = (array1, array2) => {
	let uniqueItems = [];
	for(let items in array1){
		if(!array2.includes(array1[items])){
			uniqueItems.push(array1[items])
		}
	}
	return uniqueItems;
}

const shuffleArray = (array) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
}

export {
	checkIfPresent,
	calculateAverageScore,
	validateEmail,
	addElementToArray,
	addElementsFromArray,
	removeElementFromArray,
	checkInRange,
	checkInternalType,
	checkStakeholderSelectionType,
	walkthroughHighlightObject,
	checkToHighlight,
	getMonthIntervals,
	checkToShowMonthlyReport,
	getActorNames,
	formActorLabelKeys,
	removeActorPopupPayload,
	getUniqueItemsOnly,
	shuffleArray
};
