import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'util/styleUtil';

const getSkin = (theme) => {
	const skin = theme.globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		timerContainer: {
			// background: hexToRgbA(skin.white, 0.2),
			// borderRadius: '4px',
		},
		timerText: {
			...myTypography.body1,
			textTransform: 'uppercase',
			fontSize: '10px',
			color: hexToRgbA(skin.white, 0.4),
			'@media (min-width: 1440px)' : {
				fontSize: '11.25px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '12.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '15px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '20px'
			},
		},
		timerContent: {
			...myTypography.timer,
			color: skin.white,
			fontWeight: 600,
		},
		timerContentTimeup: {
			...myTypography.timer,
			fontSize: '18px',
			color: skin.white,
			fontWeight: 600,
			'@media (min-width: 1440px)' : {
				fontSize: '20.25px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '22.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '27px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '36px'
			},
		}
	});
}

export default getSkin;