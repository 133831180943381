import React from 'react';
import styles from './metricUp.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const MetricUp = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 8 8" fill="none">
				<path d="M4 0C3.20888 0 2.43552 0.234596 1.77772 0.674121C1.11992 1.11365 0.607234 1.73836 0.304484 2.46927C0.00173314 3.20017 -0.0774802 4.00444 0.0768607 4.78036C0.231201 5.55628 0.612164 6.26902 1.17157 6.82843C1.73098 7.38784 2.44372 7.7688 3.21964 7.92314C3.99556 8.07748 4.79983 7.99827 5.53073 7.69552C6.26164 7.39277 6.88635 6.88008 7.32588 6.22228C7.7654 5.56448 8 4.79112 8 4C7.99885 2.93949 7.57706 1.92274 6.82716 1.17284C6.07726 0.422943 5.06051 0.00114703 4 0V0ZM6.18767 4.235L6.08767 4.335C6.02381 4.39751 5.93855 4.43337 5.84921 4.43529C5.75987 4.43721 5.67315 4.40505 5.60667 4.34533L4.745 3.55333C4.61167 3.42933 4.49933 3.478 4.49933 3.66133V5.823C4.49933 5.9114 4.46422 5.99619 4.4017 6.0587C4.33919 6.12121 4.25441 6.15633 4.166 6.15633H3.83267C3.74426 6.15633 3.65948 6.12121 3.59697 6.0587C3.53445 5.99619 3.49933 5.9114 3.49933 5.823V3.65633C3.49933 3.473 3.38733 3.423 3.25067 3.545L2.393 4.31167C2.32567 4.37017 2.23891 4.40141 2.14974 4.39925C2.06057 4.3971 1.97542 4.3617 1.911 4.3L1.811 4.2C1.78008 4.16933 1.75565 4.13274 1.73917 4.09242C1.72269 4.0521 1.7145 4.00887 1.71509 3.96532C1.71569 3.92177 1.72504 3.87878 1.74261 3.83892C1.76017 3.79907 1.78559 3.76315 1.81733 3.73333L3.758 1.895C3.82367 1.83425 3.90994 1.80068 3.9994 1.80105C4.08885 1.80142 4.17484 1.83571 4.24 1.897L6.18167 3.767C6.21323 3.79708 6.23846 3.83316 6.25588 3.87313C6.2733 3.9131 6.28256 3.95614 6.28312 3.99974C6.28368 4.04333 6.27552 4.0866 6.25913 4.127C6.24274 4.1674 6.21844 4.20412 6.18767 4.235Z" fill="#1BE1D4" />
			</svg>
		</div>
	);
}

export default applyWrappers(MetricUp, styles);