import React, { useState, useEffect } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'svgComponents/loader';
import initializeFreshdesk from 'util/freshdesk';
import {
	initializeFeedback,
	checkIfFeedbackIsCompleted
} from 'util/feedback';
import initializeSentry from 'util/sentry';
import {
	appFreshdeskEnabled,
	appSentryEnabled,
	appKfeedbackEnabled,
	appAnalyticsEnabled
} from 'util/appVariables';
import {
	checkIfPresent
} from 'util/functions';
import DesktopRouter from 'router/DesktopRouter';
import DesktopHeader from 'components/header/desktopHeader';
import DesktopFooter from 'components/footer/desktopFooter';
import updateRoute from 'util/updateRoute';
import { fetchInitialData } from 'actions/apiActions/init';
import Overlay from 'commonComponents/overlay';
import Tutorial from 'components/tutorial';
import analyticsUtil from 'util/analyticsUtil';

import styles  from './container.module.sass'; 
import applyWrappers from 'wrappers/ComponentWrapper';

const DesktopDashboardContainer = (props) => {

	const dispatch = useDispatch();
	let match = useRouteMatch();
	let location = useLocation();

	const myProps = useSelector(state => state);

	const [fetchingData, setFetchingData] = useState(false);

	useEffect(() => {
		checkAndRedirectToIntroduction();
		initializeExternalDependencies(myProps);
		if (!myProps.user.userDataFetched && !fetchingData) {
			setFetchingData(true);
			dispatch(fetchInitialData());
		}
	});

	const checkAndRedirectToIntroduction = () => {
		if (location.pathname === '/') {
			updateRoute({ route: '/introduction' });
		}
	}

	const checkLoadingCondition = (myProps) => myProps.user.userDataFetched

	const startSentryInitialization = (myProps) => {
		const sentryScopeObj = {
			"emailId": myProps.user.emailId,
			"groupId": myProps.user.groupId,
			"firstName": myProps.user.firstName,
			"lastName": myProps.user.lastName,
			"uliId": myProps.user.uliId,
		};

		initializeSentry(sentryScopeObj);
		myProps.setUserState({
			isSentryInitialized: true
		});
	}

	const setFeedbackCompleted = () => {
		dispatch({
			type: 'UPDATE_USER',
			payload: {
				isFeedbackSubmitInProgress: false,
				isKfeedbackSubmitted: true
			}
		});
	}

	const startKfeedbackInitialization = (myProps) => {
		const uliId = myProps.user.uliId;
		const grpId = myProps.user.groupDetails.commonDbGroupId;

		if (checkIfPresent(uliId) && checkIfPresent(grpId)) {
			initializeFeedback('commonDB', uliId,
				grpId, setFeedbackCompleted);
		}
		checkIfFeedbackIsCompleted(setFeedbackCompleted);
		dispatch({
			type: 'UPDATE_USER',
			payload: {
				isKfeedbackInitialized: true
			}
		});
	}

	const startFreshdeskInitialization = (myProps) => {
		let emailID = checkIfPresent(myProps.user.email)
			? myProps.user.email
			: '';

		initializeFreshdesk(emailID);
		dispatch({
			type: 'UPDATE_USER',
			payload: {
				isFreshdeskInitialized: true
			}
		});
	}

	const startAnalyticsInitialization = (myProps) => {
		const uliId = myProps.user.uliId;
		const grpId = myProps.user.groupDetails.commonDbGroupId;
		const emailID = checkIfPresent(myProps.user.email)
			? myProps.user.email
			: '';

		analyticsUtil.identify(uliId, { grpId, emailID });
		analyticsUtil.group(grpId, myProps.user.groupDetails);

		dispatch({
			type: 'UPDATE_USER',
			payload: {
				isAnalyticsInitialized: true
			}
		});
	}

	const initializeExternalDependencies = (myProps) => {
		if (checkLoadingCondition(myProps)) {
			if (appFreshdeskEnabled() && !myProps.user.isFreshdeskInitialized) {
				startFreshdeskInitialization(myProps)
				// console.log("Freshdesk has been initialized");
			}
			if (appSentryEnabled() && !myProps.user.isSentryInitialized) {
				startSentryInitialization(myProps);
				// console.log("Sentry has been initialized");
			}
			if (myProps.user.groupDetails.isFeedbackEnabled && !myProps.user.isKfeedbackInitialized) {
				startKfeedbackInitialization(myProps);
				// console.log("KFeedback has been initialized");
			}
			if (appAnalyticsEnabled() && !myProps.user.isAnalyticsInitialized) {
				startAnalyticsInitialization(myProps);
			}
		}
	}

	if (!checkLoadingCondition(myProps)) {
		return (
			<div className="base-app-box flex-box-center">
				<Loader fillColor="#d8d8d8" />
			</div>
		);
	}

	return (
		<div className="base-app-box">
			<DesktopHeader {...myProps} />
			<div styleName= "desktop-router">
				<DesktopRouter />
			</div>
			{
				myProps.uiState.showOverlay
					? <Overlay {...myProps} />
					: null
			}
			<Tutorial />
		</div>
	);
}

export default applyWrappers(DesktopDashboardContainer, styles);
