import React from 'react';
import styles from './lineChartWithDot.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { ResponsiveLine } from '@nivo/line';

const LineChartWithDot = (props) => {

	const data1 = props.data1;

	const data2 = props.data2;

	const myStyles = getSkin(props.skinGuide);

	const refsLayer = (prop) => {
		const items = [];
		data2[0].data.map((eachAdoption) => {
			items.push(
				<circle 
					cx={prop.xScale(eachAdoption.x)} 
					cy={prop.yScale(eachAdoption.y)} 
					r="3.5" 
					stroke="red" 
					stroke-width="2" 
					fill='#FFFFFF'
				/>
			)
		})

		return (
			<g>
				{items}
			</g>
		);
	};

	return (
		<div styleName="main-component">
			<div styleName="layer1">
				<ResponsiveLine
					tooltip={({ point }) => {
						return(
							<div
								style={{
									background: 'white',
									padding: '5px 5px',
									fontSize: '10px',
									textTransform: 'capitalize',
								}}
							>
								<div>
									<div style={{width: '8px' , height: '8px', marginRight: '4px' , padding: '0px 0px 5px 5px', background: point.serieColor, float: 'left'}}></div>
									Day: <strong style={{ marginRight: '4px'}}>{point.data.x},</strong>
									(%): <strong >{point.data.y}</strong> 
								</div>
							</div>
						)
				    }}
					data={data1}
					margin={{ top: 50, right: 10, bottom: 60, left: 60 }}
					xScale={{ type: 'point' }}
					yScale={{ type: 'linear', min: 0, max: 100, stacked: false, reverse: false }}
					axisTop={null}
					axisRight={null}
					axisBottom={{
						orient: 'bottom',
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: props.legend && props.legend.xLabel ? props.legend.xLabel : 'Days',
						legendOffset: 36,
						legendPosition: 'middle'
					}}
					axisLeft={{
						orient: 'left',
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: props.legend && props.legend.yLabel ? props.legend.yLabel : '(%)',
						legendOffset: -40,
						legendPosition: 'middle'
					}}
					colors={['#835ac5']}
					pointSize={3}
					pointColor={{ theme: 'background' }}
					pointBorderWidth={1}
					pointBorderColor={{ from: 'serieColor' }}
					pointLabel="y"
					pointLabelYOffset={-12}
					useMesh={true}
					//enableSlices="x"
					enableGridX={false}
					enableGridY={true}
					gridYValues={[0, 20, 40, 60, 80, 100]}
					//isInteractive={false}
					curve="monotoneX"
					layers={[
						"grid",
						"markers",
						"axes",
						"areas",
						"slices",
						"legends",
						"lines",
						// "points",
						refsLayer,
					  ]}
					xScale={{
						type: 'linear'
					}}
				/>
			</div>
		</div>
	);
}

export default applyWrappers(LineChartWithDot, styles);
