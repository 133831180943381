import React from 'react';
import styles from './hurryUp.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const TimeUp = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M54.9995 30.1272C55.0219 32.5354 54.2778 34.8879 52.8757 36.8424C52.8757 36.8424 44.2009 24.5 33.5 24.5C20 25.0658 13.7393 34.5 13.7393 34.5C12.0594 29.6478 13.1484 23.4081 17.9756 19.2895C18.2313 20.7018 19.0091 21.965 20.1533 22.8263C21.2975 23.6876 22.7237 24.0833 24.1462 23.9342C25.3022 15.7099 31.4728 10 41.1155 10C40.0337 12.3403 39.7497 14.973 40.3073 17.4914C40.8649 20.0099 42.2332 22.274 44.2009 23.9342C44.9118 24.0508 45.6389 24.022 46.3384 23.8496C47.038 23.6772 47.6957 23.3648 48.272 22.931C48.8483 22.4972 49.3313 21.9511 49.6921 21.3253C50.0529 20.6995 50.284 20.0071 50.3715 19.2895C53.2673 22.1554 54.9287 26.0461 54.9995 30.1272Z" fill="#FD5382"/>
				<path d="M31.1172 62C41.0583 62 49.1172 53.9411 49.1172 44C49.1172 34.0589 41.0583 26 31.1172 26C21.1761 26 13.1172 34.0589 13.1172 44C13.1172 53.9411 21.1761 62 31.1172 62Z" stroke="#14324B" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M30.668 35V47L35 49.5" stroke="#14324B" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M51.8757 33.8424C53.2778 31.8879 54.0219 29.5354 53.9995 27.1272C53.9287 23.0461 52.2673 19.1554 49.3715 16.2895C49.284 17.0071 49.0529 17.6995 48.6921 18.3253C48.3313 18.9511 47.8483 19.4972 47.272 19.931C46.6957 20.3648 46.038 20.6772 45.3384 20.8496C44.6389 21.022 43.9118 21.0508 43.2009 20.9342C41.2332 19.274 39.8649 17.0099 39.3073 14.4914C38.7497 11.973 39.0337 9.3403 40.1155 7C30.4728 7 24.3022 12.7099 23.1462 20.9342C21.7237 21.0833 20.2975 20.6876 19.1533 19.8263C18.0091 18.965 17.2313 17.7018 16.9756 16.2895C12.1484 20.4081 11.0594 26.6478 12.7393 31.5" stroke="#14324B" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
		</div>
	);
}

export default applyWrappers(TimeUp, styles);