import actionConstants from "constants/actions";

const initialState = {
    leaderboardList: [],
	isLeaderboardFetched: false,
	isLeaderboardDataBeingFetched: true
};

const Leaderboard = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConstants.SET_LEADERBOARD_DATA:
            return {
                ...state,
                leaderboardList: action.payload,
				isLeaderboardFetched: true,
				isLeaderboardDataBeingFetched: false
            }
        default:
            return state;
    }
};

export default Leaderboard;