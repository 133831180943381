import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		monthlyReportPopupContainer: {
			backgroundColor: skin.white
		},
		headerClose: {
			backgroundColor: skin.secondaryColor,
			':hover': {
				backgroundColor: hexToRgbA(skin.secondaryColor, 0.8)
			}
		},
		headerContent: {
			...myTypography.body1,
			fontSize: '18px',
			fontWeight: 600,
			'@media (min-width: 1440px)' : {
				fontSize: '20.25px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '22.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '27px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '36px'
			},
		},
		headerLine: {
			backgroundColor: hexToRgbA(skin.black, 0.1)
		},
		tabContainer: {
			borderBottom: `1px solid ${skin.grayColor3}`
		},
		tab: {
			...myTypography.body1,
			color: skin.grayColor2,
			textTransform: 'uppercase',
			'@media (min-width: 1440px)' : {
				fontSize: '15.75px'
			},
			'@media (min-width: 1600px)' : {
				fontSize: '17.5px'
			},
			'@media (min-width: 1920px)' : {
				fontSize: '21px'
			},
			'@media (min-width: 2560px)' : {
				fontSize: '28px'
			},
		},
		selectedTab: {
			...myTypography.body1,
			color: skin.grayColor1,
			fontWeight: 600,
			textTransform: 'uppercase'
		},
		selectedTabLine: {
			backgroundColor: skin.grayColor1
		},
		advisorContent: {
			...myTypography.body1,
			color: skin.grayColor1,
			border: `1px solid ${skin.grayColor2}`
		},
		advisorShadow: {
			backgroundColor: skin.white,
			boxShadow: `7px 7px 20px ${hexToRgbA(skin.black, 0.1)}`,
			border: `1px solid ${skin.white}`,
			'@media (min-width: 1440px)' : {
				boxShadow: `7.875px 7.875px 22.5px ${hexToRgbA(skin.black, 0.1)}`
			},
			'@media (min-width: 1600px)' : {
				boxShadow: `8.75px 8.75px 25px ${hexToRgbA(skin.black, 0.1)}`
			},
			'@media (min-width: 1920px)' : {
				boxShadow: `10.5px 10.5px 30px ${hexToRgbA(skin.black, 0.1)}`
			},
			'@media (min-width: 2560px)' : {
				boxShadow: `14px 14px 40px ${hexToRgbA(skin.black, 0.1)}`
			},
		},
		advisorName: {
			...myTypography.body1,
			fontWeight: 600,
			color: skin.grayColor1,
			lineHeight: 1
		},
		advisorDesignation: {
			...myTypography.body2,
			color: skin.grayColor2,
			textAlign: 'center',
			lineHeight: 1
		}
	});
}

export default getSkin;