import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		headingText: {
			...myTypography.body1,
			color: skin.grayColor2,
		},
		contentText: {
			...myTypography.body2,
			color: skin.grayColor1,
		},
		highlightText: {
			...myTypography.body1,
			color: skin.blue,
			borderBottom: `1px dotted ${skin.blue}`,
			cursor: 'pointer'
		}
	});
}

export default getSkin;
