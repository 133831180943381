import React from 'react';
import styles from './logoutPopupComponent.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { useDispatch, useSelector } from 'react-redux';
import Logout from 'svgComponents/logout';
import analyticsUtil from 'util/analyticsUtil';

import urls from 'constants/urls';

const LogoutPopupComponent = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const dispatch = useDispatch();
	const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			user: state.user
		};
	});

	const onTimerCompletedPopupClick = (e) => {
		e.stopPropagation();
	}

	const closePopup = () => {
		analyticsUtil.track('POPUP_CLOSED', { popup: myProps.uiState.overlayComponent, type: myProps.uiState.overlayComponentType });

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
		});
	}

	const logoutClickPopup = () => {
		analyticsUtil.reset();
		window.location.href = urls.LOG_OUT;
	}

	let isReturnTypeNormal = myProps.user.returnType.toLowerCase() == 'normal';
	
	return (
		<div className={css(myStyles.startSimPopupContainer)} styleName="startSimPopup-container" onClick={onTimerCompletedPopupClick}>
			<div styleName="content-container">
				<div styleName="content-details">
					<div styleName="advisor-details">
						<div styleName="advisor-image">
							<Logout />
						</div>
					</div>
					<div className={css(myStyles.advisorHeading)} styleName="advisor-desc">
						{isReturnTypeNormal 
							? props.getLabel('label_logout_double_check')
							: props.getLabel('label_exit_double_check')
						}
					</div>
					{/* <div className={css(myStyles.advisorDesc)} styleName="advisor-desc">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porttitor volutpat ligula aenean placerat egestas gravida suspendisse platea tortor.
					</div> */}
					<div styleName="button-container">
						<div className={css(myStyles.startButton)} styleName="button" onClick={logoutClickPopup}>
						{isReturnTypeNormal 
							? props.getLabel('label_logout')
							: props.getLabel('label_exit')
						}
						</div>
						<div className={css(myStyles.normalButton)} styleName="button" onClick={closePopup}>
							{props.getLabel('label_cancel')}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default applyWrappers(LogoutPopupComponent, styles);
