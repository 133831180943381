import React, { Component } from 'react';
import styles from './ecosystemSvg.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/functions';

const Leaderboard = (props) => {

    const skin = props.skinGuide.globalProfiles.palette
    let color = skin.white;
    if (checkIfPresent(props.svgColor)) {
        color = props.svgColor;
    }
    return (
        <div styleName="main-component">
   <svg width="100%" height="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9" cy="9" r="8.75" fill="#F1F3F4" stroke="#E3E6E9" stroke-width="0.5"/>
<path d="M5.2002 9.29997H2.2002" stroke="#FDAD28" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.2002 9.29997H13.2002" stroke="#FDAD28" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.7002 13.7C11.4616 13.7 13.7002 11.4614 13.7002 8.7C13.7002 5.93857 11.4616 3.7 8.7002 3.7C5.93877 3.7 3.7002 5.93857 3.7002 8.7C3.7002 11.4614 5.93877 13.7 8.7002 13.7Z" stroke="#14324B" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.7002 12.7V15.7" stroke="#14324B" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.7002 1.7V4.7" stroke="#14324B" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.7002 8.7H1.7002" stroke="#14324B" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.7002 8.7H12.7002" stroke="#14324B" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 10.3C10.2165 10.3 11 9.51649 11 8.54999C11 7.58349 10.2165 6.79999 9.25 6.79999C8.2835 6.79999 7.5 7.58349 7.5 8.54999C7.5 9.51649 8.2835 10.3 9.25 10.3Z" fill="#FDAD28"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.7002 9.69933C9.66669 9.69933 10.4502 8.91582 10.4502 7.94933C10.4502 6.98283 9.66669 6.19933 8.7002 6.19933C7.7337 6.19933 6.9502 6.98283 6.9502 7.94933C6.9502 8.91582 7.7337 9.69933 8.7002 9.69933Z" stroke="#14324B" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.7532 11.022C9.60396 9.92518 7.79567 9.92518 6.64648 11.022" stroke="#14324B" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </div>
    );
}

export default applyWrappers(Leaderboard, styles);
