import IMG_LOGO from 'images/default/logo.svg';
import IMG_DIGITAL_CHANGE from 'images/default/digital_change.png';
import IMG_DIGITAL_CHANGE_DEMO from 'images/default/digital_change_demo.png'
import IMG_KNOLSKAPE from 'images/default/knolskape_logo.png';

//Intro -- Timeline Images
import IMG_INTRO_TL_TRUNS_100 from 'images/default/intro/turns_100.png';
import IMG_INTRO_TL_ADVISOR_JOINS from 'images/default/intro/advisor_joins.png';
import IMG_INTRO_TL_MISS_TARGET from 'images/default/intro/miss_target.png';
import IMG_INTRO_TL_ROLLED_OUT from 'images/default/intro/rolled_out.png';
import IMG_INTRO_TL_TEAM_EXPAND from 'images/default/intro/team_expand.png';
import IMG_INTRO_TL_INITIATE_CHANGE from 'images/default/intro/initiate_change.png';

// Game -- Actor Images
import IMG_GAME_ACTOR_CEO from 'images/default/actors/brittany_philip.png';
// Insurance Team
import IMG_GAME_ACTOR_DIR_INSURANCE from 'images/default/actors/khaleel_idris.png';
import IMG_GAME_ACTOR_BUHEAD_INSURANCE from 'images/default/actors/ben_marshall.png';
import IMG_GAME_ACTOR_BU_INSURANCE from 'images/default/actors/general_insurance.png';
// Retail Team
import IMG_GAME_ACTOR_DIR_RETAIL from 'images/default/actors/edgar_lee.png';
import IMG_GAME_ACTOR_BUHEAD_RETAIL from 'images/default/actors/immaculata_ruiz.png';
import IMG_GAME_ACTOR_BU_RETAIL from 'images/default/actors/retail_banking.png';
// Loans & Mortagages Team
import IMG_GAME_ACTOR_DIR_LOANS from 'images/default/actors/karthik_das.png';
import IMG_GAME_ACTOR_BUHEAD_LOANS from 'images/default/actors/zuzana_hodrova.png';
import IMG_GAME_ACTOR_BU_LOANS from 'images/default/actors/loans_mortagages.png';
// Partners
import IMG_GAME_ACTOR_PARTNER_1 from 'images/default/actors/camille_carter.png';
import IMG_GAME_ACTOR_PARTNER_2 from 'images/default/actors/freda_sisulu.png';
// Clients
import IMG_GAME_ACTOR_CLIENT_1 from 'images/default/actors/alan_walker.png';
import IMG_GAME_ACTOR_CLIENT_2 from 'images/default/actors/putri_sofia.png';



// Action Images
import IMG_ACTION_RESTRICTED from 'images/default/actions/action_restricted.png';

// Strategy
import IMG_PRESENT_CASE from 'images/default/actions/strategy/present_case.png';
import IMG_CHANGE_POLICY from 'images/default/actions/strategy/change_policy.png';
import IMG_ANALYZE_TRENDS from 'images/default/actions/strategy/analyze_trends.png';
import IMG_CREATE_ROADMAP from 'images/default/actions/strategy/create_roadmap.png';
import IMG_CONSULT_ECOSYSTEM from 'images/default/actions/strategy/consult_ecosystem.png';
import IMG_ALLOCATE_BUDGET from 'images/default/actions/strategy/allocate_budget.png';
import IMG_ASSESS_SUPPORT from 'images/default/actions/strategy/assess_support.png';

// Capability
import IMG_RUN_PILOT from 'images/default/actions/capability/run_pilot.png';
import IMG_ORGANIZE_HACKATHON from 'images/default/actions/capability/organize_hackathon.png';
import IMG_SHARE_PODCASTS from 'images/default/actions/capability/share_podcasts.png';
import IMG_PITCH_ELEARNING from 'images/default/actions/capability/pitch_elearning.png';
import IMG_INVITE_EXPERTS from 'images/default/actions/capability/invite_expert.png';
import IMG_ONBOARD_PEOPLE from 'images/default/actions/capability/onboard_people.png';
import IMG_ENROLL_SUPPORTERS from 'images/default/actions/capability/enroll_supporters.png';

// Culture
import IMG_SUSTAIN_INTEREST from 'images/default/actions/culture/sustain_interest.png';
import IMG_IDENTIFY_NETWORKS from 'images/default/actions/culture/identify_networks.png';
import IMG_BUILD_NETWORKS from 'images/default/actions/culture/build_networks.png';
import IMG_RECOGNIZE_CHAMPION from 'images/default/actions/culture/recognize_champion.png';
import IMG_CROWDSOURCE_INPUTS from 'images/default/actions/culture/crowdsource_inputs.png';
import IMG_EXECUTE_CAMPAIGN from 'images/default/actions/culture/execute_campaign.png';
import IMG_PROPOSE_MECHANISM from 'images/default/actions/culture/propose_mechanism.png';

// Presentation Option Images
import BANK_ICON from 'images/default/options/bank.png';
import PLATFORM_ICON from 'images/default/options/platform.png';
import GROWING_GRAPH_ICON from 'images/default/options/growth.png';
import HAPPY_CUST_GRAPH_ICON from 'images/default/options/happy_customer.png';
import MANY_HAPPY_CUST_GRAPH_ICON from 'images/default/options/many_happy_customers.png';
import MORE_MONEY_GRAPH_ICON from 'images/default/options/more_money.png';
import BIGGER_ECO_GRAPH_ICON from 'images/default/options/bigger_ecosystem.png';
import IMG_SPEAKER_1 from 'images/default/speakers/speaker1.png';
import IMG_SPEAKER_2 from 'images/default/speakers/speaker2.png';
import IMG_SPEAKER_3 from 'images/default/speakers/speaker3.png';
import IMG_SPEAKER_4 from 'images/default/speakers/speaker4.png';
import IMG_SPEAKER_5 from 'images/default/speakers/speaker5.png';
import IMG_SPEAKER_6 from 'images/default/speakers/speaker6.png';

import IMG_INTRO_ROLE from 'images/default/intro_role.png';
import IMAGE_CDO from 'images/default/cdo.png';
import IMG_TICK from 'images/default/tick.png';
import IMG_ADOPTION_GRAPH from 'images/default/adoption_graph.png';
import IMG_ACTION_LOBBY from 'images/default/actions/lobby.png';
import image_f2f_icon from 'images/default/actions/presentation.png';
import IMG_ACTION_PRESENTION from 'images/default/actions/presentation.png';
import IMG_ACTION_INVITESPEAKER from 'images/default/actions/invite_speaker.png';


// Event option images
import IMG_SHARE from 'images/default/actions/events/share.png';
import IMG_NO_SHARE from 'images/default/actions/events/no_share.png';

import IMG_EVENT_24_1 from 'images/default/actions/events/reskill.png';
import IMG_EVENT_24_2 from 'images/default/actions/events/awareness.png';
import IMG_EVENT_24_3 from 'images/default/actions/events/ignoring.png';
import IMG_EVENT_24_4 from 'images/default/actions/events/dont_share.png';

import IMG_DEMO from 'images/default/actions/events/demo.png';
import IMG_NO_DEMO from 'images/default/actions/events/no_demo.png';

import IMG_SELECT_SLT from 'images/default/actions/events/select_slt.png';
import IMG_SELECT_BU from 'images/default/actions/events/select_bu.png';

import IMG_EVENT_30_1 from 'images/default/actions/events/social_media.png';
import IMG_EVENT_30_2 from 'images/default/actions/events/blog.png';
import IMG_EVENT_30_3 from 'images/default/actions/events/qna.png';
import IMG_EVENT_30_4 from 'images/default/actions/events/thank_you.png';

import IMG_EVENT_31_1 from 'images/default/actions/events/remove-post.png';
import IMG_EVENT_31_2 from 'images/default/actions/events/new-post.png';
import IMG_EVENT_31_3 from 'images/default/actions/events/address.png';
import IMG_EVENT_31_4 from 'images/default/actions/events/no_post.png';

const images = {
	IMG_LOGO,
	IMG_DIGITAL_CHANGE,
	IMG_DIGITAL_CHANGE_DEMO,
	IMG_KNOLSKAPE,

	//Intro -- Timeline Images
	IMG_INTRO_TL_TRUNS_100,
	IMG_INTRO_TL_ADVISOR_JOINS,
	IMG_INTRO_TL_MISS_TARGET,
	IMG_INTRO_TL_ROLLED_OUT,
	IMG_INTRO_TL_TEAM_EXPAND,
	IMG_INTRO_TL_INITIATE_CHANGE,

	// Game -- Actor Images
	IMG_GAME_ACTOR_CEO,
	// Insurance Team
	IMG_GAME_ACTOR_DIR_INSURANCE,
	IMG_GAME_ACTOR_BUHEAD_INSURANCE,
	IMG_GAME_ACTOR_BU_INSURANCE,
	// Retail Team
	IMG_GAME_ACTOR_DIR_RETAIL,
	IMG_GAME_ACTOR_BUHEAD_RETAIL,
	IMG_GAME_ACTOR_BU_RETAIL,
	// Loans & Mortagages Team
	IMG_GAME_ACTOR_DIR_LOANS,
	IMG_GAME_ACTOR_BUHEAD_LOANS,
	IMG_GAME_ACTOR_BU_LOANS,
	// Partners
	IMG_GAME_ACTOR_PARTNER_1,
	IMG_GAME_ACTOR_PARTNER_2,
	// Clients
	IMG_GAME_ACTOR_CLIENT_1,
	IMG_GAME_ACTOR_CLIENT_2,

	// Action Images
	IMG_ACTION_RESTRICTED,
	// Strategy
	IMG_PRESENT_CASE,
	IMG_CHANGE_POLICY,
	IMG_ANALYZE_TRENDS,
	IMG_CREATE_ROADMAP,
	IMG_CONSULT_ECOSYSTEM,
	IMG_ALLOCATE_BUDGET,
	IMG_ASSESS_SUPPORT,

	// Capability
	IMG_RUN_PILOT,
	IMG_ORGANIZE_HACKATHON,
	IMG_SHARE_PODCASTS,
	IMG_PITCH_ELEARNING,
	IMG_INVITE_EXPERTS,
	IMG_ONBOARD_PEOPLE,
	IMG_ENROLL_SUPPORTERS,

	// Culture
	IMG_SUSTAIN_INTEREST,
	IMG_IDENTIFY_NETWORKS,
	IMG_BUILD_NETWORKS,
	IMG_RECOGNIZE_CHAMPION,
	IMG_CROWDSOURCE_INPUTS,
	IMG_EXECUTE_CAMPAIGN,
	IMG_PROPOSE_MECHANISM,

	// Presentation Option Images
	BANK_ICON,
	PLATFORM_ICON,
	GROWING_GRAPH_ICON,
	HAPPY_CUST_GRAPH_ICON,
	MANY_HAPPY_CUST_GRAPH_ICON,
	MORE_MONEY_GRAPH_ICON,
	BIGGER_ECO_GRAPH_ICON,
	IMG_SPEAKER_1,
	IMG_SPEAKER_2,
	IMG_SPEAKER_3,
	IMG_SPEAKER_4,
	IMG_SPEAKER_5,
	IMG_SPEAKER_6,

	IMG_INTRO_ROLE,
	IMAGE_CDO,
	IMG_TICK,
	IMG_ADOPTION_GRAPH,
	image_f2f_icon,
	IMG_ACTION_PRESENTION,
	IMG_ACTION_LOBBY,
	IMG_ACTION_INVITESPEAKER,



	IMG_SHARE,
	IMG_NO_SHARE,
	IMG_EVENT_24_1,
	IMG_EVENT_24_2,
	IMG_EVENT_24_3,
	IMG_EVENT_24_4,

	IMG_DEMO,
	IMG_NO_DEMO,

	IMG_SELECT_SLT,
	IMG_SELECT_BU,

	IMG_EVENT_30_1,
	IMG_EVENT_30_2,
	IMG_EVENT_30_3,
	IMG_EVENT_30_4,

	IMG_EVENT_31_1,
	IMG_EVENT_31_2,
	IMG_EVENT_31_3,
	IMG_EVENT_31_4

};

export default images;
