import kfetch from 'util/kfetch';
import urls from 'constants/urls';

export const updateReduxBackend = (type) => (dispatch, getState) => {
    
    let data;
    switch (type) {
        case "ALREADY_SELECTED_ACTORS":{
            let {actors} = getState();
            data = actors.alreadySelectedActors;
            break;
        }
        case "CONVERTED_ACTORS":{
            let {actors} = getState();
            data = actors.convertedActors
            break;
        }
        case "DISABLE_ACTION_ACTOR":{
            let { restrictions } = getState();
            data = restrictions.disabledActionActorObj;
            break;
        }
        default:
            data = {};
            break;
    }
    
    let payload = { type, data };

	kfetch(urls.REDUX_UPDATE, payload, 'POST')
		.then((response) => {
			if(response.success) {
				console.log("Redux backend updated successfully!");
			}
        })
        .catch((error) => {
			if(error.error && error.error.status && error.error.data && error.error.data.message){
				let err = error.error;
				if(err.status == 500 && err.data.message == 'Looks like you have not loggedin to this service yet.'){
					window.location.href = urls.LOG_IN;
				}
			}
		})  
};

export const getUpdatedUserMetrics = () => (dispatch) => {
    kfetch(urls.UPDATE_METRICS)
        .then((response) =>{
            if(response.success){
                const {allUserMetrics, allUserActorMetrics} = response;
                dispatch({
                    type: "UPDATE_METRICS",
                    payload: {
                        allUserMetrics,
                        allUserActorMetrics
                    }
                });
            }
        });
}

