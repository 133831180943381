import React from 'react';
import styles from './unlockIcon.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const UnlockIcon = (props) => {
	let fillColor = '#000000'
	if(props.shade && props.shade == 'light'){
		fillColor = '#FFFFFF'
	}else{
		fillColor = '#7E8E9C'
	}
	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 108 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d)">
                    <circle cx="54" cy="45" r="32" fill="white"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M65.1579 41.8684H64.0921V35.1184C64.0921 29.8208 59.7976 25.5263 54.5 25.5263C49.2024 25.5263 44.9079 29.8208 44.9079 35.1184V41.8684H43.8421C42.2725 41.8684 41 43.1409 41 44.7105V59.6316C41 61.2012 42.2725 62.4737 43.8421 62.4737H65.1579C66.7275 62.4737 68 61.2012 68 59.6316V44.7105C68 43.1409 66.7275 41.8684 65.1579 41.8684ZM51.6579 50.3947C51.6517 49.0139 52.6389 47.8283 53.998 47.5844C55.3572 47.3406 56.695 48.1089 57.1692 49.4058C57.6435 50.7026 57.1169 52.1527 55.9211 52.8432V56.0789C55.9211 56.8637 55.2849 57.5 54.5 57.5C53.7152 57.5 53.079 56.8637 53.079 56.0789V52.8432C52.2023 52.3391 51.6608 51.406 51.6579 50.3947ZM54.5 29.0789C51.1645 29.0789 48.4605 31.7829 48.4605 35.1184V41.1579C48.4605 41.5503 48.7786 41.8684 49.1711 41.8684H59.8289C60.2214 41.8684 60.5395 41.5503 60.5395 41.1579V35.1184C60.5395 31.7829 57.8355 29.0789 54.5 29.0789Z" fill="url(#paint0_linear)"/>
                <path d="M43.8438 36.6052C43.8438 36.6052 44.6117 37.421 45.2648 37.6052C45.7989 37.7559 46.1309 38 46.6859 38C47.2408 38 47.5728 37.7559 48.1069 37.6052C48.76 37.421 49.528 36.6052 49.528 36.6052V41.8684H43.8438V36.6052Z" fill="white"/>
                <path d="M40.6518 36.2538L38.5577 31.8387C38.2361 31.1606 37.3629 30.9653 36.7831 31.4418C36.2129 31.9103 36.2233 32.7864 36.8043 33.2413L40.6518 36.2538Z" fill="#A6B1BB"/>
                <path d="M37.4593 37.8614L32.72 36.6708C31.9922 36.4879 31.2863 37.0378 31.2854 37.7882C31.2846 38.5262 31.9673 39.0752 32.6879 38.9159L37.4593 37.8614Z" fill="#A6B1BB"/>
                <path d="M39.4133 40.6785L34.9185 42.5956C34.2282 42.89 33.9984 43.7548 34.4514 44.3531C34.897 44.9414 35.7727 44.9659 36.2504 44.4033L39.4133 40.6785Z" fill="#A6B1BB"/>
                <defs>
                    <filter id="filter0_d" x="0.832851" y="0.400508" width="106.334" height="106.334" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dy="8.56766"/>
                        <feGaussianBlur stdDeviation="10.5836"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    <linearGradient id="paint0_linear" x1="42.9636" y1="25.5263" x2="68.2116" y2="27.5892" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3F4467"/>
                        <stop offset="1" stop-color="#303555"/>
                    </linearGradient>
                </defs>
            </svg>
		</div>
	);
}

export default applyWrappers(UnlockIcon, styles);