import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import applyWrappers from 'wrappers/ComponentWrapper';

import styles from './simUserProfile.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';

import UserProfile from 'svgComponents/userProfile';
import UpArrow from 'svgComponents/upArrow';
import DownArrow from 'svgComponents/downArrow';
import Logout from 'svgComponents/logout';
import { checkIfPresent } from 'util/functions';

import analyticsUtil from 'util/analyticsUtil';

const userProfileRef = React.createRef();

const SimUserProfile = (props) => {
	const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
	const [isUserProfileOnHover, setIsUserProfileOnHover] = useState(false);

	const dispatch = useDispatch();

	const myProps = useSelector((state) => {
		return state.user;
	});

	useEffect(() => {
		if (isUserProfileOpen) {
			document.addEventListener('mousedown', handleClick, false);
		} else {
			document.removeEventListener('mousedown', handleClick, false);
		}
		return () => {
			document.removeEventListener('mousedown', handleClick, false);
		}
	});

	const handleClick = (e) => {
		if (userProfileRef.current.contains(e.target)) {
			//do nothing
			return;
		}
		setIsUserProfileOpen(false);
	}

	const onClickLogout = () => {
		setIsUserProfileOpen(false);

		analyticsUtil.track('LOGOUT_CLICKED');

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: true,
				overlayComponentType: 'CENTER',
				highlightDesktopHeader: false,
				overlayComponent: 'LOGOUT_POPUP'
			}
		});

		// window.location.href = urls.LOG_OUT;
	}

	const toggleUserProfile = () => {
		setIsUserProfileOpen(!isUserProfileOpen);
	}

	const myStyles = getSkin(props.skinGuide);
	const skin = props.skinGuide.globalProfiles.palette;
	return (
		<div
			styleName="user-profile-container-with-options"
			onMouseEnter={() => { setIsUserProfileOnHover(true); }}
			onMouseLeave={() => { setIsUserProfileOnHover(false); }}
			onClick={() => {
				toggleUserProfile();
			}}
			ref={userProfileRef}
		>
			<div styleName="user-profile-container">
				<div className={css(myStyles.userprofileImageContainer)} styleName="user-profile-image-container">
					<div styleName="user-profile-image">
						<UserProfile
							svgColor={isUserProfileOnHover || isUserProfileOpen ? skin.grayColor5 : skin.white}
						/>
					</div>
				</div>
				<div styleName="user-profile-arrow-container">
					{
						isUserProfileOpen
							? <UpArrow
								svgColor={isUserProfileOnHover || isUserProfileOpen ? skin.grayColor5 : skin.white}
							/>
							: <DownArrow
								svgColor={isUserProfileOnHover || isUserProfileOpen ? skin.grayColor5 : skin.white}
							/>
					}

				</div>
			</div>
			{
				isUserProfileOpen
					? <div className={css(myStyles.userProfileOptionContainer)} styleName="user-profile-option-container">
						<div styleName="user-name-container">
							<div className={css(myStyles.userName)} styleName="user-name">
								{props.getLabel('label_hi')}&nbsp;
								{
									checkIfPresent(myProps.email)
										? myProps.email
										: ''
								}
							</div>
						</div>
						{
							props.user.returnType.toLowerCase() == 'normal'
								? 	<div
										className={css(myStyles.customOption)}
										styleName="each-option"
										onClick={() => { onClickLogout(); }}
									>
										<div styleName="each-option-image">
											<Logout />
										</div>
										{props.getLabel('label_logout')}
									</div>
								: 	<div
										className={css(myStyles.customOption)}
										styleName="each-option"
										onClick={() => { onClickLogout(); }}
									>
										<div styleName="each-option-image">
											<Logout />
										</div>
										{props.getLabel('label_exit')}
									</div>
						}

					</div>
					: null
			}

		</div>
	);
}

export default applyWrappers(SimUserProfile, styles);
