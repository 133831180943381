import actionConstants from "constants/actions";

const initialState = {
    storylineDetails: {},
    storylineInstanceDetails: {}
};

const storyline = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConstants.SET_INIT_DATA:
            return {
                ...state,
                storylineDetails: action.payload.storylineDetails,
				storylineInstanceDetails: action.payload.storylineInstanceDetails,
				storylineDefaults: action.payload.storylineDefaults
            };
        default:
            return state;
    }
};

export default storyline;
