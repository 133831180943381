import { combineReducers } from 'redux';

import user from './user';
import labels from './labels';
import colorProfiles from './colorProfiles';
import images from './images';
import uiState from './uiState';
import storyline from './storyline';
import actions from './actions';
import actors from './actors';
import phases from './phases';
import metrics from './metrics';
import leaderboard from './leaderboard';
import userActionOptions from './userActionOptions';
import userActorMetrics from './userActorMetrics';
import userMetrics from './userMetrics';
import userReports from './userReports';
import tutorial from './tutorial';
import actorInfo from './actorInfo';
import events from './events';
import userEvents from './userEvents';
import userMentorTips from './userMentorTips';
import allMetrics from './allMetrics';
import monthlyReports from './monthlyReports';
import userActiveActionResponse from './userActiveActionResponse'
import restrictions from './restrictions'

const app = combineReducers({
    user,
    labels,
    colorProfiles,
    images,
    uiState,
    storyline,
    actions,
    actors,
    phases,
    metrics,
    leaderboard,
    userActionOptions,
    userActorMetrics,
    userMetrics,
    userReports,
    tutorial,
    actorInfo,
    events,
    userEvents,
    userMentorTips,
    allMetrics,
    monthlyReports,
    userActiveActionResponse,
    restrictions
});

export default app;
