import React from 'react';
import styles from './background.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const Background = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 13 10" fill="none">
				<path d="M12.6851 2.41752L6.68509 0.0345191C6.56605 -0.0115064 6.43413 -0.0115064 6.31509 0.0345191L0.315089 2.41752C0.220614 2.45512 0.13985 2.52064 0.0835697 2.60533C0.0272892 2.69002 -0.00183222 2.78985 8.93222e-05 2.89152V6.27152C8.93222e-05 6.47043 0.0791069 6.6612 0.219759 6.80185C0.360411 6.9425 0.551177 7.02152 0.750089 7.02152C0.949002 7.02152 1.13977 6.9425 1.28042 6.80185C1.42107 6.6612 1.50009 6.47043 1.50009 6.27152V3.76552L6.33409 5.47152C6.44153 5.50933 6.55865 5.50933 6.66609 5.47152L12.6661 3.35352C12.7621 3.3197 12.8454 3.2574 12.905 3.17495C12.9647 3.0925 12.9977 2.99383 12.9998 2.8921C13.0018 2.79037 12.9728 2.69044 12.9166 2.60564C12.8604 2.52084 12.7796 2.45521 12.6851 2.41752Z" fill="#A6B1BB" />
				<path d="M9.76329 5.13552L6.50029 6.28752L3.23729 5.13552C3.16186 5.10897 3.08117 5.1009 3.00198 5.11198C2.92279 5.12307 2.84742 5.15298 2.78218 5.19922C2.71694 5.24545 2.66374 5.30666 2.62705 5.3777C2.59035 5.44875 2.57123 5.52756 2.57129 5.60752V7.75252C2.57121 7.85609 2.60331 7.95714 2.66314 8.04169C2.72297 8.12624 2.80758 8.19013 2.90529 8.22452L5.47929 9.13252C6.14013 9.36488 6.86045 9.36488 7.52129 9.13252L10.0953 8.22452C10.193 8.19013 10.2776 8.12624 10.3374 8.04169C10.3973 7.95714 10.4294 7.85609 10.4293 7.75252V5.60652C10.4293 5.52655 10.4102 5.44775 10.3735 5.3767C10.3368 5.30566 10.2836 5.24445 10.2184 5.19822C10.1532 5.15198 10.0778 5.12207 9.9986 5.11098C9.91941 5.0999 9.83871 5.10797 9.76329 5.13452V5.13552Z" fill="#A6B1BB" />
			</svg>
		</div>
	);
}

export default applyWrappers(Background, styles);