import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import styles from './desktopHeader.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';

import applyWrappers from 'wrappers/ComponentWrapper';

import SimLogo from 'components/header/simLogo';
import SimUserProfile from 'components/header/simUserProfile';
import Timer from 'components/header/timer';
import SimObjectives from 'components/header/simObjectives';
import SimLeaderBoard from 'components/header/simLeaderBoard';

const getDesktopHeaderConfig = (path) => {

	if (path.search('/introduction') === 0) {
		return {
			showSimLogo: true,
			showLeaderBoard: false,
			showObjectives: false,
			showTimer: false,
			showUserProfile: true
		}
	} else if (path.search('/game') === 0) {
		return {
			showSimLogo: true,
			showLeaderBoard: true,
			showObjectives: true,
			showTimer: true,
			showUserProfile: true
		}
	} else if (path.search('/completed') === 0) {
		return {
			showSimLogo: true,
			showLeaderBoard: true,
			showObjectives: true,
			showTimer: true,
			showUserProfile: true
		};
	}
	return {
		showSimLogo: true,
		showLeaderBoard: false,
		showObjectives: false,
		showTimer: false,
		showUserProfile: true
	};
}

const DesktopHeader = (props) => {

	const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			user: state.user,
		};
	});

	const groupDetails = myProps.user.groupDetails;
	const myStyles = getSkin(props.skinGuide);
	const location = useLocation();
	const headerConfig = getDesktopHeaderConfig(location.pathname);

	return (
		<div
			styleName="header-container"
			className={css(myStyles.headerContainer)}
			style={{
				zIndex: props.uiState.highlightDesktopHeader ? 52 : 'auto'
			}}
		>
			<div styleName="header-content">
				<div styleName="left-header">
					{headerConfig.showSimLogo ? <SimLogo {...props} /> : null}
				</div>
				<div styleName="middle-header">
					{ 
						myProps.uiState.showOverlay && 
						myProps.uiState.selectedAction && 
						myProps.uiState.isActorSelectionRequired &&
						myProps.uiState.minActorsRequired > 0 &&
							<div className = {css(myStyles.middleHeaderSelectActorsInstruction)}styleName="middle-header-select-actors-instruction">
								{myProps.uiState.actionSelectedActors.length > 0 
									?
									`${props.getLabel('label_action_actor_selected_helper','',
										{
											NUM1 : myProps.uiState.actionSelectedActors.length,
											NUM2: myProps.uiState.minActorsRequired,
										})
									}`
									:
									`${props.getLabel('label_action_actor_select_info','',
										{
											NUM1 : myProps.uiState.minActorsRequired,
										})
									}`
								}
							</div>
							
					}

				</div>
				<div styleName="right-header">
					{headerConfig.showTimer ? <Timer /> : null}
					{(headerConfig.showLeaderBoard && groupDetails.isLeaderboardEnabled) ? <SimLeaderBoard {...props} /> : null}
					{headerConfig.showObjectives ? <SimObjectives {...props} /> : null}
					{headerConfig.showUserProfile ? <SimUserProfile {...props} /> : null}
				</div>
			</div>
		</div>
	);
}

export default applyWrappers(DesktopHeader, styles);