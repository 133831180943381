import React, { useState, useEffect } from 'react';
import styles from './leaderboard.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLeaderboardData } from 'actions/apiActions/leaderboard';
import Close from 'svgComponents/close';
import Refresh from 'svgComponents/refresh';
import LeaderboardSvg from 'svgComponents/leaderboardSvg';
import ActorMetric from 'commonComponents/actorMetric';


import analyticsUtil from 'util/analyticsUtil';

const Leaderboard = (props) => {

	// const myProps = useSelector(state => state.leaderboard);
	const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			userActorMetrics: state.userActorMetrics,
			metrics: state.metrics,
			leaderboard: state.leaderboard
		};
	});
	const dispatch = useDispatch();

	const [loadingState, setLoadingState] = useState(true);
	const [apiCalled, setApiCalled] = useState(false);

	const sccMetrics = ["strategy", "capability", "culture"];

	useEffect(() => {
		if (loadingState && !apiCalled) {
			setApiCalled(true);
			dispatch(fetchLeaderboardData(showData));
		}
	}, [loadingState, apiCalled]);

	const showData = () => {
		setLoadingState(false);
	}

	const refreshLeaderboard = () => {
		analyticsUtil.track('LEADERBOARD_POPUP_REFRESHED', {});
		setApiCalled(false);
		setLoadingState(true);
	}

	const onLeaderBoardPopupClick = (e) => {
		e.stopPropagation();
	}

	const closePopup = () => {
		analyticsUtil.track('LEADERBOARD_POPUP_CLOSED', {});
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
		});
	}

	const getLoadingState = () => {
		return (
			<div styleName="loading-container">
				{props.getLabel('label_loading')}
			</div>
		);
	}

	const renderUserStatus = (isGameStarted, isGameCompleted) => {
		// TODO: Get the status from labels
		switch (true) {
			case isGameStarted === false && isGameCompleted === false:
				return (
					<div className={css(myStyles.userStatus, myStyles.userStatusRed)} styleName="user-status">
						{props.getLabel('label_leaderboard_user_not_started')}
					</div>
				);

			case isGameStarted === true && isGameCompleted === false:
				return (
					<div className={css(myStyles.userStatus, myStyles.userStatusYellow)} styleName="user-status">
						{props.getLabel('label_leaderboard_user_in_progress')}
					</div>
				);

			case isGameStarted === true && isGameCompleted === true:
				return (
					<div className={css(myStyles.userStatus, myStyles.userStatusGreen)} styleName="user-status">
						{props.getLabel('label_leaderboard_user_completed')}
					</div>
				);

			default:
				return (
					<div className={css(myStyles.userStatus, myStyles.userStatusRed)} styleName="user-status">
						{props.getLabel('label_leaderboard_user_not_started')}
					</div>
				);
		}
	}

	const renderSccMetrics = (eachUserMetrics) => {
		const metricDivs = sccMetrics.map((eachMetric, index) => {

			const currentMetricId = myProps.userActorMetrics.actorMetricIds[eachMetric];
			const metricDetails = myProps.metrics.metricsObj[currentMetricId];

			const userMetricDetails = eachUserMetrics[eachMetric];

			const borderValue = (sccMetrics.length > 1) && (index !== sccMetrics.length - 1) ? true : false;
			return (
				<ActorMetric
					highlight={false}
					actorId={1}
					metricId={1}
					borderValue={borderValue}
					value={userMetricDetails.value ? userMetricDetails.value.toFixed(1) : userMetricDetails.value}
					diff={0}
					name={metricDetails.name}
					popup={true}
				/>
			);
		});
		return metricDivs;
	}

	const getLeaderboard = () => {
		const users = myProps.leaderboard.leaderboardList.map((eachUser, index) => {
			const eachUserMetrics = eachUser.metrics;
			const adopters = eachUserMetrics.adopters.value;
			return (
				<div key={`leaderboard-user-${index}`} className={eachUser.isSelf ? css(myStyles.userContainerSelf) : css(myStyles.userContainer)} styleName="leaderboard-user">
					<div className={css(myStyles.userRank, myStyles.highlight)} styleName="rank">{eachUser.rank}</div>
					<div className={css(myStyles.highlight)} styleName="user-name">
						<div className={css(myStyles.userName)}>
							{
								eachUser.isSelf
									? props.getLabel('label_you')
									: eachUser.userName
										? eachUser.userName
										: eachUser.email
							}
						</div>
						<div>{renderUserStatus(eachUser.isGameStarted, eachUser.isGameCompleted)}</div>
						{
							eachUser.isSelf
								? <div styleName="you-arrow" className={css(myStyles.youArrow)}></div>
								: null
						}
					</div>
					<div className={css(myStyles.userText)} styleName="metrics">{eachUser.ecosystemAdoption}%</div>
					{/* <div className={css(myStyles.userText)} styleName="metrics">{adopters}</div> */}
					<div className={css(myStyles.userText)} styleName="metric-scores">
						<div styleName="metric-score-box">
							{renderSccMetrics(eachUserMetrics)}
						</div>
					</div>
					<div className={css(myStyles.userText)} styleName="day">
						{eachUser.remainingDay}
					</div>
				</div>
			);
		});
		return (
			<div styleName="leaderboard-users-container">
				<div className={css(myStyles.headerContainer)} styleName="leaderboard-header">
					<div className={css(myStyles.headerText, myStyles.highlight)} styleName="rank">{props.getLabel('label_rank')}</div>
					<div className={css(myStyles.headerText, myStyles.highlight)} styleName="user-name">{props.getLabel('label_username')}</div>
					<div className={css(myStyles.headerText)} styleName="metrics">{props.getLabel('label_ecosystem_adoption')}</div>
					{/* <div className={css(myStyles.headerText)} styleName="metrics">No. of Adoptions</div> */}
					<div className={css(myStyles.headerText)} styleName="metric-scores">{props.getLabel('label_scores')}</div>
					<div className={css(myStyles.headerText)} styleName="day">{props.getLabel('label_days_remaining')}</div>
				</div>
				<div styleName="users-container">
					{users}
				</div>
			</div>
		);
	}

	const myStyles = getSkin(props.skinGuide);
	return (
		<div className={css(myStyles.leaderboardContainer)} styleName="leaderboard-container" onClick={onLeaderBoardPopupClick}>
			<div styleName="header-container">
	
				<div className={css(myStyles.headerContent)} styleName="header-content">
				<div styleName= "leaderboard-svg">
					<LeaderboardSvg/>
				</div>
					{props.getLabel('label_leaderboard')}
				</div>
				<div styleName="refresh-container" onClick={refreshLeaderboard}>
					<div styleName="refresh-image">
						<Refresh />
					</div>
					<div className={css(myStyles.refreshText)} styleName="refresh-text">{props.getLabel('label_refresh')}</div>
				</div>
				<div className={css(myStyles.headerClose)} styleName="header-close" onClick={closePopup}>
					<div styleName="close-image">
						<Close />
					</div>
				</div>
			</div>
			<div className={css(myStyles.headerLine)} styleName="header-line"></div>
			<div styleName="content-container">
				{
					loadingState
						? getLoadingState()
						: getLeaderboard()
				}
			</div>
		</div>
	);
}

export default applyWrappers(Leaderboard, styles);
