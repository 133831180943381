import React, { useState, useEffect } from 'react';
import styles from './typingText.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

import {checkIfPresent} from 'util/functions';

const TypingText = (props) => {

	const myStyles = getSkin(props.skinGuide);

	const [displayMessage, setDisplayMessage] = useState('');
	const maxTime = checkIfPresent(props.maxTime) ? props.maxTime : 3000;

	useEffect(() => {
		const chars = props.message.trim().split('');
		const typeSpeed = Math.round(maxTime / chars.length);
		let str = ''
		chars.map((eachChar, i) => {
			setTimeout(() => {
				str += eachChar;
				setDisplayMessage(str);
			}, i*typeSpeed);
		})
	}, [props.message])

	return (
		<React.Fragment>
			{displayMessage}
		</React.Fragment>
	);
}

export default applyWrappers(TypingText, styles);