import React, { useState } from 'react';
import styles from './actionActor.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import EmptyActor from 'svgComponents/emptyActor';
import Deselect from 'svgComponents/deselect';

const ActionActor = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const [hoverState, setHoverState] = useState(false);

	const onDeselectActor = () => {
		props.onDeselect(props.id);
	}

	if (props.isEmptyActor) {
		return (
			<div className={css(myStyles.eachActorContainer)} styleName="each-actor-container">
				<EmptyActor />
			</div>
		);
	}
	return (
		<div
			className={css(myStyles.eachActorContainerSelected)}
			styleName="each-actor-container"
			onMouseEnter={() => { setHoverState(true) }}
			onMouseLeave={() => { setHoverState(false) }}
			key={props.id}
		>
			<img src={props.getImage(props.actorDetails.image)} width="100%" height="100%" styleName="actor-image" />
			{
				hoverState
					? <div styleName="deselect" onClick={onDeselectActor}>
						<Deselect />
					</div>
					: null
			}
		</div>
	);
}

export default applyWrappers(ActionActor, styles);