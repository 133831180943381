import React, { useState, useEffect } from 'react';
import styles from './impactActor.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { checkIfPresent, removeElementFromArray } from 'util/functions';
import MetricUp from 'svgComponents/metricUp';
import MetricDown from 'svgComponents/metricDown';

const ImpactActor = (props) => {

	const myStyles = getSkin(props.skinGuide);

	const myProps = useSelector(state => {
		return {
			userActionOptions: state.userActionOptions,
			uiState: state.uiState,
			actions: state.actions,
			actors: state.actors,
			tutorial: state.tutorial,
			user: state.user
		};
	});

	const renderActors = (props) => {
		// const actorDivs = userActorMetrics.map((eachActionMetric) => {
		// 	if (checkIfPresent(eachActionMetric.actorId)) {
		// 		const actorDetails = myProps.actors.actorsList.find(eachActor => eachActor.id === eachActionMetric.actorId);
		// 		return (
		// 			<div styleName="each-actor-metric">
		// 				<div styleName="each-actor-metric-image">
		// 					<div styleName="each-actor-change-image">
		// 						{
		// 							eachActionMetric.diff > 0
		// 								? <MetricUp />
		// 								: eachActionMetric.diff < 0
		// 									? <MetricDown />
		// 									: null
		// 						}
		// 					</div>
		// 					<img src={props.getImage(actorDetails.image)} styleName="history-actor" />
		// 				</div>
		// 				<div className={css(myStyles.actorName)} styleName="each-actor-metric-name">{props.getLabel(actorDetails.name)} |</div>
		// 				<div className={css(myStyles.actorMetricChange)} styleName="each-actor-metric-change">
		// 					{
		// 						eachActionMetric.diff > 0
		// 							? `+${eachActionMetric.diff}`
		// 							: eachActionMetric.diff < 0
		// 								? `${eachActionMetric.diff}`
		// 								: '0'
		// 					}
		// 				</div>
		// 			</div>
		// 		);
		// 	}
        // });
        
        const actorDetails = myProps.actors.actorsList.find(eachActor => eachActor.id === props.id);
		return(
            <div styleName="actor-metric">
                <div styleName="actor-metric-image">
                    <div styleName="actor-change-image">
                        {
                            props.impact > 0
                                ? <MetricUp />
                                : props.impact < 0
                                    ? <MetricDown />
                                    : null
                        }
                    </div>
		 			<img src={props.getImage(actorDetails.image)} styleName="impacted-actor" />
		 		</div>
                 <div styleName="actor-metric-name">{props.getLabel(actorDetails.name)} |</div>
		 				<div styleName="actor-metric-change">  
		 					{
		 						props.impact > 0
		 							? `+${props.impact}`
		 							: props.impact < 0
		 								? `${props.impact}`
		 								: '0'
		 					}
		 				</div>
            </div>
        );
	}

	return renderActors(props);
}

export default applyWrappers(ImpactActor, styles);