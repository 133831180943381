import actionConstants from "constants/actions";

const initialState = {
	userActionOptionList: [],
	selectedActors: {}
};

const userActionOptions = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConstants.SET_INIT_DATA:{
			let action_list = action.payload.userActionOptions;
			let selectedActors = action.payload.reduxData["ACTION_SELECTED_ACTORS"] || state.selectedActors;
			for(let i=0;i<action_list.length;i++){
				action_list[i].index = i;
			}
			return {
				...state,
				userActionOptionList: action_list,
				selectedActors
			};
		}
			
		case 'ACTION_RESPONSE_POPUP_CLOSE':{
			const list = state.userActionOptionList;
			const selectedActors = state.selectedActors;
			const userActionOption = action.payload.userActionOption;
			userActionOption.index = list.length;
			selectedActors[list.length] = userActionOption.actorIds;
			list.push(userActionOption);
			return {
				...state,
				userActionOptionList: list,
				selectedActors
			};
		}
		case 'SET_PENDING_ACTION_DATA':{
			const list_length = state.userActionOptionList.length;
			action.payload['index'] = list_length;
			return {
				...state,
				userActionOptionList: [...state.userActionOptionList, action.payload],
				selectedActors: state.selectedActors
			};
		}
		default:
			return state;
	}
};

export default userActionOptions;