import React from 'react';
import styles from './bulletPoint.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const BulletPoint = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="16" height="15" viewBox="0 0 16 15" fill="none">
				<circle cx="9" cy="8" r="6" fill="#FDAD28" />
				<circle cx="6.5" cy="7.5" r="6" stroke="#14324B" />
			</svg>
		</div>
	);
}

export default applyWrappers(BulletPoint, styles);