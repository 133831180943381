import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		headerContainer: {
			backgroundColor: skin.secondaryColor
		},
		headerText: {
			...myTypography.body1,
			color: skin.white,
			fontWeight: 600
		},
		sendingContainer: {
			boxShadow: `1px -4px 10px ${hexToRgbA(skin.black, 0.2)}`,
			...myTypography.body1,
			color: skin.grayColor1
		},
		headerText: {
			...myTypography.body1,
			fontWeight: 600,
			color: skin.white,
		},
		sendingTextBox: {
			borderRadius: '10px',
			border: `1px solid ${skin.grayColor3}`,
			...myTypography.body1,
			color: skin.grayColor1
		},
		sendText: {
			...myTypography.body1,
			color: skin.white,
			backgroundColor: skin.primaryColor,
			borderRadius: '4px',
			cursor: 'pointer'
		},
		sendDisabled: {
			...myTypography.body1,
			color: skin.white,
			backgroundColor: skin.primaryColor,
			borderRadius: '4px',
			cursor: 'not-allowed',
			opacity: 0.7
		},
		leftArrow: {
			borderRight: `10px solid ${skin.grayColor5}`,
			borderBottom: `10px solid transparent`
		},
		leftMessage: {
			...myTypography.body2,
			color: skin.grayColor1,
			backgroundColor: skin.grayColor5,
			boxShadow: `0px 0px 10px ${hexToRgbA(skin.black, 0.3)}`
		},
		rightArrow: {
			borderLeft: `10px solid ${skin.yellow}`,
			borderBottom: `10px solid transparent`
		},
		rightMessage: {
			...myTypography.body2,
			color: skin.grayColor1,
			backgroundColor: hexToRgbA(skin.yellow, 0.7),
			boxShadow: `0px 0px 10px ${hexToRgbA(skin.black, 0.4)}`
		}
	});
}

export default getSkin;