import environment from 'util/environment';

const host = environment.REACT_APP_BACKEND_URL;

const urls = {
    GAME_API: `${host}/game`,
    LEADERBOARD_API: `${host}/leaderboard`,
    ACTION_API: `${host}/{ACTION_URL}`,
    GAME_STARTED_API: `${host}/game-started`,
    GAME_COMPLETED_API: `${host}/game-completed`,
	GAME_UPDATE_TIMER: `${host}/update-timer`,
    LOG_OUT: `${host}/logout`,
    REDUX_UPDATE: `${host}/redux-update`,
    UPDATE_METRICS: `${host}/get-updated-metrics`,
    LOG_IN: `${host}/login`,

    //Report APIs

    GET_USER_REPORT_DATA: `${host}/get-user-report-data`,
    GET_USER_REPORT_WEB: `${host}/get-user-report-web`,
    GET_USER_REPORT_PDF: `${host}/get-user-report-pdf`,
    MAIL_USER_REPORT_PDF: `${host}/mail-user-report-pdf`,
};

export default urls;
