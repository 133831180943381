import React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import DesktopDashboardContainer from './../containers/DesktopDashboardContainer';


const appRoutes = (
    <React.Fragment>
        <Route path="/" component={DesktopDashboardContainer} />
    </React.Fragment>
);

const AppRouter = () => <HashRouter>{appRoutes}</HashRouter>;

export default AppRouter;
