import actionConsts from 'constants/actions';

const initialState = {
	actionsList: [],
	actionOptionsList: [],
	initiatives: []
};

const actions = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.SET_INIT_DATA:
			return {
				...state,
				actionsList: action.payload.actions,
				actionOptionsList: action.payload.actionOptions,
				initiatives: action.payload.initiatives
			};
		default:
			return state;
	}
};

export default actions;