const monthlyReportConfig = [
	{
		storylineId: [1],
		reportConfig: {
			progressReport: {
				isMetricDependent: true,
				metricKey: 'AVERAGE_ADOPTION',
				metricConditions: [
					{
						month: 1,
						conditions: [
							{
								min: -9999,
								max: 15,
								value: 'low'
							},
							{
								min: 15,
								max: 20,
								value: 'medium'
							},
							{
								min: 20,
								max: 9999,
								value: 'high'
							}
						] 
					},
					{
						month: 2,
						conditions: [
							{
								min: -9999,
								max: 25,
								value: 'low'
							},
							{
								min: 25,
								max: 35,
								value: 'medium'
							},
							{
								min: 35,
								max: 9999,
								value: 'high'
							}
						] 
					},
					{
						month: 3,
						conditions: [
							{
								min: -9999,
								max: 50,
								value: 'low'
							},
							{
								min: 50,
								max: 60,
								value: 'medium'
							},
							{
								min: 60,
								max: 9999,
								value: 'high'
							}
						] 
					}
				],
				messageDetails: {
					high: [
						{
							month: 1,
							text: 'label_monthly_report_month_1_mentor_high'
						},
						{
							month: 2,
							text: 'label_monthly_report_month_2_mentor_high'
						},
						{
							month: 3,
							text: 'label_monthly_report_month_3_mentor_high'
						}
					],
					medium: [
						{
							month: 1,
							text: 'label_monthly_report_month_1_mentor_medium'
						},
						{
							month: 2,
							text: 'label_monthly_report_month_2_mentor_medium'
						},
						{
							month: 3,
							text: 'label_monthly_report_month_3_mentor_medium'
						}
					],
					low: [
						{
							month: 1,
							text: 'label_monthly_report_month_1_mentor_low'
						},
						{
							month: 2,
							text: 'label_monthly_report_month_2_mentor_low'
						},
						{
							month: 3,
							text: 'label_monthly_report_month_3_mentor_low'
						}
					]
				}
			},
			ecoSystemTrend: {
				isMetricDependent: false,
				staticText: 'label_monthly_report_ecoAdoption_graph_text'
			},
			sccTrend: {
				isMetricDependent: false,
				staticText: 'label_monthly_report_sccTrend_graph_text'
			},
		}

	}
];

const getMonthlyReportConfig = (storylineId) => {
	return monthlyReportConfig.find((eachConfig) => {
		return eachConfig.storylineId.indexOf(storylineId) > -1;
	});
}

export default getMonthlyReportConfig;