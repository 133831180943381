import React from 'react';
import styles from './emptyHistory.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const EmptyHistory = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 60 60" fill="none">
				<path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M0 30C0 13.4315 13.4315 0 30 0C46.5611 0.0179121 59.9821 13.4389 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30ZM39.4675 43.2077C40.4936 44.1186 42.0576 44.05 43 43.0527V43.0552C43.4475 42.5648 43.6815 41.9166 43.6506 41.2535C43.6196 40.5904 43.3262 39.9668 42.835 39.5202L31.25 28.9002V16.2502C31.25 14.8695 30.1307 13.7502 28.75 13.7502C27.3693 13.7502 26.25 14.8695 26.25 16.2502V30.0002C26.2525 30.7023 26.5501 31.3709 27.07 31.8427L39.4675 43.2077Z" fill="#C2CAD0" />
			</svg>
		</div>
	);
}

export default applyWrappers(EmptyHistory, styles);