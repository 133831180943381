import actionConstants from "constants/actions";

const initialState = {
	tipsList: [],
	newTipsForPopup: 0,
	newTipsCount: 0
};

const userMentorTips = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConstants.SET_INIT_DATA:
			return {
				tipsList: action.payload.userMentorTips,
				newTipsCount: 0
			};
		case 'ADD_NEW_MENTOR_TIP':
			const tips = state.tipsList;
			tips.push({
				...action.payload,
				mentorTipId: action.payload.id
			})
			return {
				tipsList: tips,
				newTipsCount: state.newTipsCount + 1
			}
		case 'CLEAR_TIPS_COUNT':
			return {
				...state,
				newTipsForPopup: state.newTipsCount,
				newTipsCount: 0
			}
		case 'CLEAR_TIPS_COUNT_POPUP':
			return {
				...state,
				newTipsForPopup: 0
			}
		default:
			return state;
	}
};

export default userMentorTips;