import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		optionContainer: {
			cursor: 'pointer',
			':hover': {
				backgroundColor: skin.grayColor5
			}
		},
		name: {
			...myTypography.body1,
			color: skin.grayColor1
		},
		selectedName: {
			...myTypography.body1,
			color: skin.grayColor1,
			fontWeight: 600
		},
		description: {
			...myTypography.body2,
			color: skin.grayColor2
		},
		filterCircle: {
			border: `1px solid #7E8E9C`,
			':hover': {
				border: `1px solid #3ABDFF`
			}
		},
		filterSelectedCircle: {
			border: `4px solid #3ABDFF`,
		},
		selectedCircle: {
			backgroundColor: skin.white
		},
		ddbOptionsContainer: {
			backgroundColor: skin.white,
			borderRadius: '5px',
			boxShadow: `0px 0px 6px ${hexToRgbA(skin.black, 0.1)}`,
			border: `0.5px solid ${skin.grayColor4}`
		},
		ddbOptionContainer: {
			borderRadius: '5px',
			border: `2px solid ${skin.grayColor4}`,
			backgroundColor: skin.grayColor6,
			':hover': {
				border: `2px solid ${skin.blue}`,
			}
		},
		ddbOptionContainerSelected: {
			borderRadius: '5px',
			border: `2px solid ${skin.blue}`,
			backgroundColor: skin.grayColor6
		},
		selectionBox: {
			borderRadius: '4px',
			border: `1px solid ${skin.grayColor4}`
		},
		ddbOptionBoxText: {
			...myTypography.body1,
			color: skin.grayColor1
		}
	});
}

export default getSkin;
