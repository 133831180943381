import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		initiativeValue: {
			...myTypography.number,
			fontStyle: 'normal',
			fontWeight: 600,
			fontSize: '16px',
			'@media (min-width: 1440px)' : {
				fontSize: '18px',
			},
			'@media (min-width: 1600px)' : {
				fontSize: '20px',
			},
			'@media (min-width: 1920px)' : {
				fontSize: '24px',
			},
			'@media (min-width: 2560px)' : {
				fontSize: '32px',
			},
		},
		headerTitle: {
			...myTypography.body2,
			color: skin.grayColor2,
			fontWeight: 600,
		},
		actionName: {
			...myTypography.body1,
			color: skin.grayColor1,
			fontWeight: 600,
		},
		delayDaysStatic: {
			...myTypography.body2,
			color: skin.grayColor1,
			fontWeight: 600,
		},
		delayDaysDynamic: {
			...myTypography.body2,
			color: skin.grayColor1,
			fontSize: '10px',
			'@media (min-width: 1440px)' : {
				fontSize: '11.25px',
			},
			'@media (min-width: 1600px)' : {
				fontSize: '12.5px',
			},
			'@media (min-width: 1920px)' : {
				fontSize: '15px',
			},
			'@media (min-width: 2560px)' : {
				fontSize: '20px',
			},
		},
	});
}

export default getSkin;