import React, { useState, useEffect, useRef } from 'react';
import styles from './timer.module.sass';
import { useSelector, useDispatch } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { updateTimer } from 'actions/apiActions/updateTimer';
import { checkIfPresent, removeActorPopupPayload } from 'util/functions';
import { gameCompleted } from 'actions/apiActions/gameCompleted';

import analyticsUtil from 'util/analyticsUtil';

let timer = null;
const TIMER_INTERVAL = 10;

const Timer = (props) => {

	const dispatch = useDispatch();
	const defaultTime = 3600;

	const myProps = useSelector(state => {
		return {
			user: state.user,
			monthlyReports: state.monthlyReports,
			uiState: state.uiState
		}
	});

	const [timerInitialized, setTimerInitialized] = useState(false);
	const [fetchingTimer, setFetchingTimer] = useState(true);
	const [endTimerPopupShown, setEndTimerPopupShown] = useState(false);
	const [timeReminderPopupShown, setTimeReminderPopupShown] = useState(false);

	let totalTime = checkIfPresent(myProps.user.groupDetails.timer)
		? myProps.user.groupDetails.timer
		: defaultTime;

	let userTime = myProps.user.isGameCompleted
		? 0
		: myProps.user.isGameStarted
			? myProps.user.timerValue
			: totalTime;

	const myStyles = getSkin(props.skinGuide);

	const [localTime, setLocalTime] = useState(userTime);
	const [stopTimer, setStopTimer] = useState(false);

	const localTimeRef = useRef(localTime);
	localTimeRef.current = localTime;

	const stopTimerRef = useRef(stopTimer);
	stopTimerRef.current = stopTimer;

	const daysCompleted = () => {
		if(myProps.user.currentDay === myProps.user.groupDetails.days){
			return true
		}
		else{
			return false
		}
	}

	useEffect(() => {
		//This useEffect set isTimerCompleted to be true when someone reloads sim after timer is 0
		if (myProps.user.groupDetails.isTimerEnabled && myProps.user.timerValue == 0 && !myProps.user.isTimerCompleted ) {
			dispatch({
				type: 'UPDATE_USER',
				payload: {
					isTimerCompleted: true
				}
			});
		}
	});

	useEffect(() => {
		if (!myProps.user.isGameCompleted && !daysCompleted() && myProps.user.isGameStarted) {
			if (myProps.user.groupDetails.isTimerEnabled && !myProps.user.runTimer) {
				dispatch({
					type: 'UPDATE_USER',
					payload: {
						runTimer: true
					}
				});
			}
		}
		else if ((myProps.user.isGameCompleted || daysCompleted()) && stopTimer === false) {
			setStopTimer(true);
		}
	});

	useEffect(() => {
		if (myProps.user.groupDetails.isTimerEnabled) {
			setFetchingTimer(false);
			if (myProps.user.runTimer && !myProps.user.isGameCompleted && !timerInitialized) {
				setTimerInitialized(true);
				progressUserTimer();
			}
		}
	});

	useEffect(() => {
		// user.timerValue != 0 means when sim was loaded/reloaded timer wasn't already 0;
		if(
			myProps.user.groupDetails.isTimerEnabled
			&& (myProps.user.timerValue != 0)
			&& !myProps.monthlyReports.isAnyEventPresent 
			&& !myProps.monthlyReports.isAnyActionPresent 
			&& !endTimerPopupShown
			&& !daysCompleted()
			&& localTimeRef.current < 1){

				openTimeCompletedPopup();
				dispatch({
					type: 'UPDATE_USER',
					payload: {
						isTimerCompleted: true
					}
				});
				dispatch(gameCompleted({}));
				setEndTimerPopupShown(true);
		}
	})

	useEffect(() => {
		//This is to show the HurryUpPopup 
		if(
			myProps.user.groupDetails.isTimerEnabled
			&& !(myProps.user.timerValue < 600)
			&& !myProps.monthlyReports.isAnyEventPresent 
			&& !myProps.monthlyReports.isAnyActionPresent 
			&& !timeReminderPopupShown
			&& !daysCompleted()
			&& localTimeRef.current <= 600){

				openTimeReminderPopup();
				setTimeReminderPopupShown(true);
		}
	})

	const openTimeCompletedPopup = () => {
		analyticsUtil.track('TIME_COMPLETED');
		// close any remaining actions
		dispatch(removeActorPopupPayload());

		// now, show timer completed popup
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: true,
				overlayComponentType: 'CENTER',
				highlightDesktopHeader: false,
				overlayComponent: 'TIME_COMPLETED_POPUP'
			}
		});
	}

	const openTimeReminderPopup = () => {
		analyticsUtil.track('TIME_REMINDER');
	
		dispatch(removeActorPopupPayload());

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: true,
				overlayComponentType: 'CENTER',
				highlightDesktopHeader: false,
				overlayComponent: 'TIME_REMINDER_POPUP'
			}
		});
	}

	// const updateTimerCompletedInStore = () => {
		// openTimeCompletedPopup();
		// dispatch({
		// 	type: 'UPDATE_USER',
		// 	payload: {
		// 		isTimerCompleted: true
		// 	}
		// });
		// dispatch(gameCompleted({}));
	// }

	const updateTimerInDB = (payload) => {
		console.log("Call the DB update route");
		dispatch(updateTimer(payload));
	}

	const buildTimeLabel = (time) => {
		let minutes = Math.floor(time / 60);
		let seconds = time % 60;
		if (minutes < 10) {
			minutes = (`0${minutes}`).slice(-2);
		}
		seconds = (`0${seconds}`).slice(-2);
		return `${minutes}:${seconds}`;
	}

	const timeoutFunction = () => {
		if (localTimeRef.current >= 1) {
			setLocalTime(localTimeRef.current - 1);
			progressUserTimer();
			// postUserDataOnDataManager();
			if (localTimeRef.current % TIMER_INTERVAL === 0) {
				updateTimerInDB({ timerValue: localTimeRef.current });
			}
		}
		else {
			//Timer expired state
			console.log("Timer expired");
			if (!myProps.user.isGameCompleted && !myProps.user.isTimerCompleted) {
				updateTimerInDB({ timerValue: 0 });
				// updateTimerCompletedInStore();
			}
			if (!stopTimer) {
				setStopTimer(true);
			}
		}
	}

	const progressUserTimer = () => {
		if (!stopTimerRef.current) {
			timer = setTimeout(timeoutFunction.bind(null, localTimeRef.current), 1000);
		}
	}

	if (!myProps.user.groupDetails.isTimerEnabled) {
		return null;
	}

	const timeRunningout = <>
							<div styleName= "time-reminder"></div>
							</>


	return (
		<div styleName="timer-container" className={css(myStyles.timerContainer)}>
			<div>
				{
					(localTimeRef.current > 0) ?
					<div styleName="timer-text" className={css(myStyles.timerText)}>{props.getLabel('label_time_left')}</div> : <br/>
				}
			</div>
			<div className={css(myStyles.timerContent)} styleName="timer-content">
				{
					stopTimer
						? <span className={css(myStyles.timerContentTimeup)}>{props.getLabel('label_time_up')}</span>
						: fetchingTimer
							? "--:--"
							: buildTimeLabel(localTimeRef.current)
				}
			</div>
			<div>
				{(localTimeRef.current <= 600 && localTimeRef.current > 0)? timeRunningout : ""}
			</div>
		</div>
	);
}

export default applyWrappers(Timer, styles);
