import React, { useState, useEffect } from 'react';
import styles from './monthlyReportPopup.module.sass';
import { useSelector, useDispatch } from 'react-redux';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import GraphBox from 'components/graphBox';
import _ from 'lodash';
import getMonthlyReportConfig from 'config/game/monthlyReport';
import {getUpdatedUserMetrics} from 'actions/apiActions/updateReduxBackend'

import analyticsUtil from 'util/analyticsUtil';

const MonthlyReportPopup = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const dispatch = useDispatch();

	const [updateMetrics, setUpdateMetrics] = useState(false);

	if(!updateMetrics){
		dispatch(getUpdatedUserMetrics());
		setUpdateMetrics(true);
	}

	const myProps = useSelector((state) => {
		return {
			user: state.user,
			actors: state.actors,
			uiState: state.uiState,
			userActorMetrics: state.userActorMetrics,
			userMetrics: state.userMetrics,
			allMetrics: state.allMetrics,
			monthlyReports: state.monthlyReports
		};
	});
	const sccMetrics = ["strategy", "capability", "culture"];

	const getGraphWiseData = (day) => {
		const metricWiseData = _.groupBy(myProps.allMetrics.userMetrics, 'metricId');

		let metricObj = {
			"strategy": [],
			"capability": [],
			"culture": [],
			"avgAdoption": []
		};

		const step = 1;
		for (let i = 0; i <= day; i += step) {
			let sum = 0;
			sccMetrics.map((eachScc) => {

				const metricId = myProps.userMetrics.userMetricIds[eachScc];

				const myMetricsData = metricWiseData[metricId]
					.filter((eachMetricWiseData) => {
						return eachMetricWiseData.day <= i;
					})
					.sort((metric1, metric2) => metric2.day - metric1.day);

				if (myMetricsData.length !== 0) {
					sum += myMetricsData[0].value;
					metricObj[eachScc].push({
						x: i,
						y: myMetricsData[0].value.toFixed(1)
					});
				}
			});
			metricObj['avgAdoption'].push({ x: i, y: (sum / sccMetrics.length).toFixed(1) });
		}
		return metricObj;
	}

	const calculateProgressReportText = (config, monthNum, metricObj) => {
		if (config.isMetricDependent) {
			let monthMetricConditions = config.metricConditions.find((eachCondition) => {
				return eachCondition.month === monthNum;
			});

			if (!monthMetricConditions) {
				monthMetricConditions = config.metricConditions[config.metricConditions.length - 1];
			}

			const conditionObj = monthMetricConditions.conditions.find((eachMonthCondition) => {
				return eachMonthCondition.min < metricObj.avgAdoption && eachMonthCondition.max >= metricObj.avgAdoption;
			});

			const conditionMessages = config.messageDetails[conditionObj.value];
			return conditionMessages.find((eachMessage) => {
				return eachMessage.month === monthNum;
			});
		}
	}

	const getMonthlyReports = () => {
		let reports = myProps.monthlyReports.monthIntervals.map((eachInterval, index) => {
			if (eachInterval <= myProps.user.currentDay) {
				let obj = {
					month: index + 1,
					id: index + 1,
					monthEndDay: eachInterval,
					data: {}
				}

				const reportConfig = getMonthlyReportConfig(1).reportConfig;
				const metricObjDetails = getGraphWiseData(eachInterval);

				const strategy = metricObjDetails.strategy[metricObjDetails.strategy.length - 1].y;
				const capability = metricObjDetails.capability[metricObjDetails.capability.length - 1].y;
				const culture = metricObjDetails.culture[metricObjDetails.culture.length - 1].y;
				const avgAdoption = metricObjDetails.avgAdoption[metricObjDetails.avgAdoption.length - 1].y;
				const metricObj = {
					strategy,
					capability,
					culture,
					avgAdoption
				}

				const advisorText = calculateProgressReportText(reportConfig.progressReport, index + 1, metricObj).text;
				obj.data.advisorText = advisorText;

				obj.data.sccTrendText = reportConfig.sccTrend.staticText;
				obj.data.ecoSystemTrendText = reportConfig.ecoSystemTrend.staticText;
				return obj;
			}
		}).filter(report => report !== undefined);

		return reports;
	}

	const monthlyReports = getMonthlyReports();

	const getConversionWiseData = (graph2Data, day = myProps.user.currentDay) => {
		const metricWiseData = _.groupBy(myProps.allMetrics.userMetrics, 'metricId');

		let metricObj = {
			"conversion": []
		};

		const step = 1;
		for (let i = 1; i <= day; i += step) {

			const metricId = myProps.userMetrics.userMetricIds['adopters'];

			const myMetricsData = metricWiseData[metricId].filter((eachMetricWiseData) => {
				return eachMetricWiseData.day <= i;
			});

			if (myMetricsData.length !== 0) {
				metricObj['conversion'].push({
					x: i,
					y: myMetricsData[0].value
				});
			}
		}

		const finalObj = [{
			id: 'conversions',
			data: []
		}];

		metricObj.conversion.map((eachConversionData, index) => {
			if (index == 0) {
				if (eachConversionData.y !== 0) {
					finalObj[0].data.push({ ...eachConversionData, diff: 0, value: eachConversionData.y });
				}
			} else {
				const diff = eachConversionData.y - metricObj.conversion[index - 1].y;
				if (diff !== 0) {
					for (let loopIndex = 0; loopIndex < diff; loopIndex++) {
						finalObj[0].data.push({
							x: eachConversionData.x,
							y: +graph2Data[0].data[index + 1].y + ((loopIndex) * 7),
							diff,
							value: eachConversionData.y
						});
					}
				}
			}
		});

		return finalObj;
	}

	const getDayWiseSccMetrics = (day = myProps.user.currentDay) => {
		let graphData = [];
		const metricObj = getGraphWiseData(day);

		graphData = [
			{
				id: 'strategy',
				data: metricObj.strategy
			},
			{
				id: 'capability',
				data: metricObj.capability
			},
			{
				id: 'culture',
				data: metricObj.culture
			}
		];

		return graphData;
	}

	const getDayWiseAvgAdoptionScore = (day = myProps.user.currentDay) => {
		let graphData = [];
		const metricObj = getGraphWiseData(day);

		graphData = [
			{
				id: 'AvgAdoption',
				data: metricObj.avgAdoption
			}
		];

		return graphData;
	}

	const getLastMonth = () => {
		if (monthlyReports.length === 0) {
			return 0;
		}
		return monthlyReports[monthlyReports.length - 1].month;
	}

	const [selectedMonth, setSelectedMonth] = useState(getLastMonth());

	const onMonthlyReportPopupPopupClick = (e) => {
		e.stopPropagation();
	}

	const closePopup = () => {
		analyticsUtil.track('MONTHLY_REPORT_POPUP_CLOSED', { day: myProps.user.currentDay });

		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
		});

		if(myProps.user.currentDay >= myProps.monthlyReports.monthIntervals[myProps.monthlyReports.monthIntervals.length - 1]){
			dispatch({
				type: 'SET_FINAL_MONTH_REPORT_SEEN',
				payload: {}
			})
		}
	}

	const renderTabContent = () => {
		const monthData = monthlyReports.find((eachMonth) => {
			return eachMonth.month === selectedMonth;
		});

		const subHeading = props.getLabel('label_end_of_month_heading', '', {
			NUM: monthData.month
		});

		const graph1Data = getDayWiseSccMetrics(monthData.monthEndDay);

		const graph2Data = getDayWiseAvgAdoptionScore(monthData.monthEndDay);

		const conversionData = getConversionWiseData(graph2Data, monthData.monthEndDay);

		return (
			<div styleName="month-content">
				<div styleName="advisor-box">
					<div className={css(myStyles.advisorContent)} styleName="advisor-content">
						{props.getLabel(monthData.data.advisorText)}
					</div>
					<div className={css(myStyles.advisorShadow)} styleName="advisor-shadow-box"></div>
					<div styleName="advisor-details">
						<div styleName="advisor-image">
							<img src={props.getImage('IMAGE_CDO')} width="100%" height="100%" />
						</div>
						<div className={css(myStyles.advisorName)} styleName="advisor-name">{props.getLabel('label_name_exeAdv')}</div>
						<div className={css(myStyles.advisorDesignation)} styleName="advisor-designation">{props.getLabel('label_designation_exeAdv')}</div>
					</div>
				</div>
				<div styleName="graphs-box">
					<div styleName="each-graph-box">
						<GraphBox
							graphTitle={props.getLabel('label_monthly_report_scc_title')}
							graphType='LINE_CHART'
							graphData={graph1Data}
							subHeadingAvailable={true}
							subHeading={subHeading}
							headerMetricsAvailable={true}
							headerMetricsColorCoded={true}
							showLegend={true}
							colors={['#835AC5', '#3ABDFF', '#FF75D8']}
							headerMetrics={[
								{
									title: props.getLabel('label_strategy_cap'),
									value: (graph1Data[0].data[graph1Data[0].data.length - 1].y),
									postFix: '%',
									color: '#835AC5'
								},
								{
									title: props.getLabel('label_capability'),
									value: (graph1Data[1].data[graph1Data[1].data.length - 1].y),
									postFix: '%',
									color: '#3ABDFF'
								},
								{
									title: props.getLabel('label_culture'),
									value: (graph1Data[2].data[graph1Data[2].data.length - 1].y),
									postFix: '%',
									color: '#FF75D8'
								}
							]}
							textArray={[monthData.data.sccTrendText]}
							legend={{
								xLabel: props.getLabel('label_metric_popup_days'),
								yLabel: props.getLabel('label_metric_popup_adoption')
							}}
						/>
					</div>
					<div styleName="each-graph-box">
						<GraphBox
							graphTitle={props.getLabel('label_monthly_report_ecoAdoption_title')}
							graphType='LINE_CHART_WITH_DOT'
							graphData1={graph2Data}
							graphData2={conversionData}
							subHeadingAvailable={true}
							subHeading={subHeading}
							headerMetricsAvailable={true}
							headerMetricsColorCoded={false}
							showLegend={true}
							headerMetrics={[
								{
									title: props.getLabel('label_metric_popup_adoption'),
									value: (graph2Data[0].data[graph2Data[0].data.length - 1].y),
									postFix: '%',
									color: '#835ac5'
								},
								{
									title: props.getLabel('label_number_of_adopters'),
									value: conversionData[0].data.length > 0 ? (conversionData[0].data[conversionData[0].data.length - 1].value) : 0,
									postFix: '',
									color: 'red',
									legendType: 'point'
								}
							]}
							textArray={[monthData.data.ecoSystemTrendText]}
							legend={{
								xLabel: props.getLabel('label_metric_popup_days'),
								yLabel: props.getLabel('label_metric_popup_adoption')
							}}
						/>
					</div>
				</div>
			</div>
		);
	}

	const renderTabs = () => {
		const tabs = [];
		monthlyReports.map((eachMonth, index) => {
			if (eachMonth.month === selectedMonth) {
				tabs.push(
					<div key={index} styleName="each-tab-container">
						<div className={css(myStyles.selectedTab)} styleName="each-tab">
							{props.getLabel('label_month_heading', '', {
								NUM: eachMonth.month
							})}
						</div>
						<div className={css(myStyles.selectedTabLine)} styleName="tab-line"></div>
					</div>
				);
			} else {
				tabs.push(
					<div key={index} styleName="each-tab-container" onClick={() => { setSelectedMonth(eachMonth.month) }}>
						<div className={css(myStyles.tab)} styleName="each-tab">
							{props.getLabel('label_month_heading', '', {
								NUM: eachMonth.month
							})}
						</div>
					</div>
				);
			}
		});
		return tabs;
	}

	return (
		<div className={css(myStyles.monthlyReportPopupContainer)} styleName="monthlyReportPopup-container" onClick={onMonthlyReportPopupPopupClick}>
			<div styleName="header-container">
				<div className={css(myStyles.headerContent)} styleName="header-content">{props.getLabel('label_monthly_report_heading')}</div>
				<div className={css(myStyles.headerClose)} styleName="header-close" onClick={closePopup}>
					<div styleName="close-image">
						<Close />
					</div>
				</div>
			</div>
			<div styleName="content-container">
				{
					monthlyReports.length === 0
						? <div styleName="empty-container">
							{props.getLabel('label_monthly_reports not_available')}
						</div>
						: <React.Fragment>
							<div className={css(myStyles.tabContainer)} styleName="tab-container">
								{renderTabs()}
							</div>
							{renderTabContent()}
						</React.Fragment>
				}
			</div>
		</div>
	);
}

export default applyWrappers(MonthlyReportPopup, styles);
