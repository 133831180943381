const fontFamilyObj = {
	"titleFamily": "Montserrat",
	"bodyFamily": "Open Sans",
	"timerFamily": "Acherus Militant",
	"numberFamily": "Acherus Militant",
	"bodyCondensedFamily": "Open Sans Condensed"
};

const globalProfiles = {
	"palette": {
		"primaryColor": "#14324B",
		"primaryVariantColor": "#572676",
		"secondaryColor": "#835AC5",
		"secondaryColorLite": "#9281FA",
		"secondaryVariantColor": "#FF1E50",
		"grayColor1": "#14324B",
		"grayColor2": "#7E8E9C",
		"grayColor3": "#A6B1BB",
		"grayColor4": "#C2CAD0",
		"grayColor5": "#D2D8DD",
		"grayColor6": "#E3E6E9",
		"grayColor7": "#F1F3F4",
		"grayColor8": "#F8F9F9",
		"black": "#000000",
		"white": "#FFFFFF",
		"blue": "#42BAFF",
		"yellow": "#FDAD28",
		"red": "#FD5382",
		"green": "#1BE1D4"
	}
};

const entityProfiles = []

const skins = {
	fontFamily: fontFamilyObj,
	globalProfiles: globalProfiles,
	entityProfiles: entityProfiles
};

export default skins;
