import React, { useState, useEffect } from 'react';
import styles from './actorConvertedPopup.module.sass';
import { useSelector , useDispatch } from 'react-redux';
import getSkin from './skin.js';
import _ from 'lodash';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import Confetti from 'svgComponents/confetti';
import Converted from 'svgComponents/converted';
import { removeActorPopupPayload } from 'util/functions';
import ActorConversion from 'sounds/ActorConversion.wav';
import Sound from 'react-sound';

const ActorConvertedPopup = (props) => {

	const onActorConvertedPopupClick = (e) => {
		e.stopPropagation();
    }
    
    const dispatch = useDispatch();
    const myProps = useSelector((state) => {
		return {
			uiState: state.uiState,
			actions: state.actions,
			user: state.user,
			actors: state.actors,
            userActiveActionResponse: state.userActiveActionResponse,
            actorInfo: state.actorInfo,
            userMetrics: state.userMetrics,
			storylineDefaults: state.storyline.storylineDefaults
		};
    });

	const closePopup = () => {
		dispatch({
			type: 'UPDATE_UI_STATE',
			payload: {
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				overlayComponent: ''
			}
        });
        dispatch({
			type: 'RESET_NEW_ACTOR_CONVERTED',
			payload: {}
        });
        
        if(myProps.userActiveActionResponse.pendingActionResponse.length > 0){
            setTimeout(() => {
                dispatch(removeActorPopupPayload());
                dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                        showOverlay: true,
                        overlayComponentType: 'BOTTOM',
                        highlightDesktopHeader: false,
                        overlayComponent: 'PENDING_ACTION_RESPONSE_POPUP'
                    }
                });
            },2000)
        }
        else if(!_.isEmpty(myProps.userActiveActionResponse.event)){
            dispatch({
                type: 'ADD_DETAILS_FOR_MONTHLY_REPORT',
                payload: {
                    isAnyActionPresent: false
                }
            });
            setTimeout(() => {
                		dispatch({
                			type: 'ADD_ACTIVE_EVENT',
                			payload: myProps.userActiveActionResponse.event
                		});
                		dispatch(removeActorPopupPayload());
                		dispatch({
                			type: 'UPDATE_UI_STATE',
                			payload: {
                				showOverlay: true,
                				overlayComponentType: 'BOTTOM',
                				highlightDesktopHeader: false,
                				overlayComponent: 'EVENT_POPUP'
                			}
                		});
            }, 2000);
        }
        else{
            dispatch({
                type: 'ADD_DETAILS_FOR_MONTHLY_REPORT',
                payload: {
                    isAnyActionPresent: false,
                    isAnyEventPresent: false
                }
            });
        }

    }
    
    let playingStatus = 'PLAYING';
	const onFinishedNotification = () => {
		playingStatus = 'STOPPED';
	}

    const myStyles = getSkin(props.skinGuide);
    const maxActorToDisplay = 3; //Fixed as per the design
    const convertedActorsList = myProps.actors.NewActorConverted;
    const convertedDiff = convertedActorsList.length;
    const moreActors = convertedDiff - maxActorToDisplay;
    let actorsToDisplay = []
    if(convertedDiff > maxActorToDisplay){
        for(let i = 0; i < maxActorToDisplay; i++){
            actorsToDisplay.push(convertedActorsList[i]);
        }
    }else{
        actorsToDisplay =  [...convertedActorsList];
    }

    const actorDetails = myProps.actors.actorsList.filter(eachActor => actorsToDisplay.includes(eachActor.id));
    
    const getConvertedActorDivs = () => {
        const actorsDiv = actorDetails.map( (eachActor) => {
            return(
                <div styleName='each-actor-container'>
                    <div styleName='actor-image-container'>
                        <img src={props.getImage(eachActor.image)} styleName='actor'/>
                        <div styleName='actor-tick'><Converted /></div>
                    </div>
                    <div>
                        <div styleName='title-name'> {props.getLabel(eachActor.name)} </div>
                        <div styleName='title-designation'> {props.getLabel(eachActor.designation)} </div>
                    </div>
                </div>
            );
        })

        if (moreActors > 0) {
            actorsDiv.push(
                <div styleName='more-indicator-container'>
                    <div styleName='more-indicator-number'>
                        {'+'} {moreActors}
                    </div>
                    <div styleName='more-indicator-text'>
                        {props.getLabel('label_more_hidden')}
                    </div> 
                </div>
            )
        }
        
        return(
            <div styleName='actor-container'>
               {actorsDiv}
            </div>
        );
    }

	return (
		<div className={css(myStyles.Container)} styleName="container" onClick={onActorConvertedPopupClick}>
            <div styleName="close-container" onClick={closePopup}>
                <Close strokeWidth={1.6} />
            </div>
            
            <div styleName='confetti-container'>
                <Confetti />
            </div>

            {getConvertedActorDivs()} 

            <div styleName='content-container'>
                { convertedDiff > 1 
                    ? 
                        <div styleName='title'>
                            {props.getLabel('label_actor_converted_multiple_title', '', 
                                {
                                    NUM: convertedDiff
                                })
                            }
                        </div>
                    : 
                        <div styleName='title'>
                            {props.getLabel('label_actor_converted_title', '', 
                                {
                                    ACTOR: props.getLabel(actorDetails[0].name)
                                })
                            }
                        </div>
                }

                <div styleName='button' onClick={closePopup}>
                    <div styleName='button-text'>
                        {props.getLabel('label_actor_converted_cta')}
                    </div>
                </div>
            </div>
            <Sound
                url={ActorConversion}
                playStatus={playingStatus}
                onFinishedPlaying={onFinishedNotification}
            />
		</div>
	);
}

export default applyWrappers(ActorConvertedPopup, styles);