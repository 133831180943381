import actionConsts from 'constants/actions';

const initialState = {
	phasesList: []
};

const phases = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.SET_INIT_DATA:
			return {
				...state,
				phasesList: action.payload.phases
			};
		default:
			return state;
	}
};

export default phases;