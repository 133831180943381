import React, { Component } from 'react';
import styles from './leaderboard.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/functions';

const Leaderboard = (props) => {

    const skin = props.skinGuide.globalProfiles.palette
    let color = skin.white;
    if (checkIfPresent(props.svgColor)) {
        color = props.svgColor;
    }
    return (
        <div styleName="main-component">
            <svg width="100%" height="100%" viewBox="0 0 10 10" fill="none">
                <path d="M0.292969 0.721741H1.57623C1.57158 0.579605 1.56898 0.43663 1.56898 0.292969C1.56898 0.131149 1.70021 0 1.86195 0H8.13797C8.29979 0 8.43094 0.131149 8.43094 0.292969C8.43094 0.43663 8.42842 0.579605 8.42377 0.721741H9.70703C9.86885 0.721741 10 0.85289 10 1.01471C10 2.32742 9.65691 3.5659 9.03397 4.50211C8.4182 5.42763 7.59796 5.95848 6.71196 6.01013C6.51108 6.22871 6.29868 6.40694 6.0775 6.54289V7.845H6.56898C7.16316 7.845 7.64648 8.3284 7.64648 8.9225V9.41399H7.66731C7.82913 9.41399 7.96028 9.54521 7.96028 9.70696C7.96028 9.86878 7.82913 9.99993 7.66731 9.99993H2.33269C2.17087 9.99993 2.03972 9.86878 2.03972 9.70696C2.03972 9.54521 2.17087 9.41399 2.33269 9.41399H2.35352V8.9225C2.35352 8.3284 2.83691 7.845 3.43102 7.845H3.9225V6.54289C3.70132 6.40694 3.489 6.22871 3.28812 6.01013C2.40204 5.95848 1.58188 5.42763 0.966034 4.50211C0.343094 3.5659 0 2.32742 0 1.01471C0 0.85289 0.131226 0.721741 0.292969 0.721741ZM8.54614 4.17755C9.05998 3.4053 9.36188 2.3954 9.40788 1.30768H8.39104C8.28537 2.64481 7.9715 3.88069 7.48093 4.86183C7.4028 5.01808 7.32109 5.16564 7.23633 5.30472C7.72362 5.1207 8.17459 4.73602 8.54614 4.17755ZM1.45386 4.17755C1.82541 4.73602 2.27638 5.1207 2.76367 5.30472C2.67883 5.16564 2.5972 5.01808 2.51907 4.86183C2.0285 3.88069 1.71471 2.64481 1.60896 1.30768H0.592117C0.638123 2.3954 0.940018 3.4053 1.45386 4.17755Z" fill="white" />
            </svg>
        </div>
    );
}



export default applyWrappers(Leaderboard, styles);
