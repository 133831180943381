import React from 'react';
import styles from './bulb.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const BulletPoint = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			
					<svg width="38" height="40" viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M15.372 30.0738H25.3613C29.0428 28.2464 31.3499 24.4694 31.295 20.3597C31.1887 14.3687 26.3576 9.53769 20.3667 9.4314C14.3733 9.53779 9.54122 14.3727 9.43838 20.3662C9.38595 24.4736 11.6927 28.2474 15.372 30.0738Z" fill="#FDAD28"/>
						<path d="M23.8469 28.7139V32.3566C23.8469 33.6979 22.7596 34.7852 21.4184 34.7852H16.5613C15.2201 34.7852 14.1328 33.6979 14.1328 32.3566V28.7139" stroke="black" stroke-width="2.04015" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M19 2V4.42851" stroke="black" stroke-width="2.04015" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M2 18.9996H4.42851" stroke="black" stroke-width="2.04015" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M5.63281 6.85693L7.41372 8.56823" stroke="black" stroke-width="2.04015" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M35.9988 18.9996H33.5703" stroke="black" stroke-width="2.04015" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M32.3551 6.85693L30.5742 8.56823" stroke="black" stroke-width="2.04015" stroke-linecap="round" stroke-linejoin="round"/>
						<path fill-rule="evenodd" clip-rule="evenodd" d="M14.0127 28.7137H24.002C27.6835 26.8863 29.9906 23.1093 29.9356 18.9996C29.8293 13.0086 24.9983 8.17758 19.0073 8.07129C13.0139 8.17768 8.18184 13.0126 8.079 19.0061C8.02657 23.1135 10.3333 26.8873 14.0127 28.7137V28.7137Z" stroke="black" stroke-width="2.04015" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M19 34.7848V38.4276" stroke="black" stroke-width="2.04015" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M19 22.6426V28.7139" stroke="black" stroke-width="2.04015" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M15.3555 18.9996L18.9982 22.6424L22.641 18.9996" stroke="black" stroke-width="2.04015" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
		</div>
	);
}

export default applyWrappers(BulletPoint, styles);